import A1Words from "./words/A1Words";
import A2Words from "./words/A2Words";
import B1Words from "./words/B1Words";
import B2Words from "./words/B2Words";
import C1Words from "./words/C1Words";

const getWordLevelColor = (word: string) => {
  if (A1Words.includes(word)) {
    return "green";
  } else if (A2Words.includes(word)) {
    return "#a4a400";
  } else if (B1Words.includes(word)) {
    return "orange";
  } else if (B2Words.includes(word)) {
    return "red";
  } else if (C1Words.includes(word)) {
    return "purple";
  }

  return "black";
};

export default getWordLevelColor;
