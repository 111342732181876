const B1Words = [
  "absolutely",
  "academic",
  "access",
  "accommodation",
  "account",
  "achievement",
  "act",
  "ad",
  "addition",
  "admire",
  "admit",
  "advanced",
  "advise",
  "afford",
  "age",
  "aged",
  "agent",
  "agreement",
  "ahead",
  "aim",
  "alarm",
  "album",
  "alcohol",
  "alcoholic",
  "alternative",
  "amazed",
  "ambition",
  "ambitious",
  "analyse",
  "analysis",
  "announce",
  "announcement",
  "annoy",
  "annoyed",
  "annoying",
  "apart",
  "apologize",
  "application",
  "appointment",
  "appreciate",
  "approximately",
  "arrest",
  "arrival",
  "assignment",
  "assist",
  "atmosphere",
  "attach",
  "attitude",
  "attract",
  "attraction",
  "authority",
  "average",
  "award",
  "aware",
  "backwards",
  "bake",
  "balance",
  "ban",
  "bank (river)",
  "base",
  "basic",
  "basis",
  "battery",
  "battle",
  "beauty",
  "bee",
  "belief",
  "bell",
  "bend",
  "benefit",
  "better",
  "bite",
  "block",
  "board",
  "bomb",
  "border",
  "bother",
  "branch",
  "brand",
  "brave",
  "breath",
  "breathe",
  "breathing",
  "bride",
  "bubble",
  "bury",
  "by",
  "calm",
  "campaign",
  "campus",
  "candidate",
  "cap",
  "captain",
  "careless",
  "category",
  "ceiling",
  "celebration",
  "central",
  "centre",
  "ceremony",
  "chain",
  "challenge",
  "champion",
  "channel",
  "chapter",
  "charge",
  "cheap",
  "cheat",
  "cheerful",
  "chemical",
  "chest",
  "childhood",
  "claim",
  "clause",
  "clear",
  "click",
  "client",
  "climb",
  "close",
  "cloth",
  "clue",
  "coach",
  "coal",
  "coin",
  "collection",
  "coloured",
  "combine",
  "comment",
  "commercial",
  "commit",
  "communication",
  "comparison",
  "competitor",
  "competitive",
  "complaint",
  "complex",
  "concentrate",
  "conclude",
  "conclusion",
  "confident",
  "confirm",
  "confuse",
  "confused",
  "connection",
  "consequence",
  "consist",
  "consume",
  "consumer",
  "contact",
  "container",
  "content",
  "continuous",
  "contrast",
  "convenient",
  "convince",
  "cool",
  "costume",
  "cottage",
  "cotton",
  "count",
  "countryside",
  "court",
  "cover",
  "covered",
  "cream",
  "criminal",
  "cruel",
  "cultural",
  "currency",
  "current",
  "currently",
  "curtain",
  "custom",
  "cut",
  "daily",
  "damage",
  "deal",
  "decade",
  "decorate",
  "deep",
  "define",
  "definite",
  "definition",
  "deliver",
  "departure",
  "despite",
  "destination",
  "determine",
  "determined",
  "development",
  "diagram",
  "diamond",
  "difficulty",
  "direct",
  "directly",
  "dirt",
  "disadvantage",
  "disappointed",
  "disappointing",
  "discount",
  "dislike",
  "divide",
  "documentary",
  "donate",
  "double",
  "doubt",
  "dressed",
  "drop",
  "drum",
  "drunk",
  "due",
  "dust",
  "duty",
  "earthquake",
  "eastern",
  "economic",
  "economy",
  "edge",
  "editor",
  "educate",
  "educated",
  "educational",
  "effective",
  "effectively",
  "effort",
  "election",
  "element",
  "embarrassed",
  "embarrassing",
  "emergency",
  "emotion",
  "employment",
  "empty",
  "encourage",
  "enemy",
  "engaged",
  "engineering",
  "entertain",
  "entertainment",
  "entrance",
  "entry",
  "environmental",
  "episode",
  "equal",
  "equally",
  "escape",
  "essential",
  "eventually",
  "examine",
  "except",
  "exchange",
  "excitement",
  "exhibition",
  "expand",
  "expected",
  "expedition",
  "experience",
  "experienced",
  "experiment",
  "explode",
  "explore",
  "explosion",
  "export",
  "extra",
  "face",
  "fairly",
  "familiar",
  "fancy",
  "far",
  "fascinating",
  "fashionable",
  "fasten",
  "favour",
  "fear",
  "feature",
  "fence",
  "fighting",
  "file",
  "financial",
  "fire",
  "fitness",
  "fixed",
  "flag",
  "flood",
  "flour",
  "flow",
  "fold",
  "folk",
  "following",
  "force",
  "forever",
  "frame",
  "freeze",
  "frequently",
  "friendship",
  "frighten",
  "frightened",
  "frightening",
  "frozen",
  "fry",
  "fuel",
  "function",
  "fur",
  "further",
  "garage",
  "gather",
  "generally",
  "generation",
  "generous",
  "gentle",
  "gentleman",
  "ghost",
  "giant",
  "glad",
  "global",
  "glove",
  "go",
  "goods",
  "grade",
  "graduate",
  "grain",
  "grateful",
  "growth",
  "guard",
  "guilty",
  "hand",
  "hang",
  "happiness",
  "hardly",
  "hate",
  "head",
  "headline",
  "heating",
  "heavily",
  "helicopter",
  "highlight",
  "highly",
  "hire",
  "historic",
  "historical",
  "honest",
  "horrible",
  "horror",
  "host",
  "hunt",
  "hurricane",
  "hurry",
  "identity",
  "ignore",
  "illegal",
  "imaginary",
  "immediate",
  "immigrant",
  "impact",
  "import",
  "importance",
  "impression",
  "impressive",
  "improvement",
  "incredibly",
  "indeed",
  "indicate",
  "indirect",
  "indoor",
  "indoors",
  "influence",
  "ingredient",
  "injure",
  "injured",
  "innocent",
  "intelligence",
  "intend",
  "intention",
  "invest",
  "investigate",
  "involved",
  "iron",
  "issue",
  "IT",
  "journal",
  "judge",
  "keen",
  "key",
  "keyboard",
  "kick",
  "killing",
  "kind (caring)",
  "kiss",
  "knock",
  "label",
  "laboratory",
  "lack",
  "latest",
  "lay",
  "layer",
  "lead",
  "leading",
  "leaf",
  "leather",
  "legal",
  "leisure",
  "length",
  "level",
  "lie (tell a lie)",
  "limit",
  "lip",
  "liquid",
  "literature",
  "live",
  "living",
  "local",
  "locate",
  "located",
  "location",
  "lonely",
  "loss",
  "luxury",
  "mad",
  "magic",
  "mainly",
  "mall",
  "management",
  "market",
  "marketing",
  "marriage",
  "meanwhile",
  "measure",
  "medium",
  "mental",
  "mention",
  "mess",
  "mild",
  "mine (hole)",
  "mix",
  "mixture",
  "mood",
  "move",
  "mud",
  "murder",
  "muscle",
  "musical",
  "mystery",
  "nail",
  "narrative",
  "nation",
  "native",
  "naturally",
  "necessarily",
  "need",
  "needle",
  "neighbourhood",
  "neither",
  "net",
  "next",
  "nor",
  "normal",
  "northern",
  "note",
  "nuclear",
  "obvious",
  "obviously",
  "occasion",
  "occur",
  "odd",
  "official",
  "old-fashioned",
  "once",
  "operation",
  "organised",
  "organiser",
  "original",
  "originally",
  "ought",
  "ours",
  "outdoor",
  "outdoors",
  "pack",
  "package",
  "painful",
  "pale",
  "pan",
  "participate",
  "particularly",
  "pass",
  "passion",
  "path",
  "payment",
  "peaceful",
  "percentage",
  "perfectly",
  "performance",
  "personally",
  "persuade",
  "photographer",
  "photography",
  "pin",
  "pipe",
  "place",
  "planning",
  "pleasant",
  "pleasure",
  "plenty",
  "plot",
  "plus",
  "poem",
  "poet",
  "poetry",
  "point",
  "poison",
  "poisonous",
  "policy",
  "political",
  "politician",
  "politics",
  "port",
  "portrait",
  "possibly",
  "pot",
  "pour",
  "poverty",
  "powder",
  "powerful",
  "practical",
  "pray",
  "prayer",
  "prediction",
  "prepared",
  "presentation",
  "press",
  "pressure",
  "pretend",
  "previous",
  "previously",
  "priest",
  "primary",
  "prince",
  "princess",
  "printing",
  "prisoner",
  "private",
  "producer",
  "production",
  "profession",
  "profit",
  "programme",
  "promote",
  "proper",
  "properly",
  "property",
  "protest",
  "proud",
  "prove",
  "pull",
  "punish",
  "punishment",
  "push",
  "qualification",
  "qualified",
  "qualify",
  "queue",
  "quit",
  "quotation",
  "quote",
  "race (people)",
  "racing",
  "range",
  "rare",
  "rarely",
  "reaction",
  "reality",
  "receipt",
  "recommendation",
  "reference",
  "reflect",
  "regularly",
  "reject",
  "relate",
  "related",
  "relation",
  "relative",
  "relaxed",
  "relaxing",
  "release",
  "reliable",
  "religion",
  "religious",
  "remain",
  "remind",
  "remote",
  "rent",
  "repair",
  "repeat",
  "repeated",
  "represent",
  "request",
  "require",
  "reservation",
  "resource",
  "respect",
  "responsibility",
  "responsible",
  "result",
  "retire",
  "retired",
  "revise",
  "ring",
  "rise",
  "risk",
  "robot",
  "roll",
  "romantic",
  "rope",
  "rough",
  "row",
  "royal",
  "rugby",
  "rule",
  "safety",
  "sail",
  "sailor",
  "sample",
  "sand",
  "scan",
  "scientific",
  "script",
  "sculpture",
  "secondary",
  "security",
  "seed",
  "sensible",
  "separate",
  "seriously",
  "servant",
  "set (put)",
  "set (group)",
  "setting",
  "sex",
  "sexual",
  "shake",
  "share",
  "sharp",
  "shelf",
  "shell",
  "shift",
  "shine",
  "shiny",
  "shoot",
  "shy",
  "sight",
  "signal",
  "silent",
  "silly",
  "similarity",
  "similarly",
  "simply",
  "since",
  "sink",
  "slice",
  "slightly",
  "slow",
  "smart",
  "smooth",
  "software",
  "soil",
  "solid",
  "sort",
  "southern",
  "specifically",
  "spending",
  "spicy",
  "spirit",
  "spoken",
  "spot",
  "spread",
  "spring",
  "stadium",
  "staff",
  "standard",
  "state",
  "statistic",
  "statue",
  "stick (attach)",
  "stick (piece of wood)",
  "still",
  "store",
  "stranger",
  "strength",
  "string",
  "strongly",
  "studio",
  "stuff",
  "substance",
  "successfully",
  "sudden",
  "suffer",
  "suit",
  "suitable",
  "summarise",
  "summary",
  "supply",
  "supporter",
  "surely",
  "surface",
  "survive",
  "swim",
  "switch",
  "symptom",
  "tail",
  "talent",
  "talented",
  "tape",
  "tax",
  "technical",
  "technique",
  "tend",
  "tent",
  "that",
  "theirs",
  "theme",
  "theory",
  "therefore",
  "this",
  "though",
  "throat",
  "throughout",
  "tight",
  "till",
  "tin",
  "tiny",
  "tip",
  "toe",
  "tongue",
  "total",
  "totally",
  "touch",
  "tour",
  "trade",
  "translate",
  "translation",
  "transport",
  "treat",
  "treatment",
  "trend",
  "trick",
  "truth",
  "tube",
  "type",
  "typically",
  "tyre",
  "ugly",
  "unable",
  "uncomfortable",
  "underwear",
  "unemployed",
  "unemployment",
  "unfair",
  "union",
  "unless",
  "unlike",
  "unlikely",
  "unnecessary",
  "unpleasant",
  "update",
  "upon",
  "upset",
  "used",
  "valuable",
  "value",
  "various",
  "version",
  "victim",
  "view",
  "viewer",
  "violent",
  "volunteer",
  "vote",
  "warm",
  "warn",
  "warning",
  "waste",
  "water",
  "wave",
  "weapon",
  "weigh",
  "western",
  "whatever",
  "whenever",
  "whether",
  "while",
  "whole",
  "will",
  "win",
  "wing",
  "within",
  "wonder",
  "wool",
  "worldwide",
  "worry",
  "worse",
  "worst",
  "worth",
  "written",
  "wrong",
  "yard",
  "young",
  "youth",
];

export default B1Words;
