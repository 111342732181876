import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import VStack from "../Unknown/VStack";

const AdminCreateSentenceFields: React.FC<{
  columnSize?: number;
  enValue?: string;
  uaValue?: string;
  onUpdateValues: (enValue: string, uaValue: string, meaningValue: string) => void;
}> = ({ columnSize, enValue: inputEnValue, uaValue: inputUaValue, onUpdateValues }) => {
  const [meaningValue, setMeaningValue] = useState("");
  const [enValue, setEnValue] = useState("");
  const [uaValue, setUaValue] = useState("");

  useEffect(() => {
    if (inputEnValue) {
      setEnValue(inputEnValue);
    }
  }, [inputEnValue]);
  useEffect(() => {
    if (inputUaValue) {
      setUaValue(inputUaValue);
    }
  }, [inputUaValue]);

  return (
    <VStack alignItems="flex-start">
      <Box
        width="100%"
        sx={{
          overflowX: "scroll",
        }}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-start"
          sx={{
            width: "auto",
            overflowX: "scroll",
          }}
        >
          <Box sx={{ width: columnSize || 400 }}>
            <TextField
              type="text"
              color="primary"
              value={enValue}
              variant="outlined"
              multiline
              sx={{ width: columnSize || 600 }}
              onChange={(event) => {
                setEnValue(event.target.value);
              }}
            />
          </Box>
          <Box sx={{ width: columnSize ? columnSize * 1.5 : 600 }}>
            <TextField
              type="text"
              color="primary"
              value={uaValue}
              variant="outlined"
              multiline
              sx={{ width: columnSize ? columnSize * 1.5 : 600 }}
              onChange={(event) => {
                setUaValue(event.target.value);
              }}
            />
          </Box>
          <Box sx={{ width: columnSize || 600 }}>
            <TextField
              type="text"
              color="primary"
              value={meaningValue}
              variant="outlined"
              multiline
              sx={{ width: columnSize || 600 }}
              onChange={(event) => {
                setMeaningValue(event.target.value);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Button
        variant="outlined"
        onClick={() => {
          onUpdateValues(enValue, uaValue, meaningValue);
        }}
      >
        Update fields text
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          setMeaningValue(
            enValue
              .split("\n")
              .map((v) => v.trim())
              .filter(Boolean)
              .map((v) => "-")
              .join("\n"),
          );
          onUpdateValues(enValue, uaValue, meaningValue);
        }}
      >
        Update meanings with default value
      </Button>
    </VStack>
  );
};

export default AdminCreateSentenceFields;
