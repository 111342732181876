import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { useFirebaseApp } from "reactfire";

const AiAskTranslation: React.FC<{
  text: string;
  onTranslated: (text: string) => void;
}> = ({ text, onTranslated }) => {
  const [isLoading, setIsLoading] = useState(false);

  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");
  const askOpenAi = httpsCallable(functions, "askOpenAiFunction");

  return (
    <Box width="100%">
      <LoadingButton
        variant="outlined"
        loading={isLoading}
        disabled={!text}
        size="small"
        color="error"
        onClick={async () => {
          // ${JSON.stringify(Array.from(new Set(data.map((i) => i.words).flat())))}
          // For building sentences use only main and next words:
          setIsLoading(true);

          try {
            const translationResult = await askOpenAi({
              question: `                  
              Translate the following text to Ukrainian language.
              The response is a list of Ukrainian language strings without quotes at the beginning and at the end.
              Each translated sentence starts on the new line.

              Give response as a JSON array of strings!
              Here the text to translate: ${JSON.stringify(text.split("\n").map((s) => s.trim()))}
            `,
            });

            console.log("translationResult", translationResult);
            const translationData = translationResult.data as string;

            if (translationData) {
              navigator.clipboard.writeText(JSON.stringify(translationData));

              if (typeof translationData === "string") {
                onTranslated(translationData);
              } else if (typeof translationData === "object") {
                onTranslated((translationData as any).join("\n"));
              }
            }
          } catch (err) {
            console.log("🚀 ~ err:", err);
            setIsLoading(false);
          }
          setIsLoading(false);
        }}
      >
        Ai translate sentences
      </LoadingButton>
    </Box>
  );
};

export default AiAskTranslation;
