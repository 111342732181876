import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditText, EditTextarea } from "react-edit-text";
import { useFirebaseApp } from "reactfire";
import getGCRandomVoice from "../../../common/getGCRandomVoice";
import supabaseClient from "../../../common/supabaseClient";
import wait from "../../../common/wait";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { Word } from "../../../types/supabase";
import WordAudio from "../../Audio/WordAudio";
import HStack from "../../Unknown/HStack";
import VStack from "../../Unknown/VStack";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import { useLessonsStore } from "../../../store/lessonsStore";
import DisplayToSkipWords from "../DisplayToSkipWords";
import CopyNewWordsForGemini from "../CopyNewWordsForGemini";
import getSkipWords from "../../../common/getSkipWords";
import filterSkipedWords from "../../../common/filterSkipedWords";

const getUnverifiedWords = async () => {
  try {
    const { data: unverifiedWords } = await supabaseClient
      .from("words")
      .select()
      .is("verified", false)
      .limit(10)
      .throwOnError()
      .returns<Word[]>();

    if (!unverifiedWords) throw new Error("Something went worng. Unverified words are empty.");

    return unverifiedWords;
  } catch (err) {
    console.error("ERROR", err);
  }

  return [];
};

const AdminVerifyWords = () => {
  const [unverifiedWords, setUnverifiedWords] = useState<Word[]>([]);
  const [allWords, setWords] = useState<any[]>([]);
  const newWords = useLessonsStore((state) => state.newWords);
  const setNewWords = useLessonsStore((state) => state.setNewWords);

  // useMountedWaitAsyncEffect(async () => {
  //   if (newWords.length) {
  //     const skipWords = await getSkipWords(newWords);
  //     console.log(
  //       "🚀 ~ skipWords:",
  //       skipWords,
  //       filterSkipedWords(newWords, skipWords),
  //     );
  //     setNewWords(filterSkipedWords(newWords, skipWords));
  //   }
  // }, [newWords]);

  const [value, setValue] = useState("");
  const [wordToFetch, setWordToFetch] = useState("");

  useMountedWaitAsyncEffect(async () => {
    // const { data } = await supabaseClient.rpc("get_all_words");
    if (wordToFetch) {
      const { data } = await supabaseClient
        .from("words")
        .select()
        .eq("name", wordToFetch)
        .maybeSingle();

      if (data) setWords([data]);
    } else {
      const { data } = await supabaseClient
        .from("words")
        .select()
        .is("verified", false)
        .order("created_at", {
          ascending: false,
        })
        .limit(30);

      if (data) setWords(data);
    }
  }, [wordToFetch]);

  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");

  const textToSpeechFunction = httpsCallable(
    functions,
    "recordEnglishTextToSpeechAndReturnStorageUrl",
  );

  const createAudio = async (word: string) => {
    try {
      const result = await textToSpeechFunction({
        en: word.replace("(verb) ", ""),
        voiceName: getGCRandomVoice(),
      });
      return result;
    } catch (err) {
      console.log("🚀 try/catch create autio error", err);
    }
  };

  return (
    <Container>
      <Grid container>
        <Box>
          <HStack>
            <TextField
              name="enText"
              multiline
              value={value}
              fullWidth
              label="Word"
              onChange={(event) => setValue(event.target.value)}
            />
            <Button
              variant="outlined"
              onClick={() => {
                setWordToFetch(value);
              }}
            >
              Submit
            </Button>
          </HStack>
          {allWords.map((word) => {
            return (
              <HStack>
                <Typography>{word.name}</Typography>
                <WordAudio url={word.audio_url} />
                <Button
                  onClick={async () => {
                    const audioResponse: any = await createAudio(word.name);

                    const result = await supabaseClient
                      .from("words")
                      .update({
                        audio_url: audioResponse?.data?.url,
                      })
                      .eq("name", word.name);
                    console.log("🚀 ~ result:", result);
                  }}
                >
                  Update audio
                </Button>
              </HStack>
            );
          })}
        </Box>
      </Grid>
    </Container>
  );
};

export default memo(AdminVerifyWords);
