import SquareFootIcon from "@mui/icons-material/SquareFoot";
import {
  Badge,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
// import PoolIcon from "@mui/icons-material/Pool";
import React, { useEffect, useState } from "react";
import supabaseClient from "../../../../../common/supabaseClient";
// import Auth from "../../Auth/Page";
import { RPCUser } from "../../../../../common/fetchCurrentUser";
import isAdmin from "../../../../../common/isAdmin";
import useMountedWaitAsyncEffect from "../../../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import { Word } from "../../../../../types/supabase";
import AdminUsers from "../../../../AdminUsers";
import ColorcationsTable from "../ColorcationsTable";
import DemonstrativePronounsTable from "../DemonstrativePronounsTable";
import HStack from "../../../HStack";
import HelpingWordsTable from "../../../HelpingWordsTable";
import ModalCloseButton from "../../../ModalCloseButton";
import PronounTable from "../../../PronounTable";
import QuestionWordsTable from "../../../QuestionWordsTable";
import {
  BuldSentenceParts,
  RuleType,
  buildSentenceRules,
  tensesRules,
  words,
} from "../../../Root/grammarMap";
import ShortsTable from "../ShortsTable";
import TensesTable from "../TensesTable";
import VStack from "../../../VStack";
import Articles from "../../Articles";
import WordsDifference from "../WordsDifference";
import AccordionRule from "../AccordionRule";
import filterRules from "../utils/filterRules";
import FeedAdmin from "../FeedAdmin";
import Antonyms from "../Antonyms";
import Gerund from "../Gerund";
import H3Title from "../../../H3Title";
import WriteWordsDifference from "../WriteWordsDifference";
import ComparativeAdjectives from "../ComparativeAdjectives";

interface TabPanelProps {
  children?: React.ReactNode;
  isActive: boolean;
  isVisible: boolean;
}

const chatModalStyle = {
  position: "absolute" as "absolute",
  width: "100vw",
  height: "100%",
  overflow: "auto",
  bgcolor: "background.paper",
  py: 4,
  px: 1,
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, isActive, isVisible } = props;

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        width: "100%",
        // backgroundColor: "red",
      }}
    >
      {isActive && <Box>{children}</Box>}
    </Box>
  );
}

enum ActiveTab {
  TENSES = "часи",
  SHORTS = "скорочення",
  PRONOUNS = "займенники",
  BUILD_SENTENCES = "будова речення",
  NOUNS = "іменники",
  // WORDS = "слова",
  HELPING_WORDS = "допоміжні слова",
  QUESTION_WORDS = "питальні слова",
  ADMIN = "admin",
  DIFFERENCE_WORDS = "різниця між словами",
  COLOCATIONS = "сталі словосполучення",
  ARTICLES = "артиклі",
  ANTONYMS = "антоніми",
  COMPARATIVE_ADJECTIVES = "порівняльні прикметники",
  GERUND = "герундій",
  WRITE_WORDS = "різний правопис слів",
}

const CustomTab: React.FC<{
  label: string;
  isActive: boolean;
  newRules: boolean;
  isVisible: boolean;
  onClick: () => void;
}> = ({ label, newRules, isActive, isVisible, onClick }) => {
  const style = { cursor: "pointer", color: isActive ? "white" : "black" };

  if (!isVisible) return null;

  if (!!newRules) {
    return (
      <Box>
        <Badge
          badgeContent={newRules}
          color="primary"
          variant="dot"
          sx={{
            "& .MuiBadge-badge": {
              left: -3,
              top: 3,
              width: 0,
              height: 4,
              backgroundColor: "red",
              borderRadius: 0,
              color: "red",
              // border: `2px solid ${theme.palette.background.paper}`,
              padding: "0 4px",
            },
          }}
        >
          <Button
            size="small"
            variant={isActive ? "contained" : "outlined"}
            sx={style}
            onClick={onClick}
          >
            {label}
          </Button>
        </Badge>
      </Box>
    );
  }

  return (
    <Button size="small" variant={isActive ? "contained" : "outlined"} sx={style} onClick={onClick}>
      {label}
    </Button>
  );
};

const initialActiveMap = {
  buildSentence: 0,
  pronouns: 0,
  tenses: 0,
  nouns: 0,
  words: 0,
};

const getNewGrammarMapForCurrentLesson = (
  inputRules: RuleType[],
  lessonNumber: number,
  type?: string,
) => {
  let value = 0;

  for (let i = 0; i < inputRules.length; i++) {
    const rules = inputRules[i].rules;

    for (let j = 0; j < rules.length; j++) {
      const rule = rules[j];
      if (
        (!type && rule.lesson.includes(lessonNumber)) ||
        (type === "prev" && rule.lesson.includes(lessonNumber))
      ) {
        value = value + 1;
      }
    }
  }

  return value;
};

const GrammarFeed = () => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [value, setValue] = React.useState(0);
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const setLessonNumber = useLessonsStore((state) => state.setLessonNumber);
  const isGrammarFeedOpen = useLessonsStore((state) => state.isGrammarFeedOpen);
  const setIsGrammarFeedOpen = useLessonsStore((state) => state.setIsGrammarFeedOpen);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!lessonNumber && lessonNumber !== 0) {
      setLessonNumber(authUser?.grammar_lesson || 0);
    }
  }, [lessonNumber, authUser]);

  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.TENSES);

  const [activeMap, setActiveMap] = useState({ ...initialActiveMap });
  const [prevActiveMap, setPrevActiveMap] = useState({ ...initialActiveMap });

  const [users, setUsers] = useState<RPCUser[] | null>(null);
  const [changedUserUuid, setChangedUserUuid] = useState<string | null>(null);

  useMountedWaitAsyncEffect(async () => {
    if (authUser && users) return;
    if (!isAdmin(authUser?.id)) return;

    const { data: spUsers } = await supabaseClient.from("users").select();

    if (spUsers) {
      setUsers(spUsers);
    } else {
      setUsers([]);
    }
  }, [users, authUser]);

  useEffect(() => {
    const newActiveMap = {
      ...initialActiveMap,
      buildSentence: getNewGrammarMapForCurrentLesson(buildSentenceRules, lessonNumber),
      tenses: getNewGrammarMapForCurrentLesson(tensesRules, lessonNumber),
      // pronouns: getNewGrammarMapForCurrentLesson(personalPronouns, lessonNumber),
      words: getNewGrammarMapForCurrentLesson(words, lessonNumber),
    };
    const newPrevActiveMap = {
      ...initialActiveMap,
      buildSentence: getNewGrammarMapForCurrentLesson(buildSentenceRules, lessonNumber, "prev"),
      tenses: getNewGrammarMapForCurrentLesson(tensesRules, lessonNumber, "prev"),
      // pronouns: getNewGrammarMapForCurrentLesson(personalPronouns, lessonNumber, "prev"),
      words: getNewGrammarMapForCurrentLesson(words, lessonNumber, "prev"),
    };

    setActiveMap(newActiveMap);
    setPrevActiveMap(newPrevActiveMap);
  }, [lessonNumber]);

  return (
    <>
      <Modal
        open={isGrammarFeedOpen}
        onClose={() => {
          setIsGrammarFeedOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={chatModalStyle}>
          <ModalCloseButton
            onClose={() => {
              setIsGrammarFeedOpen(false);
            }}
          />
          {/* {isLoading && <Loader />} */}
          {isAdmin(authUser?.id) && (
            <Container>
              <Box mb={5}>
                <TextField
                  color="primary"
                  name="text"
                  value={lessonNumber}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(v) => setLessonNumber(+v.target.value)}
                  size="medium"
                  placeholder="Lesson number"
                  label="Lesson number"
                  type="text"
                  variant="outlined"
                />
              </Box>
            </Container>
          )}
          <Box sx={{ width: "100%" }}>
            <Container sx={{ mb: 7 }}>
              <HStack gap={0.5}>
                {isAdmin(authUser?.id) && (
                  <CustomTab
                    isVisible={lessonNumber >= 1}
                    label="Будова речення"
                    isActive={activeTab === ActiveTab.BUILD_SENTENCES}
                    newRules={[1].includes(lessonNumber)}
                    onClick={() => setActiveTab(ActiveTab.BUILD_SENTENCES)}
                  />
                )}
                <CustomTab
                  isVisible={lessonNumber >= 1}
                  label="Часи"
                  isActive={activeTab === ActiveTab.TENSES}
                  newRules={[1, 4, 8, 10].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.TENSES)}
                />
                <CustomTab
                  isVisible
                  label="Займенники"
                  isActive={activeTab === ActiveTab.PRONOUNS}
                  newRules={[1, 3, 5, 11, 16, 29, 30, 35].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.PRONOUNS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 1000}
                  label="Іменники"
                  isActive={activeTab === ActiveTab.NOUNS}
                  newRules={[1000].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.NOUNS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 1}
                  label="Питальні слова"
                  isActive={activeTab === ActiveTab.QUESTION_WORDS}
                  newRules={[1, 2, 5, 15, 17, 19].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.QUESTION_WORDS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 1}
                  label="Допоміжні слова"
                  isActive={activeTab === ActiveTab.HELPING_WORDS}
                  newRules={[1, 4, 7, 29].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.HELPING_WORDS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 1}
                  label="Скорочення"
                  isActive={activeTab === ActiveTab.SHORTS}
                  newRules={[1, 5, 6, 7, 8, 10, 12, 13, 17].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.SHORTS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 1}
                  label="Різниця між словами"
                  isActive={activeTab === ActiveTab.DIFFERENCE_WORDS}
                  newRules={[1, 3, 8, 12, 18, 19, 31].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.DIFFERENCE_WORDS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 5}
                  label="Сталі словосполучення"
                  isActive={activeTab === ActiveTab.COLOCATIONS}
                  newRules={[
                    4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 18, 19, 25, 26, 29, 31, 32, 35,
                  ].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.COLOCATIONS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 5}
                  label="Артиклі"
                  isActive={activeTab === ActiveTab.ARTICLES}
                  newRules={[4, 5, 6].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.ARTICLES)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 11}
                  label="Антоніми"
                  isActive={activeTab === ActiveTab.ANTONYMS}
                  newRules={[11, 16, 19].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.ANTONYMS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 30}
                  label="Порівняльні прикметники"
                  isActive={activeTab === ActiveTab.COMPARATIVE_ADJECTIVES}
                  newRules={[30].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.COMPARATIVE_ADJECTIVES)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 19}
                  label="Різний правопис слів"
                  isActive={activeTab === ActiveTab.WRITE_WORDS}
                  newRules={[19].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.WRITE_WORDS)}
                />
                <CustomTab
                  isVisible={lessonNumber >= 15}
                  label="Герундій"
                  isActive={activeTab === ActiveTab.GERUND}
                  newRules={[15, 18].includes(lessonNumber)}
                  onClick={() => setActiveTab(ActiveTab.GERUND)}
                />

                <CustomTab
                  isVisible={isAdmin(authUser?.id)}
                  label="Admin"
                  isActive={activeTab === ActiveTab.ADMIN}
                  newRules={false}
                  onClick={() => setActiveTab(ActiveTab.ADMIN)}
                />
              </HStack>
            </Container>
            <CustomTabPanel isVisible={lessonNumber >= 1} isActive={activeTab === ActiveTab.TENSES}>
              {/* tenses */}
              <Container>
                <VStack alignItems="flex-start" sx={{ mb: 3 }}>
                  <Box>
                    <Typography variant="h3">Часи в англійській мові</Typography>
                    <Typography gutterBottom variant="caption">
                      (English tenses)
                    </Typography>
                  </Box>
                  <Box mb={5}>
                    <TensesTable />
                  </Box>
                </VStack>
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 1}
              isActive={activeTab === ActiveTab.BUILD_SENTENCES}
            >
              {/* будова речення */}
              <Container>
                <BuldSentenceParts />
              </Container>
              {/* {buildSentenceRules.map((rule) => {
                return (
                  <Box key={rule.name} mb={10}>
                    <Container>
                      <Box mb={4}>
                        <Typography sx={{}} gutterBottom variant="h3">
                          {rule.name}
                        </Typography>
                      </Box>
                    </Container>
                    {filterRules(rule.rules, lessonNumber).map((rule) => (
                      <AccordionRule
                        isActive={rule.lesson.includes(lessonNumber)}
                        title={rule.title}
                        description={rule.description}
                        examples={rule.examples}
                      />
                    ))}
                  </Box>
                );
              })} */}
            </CustomTabPanel>

            <CustomTabPanel isVisible isActive={activeTab === ActiveTab.PRONOUNS}>
              {/* займенники */}
              <VStack alignItems="flex-start" my={5}>
                <Container>
                  <Box mb={7}>
                    <H3Title>Таблиця співвідношення особових займенників між собою</H3Title>
                    <PronounTable />
                  </Box>
                  {lessonNumber >= 5 && (
                    <>
                      <Box mb={2}>
                        <H3Title>Таблиця вказівних займенників</H3Title>
                        <Typography>
                          Вказівні займенники (demonstrative pronouns) - це слова, які
                          використовуються для позначення предметів, людей, ідей або місць, вказуючи
                          на їхню близькість або віддаленість від мовця.
                        </Typography>
                      </Box>
                      <DemonstrativePronounsTable />
                    </>
                  )}
                </Container>
              </VStack>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 1000}
              isActive={activeTab === ActiveTab.NOUNS}
            >
              Item Two
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 1}
              isActive={activeTab === ActiveTab.HELPING_WORDS}
            >
              {/* helping words */}
              <Container>
                <Typography variant="h3">Допоміжні дієслова</Typography>
                <Typography gutterBottom variant="caption">
                  (auxiliary verbs)
                </Typography>
              </Container>
              <Container>
                <VStack alignItems="flex-start" my={3} gap={7}>
                  <HelpingWordsTable />
                </VStack>
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 1}
              isActive={activeTab === ActiveTab.QUESTION_WORDS}
            >
              {/* question words */}
              <Container>
                <Typography variant="h3">Питальні слова</Typography>
              </Container>
              <Container>
                <VStack alignItems="flex-start" my={5} gap={7}>
                  <QuestionWordsTable />
                </VStack>
              </Container>
            </CustomTabPanel>
            <CustomTabPanel isVisible={lessonNumber >= 1} isActive={activeTab === ActiveTab.SHORTS}>
              {/* скорочення */}
              <Container>
                <Typography variant="h3">Таблиця скорочень слів</Typography>
              </Container>
              <Container>
                <VStack alignItems="flex-start" my={5} gap={7}>
                  <ShortsTable />
                </VStack>
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 4}
              isActive={activeTab === ActiveTab.COLOCATIONS}
            >
              {/* скорочення */}
              <Container>
                <Typography variant="h3">Сталі словосполучення, ідіоми та вирази</Typography>
                <Typography variant="caption">colocations / idioms</Typography>
                <Typography>
                  Стале словосполучення: це поєднання слів, які часто використовуються разом, але їх
                  значення можна зрозуміти, знаючи окремі значення кожного слова.
                </Typography>
              </Container>
              <Container>
                <VStack alignItems="flex-start" my={5} gap={7}>
                  <ColorcationsTable />
                </VStack>
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 4}
              isActive={activeTab === ActiveTab.ARTICLES}
            >
              <Articles />
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 1}
              isActive={activeTab === ActiveTab.DIFFERENCE_WORDS}
            >
              {/* різниця між словами */}
              <Container>
                <WordsDifference />
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 11}
              isActive={activeTab === ActiveTab.ANTONYMS}
            >
              <Container>
                <Antonyms />
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 30}
              isActive={activeTab === ActiveTab.COMPARATIVE_ADJECTIVES}
            >
              <Container>
                <ComparativeAdjectives />
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 19}
              isActive={activeTab === ActiveTab.WRITE_WORDS}
            >
              <Container>
                <WriteWordsDifference />
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={lessonNumber >= 15}
              isActive={activeTab === ActiveTab.GERUND}
            >
              <Container>
                <Gerund />
              </Container>
            </CustomTabPanel>
            <CustomTabPanel
              isVisible={isAdmin(authUser?.id)}
              isActive={activeTab === ActiveTab.ADMIN}
            >
              {/* admin */}
              <FeedAdmin users={users} />
            </CustomTabPanel>
          </Box>
        </Box>
      </Modal>
      {isAdmin(authUser?.id) && (
        <Box
          position="fixed"
          sx={{
            right: 60,
            bottom: 10,
            zIndex: 100,
          }}
        >
          <IconButton
            onClick={() => {
              setIsGrammarFeedOpen(true);
            }}
          >
            <SquareFootIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default GrammarFeed;
