import ReactPlayer from "react-player";
import Box from "../Box";
import { useEffect, useRef, useState } from "react";

const YouTubePlayer: React.FC<{
  id: string;
  start: number;
  duration: number;
  width?: number;
  height?: number;
}> = ({ id, start, width, height, duration }) => {
  const playerRef = useRef<any>(null);

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(start);

  useEffect(() => {
    setCurrentTime(start);
  }, [start]);

  const timeUpdateLoop = () => {
    if (playerRef.current) {
      const newCurrentTime = (playerRef.current as any).getCurrentTime();
      console.log("🚀 ~ newCurrentTime:", newCurrentTime);

      if (newCurrentTime > start + duration) {
        setCurrentTime(Math.ceil(start));
        // setPlaying(false); // Pause after the duration
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else if (newCurrentTime < start) {
        // setPlaying(false); // Pause after the duration
        setCurrentTime(Math.ceil(start));

        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
        // (playerRef.current as any).seekTo();
        // (playerRef.current as any)?.player?.getInternalPlayer()?.play();
        // setTimeout(() => {
        //   setPlaying(true);
        // }, 2500);
      } else {
        setCurrentTime(newCurrentTime);
      }
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    timer = setInterval(() => {
      if (playing) {
        timeUpdateLoop();
      }
    }, 500);
    // }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentTime, playing]);

  return (
    <Box position="relative" pb="51.2%" width={width || "100%"} mb={4}>
      <Box position="absolute" top={0} left={0} width="100%" height="100%">
        <ReactPlayer
          controls
          ref={playerRef}
          width="100%"
          height={height || "100%"}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          // onProgress={handleProgress}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          config={{
            youtube: {
              playerVars: {
                // controls: true,
                start, // Set the start time
              },
            },
          }}
          url={`https://youtu.be/${id}`}
        />
      </Box>
    </Box>
  );
};

export default YouTubePlayer;
