import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { fullScreenModalStyle } from "../../../../../common/constants";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import WordTranscription from "../../../../Word/WordTranscription";
import useGlobalWords from "../../../../hooks/getGlobalWords";
import ModalCloseButton from "../../../ModalCloseButton";
import VStack from "../../../VStack";

type CreateDataProp = {
  en: any;
  lesson: number;
};

function createData(item1: CreateDataProp, item2: CreateDataProp, item3: CreateDataProp) {
  return {
    item1,
    item2,
    item3,
  };
}

const rows = (lessonNumber: number) => [
  createData(
    {
      en: <Typography fontWeight={700}>How's</Typography>,
      lesson: 17,
    },
    {
      en: <Typography fontWeight={700}>How is</Typography>,
      lesson: 17,
    },
    {
      en: (
        <>
          <Typography>
            <strong>How's</strong> that?
          </Typography>
          <Typography>
            <strong>How's</strong> it going?
          </Typography>
        </>
      ),
      lesson: 17,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>What're</Typography>,
      lesson: 13,
    },
    {
      en: <Typography fontWeight={700}>What are</Typography>,
      lesson: 13,
    },
    {
      en: (
        <>
          <Typography>
            <strong>What're</strong> you doing?
          </Typography>
        </>
      ),
      lesson: 13,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>Where's</Typography>,
      lesson: 13,
    },
    {
      en: <Typography fontWeight={700}>Where is</Typography>,
      lesson: 13,
    },
    {
      en: (
        <>
          <Typography>
            <strong>Where's</strong> my bedroom?
          </Typography>
        </>
      ),
      lesson: 13,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>Who's</Typography>,
      lesson: 12,
    },
    {
      en: <Typography fontWeight={700}>Who is</Typography>,
      lesson: 12,
    },
    {
      en: (
        <>
          {/* <Typography>
            <strong>Who's</strong> teaching you?
          </Typography> */}
          <Typography>
            <strong>Who's</strong> from the United States?
          </Typography>
        </>
      ),
      lesson: 12,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>What's</Typography>,
      lesson: 12,
    },
    {
      en: <Typography fontWeight={700}>What is</Typography>,
      lesson: 12,
    },
    {
      en: (
        <>
          {/* <Typography>
            <strong>What's</strong> in a cake?
          </Typography> */}
          <Typography>
            <strong>What's</strong> your name?
          </Typography>
        </>
      ),
      lesson: 12,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>ok</Typography>,
      lesson: 10,
    },
    {
      en: <Typography fontWeight={700}>okay</Typography>,
      lesson: 10,
    },
    {
      en: (
        <>
          <Typography>
            It's <strong>ok</strong>.
          </Typography>
          <Typography>
            Are you <strong>ok</strong>?
          </Typography>
        </>
      ),
      lesson: 10,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>I'm</Typography>,
      lesson: 8,
    },
    {
      en: <Typography fontWeight={700}>I am</Typography>,
      lesson: 8,
    },
    {
      en: (
        <Typography>
          <strong>I'm</strong> happy.
        </Typography>
      ),
      lesson: 8,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>You're</Typography>,
      lesson: 8,
    },
    {
      en: <Typography fontWeight={700}>You are</Typography>,
      lesson: 8,
    },
    {
      en: (
        <Typography>
          <strong>You're</strong> happy.
        </Typography>
      ),
      lesson: 8,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>We're</Typography>,
      lesson: 8,
    },
    {
      en: <Typography fontWeight={700}>We are</Typography>,
      lesson: 8,
    },
    {
      en: (
        <Typography>
          <strong>We're</strong> happy.
        </Typography>
      ),
      lesson: 8,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>They're</Typography>,
      lesson: 8,
    },
    {
      en: <Typography fontWeight={700}>They are</Typography>,
      lesson: 8,
    },
    {
      en: (
        <Typography>
          <strong>They're</strong> happy.
        </Typography>
      ),
      lesson: 8,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>He's</Typography>,
      lesson: 8,
    },
    {
      en: <Typography fontWeight={700}>He is</Typography>,
      lesson: 8,
    },
    {
      en: (
        <Typography>
          <strong>He's</strong> happy.
        </Typography>
      ),
      lesson: 8,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>She's</Typography>,
      lesson: 8,
    },
    {
      en: <Typography fontWeight={700}>She is</Typography>,
      lesson: 8,
    },
    {
      en: (
        <Typography>
          <strong>She's</strong> happy.
        </Typography>
      ),
      lesson: 8,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>It's</Typography>,
      lesson: 8,
    },
    {
      en: <Typography fontWeight={700}>It is</Typography>,
      lesson: 8,
    },
    {
      en: (
        <Typography>
          <strong>It's</strong> good.
        </Typography>
      ),
      lesson: 8,
    },
  ),
  createData(
    {
      en: (
        <>
          <Typography fontWeight={700}>can't</Typography>
          <Typography variant="body2">частіше використовується у розмовній англ.</Typography>
        </>
      ),
      lesson: 7,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>cannot</Typography>
          <Typography variant="body2">частіше вживається на письмі, звучить формально</Typography>
        </>
      ),
      lesson: 7,
    },
    {
      en: (
        <>
          <Typography>
            I <strong>can't</strong> help you.
          </Typography>
          <Typography>
            She <strong>can't</strong> do that today.
          </Typography>
        </>
      ),
      lesson: 7,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>let's</Typography>,
      lesson: 6,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>let us</Typography>
          <Typography variant="body2">не використовується у повній формі</Typography>
        </>
      ),
      lesson: 6,
    },
    {
      en: (
        <>
          <Typography>
            <strong>Let's</strong> learn the alphabet.
          </Typography>
          <Typography>
            <strong>Let's</strong> go to the zoo to see the animals.
          </Typography>
        </>
      ),
      lesson: 6,
    },
  ),
  createData(
    {
      en: <Typography fontWeight={700}>that's</Typography>,
      lesson: 5,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>that is</Typography>
          <Typography variant="body2">ніколи не використовується у повній формі</Typography>
        </>
      ),
      lesson: 5,
    },
    {
      en: (
        <>
          <Typography>
            <strong>That's</strong> very beautiful.
          </Typography>
          <Typography>
            <strong>That's</strong> good.
          </Typography>
        </>
      ),
      lesson: 5,
    },
  ),
  createData(
    {
      en: (
        <>
          <Typography fontWeight={700}>don't</Typography>
          <Typography variant="body2">частіше використовується у розмовній англ.</Typography>
        </>
      ),
      lesson: 1,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>do not</Typography>
          <Typography variant="body2">
            звучить формально, частіше використовується на письмі
          </Typography>
        </>
      ),
      lesson: 1,
    },
    {
      en: (
        <>
          <Typography>
            They <strong>don't</strong> work today.
          </Typography>
          <Typography>
            We <strong>don't</strong> do it every day.
          </Typography>
        </>
      ),
      lesson: 1,
    },
  ),
  createData(
    {
      en: (
        <>
          <Typography fontWeight={700}>doesn't</Typography>
          <Typography variant="body2">частіше використовується у розмовній англ.</Typography>
        </>
      ),
      lesson: 1,
    },
    {
      en: (
        <>
          <Typography fontWeight={700}>does not</Typography>
          <Typography variant="body2">
            звучить формально, частіше використовується на письмі
          </Typography>
        </>
      ),
      lesson: 1,
    },
    {
      en: (
        <>
          <Typography>
            She <strong>doesn't</strong> work today.
          </Typography>
          <Typography>
            He <strong>doesn't</strong> do it every day.
          </Typography>
        </>
      ),
      lesson: 1,
    },
  ),
];

const ShortsTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any[]>([]);
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);

  const [word] = useGlobalWords(globalWordsOptions);

  return (
    <Box>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start">
              {activeExamples.map((e) => {
                return (
                  <Typography variant="h3" key={e.title}>
                    {e.title}
                  </Typography>
                );
              })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <TableRow>
              <TableCell sx={{ width: 220, minWidth: 220 }}>скорочення</TableCell>
              <TableCell sx={{ width: 220, minWidth: 220 }}>повністю</TableCell>
              <TableCell sx={{ width: 400, minWidth: 400 }}>приклади</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows(lessonNumber).map((row, index) => (
              <TableRow key={index}>
                <CustomTableCell lesson={row.item1.lesson} en={row.item1.en} isBold />
                <CustomTableCell lesson={row.item2.lesson} en={row.item2.en}></CustomTableCell>
                <CustomTableCell lesson={row.item3.lesson} en={row.item3.en}></CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  lesson: number;
  isBold?: boolean;
}> = ({ en, lesson, isBold }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady = lesson <= lessonNumber;

  if (!isReady) return null;

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        m: 0,
        fontWeight: isBold ? 700 : 400,
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        py: 1,
      }}
    >
      {en}
    </TableCell>
  );
};

export default ShortsTable;
