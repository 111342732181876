import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import MicIcon from "@mui/icons-material/Mic";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, IconButton, Modal, Paper, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  ListeningOptions,
  useSpeechRecognition,
} from "react-speech-recognition";
import { usePrevious } from "react-use";
import { v4 as uuidv4 } from "uuid";
import supabaseClient from "../../../common/supabaseClient";
import useContextMenuFoodTrackingRecodItem from "../../../hooks/useContextMenuFoodTrackingRecodItem";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { FoodTrackingRow } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import WordsModalCarousel from "../../Modal/WordsModalCarousel";
import Box from "../../Unknown/Box";
import HStack from "../../Unknown/HStack";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import VStack from "../../Unknown/VStack";
import FoodItemRecord from "../FoodItemRecord";
import SearchForFoodItems from "../SearchForFoodItems";
import getImageName from "../utils/getImageName";
import translations from "./data";
import { numberMap, numberRowMap } from "./number-trasform-map";

const startListeningOptions: ListeningOptions = {
  language: "en-US",
};

const FoodItemImage: React.FC<{ image: string }> = ({ image }) => {
  return (
    <Box
      width={320}
      height={100}
      overflow="hidden"
      sx={{
        backgroundImage: `url(food-items-images/${image}.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* <Box
        sx={{
          width: "100%",
        }}
        component="img"
        src={`food-items-images/${image}.jpg`}
      ></Box> */}
    </Box>
  );
};

const UpdateItemModal: React.FC<{
  open: boolean;
  setOpen: any;
  onUpdate: any;
  updateItem?: FoodTrackingRow;
}> = ({ open, setOpen, updateItem, onUpdate }) => {
  // const handleOpen = () => setOpen(true);
  const [gramm, setGramm] = useState(updateItem?.weight || 0);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(updateItem?.weight_type || "g");
  const inputRef = useRef<any>(null);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (updateItem) setGramm(updateItem.weight);
    if (updateItem) setType(updateItem.weight_type);
  }, [updateItem]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [open, inputRef]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ModalCloseButton onClose={handleClose} />
        <VStack pt={10}>
          <TextField
            fullWidth
            inputRef={inputRef}
            color="primary"
            value={gramm}
            multiline={true}
            onChange={(event) => {
              setGramm(+event.target.value || 0);
            }}
            type="text"
            variant="outlined"
          />
          <HStack width="100%">
            <Button variant={type === "g" ? "contained" : "outlined"} onClick={() => setType("g")}>
              g
            </Button>
            <Button
              variant={type === "ml" ? "contained" : "outlined"}
              onClick={() => setType("ml")}
            >
              ml
            </Button>
          </HStack>
          <HStack>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={async () => {
                if (!updateItem) return;
                // setIsLoading(true);

                if (updateItem.key && updateItem.user === "Valmiki") {
                  const { data } = await supabaseClient
                    .from("food_tracking")
                    .select()
                    .eq("key", updateItem.key)
                    .eq("date", updateItem.date)
                    .eq("user", "Dayanvita")
                    .single();

                  await supabaseClient
                    .from("food_tracking")
                    .update({
                      weight: data.weight + updateItem.weight - gramm,
                      weight_type: type,
                    })
                    .eq("id", data.id);
                  await supabaseClient
                    .from("food_tracking")
                    .update({
                      weight: gramm,
                      weight_type: type,
                    })
                    .eq("id", updateItem.id);
                } else if (updateItem.key && updateItem.user === "Dayanvita") {
                  console.log("🚀 ~ updateItem:", updateItem);
                  const { data } = await supabaseClient
                    .from("food_tracking")
                    .select()
                    .eq("user", "Valmiki")
                    .eq("date", updateItem.date)
                    .eq("key", updateItem.key)
                    .single();

                  await supabaseClient
                    .from("food_tracking")
                    .update({
                      weight: data.weight + updateItem.weight - gramm,
                      weight_type: type,
                    })
                    .eq("id", data.id);
                  await supabaseClient
                    .from("food_tracking")
                    .update({
                      weight: gramm,
                      weight_type: type,
                    })
                    .eq("id", updateItem.id);
                } else {
                  await supabaseClient
                    .from("food_tracking")
                    .update({
                      weight: gramm,
                      weight_type: type,
                    })
                    .eq("id", updateItem.id);
                }

                setIsLoading(false);
                onUpdate();
              }}
            >
              Update
            </LoadingButton>
          </HStack>
        </VStack>
      </Box>
    </Modal>
  );
};

const DateModal: React.FC<{ dateValue: any; open: boolean; setOpen: any; setDateValue: any }> = ({
  dateValue,
  setDateValue,
  open,
  setOpen,
}) => {
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ModalCloseButton onClose={handleClose} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={dateValue}
            onChange={(newValue) => {
              setDateValue(newValue);
              handleClose();
            }}
          />
        </LocalizationProvider>
      </Box>
    </Modal>
  );
};

function findMatch(spokenInput: string, phrases: string[]) {
  let normalizedInput = spokenInput.toLowerCase();
  const inputWords = spokenInput.split(" ");
  // console.log("🚀 ~ inputWords:", inputWords);

  if (inputWords.length > 1) {
    normalizedInput = normalizedInput.replace("fruit", "").trim();
  }
  // console.log("🚀 ~ normalizedInput:", normalizedInput);

  const firstPhrases = phrases.filter((phrase) => {
    const loverPhrase = phrase.toLowerCase();

    if (loverPhrase.match(`\\b${normalizedInput}\\b`)) {
      return true;
    }

    return false;
  });

  if (firstPhrases.length) return firstPhrases;

  return phrases.filter((phrase) => {
    const loverPhrase = phrase.toLowerCase();

    if (loverPhrase.match(`\\b${normalizedInput}\\b`)) {
      return true;
    } else if (
      inputWords.filter((i) => i !== "fruit").filter((i) => loverPhrase.includes(i)).length
    ) {
      return true;
    }

    return false;
  });
}

const style = {
  position: "absolute" as "absolute",
  top: "25px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 400,
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function extractNumbers(string: any) {
  const numbers: number[] = string.replaceAll(",", "").match(/\d+/g)?.map(Number) || [];

  if (!!numbers.length) return numbers;

  for (let i = 0; i < numberRowMap.length; i++) {
    const currentNumbString = numberRowMap[i];

    if (string.includes(currentNumbString)) {
      numbers.push(numberMap[currentNumbString]);
    }
  }

  return numbers;
  // return numbers ? numbers.map(Number) : [];
}

const FoodTrackingScreen: React.FC = () => {
  const [food, setFood] = useState();
  const [weight, setWeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [weightType, setWeightType] = useState("g");
  const [possibleItems, setPossibleItems] = useState<
    { name: string; en_name: string; image: boolean }[]
  >([]);
  const [currentDayData, setCurrentDayData] = useState<FoodTrackingRow[]>([]);
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs(new Date()));
  const [mealCount, setMealCount] = useState(1);
  const [activeMeal, setActiveMeal] = useState("");
  const [person, setPerson] = useState("Split");
  const [open, setOpen] = useState(false);
  const [dateModalOpen, setDateModalOpen] = useState(false);
  const [updateItem, setUpdateItem] = useState<FoodTrackingRow>();
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [updateItemModalOpen, setUpdateItemModalOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [menuData, setMenuData] = useState(null);

  const {
    menu: recordItemContextMenu,
    activeItem,
    handleFoodItemRecordContextMenu,
  } = useContextMenuFoodTrackingRecodItem({
    // menuData,
    // menuItems: [<Item id="changeWeight">Change weight</Item>],
    onUpdate: async () => {
      const newData = await getCurrentDayData();
      setCurrentDayData(newData);
    },
  });
  // const [numberMap, setNumberMap] = useState<Record<string, number>>({});
  // const [numberRowOrdered, setNumberRowOrdered] = useState<string[]>([]);
  // console.log("🚀 ~ numberRowOrdered:", numberRowOrdered);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();
  console.log("🚀 ~ transcript:", transcript);

  // useMountedWaitAsyncEffect(async () => {
  //   const map = getNumberTransformMap();
  //   setNumberMap(
  //     Object.entries(map)
  //       .sort((a, b) => b[1] - a[1])
  //       .reduce((prev, curr) => {
  //         return {
  //           ...prev,
  //           [curr[0]]: curr[1],
  //         };
  //       }, {}),
  //   );
  //   // setNumberRowOrdered(Object.keys(map).sort((a, b) => b.length - a.length));
  //   // setNumberRowOrdered(Object.keys(map).reverse());
  //   navigator.clipboard.writeText(JSON.stringify(map));
  // }, []);

  const getCurrentDayData = async (): Promise<FoodTrackingRow[]> => {
    const date = format(dateValue!.toString(), "yyyy-MM-dd");
    const { data } = await supabaseClient
      .from("food_tracking")
      .select()
      .eq("date", date)
      .order("created_at");

    return data as FoodTrackingRow[];
  };

  useEffect(() => {
    let timer: any = null;

    if (transcript && listening) {
      timer = setTimeout(() => {
        SpeechRecognition.stopListening();
      }, 3000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [listening, transcript]);

  useMountedWaitAsyncEffect(async () => {
    const data = await getCurrentDayData();

    if (data) setCurrentDayData(data);
  }, [dateValue]);

  useMountedWaitAsyncEffect(async () => {
    if (transcript) {
      const phrases = Object.values(translations);
      let normalizedTranscript = transcript;

      if (normalizedTranscript.includes("halwa")) {
        normalizedTranscript = normalizedTranscript.replaceAll("halwa", "halva"); // because it is stupid
      }

      if (transcript.match(/\bml\b/i)) {
        setWeightType("ml");
        normalizedTranscript = normalizedTranscript.replace(/\bml\b/i, "");
      }
      if (transcript.match(/\bmilliliters\b/i)) {
        setWeightType("ml");
        normalizedTranscript = normalizedTranscript.replace(/\bmilliliters\b/i, "");
      }
      if (transcript.match(/\bmilliliter\b/i)) {
        setWeightType("ml");
        normalizedTranscript = normalizedTranscript.replace(/\bmilliliter\b/i, "");
      }

      const extractedNumbers = extractNumbers(normalizedTranscript);
      console.log("🚀 ~ normalizedTranscript:", normalizedTranscript);
      console.log("🚀 ~ extractedNumbers:", extractedNumbers);

      extractedNumbers.forEach((numb: number) => {
        if (normalizedTranscript.match(numb.toString())) {
          normalizedTranscript = normalizedTranscript.replaceAll(numb.toString(), "");
        }
      });

      if (extractedNumbers.length) {
        const number = Math.max(...extractedNumbers);
        console.log("🚀 ~ number:", number);
        setWeight(number);
      }

      if (!selectedItem) {
        const { data: data1 } = await supabaseClient
          .from("food_items")
          .select("name, en_name, image")
          .ilike("en_name", `%${normalizedTranscript}%`);
        if (!data1) return;

        const mainNames = data1!.map((i) => i.name);
        const data2 = (
          await Promise.all(
            normalizedTranscript
              .replace(/\s+/g, " ")
              .replace(/[^a-zA-Z0-9\s]/g, "")
              .split(" ")
              .map((str) => {
                return supabaseClient
                  .from("food_items")
                  .select("name, en_name, image")
                  .ilike("en_name", `%${str}%`)
                  .is("image", true)
                  .limit(5);
              }),
          )
        )
          .map((i) => i.data)
          .flat();

        const data: { name: string; en_name: string; image: boolean | null }[] = Object.values(
          [...data1!, ...data2].reduce((prev, curr: any) => {
            return {
              ...prev,
              [curr.name]: curr,
            };
          }, {}),
        );
        console.log("🚀 ~ data:", data);

        // const bestValue = findMatch(transcript, phrases);
        // if (bestValue.length) {
        //   setPossibleItems(bestValue);
        // }

        // console.log("🚀 ~ bestValue:", bestValue);

        if (data.length && !selectedItem) {
          setPossibleItems(
            data.sort((a, b) => {
              if (mainNames.includes(a.name)) return 1;
              if (mainNames.includes(b.name)) return 1;
              return a.image ? -1 : 1;
            }) as any,
          );
        }
      }
    }
  }, [transcript, selectedItem]);

  const prevListening = usePrevious(listening);

  useEffect(() => {
    if (prevListening === false && listening === true) {
      setWeightType("g");
    }
  }, [prevListening, listening]);

  const getDayFoodItemsCount = async () => {
    const { data } = await supabaseClient.rpc("get_day_food_items_count", {
      date: format(dateValue!.toString(), "yyyy-MM-dd"),
    });

    return data;
  };

  useMountedWaitAsyncEffect(async () => {
    const data = await getDayFoodItemsCount();

    if (data === 0) {
      setActiveMeal("1");
    } else {
      setMealCount(data);
      setActiveMeal(data.toString());
    }
  }, [dateValue]);

  const [itemsWithImages, setItemsWithImages] = useState<any[]>([]);
  // console.log("🚀 ~ itemsWithImages:", itemsWithImages);

  useMountedWaitAsyncEffect(async () => {
    // const loadedItems: any[] = [];
    // const result = await Promise.all(
    //   itemsWithImages.map((item) => {
    //     return supabaseClient.from("food_items").update({ image: true }).eq("name", item.name);
    //   }),
    // );
    // console.log("🚀 ~ result:", result);
    // // console.log("🚀 ~ data:", data);
    // if (!data) return;
    // data.forEach((item: any) => {
    //   const name = item.en_name.replaceAll(", ", "_").replaceAll(" / ", "_").replaceAll("/", "").replaceAll(" - ", "_").replaceAll(" ", "_");
    //   console.log("🚀 ~ name:", name);
    //   const img = new Image();
    //   img.src = `food-items-images/${name}.jpg`; // Adjust path as needed
    //   img.onload = () => {
    //     // loadedItems.push(item);
    //     setItemsWithImages((prev) => [...prev, item]);
    //   };
    //   img.onerror = (err) => {
    //     // console.log("🚀 ~ err:", err);
    //     // Handle image load error if needed
    //   };
    // });
  }, []);

  const [newWordsForAi, setNewWordsFOrAi] = useState<string[]>([]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <MenuLayout isFluid>
      {/* <Button
        onClick={async () => {
          const { data } = await supabaseClient.from("food_items").select("en_name").is("image", true);

          console.log("🚀 ~ data:", data);
          setNewWordsFOrAi(data!.map((i) => i.en_name));
        }}
      >
        Load words with images
      </Button>
      <CopyNewWordsForGemini words={newWordsForAi} /> */}
      {recordItemContextMenu}
      {/* <UpdateItemModal
        onUpdate={async () => {
          setUpdateItem(undefined);

          const newData = await getCurrentDayData();
          setCurrentDayData(newData);
          setUpdateItemModalOpen(false);
        }}
        updateItem={updateItem}
        open={updateItemModalOpen}
        setOpen={setUpdateItemModalOpen}
      /> */}
      <VStack sx={{ overflow: "hidden", width: "100%" }}>
        {/* <div>Transcript: {transcript}</div> */}
        {/* <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "50%",
            transform: "scale(1) translate(-50%, 0%)",
            transformOrigin: "0 0",
            // backgroundColor: "red",
            width: "100%",
            // opacity: 0.5,
          }}
        > */}
        {/* {!listening && (
            <Box sx={{ height: "100%", position: "absolute", zIndex: 1, backgroundColor: "white", width: "100%" }}>
              <Box
                sx={{
                  backgroundColor: "#4aa805",
                  margin: "99px 0 99px 0",
                  width: "100%",
                  height: 2,
                }}
              ></Box>
            </Box>
          )}
          {listening && <AudioVisualizer />}
        </Box> */}

        {/* <Button
          variant="outlined"
          onClick={async () => {
            const { data } = await supabaseClient.from("food_items").select("name, en_name").like("en_name", "%Cooked%");
            console.log("🚀 ~ data:", data);
            // console.log(
            //   "🚀 ~ data:",
            //   data.map((d) => d.name),
            // );
            // navigator.clipboard.writeText(JSON.stringify(data.map((d) => d.name)));
            const withTranslations = data?.map((d) => {
              return {
                ...d,
                en_name: d.en_name.replace("Cooked", "Boiled"),
              };
            });

            await Promise.all(
              withTranslations?.map((d) => {
                return supabaseClient
                  .from("food_items")
                  .update({ ...d })
                  .eq("name", d.name);
              }),
            );
            // console.log("🚀 ~ withTranslations:", withTranslations);
          }}
        >
          Добавити
        </Button> */}

        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          <Typography variant="caption" sx={{ px: 2 }}>
            {dateValue?.toString()}
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setDateModalOpen(true);
            }}
          >
            Choose a different date
          </Button>
        </Box>
        <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
          <WordsModalCarousel
            buttonLabel="продукти"
            words={[
              "Whole cow's milk",
              "Butter",
              "Halva",
              "Ketchup",
              "Whole wheat bread homemade",
              "Bread",
              "Dry rye bread",
              "Orange zest",
              "Green peas raw",
              "Raw pomegranate",
              "Dried banana",
              "Raw apple",
              "Raw peach",
              "Raw plum",
              "Dates",
              "Boiled apple without seeds",
              "Raw red beans",
              "Raw green kiwi",
              "Common melon",
              "Dried apple",
              "Beans green, raw",
              "Raw pink beans",
              "Nectarine raw",
              "Raw cherry",
              "Raw green beans",
              "Apple frozen",
              "Beans pinto, raw",
              "Raisins golden seedless",
              "Poppy seeds",
              "Tomato orange",
              "Hazelnut / filbert blanched",
              "Raw pistachio",
              "Flax seeds raw",
              "Raisins seedless",
              "Sweet pepper yellow",
              "Pistachio roasted",
              "Tomato red",
              "Strawberry raw",
              "Ginger root, raw",
              "Hazelnut roasted",
              "Raspberry raw",
              "Sweet pepper red",
              "Savoy cabbage raw",
              "Peanut raw",
              "Strawberry frozen",
              "Blueberry raw",
              "Peanut roasted",
              "Carrot boiled",
              "Avocado raw",
              "Tomato yellow",
              "Summer squash raw",
              "Sunflower seeds roasted",
              "Sunflower oil industrial",
              "Sweet pepper green",
              "Cauliflower raw",
              "Eggplant raw",
              "Oatmeal flakes, dry",
              "Hazelnut / filbert raw",
              "Pumpkin and zucchini seeds raw, dried",
              "Raw white beans",
              "Cheese",
              "Freshly squeezed orange juice",
              "Cucumber raw",
              "Raw black beans",
              "Raw lemon",
              "Fermented white cabbage",
              "Potato baked in skin",
              "Raw pear",
              "Potato raw peeled",
              "Raw apple without seeds",
              "Carrot raw",
              "Raw white cabbage",
              "Raw banana",
              "Raw watermelon",
              "White rice raw",
              "Carrot juice concentrated",
              "Buckwheat flour whole grain",
              "Pumpkin raw",
              "Buckwheat roasted boiled",
              "Cashew raw",
              "Corn grain",
              "Plum prune",
              "Cashew roasted",
              "Almond blanched",
              "Pumpkin and zucchini seeds roasted",
              "Almond roasted",
              "Walnut raw",
              "Zucchini raw with skin",
              "Sunflower seeds raw, dried",
              "Corn raw",
              "Tomato green",
              "Napa cabbage raw",
              "Bell pepper frozen",
              "Buckwheat roasted dry",
            ]}
          />
          {/* <LearnWordSection
                currentWords={currentWords}
                lessonId={`difference_slider_${subject}`}
                onUpdateWords={(newWords) => {
                  setCurrentWords(newWords);
                }}
              /> */}
        </Box>

        <DateModal
          open={dateModalOpen}
          setOpen={setDateModalOpen}
          dateValue={dateValue}
          setDateValue={setDateValue}
        />

        <Box width="100%">
          {Object.entries(
            currentDayData.reduce<Record<string, FoodTrackingRow[]>>((prev, cur) => {
              if (prev[cur.meal_count]) {
                return {
                  ...prev,
                  [cur.meal_count.toString()]: [...prev[cur.meal_count], cur],
                };
              }
              return {
                ...prev,
                [cur.meal_count.toString()]: [cur],
              };
            }, {}),
          )
            .map(([mealCount, foodRows]) => {
              return [
                mealCount,
                Object.entries(
                  foodRows
                    .sort(
                      (a, b) =>
                        new Date(a.created_at || 0).getTime() -
                        new Date(b.created_at || 0).getTime(),
                    )
                    .reduce<Record<string, FoodTrackingRow[]>>((prev, cur) => {
                      const prevItems = prev[cur.user];
                      return {
                        ...prev,
                        [cur.user]: prevItems ? [...prevItems, cur] : [cur],
                      };
                    }, {}),
                ),
              ];
            })
            .map(([mealCount, [items1, items2]]) => {
              console.log("🚀 ~ items2:", items2);
              console.log("🚀 ~ items1:", items1);
              const firstPerson = items1[0] === "Valmiki" ? items1[0] : items2?.[0];
              const firstPersonItems = items1[0] === "Valmiki" ? items1?.[1] : items2?.[1];
              console.log("🚀 ~ firstPerson:", firstPerson);
              const secondPerson = items1[0] === "Dayanvita" ? items1[0] : items2?.[0];
              const secondPersonItems = items1[0] === "Dayanvita" ? items1?.[1] : items2?.[1];
              console.log("🚀 ~ secondPerson:", secondPerson);

              return (
                <Paper
                  key={mealCount as any as number}
                  elevation={3}
                  sx={{ padding: 3, mb: 5, position: "relative" }}
                >
                  <Typography
                    variant="caption"
                    sx={{ textTransform: "uppercase", position: "absolute", top: 5, left: 5 }}
                  >
                    {mealCount}
                  </Typography>
                  <Grid container spacing={3}>
                    {firstPerson && (
                      <Grid item xs={6}>
                        <Typography fontSize={15} fontWeight={700}>
                          {firstPerson}
                        </Typography>
                        <Box>
                          {(firstPersonItems as any).map((item: any) => {
                            return (
                              <FoodItemRecord
                                key={item.id}
                                isActive={activeItem?.id === item.id}
                                item={item}
                                onContextMenu={handleFoodItemRecordContextMenu}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                    )}
                    {secondPerson && (
                      <Grid item xs={6}>
                        <Typography fontSize={15} fontWeight={700}>
                          {secondPerson}
                        </Typography>
                        <Box>
                          {(secondPersonItems as any)?.map((item: FoodTrackingRow) => {
                            return (
                              <FoodItemRecord
                                key={item.id}
                                isActive={activeItem?.id === item.id}
                                item={item}
                                onContextMenu={handleFoodItemRecordContextMenu}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <HStack>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setPerson("Split");
                            setActiveMeal(mealCount as string);
                            handleOpen();
                            SpeechRecognition.startListening(startListeningOptions);
                          }}
                        >
                          Add more
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setPerson("Valmiki");
                            setActiveMeal(mealCount as string);
                            handleOpen();
                            SpeechRecognition.startListening(startListeningOptions);
                          }}
                        >
                          Valmiki
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            setPerson("Dayanvita");
                            setActiveMeal(mealCount as string);
                            handleOpen();
                            SpeechRecognition.startListening(startListeningOptions);
                          }}
                        >
                          Dayanvita
                        </Button>
                      </HStack>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
        </Box>
        <HStack mb={5}>
          {/* {mealCount === 0 &&
          } */}
          <Button
            variant="contained"
            onClick={async () => {
              // setActiveMeal((index + 1).toString());
              handleOpen();
              const data = await getDayFoodItemsCount();
              setActiveMeal(data + 1);
              setTimeout(() => {
                SpeechRecognition.startListening(startListeningOptions);
              }, 100);
            }}
          >
            {/* {mealCount === 1 ? "First" : index + 1} */}
            Add new meal
          </Button>
          {/* {mealCount === 1 && <Button variant={activeMeal === mealCount.toString() || activeMeal === "First" ? "contained" : "outlined"}>{mealCount === 1 ? mealCount + 1 : "First"}</Button>} */}
        </HStack>
        <Box mt={5} mb={10} width="100%" maxWidth={400}>
          <SearchForFoodItems type="ua" />
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ModalCloseButton onClose={handleClose} />
            <Typography>№: {activeMeal}</Typography>
            <Typography variant="caption">{transcript}</Typography>
            <VStack>
              {!!activeMeal && (
                <Box>
                  <IconButton
                    // sx={{
                    //   pointerEvents: "none",
                    // }}
                    onClick={() => {
                      if (listening) {
                        SpeechRecognition.stopListening();
                      } else {
                        SpeechRecognition.startListening(startListeningOptions);
                      }
                    }}
                  >
                    <MicIcon
                      sx={{
                        fontSize: 50,
                        color: listening ? "#4aa805" : "black",
                      }}
                    />
                  </IconButton>
                  {/* <Button onClick={() => SpeechRecognition.startListening(startListeningOptions)}>Start</Button> */}
                  {/* <Button onClick={SpeechRecognition.stopListening}>Stop</Button> */}
                  {/* <Button onClick={resetTranscript}>Reset</Button> */}
                  {/* <p>{transcript}</p> */}
                </Box>
              )}
            </VStack>
            {!!weight && (
              <Box>
                <Typography>
                  {weight} {weightType}
                </Typography>
              </Box>
            )}
            {(selectedItem
              ? possibleItems.filter((i) => i.name === selectedItem)
              : possibleItems
            ).map((item) => (
              <Typography
                onClick={() => {
                  // setPossibleItems((prev) => prev.filter((i) => i.en_name === item.en_name));
                  if (selectedItem) {
                    setSelectedItem(null);
                  } else {
                    setSelectedItem(item.name);
                  }
                }}
                sx={{ cursor: "pointer" }}
                gutterBottom
                key={item.name}
                color={selectedItem === item.name ? "primary" : "black"}
              >
                <Typography fontWeight={700} fontFamily="Rubik" fontSize={22}>
                  {item.en_name}
                </Typography>

                {item.en_name && item.image && <FoodItemImage image={getImageName(item.en_name)} />}
                {/* {item.en_name && item.image && getImageName(item.en_name)} */}
                {item.en_name && !item.image && <hr />}
              </Typography>
            ))}
            {selectedItem && possibleItems.length >= 1 && (
              <HStack mb={5}>
                <Button
                  variant={person === "Valmiki" ? "contained" : "outlined"}
                  onClick={() => setPerson("Valmiki")}
                >
                  Valmiki
                </Button>
                <Button
                  variant={person === "Split" ? "contained" : "outlined"}
                  onClick={() => setPerson("Split")}
                >
                  Split
                </Button>
                <Button
                  variant={person === "Dayanvita" ? "contained" : "outlined"}
                  onClick={() => setPerson("Dayanvita")}
                >
                  Dayanvita
                </Button>
              </HStack>
            )}
            {selectedItem && possibleItems.length >= 1 && !!weight && (
              <>
                <LoadingButton
                  loading={isLoading}
                  variant="outlined"
                  onClick={async () => {
                    // setIsLoading(true);

                    const date = format(dateValue!.toString(), "yyyy-MM-dd");
                    console.log("🚀 ~ possibleItems:", possibleItems);
                    const [item] = possibleItems.filter((i) => i.name === selectedItem);
                    console.log("🚀 ~ selectedItem:", selectedItem);
                    console.log("🚀 ~ item:", item);
                    const key = uuidv4();

                    const { data: foodItem } = await supabaseClient
                      .from("food_items")
                      .select("name")
                      .eq("en_name", item.en_name)
                      .single();

                    if (person === "Split") {
                      await supabaseClient.from("food_tracking").insert({
                        date,
                        meal_count: activeMeal,
                        weight: weight / 2,
                        weight_type: weightType,
                        user: "Valmiki",
                        key,
                        item: foodItem!.name,
                      });
                      await supabaseClient.from("food_tracking").insert({
                        date,
                        meal_count: activeMeal,
                        weight_type: weightType,
                        weight: weight / 2,
                        user: "Dayanvita",
                        key,
                        item: foodItem!.name,
                      });
                    } else {
                      await supabaseClient.from("food_tracking").insert({
                        date,
                        meal_count: activeMeal,
                        weight_type: weightType,
                        weight,
                        user: person,
                        item: foodItem!.name,
                      });
                    }

                    const newData = await getCurrentDayData();
                    setCurrentDayData(newData);
                    setWeight(0);
                    setPossibleItems([]);
                    resetTranscript();
                    // handleClose
                    setSelectedItem(null);
                    setIsLoading(false);
                  }}
                >
                  Добавити
                </LoadingButton>
              </>
            )}
          </Box>
        </Modal>
      </VStack>
    </MenuLayout>
  );
};

export default FoodTrackingScreen;
