import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import LearnWordSection, { ChangePositionButton } from "../../Unknown/LearnWordsSection";
import { Box, Button, Drawer, Typography } from "@mui/material";
import FullScreenLoader from "../../Unknown/FullScreenLoader";
import LinearProgress from "../../User/LinearProgress";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import VStack from "../../Unknown/VStack";
import HStack from "../../Unknown/HStack";
import { useLessonsStore } from "../../../store/lessonsStore";
import WordExample from "../../Unknown/LearnWordsSection/WordExample";
import { Word } from "../../Admin/SPTranslationItem";
import { ReadToLearnLetter } from "../LearnToReadScreen";
import useGlobalWords from "../../hooks/getGlobalWords";
import WordAudio from "../../Audio/WordAudio";
import YouTubePlayer from "../../Unknown/YouTubePlayer";

const LettersCarousel: React.FC<{
  letters: ReadToLearnLetter[];
  newLettersCount?: number;
  newSounsCount?: number;
}> = ({ letters, newLettersCount, newSounsCount }) => {
  const [currentLetters, setCurrentLetters] = useState<ReadToLearnLetter[]>(letters);

  useEffect(() => {
    if (!currentLetters.length) setCurrentLetters(letters);
  }, [letters]);

  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {
        <Button variant="outlined" onClick={() => setIsOpen(true)}>
          Літери {newLettersCount ? `(${newLettersCount})` : ""} / звуки ({newSounsCount})
        </Button>
      }
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <DrawerContent currentLetters={currentLetters} onClose={handleDrawerClose} />
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const DrawerContent: React.FC<{
  currentLetters: ReadToLearnLetter[];
  onClose: () => void;
}> = ({ currentLetters: inputCurrentLetters, onClose }) => {
  const [currentLetters, setCurrentLetters] = useState(() => {
    return inputCurrentLetters.filter((l) => !l.isHidden);
  });

  useEffect(() => {
    setCurrentLetters(inputCurrentLetters.filter((l) => !l.isHidden));
  }, [inputCurrentLetters]);

  const [repeatedWords, setRepeatedWords] = useState<string[]>([]);
  const [isTranslated, setIsTranslated] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const setPersonalLessonsWords = useLessonsStore((state) => state.setPersonalLessonsWords);
  const [letterIndex, setLetterIndex] = useState(0);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const currentLetter = currentLetters[letterIndex] || [];
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});

  const allWords = useMemo(() => {
    return inputCurrentLetters
      .map(({ letter, word }) => [letter, word])
      .flat()
      .filter(Boolean);
  }, [inputCurrentLetters]);

  const options = useMemo(() => {
    return { words: allWords };
  }, [allWords]);

  useGlobalWords(options);

  useEffect(() => {
    if (currentLetters?.length) {
      const map = globalWords
        .filter((w) => allWords.includes(w.name) || allWords.includes(w.name.toLowerCase()))
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
          };
        }, {});

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, allWords]);

  const drawer = useMemo(() => {
    if (!currentLetter)
      return (
        <Box sx={drawerContentStyle}>
          <FullScreenLoader />
        </Box>
      );

    return (
      <Box sx={drawerContentStyle}>
        <LinearProgress elementsCount={currentLetters.length - 1} currentValue={letterIndex} />
        <ModalCloseButton onClose={onClose} />
        <VStack
          px={5}
          justifyContent="center"
          position="relative"
          width="100vw"
          flexWrap="nowrap"
          pt={10}
          pb={10}
          sx={{
            overflowX: "hidden",
          }}
        >
          <VStack position="relative" sx={{ width: "100%", overflowX: "hidden", pb: 5 }}>
            {currentLetter.isPrev && (
              <Typography
                sx={{
                  display: "block",
                  fontFamily: "Rubik",
                  textTransform: "uppercase",
                  color: "green",
                }}
              >
                повторення
              </Typography>
            )}
            <VStack mb={5}>
              <HStack alignItems="center">
                {curerntModuleWordsMap[currentLetter.letter]?.audio_url && (
                  <WordAudio small url={curerntModuleWordsMap[currentLetter.letter].audio_url} />
                )}
                {/* {curerntModuleWordsMap[currentLetter.letter]?.audio_url && (
                  <Typography fontSize={30} color="black" fontFamily="Roboto">
                    {curerntModuleWordsMap[currentLetter.letter].transcription}
                  </Typography>
                )} */}
                <Typography color="primary" variant="h3" fontSize={50} fontFamily="Rubik">
                  {currentLetter.letter}
                </Typography>
              </HStack>

              <Typography
                fontSize={20}
                fontFamily="Rubik"
                sx={{ display: "flex" }}
                alignItems="center"
              >
                Дає звук:&nbsp;
                <Typography fontSize={24} color="#ffa100" fontFamily="Roboto">
                  |{currentLetter.sound}|
                </Typography>
              </Typography>
              <Typography fontSize={50} fontFamily="Rubik">
                {currentLetter.word}
              </Typography>
              <HStack alignItems="center">
                {curerntModuleWordsMap[currentLetter.word]?.audio_url && (
                  <WordAudio small url={curerntModuleWordsMap[currentLetter.word].audio_url} />
                )}
                <Typography fontSize={30} color="#ffa100" fontFamily="Roboto">
                  {currentLetter.trn}
                </Typography>
              </HStack>
            </VStack>

            <Button
              variant="outlined"
              onClick={() => {
                if (letterIndex < currentLetters.length - 1) {
                  setLetterIndex(letterIndex + 1);
                } else {
                  setLetterIndex(0);
                }
              }}
            >
              Далі
            </Button>

            {currentLetter.sound === "ŋ" && (
              <HStack alignItems="flex-start">
                <YouTubePlayer id="SqKXiVHPgDE" start={31} duration={21} width={300} height={180} />
                <YouTubePlayer id="LiHHQJgEfJI" start={32} duration={21} width={300} height={180} />
              </HStack>
            )}
          </VStack>
        </VStack>
      </Box>
    );
  }, [authUser, currentLetter, currentLetters, isTranslated, repeatedWords, onClose]);

  return drawer;
};

export default LettersCarousel;
