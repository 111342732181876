import { Link as MuiLink, Box, Typography, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { Module, Sentence, Word } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import Loader from "../../Unknown/Loader";
import RepeatLastLessons from "./RepeatLastLessons";
import YouTubeClipPlayer from "../YouTubeClipPlayer";

export const GrammarScreen: React.FC = () => {
  const [lessonSentences, setLessonSentences] = useState<Sentence[]>([]);
  const [module, setModule] = useState<Module>();
  const [currentModuleWords, setCurrentModuleWords] = useState<Word[]>([]);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const authUser = useLessonsStore((state) => state.authUser);
  const grammarModules = useLessonsStore((state) => state.grammarModules);
  const setGrammarModules = useLessonsStore((state) => state.setGrammarModules);

  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});

  // const [videos, setVideos] = useState<any[]>([]);
  // useMountedWaitAsyncEffect(async () => {
  //   const { data } = await supabaseClient
  //     .from("english_verses")
  //     .select()
  //     .eq("youtube_id", "SmSRH7kpdYA");
  //   setVideos(data!);
  // }, []);

  useEffect(() => {
    if (module) {
      const map = globalWords
        .filter(
          (w) =>
            module.words.includes(w.name) || module.words.includes((w.name || "").toLowerCase()),
        )
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [(w.name || "").toLowerCase()]: w,
          };
        }, {});
      console.log("🚀 ~ map:", map);

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, module]);

  useMountedWaitAsyncEffect(async () => {
    if (module?.words) {
      const existingWords = globalWords.filter(
        (w) => module.words.includes(w.name) || module.words.includes(w.name.toLowerCase()),
      );

      if (existingWords.length === module.words.length) {
        setCurrentModuleWords(existingWords);
      } else {
        const rowExistingWords = existingWords.map((w) => w.name);
        const notExistingWords = module.words.filter(
          (w) => !rowExistingWords.includes(w) && !rowExistingWords.includes(w.toLowerCase()),
        );

        const { data } = await supabaseClient.from("words").select().in("name", notExistingWords);

        if (data) {
          setCurrentModuleWords([...existingWords, ...data]);

          setGlobalWords(data);
        }
      }
    }
  }, [module]);

  useMountedWaitAsyncEffect(async () => {
    if (!grammarModules.length && authUser?.grammar_lesson) {
      const { data: modules } = await supabaseClient
        .from("grammar_course")
        .select()
        .order("module")
        .limit(authUser.grammar_lesson < 10 ? authUser.grammar_lesson : authUser.grammar_lesson + 3)
        .returns<Module[]>();

      if (modules) {
        const sorted = modules.sort((a, b) => b.module - a.module);
        const updatedModules: Module[] = [];

        sorted.forEach((module) => {
          if (authUser.grammar_lesson! < module.module) {
            updatedModules.push({ ...module, isVisible: false });
          } else {
            updatedModules.push(module);
          }
        });
        setGrammarModules(updatedModules);
      }
    }
  }, [authUser]);

  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const setLessonNumber = useLessonsStore((state) => state.setLessonNumber);

  useEffect(() => {
    if (!lessonNumber && lessonNumber !== 0) {
      setLessonNumber(authUser?.grammar_lesson || 0);
    }
  }, [lessonNumber, authUser]);

  return (
    <MenuLayout isFluid>
      {/* {videos.map((v) => (
        <YouTubeClipPlayer videoID="SmSRH7kpdYA" start={v.clip_start} duration={v.duration} />
      ))} */}
      {/* {allWords.map((w) => (
              <Box component="span" sx={{ p: 1, display: "inline-block" }}>
                <br />
                ---
                <br />
                module: {w.module}
                <br />
                ---
                <br />
                {w.words?.map((w: any) => (
                  <Box component="span" sx={{ p: 1, display: "inline-block" }}>
                    {w}
                  </Box>
                ))}
              </Box>
            ))} */}
      {/* <Container> */}
      {/* <Typography>Відео</Typography>
              <Box>
                <Typography>The Alphabet</Typography>
              </Box> */}
      {/* </Container> */}
      <Container sx={{ backgroundColor: "white", py: 5 }}>
        {/* {grammarModules.map((l) => (
          <Box>{l.id}</Box>
        ))} */}
        <RepeatLastLessons modules={grammarModules} />
        <Typography variant="h3">Уроки</Typography>
        {!grammarModules?.length && <Loader />}
        {!!grammarModules.length &&
          grammarModules
            // .sort((a, b) => a.module - b.module)
            .map((module) => {
              const linkText = (
                <Typography pb={1}>
                  {module.module}.{module.description}
                  <Typography component="span" color="primary" sx={{ fontSize: 12 }}>
                    {" "}
                    (
                    {module.module === 1
                      ? module.words_count
                      : `${module?.words_count} / +${module?.new_words_count}`}
                    )
                  </Typography>
                </Typography>
              );

              if (
                !authUser ||
                !authUser.grammar_lesson ||
                authUser.grammar_lesson < module.module
              ) {
                return <Box sx={{ color: "grey" }}>{linkText}</Box>;
              }

              return (
                <Box>
                  <MuiLink
                    key={module.module}
                    color="secondary.dark"
                    component={Link}
                    display="inline-block"
                    to={`/grammar/grammar-lesson/${module.module}/build-words-list`}
                  >
                    {linkText}
                  </MuiLink>
                </Box>
              );
            })}
      </Container>
    </MenuLayout>
  );
};
