import { Box, Button, Modal } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import ModalCloseButton from "./ModalCloseButton";
import { fullScreenModalStyle } from "../../common/constants";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";

const PdfModal: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scale, setScale] = useState(1);
  const [page, setPage] = useState(1);
  const windowRef = useRef();
  const [pdfDocument, setPdfDocument] = useState<any>(null);
  const [extractedText, setExtractedText] = useState("");

  const handleClose = () => setIsOpen(false);

  // useMountedWaitAsyncEffect(async () => {
  //   const loadingTask = pdfjsLib.getDocument(url);
  //   loadingTask.promise
  //     .then(function (doc: any) {
  //       const numPages = doc.numPages;
  //       console.log("# Document Loaded");
  //       console.log("Number of Pages: " + numPages);
  //       console.log();

  //       let lastPromise; // will be used to chain promises
  //       lastPromise = doc.getMetadata().then(function (data: any) {
  //         console.log("# Metadata Is Loaded");
  //         console.log("## Info");
  //         console.log(JSON.stringify(data.info, null, 2));
  //         console.log();
  //         if (data.metadata) {
  //           console.log("## Metadata");
  //           console.log(JSON.stringify(data.metadata.getAll(), null, 2));
  //           console.log();
  //         }
  //       });
  //       const loadPage = function (pageNum: number) {
  //         return doc.getPage(pageNum).then(function (page: any) {
  //           console.log("# Page " + pageNum);
  //           const viewport = page.getViewport({ scale: 1.0 });
  //           console.log("Size: " + viewport.width + "x" + viewport.height);
  //           console.log();
  //           return page
  //             .getTextContent()
  //             .then(function (content: any) {
  //               // Content contains lots of information about the text layout and
  //               // styles, but we need only strings at the moment
  //               const strings = content.items.map(function (item: any) {
  //                 return item.str;
  //               });

  //               setExtractedText((prevText) => prevText + strings.join(" "));

  //               console.log("## Text Content");
  //               console.log(strings.join(" "));
  //               // Release page resources.
  //               page.cleanup();
  //             })
  //             .then(function () {
  //               console.log();
  //             });
  //         });
  //       };
  //       // Loading of the first page will wait on metadata and subsequent loadings
  //       // will wait on the previous pages.
  //       for (let i = 1; i <= numPages; i++) {
  //         lastPromise = lastPromise.then(loadPage.bind(null, i));
  //       }
  //       return lastPromise;
  //     })
  //     .then(
  //       function () {
  //         console.log("# End of Document");
  //       },
  //       function (err: any) {
  //         console.error("Error: " + err);
  //       },
  //     );
  // }, []);

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Читати
      </Button>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={fullScreenModalStyle}>
          {/* <ModalCloseButton onClose={handleClose} />
          <div>{extractedText}</div> */}
        </Box>
      </Modal>
    </>
  );
};

export default PdfModal;
