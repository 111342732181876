import { useEffect, useMemo, useState } from "react";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import { Word } from "../../types/supabase";
import { usePrevious } from "react-use";
import deepEqual from "deep-equal";
import firstLetterUppercase from "../../common/firstLetterUppercase";

const useGlobalWords = ({ words: iW }: { words: string[] }): Word[] => {
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [inputWords, setInputWords] = useState(iW.map((w) => w.replace("–", "-")));

  useEffect(() => {
    setInputWords(iW.map((w) => w.replace("–", "-")));
  }, [iW]);
  // const [isFirstLoaded, setIsFirstLoaded] = useState(false);

  // console.log("🚀 ~ inputWords:", inputWords);
  const words = useMemo(() => {
    return globalWords.filter((word) => {
      try {
        const searchWords =
          (inputWords.length && inputWords.includes(word.name)) ||
          inputWords.map((w) => w.toLowerCase()).includes(word.name) ||
          inputWords.map((w) => w.toLowerCase()).includes(word.name.toLowerCase());
        return searchWords;
      } catch (err) {
        console.log("🚀 ~ err:", err);
        return [];
      }
    });
  }, [inputWords, globalWords]);
  // console.log("🚀 &&&&&&&&&&:", words);

  const prevInputWords = usePrevious(inputWords);
  // console.log("🚀 ~ prevInputWords:", prevInputWords);

  // useEffect(() => {
  //   if (words.length && prevInputWords && prevInputWords?.length > words.length) {
  //     const rowWords = words.map((w) => w.name);

  //     const notExistingWords = inputWords.filter((w) => !rowWords.includes(w));
  // console.log("🚀 ~ notExistingWords:", notExistingWords);

  // if (notExistingWords.length) alert(`No existing words: ${notExistingWords.join(", ")}`);
  //   }
  // }, [prevInputWords, words]);

  useMountedWaitAsyncEffect(
    // useEffect(() => {
    async () => {
      const rowWords = words.map((w) => w.name);
      const notExistingWords = inputWords.filter((w) => !rowWords.includes(w));
      // console.log("🚀 ~ notExistingWords:", notExistingWords, inputWords, rowWords);

      try {
        if (
          inputWords.filter(Boolean).length &&
          inputWords.join("") !== words.map((w) => w.name).join("") &&
          // !deepEqual(prevInputWords, inputWords) &&
          notExistingWords.length &&
          words.length < inputWords.length
        ) {
          // ||
          const rowWords = words.map((w) => w.name.toLowerCase()).filter(Boolean);
          console.log("🚀 ~ rowWords:", rowWords);
          const notExistingWords = inputWords
            .map((w) => w.replace("–", "-"))
            .filter(Boolean)
            .filter((w) => !rowWords.includes(w.toLowerCase()));
          // .filter((w) => !["1-10"].includes(w));
          console.log("🚀 ~ notExistingWords:", notExistingWords);
          if (notExistingWords.length) {
            const shit = notExistingWords[0];

            // console.log("🚀 ~ shit:", shit);
            // console.log("🚀 ~ shit length:", shit.length);
            // console.log("🚀 ~ 1-10 length:", "1-10".length);
            // console.log("🚀 ~ shit 0:", shit[0] === "1-10"[0]);
            // console.log("🚀 ~ shit 1:", shit[1], "1-10"[1], shit[1] === "1-10"[1]);
            // console.log("🚀 ~ shit 2:", shit[2] === "1-10"[2]);
            // console.log("🚀 ~ shit 3:", shit[3] === "1-10"[3]);

            // console.error(
            //   `Not existing words found: ${notExistingWords.join(", ")} -- ${deepEqual(
            //     notExistingWords[0].split("").join(),
            //     "1-10",
            //   )} --  ${notExistingWords[0].split("")} -- ${
            //     notExistingWords[0].length === "1-10".length
            //   }`,
            // );
          } else {
            return;
          }

          // !isFirstLoaded
          const wordsToSearch = Array.from(
            new Set([
              ...inputWords,
              ...inputWords.map((w) => w.toLowerCase()),
              ...inputWords.map((w) => firstLetterUppercase(w)),
            ]),
          );
          // console.log("🚀 ~ wordsToSearch:", wordsToSearch);
          // console.log("🚀 ~ wordsToSearch:", wordsToSearch);
          const { data } = await supabaseClient
            .from("words")
            .select()
            .in("name", wordsToSearch)
            .returns<Word[]>();
          // setIsFirstLoaded(true);
          console.log("🚀 ~ data:", wordsToSearch, data);
          if (data) setGlobalWords(data);
        }
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    },
    [words, inputWords, prevInputWords],
  );

  useEffect(() => {
    (async () => {
      try {
        if (
          inputWords.filter(Boolean).length &&
          inputWords.join("") !== words.map((w) => w.name).join("") &&
          !deepEqual(prevInputWords, inputWords)
        ) {
          const wordsToSearch = Array.from(
            new Set([...inputWords, ...inputWords.map((w) => w.toLowerCase())]),
          );
          // console.log("🚀 ~ wordsToSearch:", wordsToSearch);
          const { data } = await supabaseClient
            .from("words")
            .select()
            .in("name", wordsToSearch)
            .returns<Word[]>();
          console.log("🚀 ~ data:", wordsToSearch, data);
          if (data) setGlobalWords(data);
        }
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    })();
  }, []);

  return words;
};

export default useGlobalWords;
