import removePunctuationFromString from "./removePunctuationFromString";

function startsWithSubstringAndNonLetter(str: string, substring: string) {
  const cleanStr = removePunctuationFromString(str.toLowerCase());
  const cleanSubstring = removePunctuationFromString(substring.toLowerCase());
  // Check if the string starts with the substring
  if (!cleanStr.startsWith(cleanSubstring)) {
    return false;
  }

  // Check if the character after the substring is not a letter
  const nextChar = cleanStr[cleanSubstring.length];
  return !/[a-zA-Z]/.test(nextChar) || cleanStr.length === cleanSubstring.length;
}

export default startsWithSubstringAndNonLetter;
