import { Typography } from "@mui/material";

interface ItalicTitleProps {
  text: any;
  color?: string;
  size?: number;
  fontFamily?: string;
  isItalic?: boolean;
}

const ItalicTitle = ({ text, color, fontFamily, isItalic = true, size }: ItalicTitleProps) => {
  return (
    <Typography
      variant="caption"
      fontStyle={isItalic ? "italic" : "normal"}
      fontFamily={fontFamily || "Rubik"}
      fontSize={size || 18}
      fontWeight="700"
      // color="primary.dark"
      color={color || "secondary.dark"}
    >
      {text}
    </Typography>
  );
};

export default ItalicTitle;
