import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import { addSeconds } from "date-fns";
import useCreateAudio from "../../../common/useCreateAudio";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import Loader from "../../Unknown/Loader";
import supabaseClient from "../../../common/supabaseClient";
import { Sentence } from "../../../types/supabase";
import HStack from "../../Unknown/HStack";

const allCourseWords = `let, taste, pluck, gives, let's, give, us, language, eats, flowers, coffee, vegetables, her, breakfast, fruit, water, like, some, why, starts, so, eat, sleep, Charlie's, lunch, cup, me, plucks, help, books, on, always, Brazil, drink, watches, sleeping, kitchen, watching, drinks, Mondays, start, flower, the, week, them, Charlie, Martha, watch, animals, tea, meeting, please, likes, Monday, fruits, Fran's, him, helping, later, late, people, care, vegetarian, soup, good, are, is, candy, rice, boy, fish, apples, juice, new, rose, am, animal, zipper, Fran, a, cats, girl, house, cat, newspaper, socks, tomatoes, buys, cares, kangaroo, dogs, tastes, X-ray, dog, just, apple, umbrella, other, buy, rope, Lisa, grass, needs, alphabet, queen, chicken, ice cream, orange, meat, money, ball, doctor, elephant, an, yellow, violin, owl, pencil, banana, goat, roses, park, running, worrying, eating, dancing, run, jumping, walking, teacher, walks, Canada, United States, what's, too, drinking, from, name, who's, sorry, teach, guitar, nice, play, Ukraine, playing, plays, meet, thank, hi, alright, piano, name's, cake, catch, build, yes, pig, hear, can, cancan, I, speak, you, we, they, he, speaks, she, know, knows, do, does, don't, doesn't, study, English, in, school, studies, and, work, works, every, day, it, learn, learns, very, well, go, to, today, jig, no, not, who, loves, not, what, be, love, want, wants, think, dance, climb, tree, can't, runs, jump, rat, mouse, walk, happy, with, need, have, worry, has, dances, fleas, jumps, worries, oranges, about, bananas, climbs, flea, builds, hospital, library, ok, I'm, tasting, okay, climbing, she's, they're, now, your, you're, going, or, he's, learning, tall, book, reading, studying, working, it's, glass, doing, bedroom, building, we're, brother, room, bed, read, for, full, where's, but, hello, that, that's, zoo, jungles, say, see, where, snake, sister, Betty, of, parrot, beautiful, monkey, short, Betty's, if, lion, my`;

const RenderPossibleAnswer: React.FC<{
  ua: string;
  en: string;
  onRemove: () => void;
  onUpdate: (en: string, ua: string) => void;
}> = ({ ua, en, onUpdate, onRemove }) => {
  return (
    <Box position="relative">
      <Box position="absolute" left={-50} top={0}>
        <IconButton
          onClick={() => {
            onRemove();
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <TextField
        // value={en}
        defaultValue={en}
        multiline
        size="small"
        InputProps={{
          sx: {
            fontWeight: 700,
          },
        }}
        fullWidth
        onChange={(event) => {
          // setAEn(event.target.value);
          onUpdate(event.target.value, ua);
        }}
      />
      <TextField
        // value={ua}
        defaultValue={ua}
        multiline
        size="small"
        fullWidth
        onChange={(event) => {
          // setAUa(event.target.value);
          onUpdate(en, event.target.value);
        }}
      />
      {/* <Box>
        {possibleAnswers.map((pA) => {
          return (
            <Box pl={20}>
              <Typography>{pA.en}</Typography>
              <Typography variant="caption" sx={{ display: "block" }}>
                {pA.ua}
              </Typography>
            </Box>
          );
        })}
      </Box> */}
    </Box>
  );
};

type Question = {
  en: string;
  ua: string;
};
type Answer = {
  en: string;
  ua: string;
};

const RenderQuestion: React.FC<{
  question: Question;
  possibleAnswers: Answer[];
  chapterId: string;
  onUpdate: (newQuestionObject: { question: Question; possibleAnswers: Answer[] }) => void;
  onRemove: () => void;
}> = ({ question, chapterId, possibleAnswers: inputPossibleAnswers }) => {
  const [questionEn, setQuestionEn] = useState(question.en);
  const [questionUa, setQuestionUa] = useState(question.ua);
  const [isLoading, setIsLoading] = useState(false);
  const [isExists, setIsExists] = useState(false);
  const [possibleAnswers, setPossibleAnswers] = useState(inputPossibleAnswers);

  const { createAudio } = useCreateAudio();

  useMountedWaitAsyncEffect(
    async () => {
      const { data: existingSentence } = await supabaseClient
        .from("sentences")
        .select()
        .eq("en", question.en.trim())
        .maybeSingle();

      if (existingSentence) setIsExists(true);
    },
    [, questionEn, questionUa],
    2000,
  );

  if (!questionEn) return null;

  return (
    <Box mb={isExists ? 1 : 4} position="relative">
      {!isExists && (
        <Box position="absolute" left={-50} top={0}>
          <IconButton
            sx={{ color: "red" }}
            onClick={() => {
              setQuestionEn("");
            }}
          >
            <DeleteIcon />
          </IconButton>
          {isLoading && <Loader />}
          <IconButton
            disabled={isLoading}
            sx={{
              top: 50,
              left: 0,
              color: "green",
              position: "absolute",
            }}
            // loading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);

                const { data: existingSentence } = await supabaseClient
                  .from("sentences")
                  .select()
                  .eq("en", questionEn.trim())
                  .maybeSingle();
                console.log("🚀 ~ existingSentence:", existingSentence);

                if (existingSentence) {
                  setIsExists(true);
                  setIsLoading(true);
                  return;
                }

                const { data: lastId } = await supabaseClient
                  .from("sentences")
                  .select("id")
                  .order("id", { ascending: false })
                  .limit(1)
                  .single();
                console.log("🚀 ~ lastId:", lastId);

                if (!lastId) throw new Error("Last is is not found");

                const audio = await createAudio(questionEn);
                console.log("🚀 ~ audio:", audio);
                if (!audio) throw new Error("Audio not found");

                const newSentence = {
                  en: questionEn.trim(),
                  ua: questionUa.trim(),
                  id: lastId.id + 1,
                  en_audio_url: audio,
                  ua_audio_url: "",
                  answers: possibleAnswers,
                  clip_id: chapterId,
                  is_question: true,
                } as Sentence;

                await supabaseClient.from("sentences").insert(newSentence);
                await supabaseClient.from("user-sentences").insert({
                  id: `19c7ac10-110d-43e3-ad86-5e425aef49a5-${questionEn.trim()}`,
                  user: "19c7ac10-110d-43e3-ad86-5e425aef49a5",
                  en: questionEn.trim(),
                  repeat: addSeconds(new Date(), 2).toISOString(),
                });
                setIsExists(true);
              } catch (err: any) {
                console.log("🚀 ~ err:", err.message);
              }

              setIsLoading(false);
            }}
          >
            <UploadIcon />
          </IconButton>
        </Box>
      )}
      <TextField
        value={questionEn}
        multiline
        size="small"
        InputProps={{
          sx: {
            fontWeight: 700,
            color: isExists ? "green" : "black",
          },
        }}
        fullWidth
        onChange={(event) => {
          setQuestionEn(event.target.value);
        }}
      />
      {!isExists && (
        <TextField
          value={questionUa}
          multiline
          size="small"
          fullWidth
          onChange={(event) => {
            setQuestionUa(event.target.value);
          }}
        />
      )}
      {!isExists && (
        <Box pl={10}>
          {possibleAnswers.map((pA, index) => {
            return (
              <RenderPossibleAnswer
                key={pA.en}
                en={pA.en}
                ua={pA.ua}
                onRemove={() => {
                  const newPossibleAnswers = possibleAnswers.filter(
                    (a, iIndex) => iIndex !== index,
                  );
                  setPossibleAnswers(newPossibleAnswers);
                }}
                onUpdate={(en, ua) => {
                  console.log("on update", en, pA.en);
                  setPossibleAnswers(
                    possibleAnswers.map((a, prevIndex) => {
                      if (index === prevIndex) return { en, ua };
                      return a;
                    }),
                  );
                }}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

const AiQuestionAnswer: React.FC<{
  chapterText: string;
  chapterId: string;
}> = ({ chapterText, chapterId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sentencesIds, setSentencesIds] = useState<number[]>([]);
  const { createAudio } = useCreateAudio();
  const [questions, setQuestions] = useState<{ question: Question; possibleAnswers: Answer[] }[]>(
    [],
  );
  const [tquestions, setTQuestions] = useState<{ question: Question; possibleAnswers: Answer[] }[]>(
    [],
  );

  useEffect(() => {
    if (tquestions.length) {
      setQuestions(tquestions);
      setTQuestions([]);
    }
  }, [tquestions]);

  return (
    <HStack>
      <Button
        variant="outlined"
        onClick={() => {
          navigator.clipboard.writeText(`
            Я вчу англійську.
            Проаналізуй наступний уривок:
            ${chapterText}

            Дай мені питання і можливі відповіді до кожно го питання. Хочаби 10 питань та по 2-3 відповіді на питання. Але якщо зможеш створити більше питань то це краще.

            Дай мені відповідь у JSON форматі.
            Ось патерн відповіді:
            [
              {
                "question": {
                  en: "An english question",
                  ua: "A ukrainian translation of en question"
                },
                "possibleAnswers": [
                  {
                    en: "An english possible answer",
                    ua: "A ukrainian translation of en answer"
                  },
                  ...
                  // 2-3 ones at least
                ]
              },
              ....
            ]


            Для речень максимально використовуй наступні слова (це слова які я знаю):
            ${allCourseWords}
            `);
        }}
      >
        copy for ai
      </Button>
      <Box width="100%" pl={7}>
        {questions.map(({ question, possibleAnswers }, index) => {
          return (
            <RenderQuestion
              key={index}
              chapterId={chapterId}
              question={question}
              possibleAnswers={possibleAnswers}
              onRemove={() => {
                setQuestions(
                  questions.filter(({ question: q }) => {
                    return q.en !== question.en;
                  }),
                );
              }}
              onUpdate={(newQuestions) => {
                setQuestions(
                  questions.map(({ question: q, possibleAnswers: pA }, qIndex) => {
                    if (index === qIndex) return newQuestions;
                    return { question: q, possibleAnswers: pA };
                  }),
                );
              }}
            />
          );
        })}
      </Box>

      {sentencesIds.join(", ")}
      {/* <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        variant="contained"
        onClick={async () => {
          try {
            setIsLoading(true);

            const { data: existingSentences } = await supabase
              .from("sentences")
              .select()
              .in(
                "en",
                questions.map((q) => q.question.en.trim()),
              );

            const ids = existingSentences?.map((s) => s.id) || [];
            setSentencesIds(ids);
          } catch (err: any) {
            console.log("🚀 ~ err:", err.message);
          }
          setIsLoading(false);
        }}
      >
        Get sentences ids
      </LoadingButton> */}

      <Box width="100%">
        <TextField
          value={JSON.stringify(tquestions, null, 2)}
          fullWidth
          placeholder="questions"
          sx={{
            height: 100,
            overflow: "auto",
          }}
          multiline
          onChange={(event) => {
            try {
              setTQuestions(
                JSON.parse(event.target.value).map(({ question, possibleAnswers }: any) => {
                  const uniquePossibleAnswers = possibleAnswers.reduce((acm: any, curr: any) => {
                    return {
                      ...acm,
                      [curr.en]: curr,
                    };
                  }, {});

                  console.log(
                    "uniquePossibleAnswers",
                    Object.values(uniquePossibleAnswers).map((i: any) => i.en),
                  );

                  return {
                    question,
                    possibleAnswers: Object.values(uniquePossibleAnswers),
                  };
                }),
              );
            } catch (err: any) {
              console.log("🚀 ~ err:", err.message);
            }
          }}
        />
      </Box>
    </HStack>
  );
};

export default AiQuestionAnswer;
