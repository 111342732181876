import { useParams } from "react-router-dom";
import { Word } from "../../../types/supabase";
import { useEffect, useMemo, useState } from "react";
import { Box, Button, CircularProgress, Drawer, Typography } from "@mui/material";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import LinearProgress from "../../User/LinearProgress";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import WordPazleItem from "../../Unknown/WordPazleItem";
import HStack from "../../Unknown/HStack";
import { useLessonsStore } from "../../../store/lessonsStore";
import useGlobalWords from "../../hooks/getGlobalWords";
import LoadingButton from "@mui/lab/LoadingButton";
import supabaseClient from "../../../common/supabaseClient";
import Loader from "../../Unknown/Loader";
import shuffle from "../../../common/shuffle";
import ItalicTitle from "../../Title/ItalicTitle";
import isAdmin from "../../../common/isAdmin";
import getGCRandomVoice from "../../../common/getGCRandomVoice";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useFirebaseApp } from "reactfire";
import WordAudio from "../../Audio/WordAudio";

function splitByQuotes(str: string) {
  const result = [];
  let currentWord = "";
  let beforeOrLastWords = "";
  let insideQuotes = false;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === "'") {
      insideQuotes = !insideQuotes;
      // currentWord += char; // Add the quote to currentWord
      if (beforeOrLastWords) {
        result.push(beforeOrLastWords);
        beforeOrLastWords = "";
      }
    } else if (!insideQuotes) {
      beforeOrLastWords += char;
      if (currentWord) {
        result.push(currentWord); // Push the word to the result array
        currentWord = ""; // Reset currentWord
      }
    } else {
      currentWord += char; // Add the character to currentWord
    }
  }

  // Add the last word if it exists
  if (currentWord) {
    result.push(currentWord);
  }
  if (beforeOrLastWords) {
    result.push(beforeOrLastWords);
  }

  return result;
}

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

type Task = {
  letter: string;
  questionUa: string;
  correctCount: number;
  correctWords: string[];
  additionalWords: string[];
};

const alphabetLetters1 = [
  {
    letter: "n",
    questionUa: "Знайди 3 слова з літерою 'n'",
    correctCount: 3,
    correctWords: ["not", "pen", "ten", "tennis", "continent", "end"],
    additionalWords: ["car", "little", "leg", "sister", "star", "top", "sport"],
  },
  {
    letter: "o",
    questionUa: "Знайди 3 слова з літерою 'o'",
    correctCount: 3,
    correctWords: ["not", "sport", "top", "continent"],
    additionalWords: ["car", "little", "leg", "sister", "star", "end", "egg"],
  },
  {
    letter: "t",
    questionUa: "Знайди 3 слова з літерою 't'",
    correctCount: 3,
    correctWords: ["ten", "tennis", "little", "star", "pet"],
    additionalWords: ["pen", "car", "red", "bed", "leg", "big", "end", "egg"],
  },
  {
    letter: "p",
    questionUa: "Знайди 3 слова з літерою 'p'",
    correctCount: 3,
    correctWords: ["pen", "sport", "pet", "top"],
    additionalWords: ["tennis", "car", "red", "bed", "leg", "big", "end", "egg"],
  },
  {
    letter: "e",
    questionUa: "Знайди 3 слова з літерою 'e'",
    correctCount: 3,
    correctWords: ["pen", "bed", "pet", "leg", "little", "continent", "egg", "red"],
    additionalWords: ["sport", "car", "star", "big"],
  },
  {
    letter: "c",
    questionUa: "Знайди 2 слова з літерою 'c'",
    correctCount: 2,
    correctWords: ["car", "continent"],
    additionalWords: ["sport", "car", "star", "big", "end", "egg", "sister"],
  },
  {
    letter: "a",
    questionUa: "Знайди 2 слова з літерою 'a'",
    correctCount: 2,
    correctWords: ["car", "star"],
    additionalWords: ["sport", "big", "end", "egg", "sister", "little", "top"],
  },
  {
    letter: "r",
    questionUa: "Знайди 3 слова з літерою 'r'",
    correctCount: 3,
    correctWords: ["red", "sister", "sport", "car"],
    additionalWords: ["test", "big", "end", "egg", "pen", "little", "top"],
  },
  {
    letter: "b",
    questionUa: "Знайди 2 слова з літерою 'b'",
    correctCount: 2,
    correctWords: ["bed", "big"],
    additionalWords: ["test", "sport", "end", "egg", "pen", "little", "top"],
  },
  {
    letter: "d",
    questionUa: "Знайди 3 слова з літерою 'd'",
    correctCount: 3,
    correctWords: ["bed", "red", "end"],
    additionalWords: ["test", "sport", "star", "egg", "pen", "little", "top"],
  },
  {
    letter: "s",
    questionUa: "Знайди 3 слова з літерою 's'",
    correctCount: 3,
    correctWords: ["tennis", "test", "sister", "star", "sport"],
    additionalWords: ["ten", "leg", "big", "egg", "pen", "little", "top"],
  },
  {
    letter: "l",
    questionUa: "Знайди 2 слова з літерою 'l'",
    correctCount: 2,
    correctWords: ["leg", "little"],
    additionalWords: ["ten", "sport", "big", "egg", "pen", "continent", "top"],
  },
  {
    letter: "i",
    questionUa: "Знайди 3 слова з літерою 'i'",
    correctCount: 3,
    correctWords: ["sister", "little", "tennis", "continent", "big"],
    additionalWords: ["ten", "sport", "star", "egg", "pen", "top"],
  },
  {
    letter: "g",
    questionUa: "Знайди 3 слова з літерою 'g'",
    correctCount: 3,
    correctWords: ["leg", "egg", "big"],
    additionalWords: ["ten", "sport", "star", "pet", "bed", "pen", "top"],
  },
];

const alphabetLetters2 = [
  {
    letter: "m",
    questionUa: "Знайди 3 слова з літерою 'm'",
    correctCount: 3,
    correctWords: ["arm", "Mary", "lamb"],
    additionalWords: [
      "no",
      "go",
      "note",
      "stone",
      "poet",
      "art",
      "card",
      "cat",
      "apple",
      "hand",
      "hat",
    ],
  },
  {
    letter: "h",
    questionUa: "Знайди 3 слова з літерою 'h'",
    correctCount: 3,
    correctWords: ["hand", "had", "hat", "hobby"],
    additionalWords: ["note", "no", "go", "stone", "arm", "apple", "top"],
  },
  {
    letter: "y",
    questionUa: "Знайди 3 слова з літерою 'y'",
    correctCount: 3,
    correctWords: ["yes", "yard", "Mary", "hobby"],
    additionalWords: ["lamb", "hat", "apple", "cat", "card", "poet", "stone"],
  },
];
const alphabetLetters3 = [
  {
    letter: "f",
    questionUa: "Знайди 3 слова з літерою 'f'",
    correctCount: 3,
    correctWords: ["fact", "frog", "family", "finger"],
    additionalWords: ["lamb", "hat", "apple", "cat", "card", "poet", "stone"],
  },
];
const alphabetLetters4 = [
  {
    letter: "u",
    questionUa: "Знайди 5 слова з літерою 'u'",
    correctCount: 5,
    correctWords: ["cup", "up", "butter", "run", "summer"],
    additionalWords: ["lamb", "hat", "apple", "cat", "card", "poet", "stone"],
  },
  {
    letter: "w",
    questionUa: "Знайди 2 слова з літерою 'w'",
    correctCount: 2,
    correctWords: ["we", "winter"],
    additionalWords: ["lamb", "butter", "summer", "spring", "card", "poet", "stone"],
  },
];
const alphabetLetters5 = [
  {
    letter: "k",
    questionUa: "Знайди 2 слова з літерою 'k'",
    correctCount: 2,
    correctWords: ["park", "bank"],
    additionalWords: ["lamb", "hat", "apple", "cat", "card", "poet", "stone"],
  },
];

const lettersRead: Record<string, string> = {
  a: "ей",
  b: "бі",
  c: "сі",
  d: "ді",
  e: "і",
  f: "еф",
  g: "джі",
  h: "ейч",
  i: "ай",
  j: "джей",
  k: "кей",
  l: "ел",
  m: "ем",
  n: "ен",
  o: "оу",
  p: "пі",
  q: "к'ю",
  r: "aр",
  s: "ес",
  t: "ті",
  u: "ю",
  v: "ві",
  w: "дáбл'ю:",
  x: "екс",
  y: "вай",
  z: "зі / зед",
};

const tasks: Record<string, Task[]> = {
  1: [...alphabetLetters1],
  2: [
    ...alphabetLetters1,
    ...alphabetLetters2,
    {
      letter: "a",
      questionUa: "Знайди 4 слова де літера 'a' вимовляється як '/a:/' перед літерою 'r'",
      correctCount: 4,
      correctWords: ["car", "star", "arm", "art", "card"],
      additionalWords: ["hat", "cat", "apple", "lamb", "had"],
    },
    {
      letter: "e",
      questionUa: "Знайди 4 слова де літера 'e' не вимовляється",
      correctCount: 4,
      correctWords: ["stone", "note", "little", "apple"],
      additionalWords: ["yes", "poet", "pet", "bed", "red", "end"],
    },
    {
      letter: "o",
      questionUa: "Знайди 3 слова де літера 'o' вимовляється як '/ɒ/' у закритому складі",
      correctCount: 3,
      correctWords: ["not", "hobby", "top"],
      additionalWords: ["note", "poet", "go", "no", "stone", "sport"],
    },
    {
      letter: "a",
      questionUa:
        "Знайди 4 слова де літера 'a' вимовляється як '/æ/' між приголосними та на початку слова",
      correctCount: 4,
      correctWords: ["apple", "hand", "hat", "lamb", "cat", "had"],
      additionalWords: ["star", "arm", "card", "yard", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 1 слово де літера 'o' вимовляється як '/ɔː/' перед літерою 'r'",
      correctCount: 1,
      correctWords: ["sport"],
      additionalWords: ["hobby", "top", "note", "poet", "go", "no", "stone"],
    },
    {
      letter: "y",
      questionUa: "Знайди 2 слова де літера 'y' вимовляється як '/j/' на початку слова",
      correctCount: 2,
      correctWords: ["yard", "yes"],
      additionalWords: ["hobby", "Mary", "card", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/əʊ/' у відкритому складі",
      correctCount: 4,
      correctWords: ["note", "poet", "go", "no", "stone"],
      additionalWords: ["not", "hobby", "top", "sport"],
    },
    {
      letter: "y",
      questionUa: "Знайди 2 слова де літера 'y' вимовляється як '/ɪ/' всередині та у кінці слова",
      correctCount: 2,
      correctWords: ["hobby", "Mary"],
      additionalWords: ["yard", "yes", "card", "car"],
    },
  ],
  3: [
    ...alphabetLetters1,
    ...alphabetLetters2,
    ...alphabetLetters3,
    {
      letter: "n",
      questionUa: "Знайди 3 слова де літера 'n' вимовляється як '/ŋ/' перед літерою 'g'",
      correctCount: 3,
      correctWords: ["finger", "ring", "song"],
      additionalWords: ["no", "note", "stone", "hand"],
    },
    {
      letter: "g",
      questionUa: "Знайди 2 слова де літера 'g' не вимовляється після літери 'n'",
      correctCount: 2,
      correctWords: ["ring", "song"],
      additionalWords: ["egg", "finger", "leg", "big"],
    },
    {
      letter: "s",
      questionUa: "Знайди 4 слова де літера 's' вимовляється як '/z/'",
      correctCount: 4,
      correctWords: ["nose", "rose", "roses", "dogs"],
      additionalWords: ["test", "tennis", "cats", "sister", "star", "stone", "yes"],
    },
    {
      letter: "a",
      questionUa: "Знайди 4 слова де літера 'a' вимовляється як '/a:/' перед літерою 'r'",
      correctCount: 4,
      correctWords: ["car", "star", "arm", "art", "card"],
      additionalWords: ["hat", "cat", "apple", "lamb", "had"],
    },
    {
      letter: "e",
      questionUa: "Знайди 4 слова де літера 'e' не вимовляється",
      correctCount: 4,
      correctWords: ["nose", "rose", "stone", "note", "little", "apple"],
      additionalWords: ["yes", "poet", "pet", "bed", "red", "end"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/ɒ/' у закритому складі",
      correctCount: 4,
      correctWords: ["mom", "dog", "not", "hobby", "top"],
      additionalWords: ["note", "poet", "go", "no", "stone", "sport"],
    },
    {
      letter: "a",
      questionUa:
        "Знайди 4 слова де літера 'a' вимовляється як '/æ/' між приголосними та на початку слова",
      correctCount: 4,
      correctWords: [
        "dad",
        "fact",
        "family",
        "cats",
        "and",
        "apple",
        "hand",
        "hat",
        "lamb",
        "cat",
        "had",
      ],
      additionalWords: ["star", "arm", "card", "yard", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 1 слово де літера 'o' вимовляється як '/ɔː/' перед літерою 'r'",
      correctCount: 1,
      correctWords: ["sport"],
      additionalWords: ["hobby", "top", "note", "poet", "go", "no", "stone"],
    },
    {
      letter: "y",
      questionUa: "Знайди 2 слова де літера 'y' вимовляється як '/j/' на початку слова",
      correctCount: 2,
      correctWords: ["yard", "yes"],
      additionalWords: ["hobby", "Mary", "card", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/əʊ/' у відкритому складі",
      correctCount: 4,
      correctWords: ["hello", "nose", "rose", "note", "poet", "go", "no", "stone"],
      additionalWords: ["not", "hobby", "top", "sport"],
    },
    {
      letter: "y",
      questionUa: "Знайди 4 слова де літера 'y' вимовляється як '/ɪ/' всередині та у кінці слова",
      correctCount: 4,
      correctWords: ["daddy", "mommy", "hobby", "Mary"],
      additionalWords: ["yard", "yes", "card", "car"],
    },
  ],
  4: [
    ...alphabetLetters1,
    ...alphabetLetters2,
    ...alphabetLetters3,
    ...alphabetLetters4,
    {
      letter: "e",
      questionUa: "Знайди 2 слова де літера 'e' вимовляється як '/i:/' у відкритому складі",
      correctCount: 2,
      correctWords: ["he", "we"],
      additionalWords: ["here", "note", "stone", "tiger"],
    },
    {
      letter: "i",
      questionUa: "Знайди 5 слів де літера 'i' вимовляється як '/aɪ/' у відкритому складі",
      correctCount: 5,
      correctWords: ["I", "hi", "tiger", "spider", "time"],
      additionalWords: ["spring", "sister", "little", "finger", "ring", "in", "big"],
    },
    {
      letter: "e",
      questionUa: "Знайди 1 слово де літера 'e' вимовляється як '/i/' у відкритому складі",
      correctCount: 1,
      correctWords: ["here"],
      additionalWords: ["he", "we", "stone", "tiger"],
    },
    {
      letter: "n",
      questionUa: "Знайди 3 слова де літера 'n' вимовляється як '/ŋ/' перед літерою 'g'",
      correctCount: 3,
      correctWords: ["finger", "ring", "song"],
      additionalWords: ["no", "note", "stone", "hand"],
    },
    {
      letter: "e",
      questionUa: "Знайди 5 слів де літера 'e' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 5,
      correctWords: ["spider", "sister", "tiger", "winter", "summer"],
      additionalWords: ["he", "we", "stone", "little", "end"],
    },
    {
      letter: "a",
      questionUa: "Знайди 1 слово де літера 'a' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 1,
      correctWords: ["England"],
      additionalWords: ["apple", "daddy", "had", "hat"],
    },
    {
      letter: "i",
      questionUa: "Знайди 1 слово де літера 'i' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 1,
      correctWords: ["family"],
      additionalWords: ["sister", "finger", "little", "spring"],
    },
    {
      letter: "g",
      questionUa: "Знайди 2 слова де літера 'g' не вимовляється після літери 'n'",
      correctCount: 2,
      correctWords: ["ring", "song"],
      additionalWords: ["egg", "finger", "leg", "big"],
    },
    {
      letter: "s",
      questionUa: "Знайди 4 слова де літера 's' вимовляється як '/z/'",
      correctCount: 4,
      correctWords: ["nose", "rose", "roses", "dogs"],
      additionalWords: ["test", "tennis", "cats", "sister", "star", "stone", "yes"],
    },
    {
      letter: "a",
      questionUa: "Знайди 4 слова де літера 'a' вимовляється як '/a:/' перед літерою 'r'",
      correctCount: 4,
      correctWords: ["car", "star", "arm", "art", "card"],
      additionalWords: ["hat", "cat", "apple", "lamb", "had"],
    },
    {
      letter: "e",
      questionUa: "Знайди 4 слова де літера 'e' не вимовляється",
      correctCount: 4,
      correctWords: ["nose", "rose", "stone", "note", "little", "apple"],
      additionalWords: ["yes", "poet", "pet", "bed", "red", "end"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/ɒ/' у закритому складі",
      correctCount: 4,
      correctWords: ["mom", "dog", "not", "hobby", "top"],
      additionalWords: ["note", "poet", "go", "no", "stone", "sport"],
    },
    {
      letter: "a",
      questionUa:
        "Знайди 4 слова де літера 'a' вимовляється як '/æ/' між приголосними та на початку слова",
      correctCount: 4,
      correctWords: [
        "dad",
        "fact",
        "family",
        "cats",
        "and",
        "apple",
        "hand",
        "hat",
        "lamb",
        "cat",
        "had",
      ],
      additionalWords: ["star", "arm", "card", "yard", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 1 слово де літера 'o' вимовляється як '/ɔː/' перед літерою 'r'",
      correctCount: 1,
      correctWords: ["sport"],
      additionalWords: ["hobby", "top", "note", "poet", "go", "no", "stone"],
    },
    {
      letter: "y",
      questionUa: "Знайди 2 слова де літера 'y' вимовляється як '/j/' на початку слова",
      correctCount: 2,
      correctWords: ["yard", "yes"],
      additionalWords: ["hobby", "Mary", "card", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/əʊ/' у відкритому складі",
      correctCount: 4,
      correctWords: ["hello", "nose", "rose", "note", "poet", "go", "no", "stone"],
      additionalWords: ["not", "hobby", "top", "sport"],
    },
    {
      letter: "y",
      questionUa: "Знайди 4 слова де літера 'y' вимовляється як '/ɪ/' всередині та у кінці слова",
      correctCount: 4,
      correctWords: ["daddy", "mommy", "hobby", "Mary"],
      additionalWords: ["yard", "yes", "card", "car"],
    },
  ],
  5: [
    ...alphabetLetters1,
    ...alphabetLetters2,
    ...alphabetLetters3,
    ...alphabetLetters4,
    ...alphabetLetters5,
    {
      letter: "e",
      questionUa: "Знайди 2 слова де літера 'e' вимовляється як '/i:/' у відкритому складі",
      correctCount: 2,
      correctWords: ["he", "we"],
      additionalWords: ["here", "note", "stone", "tiger"],
    },
    {
      letter: "i",
      questionUa: "Знайди 5 слів де літера 'i' вимовляється як '/aɪ/' у відкритому складі",
      correctCount: 5,
      correctWords: ["I", "hi", "tiger", "spider", "time"],
      additionalWords: ["spring", "sister", "little", "finger", "ring", "in", "big"],
    },
    {
      letter: "e",
      questionUa: "Знайди 1 слово де літера 'e' вимовляється як '/i/' у відкритому складі",
      correctCount: 1,
      correctWords: ["here"],
      additionalWords: ["he", "we", "stone", "tiger"],
    },
    {
      letter: "n",
      questionUa: "Знайди 3 слова де літера 'n' вимовляється як '/ŋ/' перед літерою 'g'",
      correctCount: 3,
      correctWords: ["finger", "ring", "song"],
      additionalWords: ["no", "note", "stone", "hand"],
    },
    {
      letter: "e",
      questionUa: "Знайди 5 слів де літера 'e' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 5,
      correctWords: ["spider", "sister", "tiger", "winter", "summer"],
      additionalWords: ["he", "we", "stone", "little", "end"],
    },
    {
      letter: "a",
      questionUa: "Знайди 1 слово де літера 'a' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 1,
      correctWords: ["England"],
      additionalWords: ["apple", "daddy", "had", "hat"],
    },
    {
      letter: "i",
      questionUa: "Знайди 1 слово де літера 'i' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 1,
      correctWords: ["family"],
      additionalWords: ["sister", "finger", "little", "spring"],
    },
    {
      letter: "g",
      questionUa: "Знайди 2 слова де літера 'g' не вимовляється після літери 'n'",
      correctCount: 2,
      correctWords: ["ring", "song"],
      additionalWords: ["egg", "finger", "leg", "big"],
    },
    {
      letter: "s",
      questionUa: "Знайди 4 слова де літера 's' вимовляється як '/z/'",
      correctCount: 4,
      correctWords: ["nose", "rose", "roses", "dogs"],
      additionalWords: ["test", "tennis", "cats", "sister", "star", "stone", "yes"],
    },
    {
      letter: "a",
      questionUa: "Знайди 4 слова де літера 'a' вимовляється як '/a:/' перед літерою 'r'",
      correctCount: 4,
      correctWords: ["car", "star", "arm", "art", "card"],
      additionalWords: ["hat", "cat", "apple", "lamb", "had"],
    },
    {
      letter: "e",
      questionUa: "Знайди 4 слова де літера 'e' не вимовляється",
      correctCount: 4,
      correctWords: ["nose", "rose", "stone", "note", "little", "apple"],
      additionalWords: ["yes", "poet", "pet", "bed", "red", "end"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/ɒ/' у закритому складі",
      correctCount: 4,
      correctWords: ["mom", "dog", "not", "hobby", "top"],
      additionalWords: ["note", "poet", "go", "no", "stone", "sport"],
    },
    {
      letter: "a",
      questionUa:
        "Знайди 4 слова де літера 'a' вимовляється як '/æ/' між приголосними та на початку слова",
      correctCount: 4,
      correctWords: [
        "dad",
        "fact",
        "family",
        "cats",
        "and",
        "apple",
        "hand",
        "hat",
        "lamb",
        "cat",
        "had",
      ],
      additionalWords: ["star", "arm", "card", "yard", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 1 слово де літера 'o' вимовляється як '/ɔː/' перед літерою 'r'",
      correctCount: 1,
      correctWords: ["sport"],
      additionalWords: ["hobby", "top", "note", "poet", "go", "no", "stone"],
    },
    {
      letter: "y",
      questionUa: "Знайди 2 слова де літера 'y' вимовляється як '/j/' на початку слова",
      correctCount: 2,
      correctWords: ["yard", "yes"],
      additionalWords: ["hobby", "Mary", "card", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/əʊ/' у відкритому складі",
      correctCount: 4,
      correctWords: ["hello", "nose", "rose", "note", "poet", "go", "no", "stone"],
      additionalWords: ["not", "hobby", "top", "sport"],
    },
    {
      letter: "y",
      questionUa: "Знайди 4 слова де літера 'y' вимовляється як '/ɪ/' всередині та у кінці слова",
      correctCount: 4,
      correctWords: ["daddy", "mommy", "hobby", "Mary"],
      additionalWords: ["yard", "yes", "card", "car"],
    },
  ],
  6: [
    ...alphabetLetters1,
    ...alphabetLetters2,
    ...alphabetLetters3,
    ...alphabetLetters4,
    ...alphabetLetters5,
    {
      letter: "a",
      questionUa: "Знайди 4 слова де літера 'a' вимовляється як '/eɪ/'",
      correctCount: 4,
      correctWords: ["baby", "lady", "name", "plane"],
      additionalWords: ["apple", "card", "plan", "fat", "hand"],
    },
    {
      letter: "o",
      questionUa: "Знайди 2 слова де літера 'o' вимовляється як '/uː/'",
      correctCount: 2,
      correctWords: ["do", "two"],
      additionalWords: ["go", "no", "note", "stone", "poet"],
    },
    {
      letter: "ou",
      questionUa: "Знайди 3 слова з комбінацією 'ou' що вимовляється як '/uː/'",
      correctCount: 3,
      correctWords: ["you", "soup", "group"],
      additionalWords: ["house", "about", "round", "soul"],
    },
    {
      letter: "ow",
      questionUa: "Знайди 3 слова з комбінацією 'ow' що вимовляється як '/aʊ/'",
      correctCount: 3,
      correctWords: ["how", "down", "cow"],
      additionalWords: ["window", "arrow", "yellow"],
    },
    {
      letter: "e",
      questionUa: "Знайди 2 слова де літера 'e' вимовляється як '/i:/' у відкритому складі",
      correctCount: 2,
      correctWords: ["he", "we"],
      additionalWords: ["here", "note", "stone", "tiger"],
    },
    {
      letter: "i",
      questionUa: "Знайди 5 слів де літера 'i' вимовляється як '/aɪ/' у відкритому складі",
      correctCount: 5,
      correctWords: ["I", "hi", "tiger", "spider", "time"],
      additionalWords: ["spring", "sister", "little", "finger", "ring", "in", "big"],
    },
    {
      letter: "e",
      questionUa: "Знайди 1 слово де літера 'e' вимовляється як '/i/' у відкритому складі",
      correctCount: 1,
      correctWords: ["here"],
      additionalWords: ["he", "we", "stone", "tiger"],
    },
    {
      letter: "n",
      questionUa: "Знайди 3 слова де літера 'n' вимовляється як '/ŋ/' перед літерою 'g'",
      correctCount: 3,
      correctWords: ["finger", "ring", "song"],
      additionalWords: ["no", "note", "stone", "hand"],
    },
    {
      letter: "e",
      questionUa: "Знайди 5 слів де літера 'e' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 5,
      correctWords: ["spider", "sister", "tiger", "winter", "summer"],
      additionalWords: ["he", "we", "stone", "little", "end"],
    },
    {
      letter: "a",
      questionUa: "Знайди 1 слово де літера 'a' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 1,
      correctWords: ["England"],
      additionalWords: ["apple", "daddy", "had", "hat"],
    },
    {
      letter: "i",
      questionUa: "Знайди 1 слово де літера 'i' вимовляється як '/ə/' у ненаголошеному складі",
      correctCount: 1,
      correctWords: ["family"],
      additionalWords: ["sister", "finger", "little", "spring"],
    },
    {
      letter: "g",
      questionUa: "Знайди 2 слова де літера 'g' не вимовляється після літери 'n'",
      correctCount: 2,
      correctWords: ["ring", "song"],
      additionalWords: ["egg", "finger", "leg", "big"],
    },
    {
      letter: "s",
      questionUa: "Знайди 4 слова де літера 's' вимовляється як '/z/'",
      correctCount: 4,
      correctWords: ["nose", "rose", "roses", "dogs"],
      additionalWords: ["test", "tennis", "cats", "sister", "star", "stone", "yes"],
    },
    {
      letter: "a",
      questionUa: "Знайди 4 слова де літера 'a' вимовляється як '/a:/' перед літерою 'r'",
      correctCount: 4,
      correctWords: ["car", "star", "arm", "art", "card"],
      additionalWords: ["hat", "cat", "apple", "lamb", "had"],
    },
    {
      letter: "e",
      questionUa: "Знайди 4 слова де літера 'e' не вимовляється",
      correctCount: 4,
      correctWords: ["nose", "rose", "stone", "note", "little", "apple"],
      additionalWords: ["yes", "poet", "pet", "bed", "red", "end"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/ɒ/' у закритому складі",
      correctCount: 4,
      correctWords: ["mom", "dog", "not", "hobby", "top"],
      additionalWords: ["note", "poet", "go", "no", "stone", "sport"],
    },
    {
      letter: "a",
      questionUa:
        "Знайди 4 слова де літера 'a' вимовляється як '/æ/' між приголосними та на початку слова",
      correctCount: 4,
      correctWords: [
        "dad",
        "fact",
        "family",
        "cats",
        "and",
        "apple",
        "hand",
        "hat",
        "lamb",
        "cat",
        "had",
      ],
      additionalWords: ["star", "arm", "card", "yard", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 1 слово де літера 'o' вимовляється як '/ɔː/' перед літерою 'r'",
      correctCount: 1,
      correctWords: ["sport"],
      additionalWords: ["hobby", "top", "note", "poet", "go", "no", "stone"],
    },
    {
      letter: "y",
      questionUa: "Знайди 2 слова де літера 'y' вимовляється як '/j/' на початку слова",
      correctCount: 2,
      correctWords: ["yard", "yes"],
      additionalWords: ["hobby", "Mary", "card", "car"],
    },
    {
      letter: "o",
      questionUa: "Знайди 4 слова де літера 'o' вимовляється як '/əʊ/' у відкритому складі",
      correctCount: 4,
      correctWords: ["hello", "nose", "rose", "note", "poet", "go", "no", "stone"],
      additionalWords: ["not", "hobby", "top", "sport"],
    },
    {
      letter: "y",
      questionUa: "Знайди 4 слова де літера 'y' вимовляється як '/ɪ/' всередині та у кінці слова",
      correctCount: 4,
      correctWords: ["daddy", "mommy", "hobby", "Mary"],
      additionalWords: ["yard", "yes", "card", "car"],
    },
  ],
};

const lastLessonWithTasks = 6;

const LessonTasks: React.FC<{}> = () => {
  const { lessonId, lessonType } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [taskIndex, setTaskIndex] = useState(0);
  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isHoverLoading, setIsHoverLoading] = useState(false);
  const [correctAnswered, setCorrectAnswered] = useState<string[]>([]);
  const [isAnswerd, setIsAnswerd] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [isLetterShow, setIsLetterShow] = useState(true);
  const authUser = useLessonsStore((state) => state.authUser);
  // const [needToFind, setNeedToFind] = useState(0);

  const [lessonTasks, setLessonTasks] = useState<Task[]>([]);

  useEffect(() => {
    const t = tasks[lessonId || "1"];
    if (t) {
      setLessonTasks(t);
    }
  }, [lessonId]);

  const restart = () => {
    setTaskIndex(0);
    setCorrectAnswered([]);
    setIsAnswerd(false);
    setErrorCount(0);
    setIsSuccess(false);
    setLessonTasks(tasks[lessonId || "1"]);
  };

  useEffect(() => {
    restart();
  }, [isOpen]);

  const currentTask = useMemo(() => {
    if (!lessonTasks) return null;
    return lessonTasks[taskIndex];
  }, [lessonTasks, taskIndex]);

  useEffect(() => {
    let timer: any = null;
    if (currentTask && correctAnswered.length === currentTask.correctCount) {
      setTimeout(() => {
        setIsAnswerd(true);
      }, 1500);
    } else if (correctAnswered.length === 0) {
      setIsLoading(true);

      timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [correctAnswered, currentTask]);
  const tasksWords = useMemo(() => {
    if (!lessonTasks) return [];
    const words = [...lessonTasks.map((t) => [...t.correctWords, ...t.additionalWords]).flat()];
    if (lessonId && Number(lessonId) <= lastLessonWithTasks && tasks[lessonId!]) {
      words.push(...tasks[lessonId!].map((task) => task.letter));
    }
    return Array.from(new Set(words));
  }, [lessonTasks, lessonId]);

  const taskWords = useMemo(() => {
    if (!currentTask) return [];
    return shuffle(
      Array.from(
        new Set([
          ...shuffle(currentTask.correctWords).slice(0, currentTask.correctCount),
          ...shuffle(currentTask.additionalWords).slice(0, 5),
        ]),
      ),
    );
  }, [currentTask]);

  const [curentModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  console.log("🚀 ~ curentModuleWordsMap:", curentModuleWordsMap);
  const globalWords = useLessonsStore((state) => state.globalWords);

  const options = useMemo(() => {
    const words = [...tasksWords];
    return { words };
  }, [tasksWords, lessonId]);

  useGlobalWords(options);

  useEffect(() => {
    if (tasksWords?.length) {
      const map = globalWords
        .filter((w) => tasksWords.includes(w.name) || tasksWords.includes(w.name.toLowerCase()))
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
          };
        }, {});

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, tasksWords]);

  const handleDrawerClose = () => {
    setIsOpen(false);
    // if (onDrawerClose) onDrawerClose();
  };

  useEffect(() => {
    if (currentTask && currentTask.letter.length !== 1) {
      setIsLetterShow(false);
    } else if (
      currentTask &&
      currentTask.letter.length === 1 &&
      lessonTasks.length - taskIndex !== 1
    ) {
      setIsLetterShow(true);
    }
  }, [currentTask, lessonTasks, taskIndex]);
  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");

  const textToSpeechFunction = httpsCallable(
    functions,
    "recordEnglishTextToSpeechAndReturnStorageUrl",
  );

  const createAudio = async (word: string) => {
    try {
      const result = await textToSpeechFunction({
        en: word.replace("(verb) ", ""),
        voiceName: getGCRandomVoice(),
      });
      return result;
    } catch (err) {
      console.log("🚀 try/catch create autio error", err);
    }
  };

  if (!lessonTasks || !lessonTasks.length) return null;

  return (
    <>
      {/* <LinearProgress height={4} elementsCount={wordsLength} currentValue={wordIndex} /> */}
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Запитання
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />
          {isAdmin(authUser?.id) && (
            <>
              <LoadingButton
                loading={isLoading}
                variant="outlined"
                color="error"
                sx={{ position: "absolute", top: 0, left: 0 }}
                onClick={async () => {
                  setIsLoading(true);

                  try {
                    const questions = lessonTasks.map((task) => task.letter);

                    for (let i = 0; i < questions.length; i++) {
                      const letter = questions[i];

                      const { data: existionOne } = await supabaseClient
                        .from("words")
                        .select()
                        .eq("name", letter)
                        .maybeSingle();

                      if (existionOne) continue;

                      const audioResponse: any = await createAudio(letter);

                      const newDocProps = {
                        name: letter,
                        // examples,
                        audio_url: audioResponse?.data?.url,
                        verified: false,
                        // language_parts: languageParts || [],
                        // description,
                        // translation,
                        // transcription,
                        // image_name: getImageName(word),
                        // qa: wordQa ? wordQa : {},
                      };

                      const result = await supabaseClient
                        .from("words")
                        .insert(newDocProps)
                        .throwOnError();
                      console.log("🚀 ~ result:", result);
                    }
                  } catch (err) {
                    console.log("🚀 ~ err:", err);
                  }
                  setIsLoading(false);
                }}
              >
                Create audio for questions
              </LoadingButton>
            </>
          )}
          <LinearProgress height={7} elementsCount={lessonTasks.length} currentValue={taskIndex} />
          {!isSuccess && (
            <Box position="absolute" top={10} left={10}>
              <Typography>
                Завданнь залишилось:&nbsp;{" "}
                <ItalicTitle
                  text={(lessonTasks.length - taskIndex).toString()}
                  color="green"
                  size={25}
                />
              </Typography>
            </Box>
          )}
          <Box sx={{ backgroundColor: "white" }} py={15} px={3} mb={2} width="100%">
            {isSuccess && (
              <VStack>
                <Typography textAlign="center" pt={2}>
                  Всі завдання виконані&nbsp;🚀
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    restart();
                  }}
                >
                  Почати з початку
                </Button>
              </VStack>
            )}
            {isLetterShow && currentTask && currentTask.letter.length === 1 && (
              <HStack
                sx={{
                  position: "absolute",
                  left: 0,
                  top: 10,
                  width: "100%",
                  minHeight: "100%",
                  backgroundColor: "white",
                  zIndex: 2,
                }}
              >
                <VStack pb={5}>
                  <ItalicTitle text={`Літера алфавіту`} size={20} />
                  <HStack>
                    <WordAudio autoPlay url={curentModuleWordsMap[currentTask.letter]?.audio_url} />
                    <ItalicTitle text={currentTask.letter} size={30} />
                  </HStack>
                  <HStack>
                    <ItalicTitle text={`Приблизна вимова українською:`} size={18} />
                    <ItalicTitle text={lettersRead[currentTask.letter]} color="green" size={20} />
                  </HStack>
                  <Button
                    variant="outlined"
                    sx={{ mb: 5 }}
                    onClick={() => {
                      setIsLetterShow(false);
                    }}
                  >
                    Далі
                  </Button>
                </VStack>
              </HStack>
            )}

            {!isSuccess && currentTask && (
              <VStack>
                <Box mb={4}>
                  {currentTask.questionUa && (
                    <>
                      {splitByQuotes(currentTask.questionUa).map((part, index) => {
                        if (index % 2 !== 0) {
                          return (
                            <ItalicTitle
                              key={index}
                              text={part}
                              color="green"
                              isItalic={false}
                              fontFamily="Roboto"
                            />
                          );
                        }
                        return <ItalicTitle key={index} isItalic={false} text={part} />;
                      })}
                    </>
                  )}
                  <HStack gap={5}>
                    {Boolean(currentTask.correctCount - correctAnswered.length) && (
                      <Typography>
                        Слів залишилось:&nbsp;{" "}
                        <ItalicTitle
                          text={(currentTask.correctCount - correctAnswered.length).toString()}
                          color="green"
                          size={25}
                        />
                      </Typography>
                    )}
                    {!!errorCount && (
                      <Typography>
                        Помилки:&nbsp;{" "}
                        <ItalicTitle text={errorCount.toString()} color="red" size={25} />
                      </Typography>
                    )}
                  </HStack>
                </Box>
                <HStack mb={2}>
                  <HoverDetails
                    gap={3}
                    words={correctAnswered.map((w, index) => {
                      return {
                        ...curentModuleWordsMap[w],
                        id: w,
                        page: 1,
                        word: w,
                        label: w,
                      };
                    })}
                  />
                </HStack>
                {!isLoading && !isAnswerd && (
                  <HStack mb={4} justifyContent="center" maxWidth={500} position="relative">
                    {!Boolean(currentTask.correctCount - correctAnswered.length) && (
                      <VStack
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="110%"
                        sx={{ backgroundColor: "white", zIndex: 1 }}
                      >
                        <CircularProgress />
                      </VStack>
                    )}
                    {taskWords.map((word, index) => {
                      return (
                        <WordPazleItem
                          word={word}
                          label={word}
                          correctWords={currentTask.correctWords}
                          audio={
                            curentModuleWordsMap[word] ? curentModuleWordsMap[word].audio_url : ""
                          }
                          key={index}
                          visible={!correctAnswered.includes(word)}
                          onAnswer={(type) => {
                            if (type === "success") {
                              setCorrectAnswered((prev) => [...prev, word]);
                            } else if (type === "error") {
                              setErrorCount((prev) => prev + 1);
                            }
                          }}
                        />
                      );
                    })}
                  </HStack>
                )}
                {isAnswerd && (
                  <Box py={6}>
                    <LoadingButton
                      variant="outlined"
                      loading={nextIsLoading}
                      onClick={async () => {
                        // if (errorCount) {
                        //   setNextIsLoading(true);

                        //   try {
                        //     const { data: map } = await supabaseClient
                        //       .from("errors_map")
                        //       .select()
                        //       .eq("id", `${lessonId}_${authUser!.uuid}`)
                        //       .maybeSingle();

                        //     if (map) {
                        //       const prevTaskErrorsCount = map.map[currentTask.letter]?.count || 0;
                        //       const prevTaskErrorsTries = map.map[currentTask.letter]?.tries || 0;

                        //       await supabaseClient
                        //         .from("errors_map")
                        //         .update({
                        //           id: `${lessonId}_${authUser!.uuid}`,
                        //           lesson_id: lessonId,
                        //           user: authUser!.uuid,
                        //           map: {
                        //             ...map.map,
                        //             [currentTask.letter]: {
                        //               date: new Date().toISOString(),
                        //               count: prevTaskErrorsCount + errorCount,
                        //               tries: prevTaskErrorsTries + 1,
                        //             },
                        //           },
                        //         })
                        //         .eq("id", map.id);
                        //     } else {
                        //       await supabaseClient.from("errors_map").insert({
                        //         id: `${lessonId}_${authUser!.uuid}`,
                        //         lesson_id: lessonId,
                        //         user: authUser!.uuid,
                        //         map: {
                        //           [currentTask.letter]: {
                        //             date: new Date().toISOString(),
                        //             count: errorCount,
                        //             tries: 1,
                        //           },
                        //         },
                        //       });
                        //     }
                        //   } catch (err) {
                        //     console.log("🚀 ~ err:", err);
                        //   }

                        //   setNextIsLoading(false);
                        // }

                        setCorrectAnswered([]);
                        if (lessonTasks.length - taskIndex !== 1) {
                          setIsLetterShow(true);
                        }
                        if (taskIndex + 1 !== lessonTasks.length) {
                          setTaskIndex(taskIndex + 1);
                        } else {
                          setTaskIndex(0);
                          setIsSuccess(true);
                        }
                        setTimeout(() => {
                          setIsAnswerd(false);
                        }, 500);

                        if (errorCount) {
                          setLessonTasks((prev) => [...prev, currentTask]);
                          setErrorCount(0);
                        }
                      }}
                    >
                      Далі
                    </LoadingButton>
                  </Box>
                )}
              </VStack>
            )}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default LessonTasks;
