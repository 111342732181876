import { Typography } from "@mui/material";
import { useMemo } from "react";

export enum AccentType {
  goal = "goal",
  underlined = "underlined",
  black = "black",
  accent = "accent",
}

interface AccentStrongProps {
  text: string;
  type?: AccentType[];
}

const AccentStrong: React.FC<AccentStrongProps> = ({ text, type }) => {
  const sx = useMemo(() => {
    const styles: Record<string, unknown> = {};

    if (!type) return styles;
    if (type.includes(AccentType.goal)) {
      styles.color = "red";
    }
    if (type.includes(AccentType.underlined)) {
      styles.textDecoration = "underline";
    }
    if (type.includes(AccentType.black)) {
      styles.color = "black";
    }
    if (type.includes(AccentType.accent)) {
      styles.color = "#4aa805";
    }
    return styles;
  }, [type]);

  return (
    <Typography fontWeight="inherit" fontSize="inherit" component="span" color="" sx={sx}>
      {text}
    </Typography>
  );
};

export default AccentStrong;
