import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import getSkipWords from "../../../common/getSkipWords";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { Sentence, WordExample } from "../../../types/supabase";
import HStack from "../../Unknown/HStack";
import upperCaseWords from "../../../common/uppercaseWords";

const AdminItem: React.FC<{ sentence: Sentence }> = ({ sentence }) => {
  const [wordsMap, setWordsMap] = useState<any>({});
  const [wordExamplesMap, setWordExamplesMap] = useState<Record<string, WordExample[]>>({});

  useMountedWaitAsyncEffect(async () => {
    const wordsToSkip = await getSkipWords();
    const sentenceWords = sentence.en
      .split(" ")
      .map((w) => removePunctuationFromString(w))
      .filter(Boolean);

    const normalizedSentenceWords = sentenceWords
      .map((w) => {
        if (upperCaseWords.includes(w)) return w;
        return w.toLowerCase();
      })
      .filter((w) => !wordsToSkip.includes(w));

    const { data: spSentenceCount } = await supabaseClient
      .from("sentences")
      // .select("*", { count: "exact", head: true })
      .select("new_word")
      .in("new_word", normalizedSentenceWords);

    const { data: words } = await supabaseClient
      .from("words")
      .select("name, examples")
      .in("name", normalizedSentenceWords);

    const wordsMap = words?.reduce<Record<string, WordExample[]>>((prev, w) => {
      return {
        ...prev,
        [w.name]: w.examples,
      };
    }, {});

    if (wordsMap) setWordExamplesMap(wordsMap);

    const sentenceMap = spSentenceCount?.reduce<Record<string, number>>(
      (prev, { new_word }) => {
        return {
          ...prev,
          [new_word]: prev[new_word] ? prev[new_word] + 1 : 1,
        };
      },
      {
        ...normalizedSentenceWords.reduce((prev, w) => {
          return {
            ...prev,
            [w]: 0,
          };
        }, {}),
      },
    );

    if (sentenceMap && wordsMap) {
      setWordsMap(
        Object.entries(sentenceMap).reduce<Record<string, string>>((prev, [key, value]) => {
          return {
            ...prev,
            [key]: `${value} - ${wordsMap[key].length}`,
          };
        }, {}),
      );
    }
  }, [sentence]);

  const [active, setActive] = useState("");

  return (
    <Box py={10}>
      <Typography>{sentence.en}</Typography>
      {Object.entries(wordsMap).map(([key, value]: any) => {
        return (
          <HStack>
            <Button
              variant={active === key ? "contained" : "text"}
              color={value.endsWith("0") ? "error" : "success"}
              onClick={() => {
                setActive(key);
              }}
            >
              {key}
            </Button>
            <Typography>{value}</Typography>
          </HStack>
        );
      })}
      <Button
        size="large"
        variant="outlined"
        onClick={async () => {
          const newWordExamples = [
            ...wordExamplesMap[active],
            { en: sentence.en, ua: sentence.ua },
          ];

          await supabaseClient
            .from("words")
            .update({
              examples: newWordExamples,
            })
            .eq("name", active);
          await supabaseClient
            .from("sentences")
            .update({
              new_word: active,
            })
            .eq("en", sentence.en);
        }}
      >
        Submit
      </Button>
      <Button
        size="large"
        variant="outlined"
        onClick={async () => {
          await supabaseClient
            .from("sentences")
            .update({
              new_word: active,
            })
            .eq("en", sentence.en);
        }}
      >
        Skip
      </Button>
    </Box>
  );
};

const AdminAddSentences = () => {
  const [sentences, setSentences] = useState<Sentence[]>([]);

  useMountedWaitAsyncEffect(async () => {
    const { data: spSentences } = await supabaseClient
      .from("sentences")
      .select()
      .is("new_word", null)
      .limit(10)
      .returns<Sentence[]>();

    if (spSentences) setSentences(spSentences);
  }, []);

  return (
    <Container maxWidth="sm">
      <Grid container pb={200}>
        <Grid item>
          {sentences.map((sentence) => {
            return <AdminItem key={sentence.en} sentence={sentence} />;
          })}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminAddSentences;
