import { produce } from "immer";
import { RPCUser } from "../../common/fetchCurrentUser";
import { Session } from "@supabase/supabase-js";
import { Module, Sentence, Word } from "../../types/supabase";

export interface LessonsState {
  englishGrammarCourseSliderPositions: Record<string, number>;
  authUser: RPCUser | null;
  session: Session | null;
  newWords: string[];
  isGrammarFeedOpen: boolean;
  setIsGrammarFeedOpen: (isOpen: boolean) => void;
  setNewWords: (words: string[], toReplace?: boolean) => void;
  userWords: string[];
  lessonNumber: number;
  setLessonNumber: (lesson: number) => void;
  setUserWords: (words: string[]) => void;
  globalWords: Word[];
  grammarModules: Module[];
  lessonData: Record<
    string,
    {
      lessonId: string;
      module: Module;
      sentences: Sentence[];
    }
  >;
  setGlobalWords: (words: Word[]) => void;
  setGrammarModules: (modules: Module[]) => void;
  setLessonData: ({
    lessonId,
    module,
    sentences,
  }: {
    lessonId: string;
    module: Module;
    sentences: Sentence[];
  }) => void;
  explainText: string[];
  setExplainText: (text: string[]) => void;
  sentences: Record<string, Sentence[]>;
  isLoading: boolean;
  currentLessonId: number;
  lastLessonNumber: number;
  isPersonalLesson: null | boolean;
  setEnglishGrammarCourseSliderPositions: (sliderId: string, index: number) => void;
  setIsPersonalLesson: (isPersonalLesson: boolean) => void;
  setRPCUser: (user: RPCUser) => void;
  setSession: (session: Session | null) => void;
  resetPersonalLessons: () => void;
  personalLessonsWords: Record<string, Word[]>;
  setPersonalLessonsWords: (lesson: string, words: Word[]) => void;
  personalLessonsSentences: Record<string, Sentence[]>;
  setPersonalLessonsSentences: (lesson: string, sentences: Sentence[]) => void;
}

export const setLessonNumber = (set: any) => {
  return (lesson: number) => {
    set(
      produce((state: LessonsState) => {
        state.lessonNumber = lesson;
      }),
    );
  };
};
