import { Box, Button, Container, Link as MuiLink, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLessonsStore } from "../../../store/lessonsStore";
import { Module, Sentence, Word } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import HStack from "../../Unknown/HStack";
import VStack from "../../Unknown/VStack";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import LoadingButton from "@mui/lab/LoadingButton";
import FindVowelLetter from "../UkTrainers/FindVowelLetter";
import FindConconantLetter from "../UkTrainers/FindConconantLetter";
import TrainersModalWithButton from "../UkTrainers/TrainersModalWithButton";
import { TrainersConfig } from "../UkTrainers/constants";

export const sounds = {
  ɒ: [
    "Губи повинні бути розслаблені, не округлені і не розтягнуті.",
    "Язик повинен бути опущений до низу ротової порожнини, не торкаючись зубів.",
    'Рот повинен бути відкритий ширше, ніж для звука "а".',
    'Звук короткий, відкритий звук, схожий на українське "о", але з нижчим тоном.',
  ],
  n: [
    "Кінчик язика торкається альвеол: Альвеоли - це горбики за верхніми зубами. Кінчик язика повинен легко торкатися їх",
    'Повітря проходить через ніс: Під час вимови "n" повітря проходить через ніс, а не через рот.',
    "Голосові зв'язки вібрують: Звук \"n\" - це дзвінкий приголосний, тому голосові зв'язки вібрують під час його вимови.",
  ],
  t: [
    "Кінчик язика торкається альвеол: Альвеоли - це горбики за верхніми зубами. Кінчик язика повинен легко торкатися їх.",
    'Повітря затримується, а потім різко випускається: Під час вимови "t" повітря затримується за язиком, а потім різко випускається, створюючи звук "хлопка".',
    "Голосові зв'язки не вібрують: Звук \"t\" - це глухий приголосний, тому голосові зв'язки не вібрують під час його вимови.",
  ],
  e: [
    "Короткий звук",
    "Завжди наголошений",
    "Розслабте губи: Губи повинні бути розслаблені, не округлені і не розтягнуті.",
    "Середнє положення язика: Язик знаходиться в середньому положенні, не торкаючись ні зубів, ні піднебіння.",
    'Відкрийте рот: Рот відкривається трохи, менше, ніж для звуку "а", але більше, ніж для "і".',
    'Вимовте короткий звук: Вимовте короткий, чіткий звук, схожий на "е" в слові "сенс".',
  ],
  p: [
    "Змкнути губи, не дозволяючи повітрю виходити.",
    "Затримайти повітря за зімкнутими губами, створюючи тиск.",
    'Різко розімкнути губи, випускаючи повітря з "хлопком".',
    'Звук "p" - це глухий приголосний, тому голосові зв\'язки не вібрують під час його вимови.',
  ],
  r: [
    "Кінчик язика: Кінчик язика піднімається до альвеол (горбиків за верхніми зубами), але не торкається їх.",
    "Середня частина язика: Середня частина язика злегка вигинається догори, наближаючись до твердого піднебіння.",
    "Губи: Губи розслаблені, не округлені.",
    "Повітря: Повітря проходить вільно між язиком і піднебінням, не створюючи вібрації.",
    'Англійський звук "r" не є вібруючим, як український "р".',
    'Він вимовляється м\'яко, майже як "у" в слові "дуб".',
  ],
  s: [
    "Положення язика: Кінчик язика знаходиться за верхніми зубами, майже торкаючись альвеол (горбків за зубами).",
    "Повітряний потік: Повітря проходить крізь вузький простір між язиком і альвеолами, створюючи свистячий звук.",
    "Голосові зв'язки: Голосові зв'язки не вібрують, оскільки це глухий звук.",
  ],
  d: [
    "Положення язика: Кінчик язика притискається до альвеол (горбків за верхніми зубами).",
    "Повітряний потік: Повітря ненадовго блокується язиком, а потім різко випускається, створюючи проривний звук.",
    "Голосові зв'язки: Голосові зв'язки вібрують, оскільки це дзвінкий звук.",
  ],
  ɔː: [
    'Положення губ: Губи округлені, як при вимові "о", але трохи розтягнуті в сторони.',
    "Положення язика: Задня частина язика піднята до м'якого піднебіння.",
    'Тривалість: Звук вимовляється довго, приблизно вдвічі довше, ніж короткий звук "о".',
  ],
  i: [
    "Положення губ: Губи злегка розтягнуті в сторони.",
    'Положення язика: Середня частина язика піднята до піднебіння, але не так високо, як при вимові звуку "і".',
    "Тривалість: Звук вимовляється коротко.",
  ],
  g: [
    "Положення язика: Задня частина язика піднімається до м'якого піднебіння, блокуючи потік повітря.",
    "Повітряний потік: Повітря ненадовго блокується язиком, а потім різко випускається, створюючи проривний звук.",
    "Голосові зв'язки: Голосові зв'язки вібрують, оскільки це дзвінкий звук.",
  ],
  l: [
    "Положення язика: Кінчик язика притискається до альвеол (горбків за верхніми зубами).",
    "Повітряний потік: Повітря проходить по боках язика, оскільки його середина блокує прямий потік.",
    "Голосові зв'язки: Голосові зв'язки вібрують, оскільки це дзвінкий звук.",
  ],
  ə: [
    "Нейтральний звук: Це означає, що язик і губи перебувають у розслабленому, нейтральному положенні.",
    "Ненаголошений: Цей звук ніколи не буває наголошеним у слові.",
    "Короткий: Він вимовляється дуже коротко і нечітко.",
    "Приклади: about, taken, letter.",
  ],
};

export type ReadToLearnLetter = {
  letter: string;
  word: string;
  trn: string;
  isPrev?: boolean;
  sound: string;
  isHidden?: boolean;
};
type ReadToLearnLesson = {
  number: number;
  words: string[];
  storyIds: number[] | { id: number; start: number; end: number }[];
  letters: ReadToLearnLetter[];
  alphabet?: string[];
  newLettersCount?: number;
  bookImages?: string[];
  newSounsCount?: number;
  storyWithText?: boolean;
};
export const lessons: Record<string, ReadToLearnLesson> = {
  1: {
    letters: [
      { letter: "n", sound: "n", word: "not", trn: "|nɒt|" },
      { letter: "o", sound: "ɒ", word: "not", trn: "|nɒt|" },
      { letter: "t", sound: "t", word: "not", trn: "|nɒt|" },
      { letter: "p", sound: "p", word: "pet", trn: "|pet|" },
      { letter: "e", sound: "e", word: "pet", trn: "|pet|" },
      { letter: "e", sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
      { letter: "c", sound: "k", word: "car", trn: "|kɑːr|" },
      { letter: "a", sound: "ɑː", word: "car", trn: "|kɑːr|" },
      { letter: "r", sound: "r", word: "car", trn: "|kɑːr|" },
      { letter: "b", sound: "b", word: "bed", trn: "|bed|" },
      { letter: "d", sound: "d", word: "bed", trn: "|bed|" },
      { letter: "s", sound: "s", word: "star", trn: "|stɑːr|" },
      { letter: "l", sound: "l", word: "little", trn: "|ˈlɪtl|" },
      { letter: "i", sound: "i", word: "little", trn: "|ˈlɪtl|" },
      { letter: "g", sound: "g", word: "egg", trn: "|eɡ|" },
      { letter: "o", sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
    ],
    newLettersCount: 15,
    newSounsCount: 15,
    number: 1,
    storyIds: [],
    words: [
      "not",
      "pen",
      "red",
      "test",
      "bed",
      "sport",
      "top",
      "ten",
      "tennis",
      "leg",
      "big",
      "little",
      "sister",
      "pet",
      "continent",
      "egg",
      "car",
      "star",
      "end",
    ],
  },
  2: {
    letters: [
      { letter: "a", word: "hat", sound: "æ", trn: "|hæt|" },
      { letter: "m", word: "arm", sound: "m", trn: "|ɑːrm|" },
      { letter: "h", sound: "h", word: "hand", trn: "|hænd|" },
      { letter: "y", sound: "j", word: "yard", trn: "|jɑːrd|" },
      { letter: "o", sound: "əʊ", word: "stone", trn: "|stəʊn|" },
      { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
      { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
      { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
      { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
      { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
      { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
      { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
      { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
      { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
      { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
      { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
      { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
      { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
      { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
    ],
    number: 2,
    storyIds: [1697],
    // alphabet: [

    // ],
    newLettersCount: 3,
    newSounsCount: 5,
    words: [
      "no",
      "go",
      "note",
      "stone",
      "poet",
      "arm",
      "art",
      "card",
      "cat",
      "apple",
      "hand",
      "hat",
      "had",
      "lamb",
      "yes",
      "yard",
      "hobby",
      "Mary",
    ],
  },
  3: {
    letters: [
      { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
      { letter: "f", word: "frog", sound: "f", trn: "|frɒɡ|" },
      { letter: "n", word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
      { letter: "s", word: "rose", sound: "z", trn: "|rəʊz|" },
      { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
      { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
      { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
      { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
      { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
      { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
      { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
      { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
      { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
      { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
      { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
      { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
      { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
      { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
      { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
      { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
      { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
      { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
      { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
    ],
    number: 3,
    storyIds: [
      {
        id: 1589,
        start: 10,
        end: 20,
      },
    ],
    newLettersCount: 1,
    newSounsCount: 3,
    words: [
      "hello",
      "letter",
      "dad",
      "daddy",
      "as",
      "and",
      "in",
      "fact",
      "family",
      "frog",
      "dog",
      "cats",
      "dogs",
      "nose",
      "rose",
      "roses",
      "mom",
      "mommy",
      "finger",
      "ring",
      "song",
    ],
  },
  4: {
    letters: [
      { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mom", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "dog", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hello", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "nose", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "as", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "and", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "fact", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "family", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "daddy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "finger", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "song", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "roses", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "big", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "continent", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "red", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "leg", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "tennis", sound: "", trn: "" },
      { letter: "u", word: "run", sound: "ʌ", trn: "|rʌn|" },
      { letter: "w", word: "winter", sound: "w", trn: "|ˈwɪntər|" },
      { letter: "e", word: "he", sound: "i:", trn: "|hiː|" },
      { letter: "e", word: "here", sound: "ɪ", trn: "|hɪr|" },
      { letter: "i", word: "time", sound: "aɪ", trn: "|taɪm|" },
      { letter: "f", isPrev: true, word: "frog", sound: "f", trn: "|frɒɡ|" },
      { letter: "n", isPrev: true, word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
      { letter: "s", isPrev: true, word: "rose", sound: "z", trn: "|rəʊz|" },
      { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
      { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
      { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
      { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
      { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
      { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
      { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
      { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
      { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
      { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
      { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
      { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
      { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
      { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
      { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
      { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
      { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
      { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
      { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
    ],
    number: 4,
    storyIds: [
      // {
      //   id: 1589,
      //   start: 10,
      //   end: 20,
      // },
    ],
    bookImages: [
      "/image-pdf-books/book-3/image-book-3-0.jpg",
      "/image-pdf-books/book-3/image-book-3-1.jpg",
      "/image-pdf-books/book-3/image-book-3-2.jpg",
      "/image-pdf-books/book-3/image-book-3-3.jpg",
      "/image-pdf-books/book-3/image-book-3-4.jpg",
      "/image-pdf-books/book-3/image-book-3-5.jpg",
      "/image-pdf-books/book-3/image-book-3-6.jpg",
      "/image-pdf-books/book-3/image-book-3-7.jpg",
      "/image-pdf-books/book-3/image-book-3-8.jpg",
      "/image-pdf-books/book-3/image-book-3-9.jpg",
      "/image-pdf-books/book-3/image-book-3-10.jpg",
    ],
    newLettersCount: 2,
    newSounsCount: 5,
    words: [
      "I",
      "hi",
      "tiger",
      "spider",
      "time",
      "winter",
      "spring",
      "hop",
      "can",
      "am",
      "he",
      "we",
      "here",
      "England",
      "summer",
      "run",
      "butter",
      "up",
      "cup",
    ],
  },
  5: {
    letters: [
      { isHidden: true, letter: "", word: "this", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "is", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "rat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "the", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "sat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "on", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "look", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "too", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "fat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "park", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "bank", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "room", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "bedroom", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "book", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "notebook", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "spoon", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "wood", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mom", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "dog", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hello", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "nose", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "as", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "and", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "fact", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "family", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "daddy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "finger", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "song", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "roses", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "big", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "continent", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "red", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "leg", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "tennis", sound: "", trn: "" },
      { letter: "k", word: "park", sound: "k", trn: "|pɑːrk|" },
      { letter: "th", word: "this", sound: "ð", trn: "|ðɪs|" },
      { letter: "oo", word: "look", sound: "ʊ", trn: "|lʊk|" },
      { letter: "oo", word: "too", sound: "uː", trn: "|tuː|" },
      { letter: "u", isPrev: true, word: "run", sound: "ʌ", trn: "|rʌn|" },
      { letter: "w", isPrev: true, word: "winter", sound: "w", trn: "|ˈwɪntər|" },
      { letter: "e", isPrev: true, word: "he", sound: "i:", trn: "|hiː|" },
      { letter: "e", isPrev: true, word: "here", sound: "ɪ", trn: "|hɪr|" },
      { letter: "i", isPrev: true, word: "time", sound: "aɪ", trn: "|taɪm|" },
      { letter: "f", isPrev: true, word: "frog", sound: "f", trn: "|frɒɡ|" },
      { letter: "n", isPrev: true, word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
      { letter: "s", isPrev: true, word: "rose", sound: "z", trn: "|rəʊz|" },
      { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
      { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
      { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
      { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
      { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
      { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
      { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
      { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
      { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
      { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
      { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
      { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
      { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
      { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
      { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
      { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
      { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
      { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
      { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
    ],
    number: 5,
    storyWithText: true,
    storyIds: [
      {
        id: 1698,
        start: 10,
        end: 174,
      },
    ],
    bookImages: [
      // "/image-pdf-books/book-3/image-book-3-0.jpg",
      // "/image-pdf-books/book-3/image-book-3-1.jpg",
      // "/image-pdf-books/book-3/image-book-3-2.jpg",
      // "/image-pdf-books/book-3/image-book-3-3.jpg",
      // "/image-pdf-books/book-3/image-book-3-4.jpg",
      // "/image-pdf-books/book-3/image-book-3-5.jpg",
      // "/image-pdf-books/book-3/image-book-3-6.jpg",
      // "/image-pdf-books/book-3/image-book-3-7.jpg",
      // "/image-pdf-books/book-3/image-book-3-8.jpg",
      // "/image-pdf-books/book-3/image-book-3-9.jpg",
      // "/image-pdf-books/book-3/image-book-3-10.jpg",
    ],
    newLettersCount: 1,
    newSounsCount: 4,
    words: [
      "this",
      "is",
      "rat",
      "the",
      "sat",
      "on",
      "mat",
      "look",
      "too",
      "fat",
      "park",
      "bank",
      "room",
      "bedroom",
      "book",
      "notebook",
      "spoon",
      "wood",
    ],
  },
  6: {
    letters: [
      { isHidden: true, letter: "", word: "this", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "is", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "rat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "the", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "sat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "on", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "look", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "too", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "fat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "park", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "bank", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "room", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "bedroom", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "book", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "notebook", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "spoon", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "wood", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mom", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "dog", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hello", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "nose", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "as", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "and", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "fact", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "family", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "daddy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "finger", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "song", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "roses", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "big", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "continent", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "red", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "leg", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "tennis", sound: "", trn: "" },
      { letter: "a", word: "name", sound: "eɪ", trn: "|neɪm|" },
      // { letter: "ou", sword: "you", sound: "uː", trn: "|juː|" },
      { letter: "o", word: "do", sound: "uː", trn: "|duː|" },
      // { letter: "ow", word: "how", sound: "aʊ", trn: "|haʊ|" },
      { letter: "k", isPrev: true, word: "park", sound: "k", trn: "|pɑːrk|" },
      { letter: "th", isPrev: true, word: "this", sound: "ð", trn: "|ðɪs|" },
      { letter: "oo", isPrev: true, word: "look", sound: "ʊ", trn: "|lʊk|" },
      { letter: "oo", isPrev: true, word: "too", sound: "uː", trn: "|tuː|" },
      { letter: "u", isPrev: true, word: "run", sound: "ʌ", trn: "|rʌn|" },
      { letter: "w", isPrev: true, word: "winter", sound: "w", trn: "|ˈwɪntər|" },
      { letter: "e", isPrev: true, word: "he", sound: "i:", trn: "|hiː|" },
      { letter: "e", isPrev: true, word: "here", sound: "ɪ", trn: "|hɪr|" },
      { letter: "i", isPrev: true, word: "time", sound: "aɪ", trn: "|taɪm|" },
      { letter: "f", isPrev: true, word: "frog", sound: "f", trn: "|frɒɡ|" },
      { letter: "n", isPrev: true, word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
      { letter: "s", isPrev: true, word: "rose", sound: "z", trn: "|rəʊz|" },
      { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
      { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
      { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
      { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
      { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
      { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
      { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
      { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
      { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
      { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
      { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
      { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
      { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
      { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
      { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
      { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
      { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
      { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
      { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
    ],
    number: 6,
    storyWithText: true,
    storyIds: [
      {
        id: 1589,
        start: 10,
        end: 174,
      },
    ],
    bookImages: [],
    newLettersCount: 0,
    newSounsCount: 4,
    words: [
      "brother",
      "group",
      "soup",
      "you",
      "are",
      "where",
      "baby",
      "lady",
      "plan",
      "name",
      "plane",
      "cow",
      "how",
      "down",
      "do",
      "two",
      "one",

      // "this",
      // "rat",
      // "sat",
      // "on",
      // "mat",
      // "look",
      // "too",
      // "fat",
      // "park",
      // "room",
      // "bedroom",
      // "book",
      // "notebook",
      // "tiger",
      // "spider",
      // "time",
      // "winter",
      // "spring",
      // "hop",
      // "can",
      // "he",
      // "we",
      // "here",
      // "England",
      // "summer",
      // "run",
      // "butter",
      // "up",
      // "cup",
      // "and",
      // "in",
      // "family",
      // "frog",
      // "finger",
      // "ring",
      // "song",

      // "no",
      // "go",
      // "note",
      // "stone",
      // "apple",
      // "hand",
      // "hat",
      // "had",
      // "lamb",
      // "yes",
      // "yard",
      // "hobby",

      // "not",
      // "pen",
      // "bed",
      // "top",
      // "big",
      // "pet",
      // "star",
      // "end",
    ],
  },
  7: {
    letters: [
      { isHidden: true, word: "map", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "cap", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "apple", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "are", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "name", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "sat", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "car", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "plane", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "rat", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "star", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "table", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "mat", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "arm", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "take", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "fat", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "art", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "same", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "bank", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "card", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "case", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "am", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "scar", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "face", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "hand", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "bark", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "race", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "hat", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "tar", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "migrate", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "lamb", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "park", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "late", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "as", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "lark", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "mate", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "and", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "carp", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "plate", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "fact", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "spark", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "state", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "family", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "cart", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "game", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "cat", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "dark", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "blame", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "cats", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "far", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "crane", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "can", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "tart", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "lake", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "had", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "barn", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "wake", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "dad", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "garden", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "cake", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "plan", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "garlic", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "brake", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "task", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "market", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "make", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "parent", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "yard", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "baby", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "carry", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "harm", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "tasty", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "marry", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "mark", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "lady", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "narrow", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "smart", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "crazy", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "aware", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "start", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "lazy", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "carrot", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "farm", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "hazy", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "arrow", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "hard", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "hasty", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "zoo", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "zip", letter: "", sound: "", trn: "" },
      { isHidden: true, word: "puzzle", letter: "", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "this", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "is", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "rat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "the", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "sat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "on", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "look", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "too", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "fat", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "park", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "bank", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "room", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "bedroom", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "book", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "notebook", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "spoon", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "wood", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "art", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "card", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "no", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "note", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "apple", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "lamb", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "yes", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "Mary", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "poet", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "go", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hobby", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mom", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "dog", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "hello", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "nose", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "as", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "and", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "fact", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "family", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "daddy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "mommy", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "finger", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "song", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "cats", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "roses", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "big", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "continent", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "red", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "leg", sound: "", trn: "" },
      { isHidden: true, letter: "", word: "tennis", sound: "", trn: "" },
      { letter: "z", isPrev: true, word: "zoo", sound: "uː", trn: "|zuː|" },
      { letter: "a", isPrev: true, word: "name", sound: "eɪ", trn: "|neɪm|" },
      { letter: "ou", isPrev: true, word: "you", sound: "uː", trn: "|juː|" },
      { letter: "o", isPrev: true, word: "do", sound: "uː", trn: "|duː|" },
      { letter: "ow", isPrev: true, word: "how", sound: "aʊ", trn: "|haʊ|" },
      { letter: "k", isPrev: true, word: "park", sound: "k", trn: "|pɑːrk|" },
      { letter: "th", isPrev: true, word: "this", sound: "ð", trn: "|ðɪs|" },
      { letter: "oo", isPrev: true, word: "look", sound: "ʊ", trn: "|lʊk|" },
      { letter: "oo", isPrev: true, word: "too", sound: "uː", trn: "|tuː|" },
      { letter: "u", isPrev: true, word: "run", sound: "ʌ", trn: "|rʌn|" },
      { letter: "w", isPrev: true, word: "winter", sound: "w", trn: "|ˈwɪntər|" },
      { letter: "e", isPrev: true, word: "he", sound: "i:", trn: "|hiː|" },
      { letter: "e", isPrev: true, word: "here", sound: "ɪ", trn: "|hɪr|" },
      { letter: "i", isPrev: true, word: "time", sound: "aɪ", trn: "|taɪm|" },
      { letter: "f", isPrev: true, word: "frog", sound: "f", trn: "|frɒɡ|" },
      { letter: "n", isPrev: true, word: "ring", sound: "ŋ", trn: "|rɪŋ|" },
      { letter: "s", isPrev: true, word: "rose", sound: "z", trn: "|rəʊz|" },
      { letter: "a", isPrev: true, word: "hat", sound: "æ", trn: "|hæt|" },
      { letter: "m", isPrev: true, word: "arm", sound: "m", trn: "|ɑːrm|" },
      { letter: "h", isPrev: true, sound: "h", word: "hand", trn: "|hænd|" },
      { letter: "y", isPrev: true, sound: "j", word: "yard", trn: "|jɑːrd|" },
      { letter: "o", isPrev: true, sound: "əʊ", word: "stone", trn: "|stəʊn|" },
      { letter: "n", isPrev: true, sound: "n", word: "not", trn: "|nɒt|" },
      { letter: "o", isPrev: true, sound: "ɒ", word: "not", trn: "|nɒt|" },
      { letter: "t", isPrev: true, sound: "t", word: "not", trn: "|nɒt|" },
      { letter: "p", isPrev: true, sound: "p", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "e", word: "pet", trn: "|pet|" },
      { letter: "e", isPrev: true, sound: "ə", word: "sister", trn: "|ˈsɪstər|" },
      { letter: "c", isPrev: true, sound: "k", word: "car", trn: "|kɑːr|" },
      { letter: "a", isPrev: true, sound: "ɑː", word: "car", trn: "|kɑːr|" },
      { letter: "r", isPrev: true, sound: "r", word: "car", trn: "|kɑːr|" },
      { letter: "b", isPrev: true, sound: "b", word: "bed", trn: "|bed|" },
      { letter: "d", isPrev: true, sound: "d", word: "bed", trn: "|bed|" },
      { letter: "s", isPrev: true, sound: "s", word: "star", trn: "|stɑːr|" },
      { letter: "l", isPrev: true, sound: "l", word: "little", trn: "|ˈlɪtl|" },
      { letter: "i", isPrev: true, sound: "i", word: "little", trn: "|ˈlɪtl|" },
      { letter: "g", isPrev: true, sound: "g", word: "egg", trn: "|eɡ|" },
      { letter: "o", isPrev: true, sound: "ɔː", word: "sport", trn: "|spɔːrt|" },
    ],
    number: 7,
    storyWithText: true,
    storyIds: [
      // {
      //   id: 1589,
      //   start: 10,
      //   end: 174,
      // },
      {
        id: 1698,
        start: 10,
        end: 174,
      },
    ],
    bookImages: [],
    newLettersCount: 1,
    newSounsCount: 0,
    words: [
      "lazy",
      "crazy",
      "zoo",
      "zip",
      "puzzle",
      "baby",
      "lady",
      "plane",
      "brother",
      "group",
      "soup",
      "you",
      "where",
      "plan",
      "name",
      "cow",
      "how",
      "down",
      "do",
      "this",
      "rat",
      "sat",
      "on",
      "mat",
      "look",
      "too",
      "fat",
      "park",
      "room",
      "bedroom",
      "book",
      "notebook",
      "tiger",
      "spider",
      "time",
      "winter",
      "spring",
      "hop",
      "can",
      "he",
      "we",
      "here",
      "England",
      "summer",
      "run",
      "butter",
      "up",
      "cup",
      "and",
      "in",
      "family",
      "frog",
      "finger",
      "ring",
      "song",

      "no",
      "go",
      "note",
      "stone",
      "apple",
      "hand",
      "hat",
      "had",
      "lamb",
      "yes",
      "yard",
      "hobby",

      "not",
      "pen",
      "bed",
      "top",
      "big",
      "pet",
      "star",
      "end",
    ],
  },
};

export const readToLearnCourseWords = Object.values(lessons)
  .map((l) => l.words)
  .flat();

export const LearnToReadScreen: React.FC = () => {
  const [lessonSentences, setLessonSentences] = useState<Sentence[]>([]);
  const [module, setModule] = useState<Module>();
  const [currentModuleWords, setCurrentModuleWords] = useState<Word[]>([]);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const authUser = useLessonsStore((state) => state.authUser);
  const grammarModules = useLessonsStore((state) => state.grammarModules);
  const setGrammarModules = useLessonsStore((state) => state.setGrammarModules);
  const [notExistingUserWords, setNotExistingUserWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <MenuLayout isFluid>
      <Container sx={{ backgroundColor: "white", py: 5 }}>
        {isAdmin(authUser?.id) && (
          <Box sx={{ border: "2px solid red" }} p={2} mb={2}>
            <Typography>Admin</Typography>
            <HStack>
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  const { data: userWords } = await supabaseClient
                    .from("user-words")
                    .select()
                    .eq("user", "53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b");

                  const rowUserWords: any = Array.from(new Set(userWords?.map((w) => w.word)));

                  const notExistingUserWords = readToLearnCourseWords.filter((w) => {
                    if (w === "tv") return false;
                    return !rowUserWords?.includes(w);
                  });
                  setNotExistingUserWords(notExistingUserWords);
                  console.log("🚀 ~ notExistingUserWords:", notExistingUserWords);
                }}
              >
                Check not existing words
              </Button>
              {!!notExistingUserWords.length && (
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={async () => {
                    setIsLoading(true);

                    const deployResult = await Promise.all(
                      notExistingUserWords.map((w: string) => {
                        return supabaseClient.from("user-words").insert({
                          user: "53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b",
                          id: `53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b-${w}`,
                          word: w,
                        });
                      }),
                    );
                    console.log("🚀 ~ deployResult:", deployResult);

                    setIsLoading(false);
                  }}
                >
                  Deploy words
                </LoadingButton>
              )}
            </HStack>
          </Box>
        )}
        <VStack gap={0}>
          <VStack mb={4}>
            <Typography gutterBottom variant="h3">
              Тренажери української мови
            </Typography>
            <FindVowelLetter />
            <FindConconantLetter />
          </VStack>
          <Typography gutterBottom variant="h3">
            Уроки англійської
          </Typography>
          {/* {!grammarModules?.length && <Loader />} */}
          {Object.values(lessons).map((lesson) => {
            const linkText = (
              <Typography pb={1}>
                Урок {lesson.number}.{" "}
                <Typography component="span" color="primary" sx={{ fontSize: 12 }}>
                  {lesson.words.length}
                </Typography>
              </Typography>
            );

            if (!authUser || !authUser.read_lesson || authUser.read_lesson < lesson.number) {
              return <Box sx={{ color: "grey" }}>{linkText}</Box>;
            }

            return (
              <MuiLink
                key={lesson.number}
                color="secondary.dark"
                component={Link}
                display="inline-block"
                // to={`/grammar/grammar-lesson/${lesson.number}/build-words-list`}
                to={`/learn-to-read/${lesson.number}`}
              >
                {linkText}
              </MuiLink>
            );
          })}
        </VStack>
      </Container>
    </MenuLayout>
  );
};
