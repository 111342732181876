const storiesData = [
  {
    row_new_words: ["a", "fan", "then", "jan", "she"],
    words: ["dad", "had", "a", "fan", "then", "he", "no", "jan", "hat", "she"],
    link: "AvlOlGoCgQo",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Dad had a fan.\nThen he had no fan.\nJan had a hat.\nThen she had no hat.",
    story_id: 1699,
    word_count: 10,
    all_intersection_count: 5,
    new_words_count: 5,
  },
  {
    row_new_words: [
      "how's",
      "weather",
      "today",
      "it",
      "sunny",
      "rainy",
      "cloudy",
      "snowy",
      "let's",
      "outside",
    ],
    words: [
      "how's",
      "the",
      "weather",
      "today",
      "is",
      "it",
      "sunny",
      "rainy",
      "cloudy",
      "snowy",
      "let's",
      "look",
      "outside",
    ],
    link: "D1qV5PsDoQw",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "How's the weather? How's the weather? How's the weather today? Is it sunny? Is it rainy? Is it cloudy? Is it snowy? How's the weather today? Let's look outside. How's the weather? Is it sunny today? Let's look outside. How's the weather? Is it rainy today? Look outside. How's the weather? Is it cloudy today? Let's look outside. Is it snowy today? How's the weather? How's the weather? How's the weather today? Is it sunny? Is it rainy? Is it cloudy? Is it snowy? How's the weather today? How's the weather? How's the weather? How's the weather today? Is it sunny? Is it rainy? Is it cloudy? Is it snowy? How's the weather today?",
    story_id: 1712,
    word_count: 13,
    all_intersection_count: 3,
    new_words_count: 10,
  },
  {
    row_new_words: ["dan", "ran", "to", "a", "man", "pan", "fan", "ham", "then", "his"],
    words: [
      "this",
      "is",
      "dan",
      "ran",
      "to",
      "a",
      "man",
      "pan",
      "can",
      "fan",
      "ham",
      "and",
      "then",
      "his",
      "mat",
    ],
    link: "(1)6aH1k7Gle58",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "This is Dan.\nDan ran. Dan ran to a man.\nDan ran to a pan. Dan ran to a can.\nDan ran to a fan. Dan ran to a ham.\nDan ran and ran and ran.\nThen Dan ran to his mat.",
    story_id: 1753,
    word_count: 15,
    all_intersection_count: 5,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "jingle",
      "bell",
      "my",
      "it",
      "fast",
      "slow",
      "high",
      "low",
      "over",
      "there",
      "everywhere",
    ],
    words: [
      "jingle",
      "little",
      "bell",
      "I",
      "can",
      "ring",
      "my",
      "it",
      "fast",
      "slow",
      "high",
      "low",
      "over",
      "here",
      "there",
      "everywhere",
    ],
    link: "VJXg2xmdG7k",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Jingle, jingle little bell I can ring my little bell.\nRing it fast, ring it slow, ring it high, ring it low.\nJingle, jingle little bell I can ring my little bell.\nRing it fast, ring it slow, ring it high, ring it low.\nJingle, jingle little bell I can ring my little bell.\nRing it over here, ring it over there, ring it everywhere\nJingle, jingle little bell I can ring my little bell.\nRing it over here, ring it over there, ring it everywhere.\nJingle, jingle little bell I can ring my little bell.",
    story_id: 1725,
    word_count: 16,
    all_intersection_count: 5,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "starlight",
      "bright",
      "first",
      "see",
      "tonight",
      "wish",
      "may",
      "might",
      "have",
      "say",
      "mighth",
      "to",
      "night",
    ],
    words: [
      "starlight",
      "star",
      "bright",
      "first",
      "I",
      "see",
      "tonight",
      "wish",
      "may",
      "might",
      "have",
      "the",
      "say",
      "mighth",
      "to",
      "night",
    ],
    link: "OWip7yvXukI",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Starlight star bright first star I see tonight Wish I may Wish I might have the wish I wish tonight Starlight, star bright first star I say tonight Wish I may wish I might have the wish I wish tonight Starlight, star bright first star I see tonight Wish I may, Wish I might have the wish I wish tonight Starlight, star bright first star I see tonight Wish I may wish I mighth have the wish I wish to night",
    story_id: 1597,
    word_count: 16,
    all_intersection_count: 3,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "rain",
      "away",
      "come",
      "again",
      "another",
      "day",
      "wants",
      "to",
      "play",
      "",
      "brother",
      "baby",
      "all",
    ],
    words: [
      "rain",
      "go",
      "away",
      "come",
      "again",
      "another",
      "day",
      "daddy",
      "wants",
      "to",
      "play",
      "",
      "mommy",
      "brother",
      "sister",
      "baby",
      "all",
      "the",
      "family",
    ],
    link: "LFrKYjrIDs8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Rain, rain, go away\nCome again another day\nDaddy wants to play\n\nRain, rain, go away\nCome again another day\nMommy wants to play\n\nRain, rain, go away\nCome again another day\nBrother wants to play\n\nRain, rain, go away \nCome again another day\nSister wants to play\n\nRain, rain, go away\nCome again another day\nBaby wants to play\n\nRain, rain, go away\nCome again another day\nAll the family family wants to play\n\nRain, rain, go away",
    story_id: 1599,
    word_count: 19,
    all_intersection_count: 6,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "jan",
      "has",
      "a",
      "her",
      "blue",
      "wet",
      "net",
      "she",
      "get",
      "let",
      "it",
      "jet",
      "vet",
      "fish",
    ],
    words: [
      "jan",
      "has",
      "a",
      "pet",
      "her",
      "is",
      "little",
      "blue",
      "wet",
      "net",
      "she",
      "can",
      "get",
      "let",
      "it",
      "go",
      "jet",
      "vet",
      "fish",
    ],
    link: "(1)fh-zRxSG6N8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Jan has a pet. Her pet is little. Her pet is blue. Her pet is wet. Jan has a net. She can get her wet pet. She can let it go. Her pet has a jet. Her pet has a vet. It is a fish.",
    story_id: 1751,
    word_count: 19,
    all_intersection_count: 5,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "hot",
      "cross",
      "buns",
      "one",
      "a",
      "penny",
      "two",
      "give",
      "them",
      "to",
      "your",
      "daughters",
      "sons",
      "1",
      "2",
    ],
    words: [
      "hot",
      "cross",
      "buns",
      "one",
      "a",
      "penny",
      "two",
      "give",
      "them",
      "to",
      "your",
      "daughters",
      "sons",
      "1",
      "2",
    ],
    link: "re3gXNTtwig",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Hot cross buns, Hot cross buns. One a penny, two a penny. Hot cross buns, Hot cross buns, Hot cross buns. One a penny, two a penny. Hot cross buns. Give them to your daughters, Give them to your sons. One a penny, two a penny. Hot cross buns, Hot cross buns, Hot cross buns. One a penny, two a penny. Hot cross buns, Hot cross buns, hot cross buns. 1 a penny, 2 a penny hot cross buns. Give them to your daughters, Give them to your sons. One a penny to a penny Hot cross buns.",
    story_id: 1600,
    word_count: 15,
    all_intersection_count: 0,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "twinkle",
      "la",
      "how",
      "wonder",
      "what",
      "you",
      "are",
      "above",
      "world",
      "so",
      "high",
      "like",
      "a",
      "diamond",
      "sky",
      "littleow",
      "leo",
    ],
    words: [
      "twinkle",
      "la",
      "star",
      "how",
      "I",
      "wonder",
      "what",
      "you",
      "are",
      "up",
      "above",
      "the",
      "world",
      "so",
      "high",
      "like",
      "a",
      "diamond",
      "in",
      "sky",
      "littleow",
      "leo",
      "little",
    ],
    link: "yCjJyiqpAuU",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Twinkle, twinkle la star How I wonder what you are up above the world so high Like a diamond in the sky Twinkle, twinkle littleow star How I wonder what you are Twinkle, twinkle Leo star How I wonder what you are up above the world so high Like a diamond in the sky Twinkle, twinkle little little star How I wonder what you are",
    story_id: 1595,
    word_count: 23,
    all_intersection_count: 6,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "three",
      "times",
      "nine",
      "monkeys",
      "a",
      "vine",
      "9",
      "3",
      "4",
      "12",
      "books",
      "shelf",
      "will",
      "read",
      "them",
      "all",
      "four",
    ],
    words: [
      "three",
      "times",
      "is",
      "nine",
      "monkeys",
      "on",
      "a",
      "vine",
      "9",
      "3",
      "4",
      "12",
      "books",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
      "four",
    ],
    link: "8vL-tlcPhC4",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Three times three. Three times three is nine. Nine monkeys on a vine. Three times three is nine. 9 monkeys on a vine. Three times 3 is monkeys on a vine. Three times 4 is 12. 12 books on a shelf, I will read them all. Three times four is 12. 12 books on a shelf, I will read them all.",
    story_id: 1664,
    word_count: 20,
    all_intersection_count: 3,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "that",
      "evening",
      "when",
      "to",
      "see",
      "starshine",
      "overhead",
      "they",
      "are",
      "daysies",
      "white",
      "dart",
      "midadle",
      "of",
      "night",
      "at",
      "dark",
      "middle",
    ],
    words: [
      "that",
      "evening",
      "when",
      "I",
      "go",
      "to",
      "bed",
      "see",
      "the",
      "starshine",
      "overhead",
      "they",
      "are",
      "little",
      "daysies",
      "white",
      "dart",
      "midadle",
      "of",
      "night",
      "at",
      "dark",
      "middle",
    ],
    link: "MSo_1WRkNf0",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "That evening when I go to bed I see the starshine overhead. They are the little daysies white that dart the midadle of the night of the night. At evening when I go to bed I see the starshine overhead. They are the little daysies white that dark the middle of the night of the night.",
    story_id: 1673,
    word_count: 23,
    all_intersection_count: 5,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "row",
      "your",
      "boat",
      "gently",
      "down",
      "stream",
      "merrily",
      "life",
      "but",
      "a",
      "dream",
      "ro",
      "throw",
      "stroll",
      "me",
      "your're",
      "gent",
      "fe",
      "dre",
    ],
    words: [
      "row",
      "your",
      "boat",
      "gently",
      "down",
      "the",
      "stream",
      "merrily",
      "life",
      "is",
      "but",
      "a",
      "dream",
      "ro",
      "throw",
      "stroll",
      "me",
      "your're",
      "gent",
      "fe",
      "on",
      "dre",
    ],
    link: "7otAJa3jui8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Row, row, row your boat gently down the stream Merrily, merrily, merrily, merrily Life is but a dream Row, row, row your boat gently down the stream Merrily, merrily, merrily, merrily Life is but a dream Row ro, row your boat gently down the stream Merrily, merrily, merrily, merrily Life is but a dream Row, row, row your boat gently down the stream Merrily, merrily, merrily, merrily Life is but a dream Row, row, row your boat gently down Throw your boat gently down the Stroll your boat Merrily, merrily Me your're merrily gent fe down on the stream Merrily, merrily, merrily, merrily Life is but a dream Life is a dre.",
    story_id: 1717,
    word_count: 22,
    all_intersection_count: 3,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "wheels",
      "bus",
      "round",
      "door",
      "goes",
      "open",
      "shut",
      "wipers",
      "swish",
      "horn",
      "beep",
      "people",
      "down",
      "babies",
      "wh",
      "wham",
      "where",
      "school",
      "mummy",
      "daddy'on",
    ],
    words: [
      "the",
      "wheels",
      "on",
      "bus",
      "go",
      "round",
      "and",
      "door",
      "goes",
      "open",
      "shut",
      "wipers",
      "swish",
      "horn",
      "beep",
      "people",
      "up",
      "down",
      "babies",
      "wh",
      "wham",
      "where",
      "school",
      "mummy",
      "daddy'on",
    ],
    link: "yWirdnSDsV4",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "The wheels on the bus go round and round round and round round and round the wheels on the bus go round and round, round and round the door on the bus goes open and shut open and shut open and shut the door on the bus goes open and shut open and shut the wipers on the bus go swish swish swish swish swish swish swish swish swish the wipers on the bus go swish swish swish swish swish swish the horn on the bus goes beep beep beep beep beep beep beep beep beep the horn on the bus goes beep beep beep beep beep beep the people on the bus go up and down, up and down, up and down the people on the bus go up and down, up and down the babies on the bus go wh wham wh wham wham wh wham wham where the babies on the bus go? School where where where where where where? The mummy on the bus go? The daddy'on the bus go.",
    story_id: 1715,
    word_count: 25,
    all_intersection_count: 5,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "my",
      "soft",
      "when",
      "quite",
      "alone",
      "god",
      "watches",
      "me",
      "with",
      "care",
      "sees",
      "at",
      "rising",
      "kneeling",
      "down",
      "listens",
      "to",
      "prayer",
      "suffered",
      "see",
      "pray",
    ],
    words: [
      "in",
      "my",
      "soft",
      "bed",
      "when",
      "quite",
      "alone",
      "god",
      "watches",
      "me",
      "with",
      "care",
      "sees",
      "at",
      "rising",
      "kneeling",
      "down",
      "and",
      "listens",
      "to",
      "prayer",
      "suffered",
      "see",
      "pray",
    ],
    link: "QzqYHCrKBO8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "In my soft bed when quite alone God watches me with care Sees me at rising, kneeling down and listens to my prayer in my suffered when quite alone God watches me with care See me at rising, kneeling down and listens to my pray",
    story_id: 1671,
    word_count: 24,
    all_intersection_count: 3,
    new_words_count: 21,
  },
  {
    row_new_words: [
      "5",
      "times",
      "55",
      "20",
      "25",
      "bees",
      "beside",
      "a",
      "hive",
      "205",
      "five",
      "high",
      "3",
      "4",
      "1212",
      "books",
      "shelf",
      "will",
      "read",
      "them",
      "all",
    ],
    words: [
      "5",
      "times",
      "55",
      "is",
      "20",
      "25",
      "bees",
      "beside",
      "a",
      "hive",
      "205",
      "five",
      "high",
      "3",
      "4",
      "1212",
      "books",
      "on",
      "shelf",
      "I",
      "will",
      "read",
      "them",
      "all",
    ],
    link: "NPfydhrQYzA",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "5 times 55 times 5 is 20 25, 25 bees beside a hive 5 times 5 is 20 25, 205 bees beside a hive 5 times five is bees beside a high 3 times 4 is 1212 books on a shelf, I will read them all. 3 times 4 is 1212 books ON A shelf, I will read them all.",
    story_id: 1665,
    word_count: 24,
    all_intersection_count: 3,
    new_words_count: 21,
  },
  {
    row_new_words: [
      "walking",
      "running",
      "now",
      "let's",
      "stop",
      "tip",
      "toe",
      "jump",
      "swimming",
      "sleep",
      "wake",
      "it's",
      "to",
      "are",
      "you",
      "ready",
      "fast",
      "okay",
      "let",
      "runningning",
      "u",
    ],
    words: [
      "walking",
      "hop",
      "running",
      "now",
      "let's",
      "stop",
      "tip",
      "toe",
      "jump",
      "swimming",
      "sleep",
      "wake",
      "up",
      "it's",
      "time",
      "to",
      "go",
      "are",
      "you",
      "ready",
      "fast",
      "okay",
      "run",
      "and",
      "let",
      "runningning",
      "u",
    ],
    link: "fPMjnlTEZwU",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Running, running, running, running, running, running. Now let's stop. Now let's stop. Walking, walking, walking, walking. Hop, hop, hop, hop hop hop. Running, running, running, running, running, running. Now let's stop. Now let's stop. Tip toe tip, toe tip toe tip toe. Jump, jump, jump, jump, jump, jump. Swimming, swimming, swimming, swimming, swimming, swimming. Now let's sleep. Now let's sleep. Wake up, it's time to go Are you ready to go fast? Okay. Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Running, running, running run and running. Now let stop. Now let's stop. Walking, walking, walking, walking. Hop, hop, hop hop hop hop. Runningning, running, running, running, running. Now let stop. Now let's stop. U.",
    story_id: 1713,
    word_count: 27,
    all_intersection_count: 6,
    new_words_count: 21,
  },
  {
    row_new_words: [
      "a",
      "whose",
      "fleece",
      "was",
      "white",
      "snow",
      "giraffe",
      "neck",
      "very",
      "long",
      "rhinoceros",
      "horn",
      "sharp",
      "ouch",
      "kangaroo",
      "pouch",
      "warm",
      "camel",
      "huh",
      "hump",
      "round",
    ],
    words: [
      "Mary",
      "had",
      "a",
      "little",
      "lamb",
      "whose",
      "fleece",
      "was",
      "white",
      "as",
      "snow",
      "and",
      "big",
      "giraffe",
      "neck",
      "very",
      "long",
      "rhinoceros",
      "horn",
      "sharp",
      "ouch",
      "kangaroo",
      "too",
      "pouch",
      "warm",
      "camel",
      "huh",
      "hump",
      "round",
    ],
    link: "YE7PiTwhTQk",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Mary had a little lamb, little lamb, little lamb.\nMary had a little lamb whose fleece was white as snow.\nMary had a little lamb and a big giraffe.\nMary had a big giraffe.\nMary had a big giraffe whose neck was very long, long, long, very, very, very long.\nAnd a rhinoceros.\nMary had a rhinoceros.\nMary had a rhinoceros whose horn was very sharp.\nOuch. Sharp, sharp, sharp, sharp, sharp, sharp, sharp, sharp.\nVery, very, very sharp.\nA kangaroo too.\nMary had a kangaroo, kangaroo, kangaroo.\nMary had a kangaroo whose pouch was very warm, warm, warm, warm, warm, warm, warm, warm, warm.\nVery, very, very warm.\nAnd a camel, huh?\nMary had a camel, camel, camel.\nMary had a camel whose hump was very round, round, round, round, round, round, round, round, round.\nVery, very, very round.",
    story_id: 1593,
    word_count: 29,
    all_intersection_count: 8,
    new_words_count: 21,
  },
  {
    row_new_words: [
      "welcome",
      "to",
      "kids",
      "academy",
      "where",
      "are",
      "you",
      "how",
      "do",
      "brother",
      "baby",
      "subscribe",
      "our",
      "channel",
      "stay",
      "updated",
      "new",
      "videos",
      "find",
      "links",
      "apps",
      "comments",
      "below",
    ],
    words: [
      "welcome",
      "to",
      "kids",
      "academy",
      "daddy",
      "finger",
      "where",
      "are",
      "you",
      "here",
      "I",
      "am",
      "how",
      "do",
      "mommy",
      "brother",
      "sister",
      "baby",
      "subscribe",
      "our",
      "channel",
      "stay",
      "updated",
      "on",
      "new",
      "videos",
      "find",
      "links",
      "apps",
      "in",
      "the",
      "comments",
      "below",
    ],
    link: "G6k7dChBaJ8",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "Welcome to Kids Academy. Daddy finger. Daddy finger. Where are you? Here I am. Here I am. How do you do? Mommy finger, Mommy finger. Where are you? Here I am. Here I am. How do you do? Brother finger, Brother finger, Where are you? Here I am. Here I am. How do you do? Sister finger, Sister finger, Where are you? Here I am. Here I am. How do you do? Baby finger, Baby finger, Where are you? Here I am. Here I am. How do you do? Subscribe to our channel to stay updated on new videos. Find links to our apps in the comments below.",
    story_id: 1589,
    word_count: 33,
    all_intersection_count: 10,
    new_words_count: 23,
  },
  {
    row_new_words: [
      "if",
      "you're",
      "happy",
      "clap",
      "your",
      "hands",
      "angry",
      "stomp",
      "feet",
      "stopomp",
      "scared",
      "say",
      "oh",
      "sleepy",
      "take",
      "a",
      "nap",
      "ducks",
      "farm",
      "quack",
      "do",
      "you",
      "like",
      "broccoli",
      "1",
      "2",
      "3",
    ],
    words: [
      "if",
      "you're",
      "happy",
      "clap",
      "your",
      "hands",
      "angry",
      "stomp",
      "feet",
      "stopomp",
      "scared",
      "say",
      "oh",
      "no",
      "sleepy",
      "take",
      "a",
      "nap",
      "the",
      "ducks",
      "on",
      "farm",
      "quack",
      "do",
      "you",
      "like",
      "broccoli",
      "1",
      "2",
      "3",
    ],
    link: "l4WNrvVjiTw",
    type: "song",
    grammar: null,
    pdf_name: null,
    storage_link: null,
    id: "If you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands Clap your hands if you're happy, happy, happy Clap your hands if you're angry, angry, angry Stomp your feet if you're angry, angry, angry Stomp your feet if you're angry, angry, angry Stopomp your feet Stomp your feet if you're angry, angry, angry Stomp your feet if you're scared, scared, scared say oh no, oh no if you're scared, scared, scared say oh no, oh no if you're scared, scared, scared say oh no say oh no if you're scared, scared, scared say oh no, oh no if you're sleepy, sleepy, sleepy Take a nap if you're sleepy, sleepy, sleepy Take a nap if you're sleepy, sleepy, sleepy Take a nap, take a nap if you're sleepy, sleepy, sleepy Take a nap if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands if you're happy, happy, happy Clap your hands, clap your hands if you're happy, happy, happy Clap your hands the ducks on the farm say quack, quack, quack. Do you like broccoli? 1, 2, 3.",
    story_id: 1711,
    word_count: 30,
    all_intersection_count: 3,
    new_words_count: 27,
  },
  {
    row_new_words: ["you'll", "liked", "old", "was", "large", "larger"],
    words: [
      "I",
      "think",
      "you'll",
      "like",
      "my",
      "new",
      "bicycle",
      "liked",
      "your",
      "old",
      "it",
      "was",
      "fast",
      "that's",
      "right",
      "but",
      "is",
      "faster",
      "apartment",
      "large",
      "larger",
    ],
    link: "(3)https://www.youtube.com/watch?v=GSx9o9uZosQ&list=PLD7AA7B1BC72ACC28&index=9",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/was1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Geq0KZXDpgBOupSib7TNDnHcJPYaCP9wHPBRhLopcTTy%2FFUs5qyi3JnJ83XLTk1O3fne2VpAD4jppCFZKe1v5ZeF%2F1uUK581hZb0H5L4Zvhb5vb6wf1aWxvOxCtLsH39%2F%2FmQ5lHTRgtkNAhKodPn8UPFRSLzTNXpGBX0CL7kYEHGI52eCLM9%2BKdtPHuYGX50i%2FNGZk4ulJegkjjgAZ6ub7ANDkgxYprdbEwD4PQz%2F7IbPPN34iLYvy3NYq6bNsTTiSTTlz4YSIbAL47FZIgrueDgytteM9wLtdV4mZ2VQuLv3XneQqap4kU%2FBF9SEYt7IOU7WNCNlQAKPM2YkFAbeg%3D%3D",
    id: "I think you'll like my new bicycle.\nI liked your old bicycle. It was fast.\nThat's right. But my new bicycle is faster.\n\nI think you'll like my new apartment.\nBut I liked your old apartment. It was large.\nThat's right. But my new apartment is larger.",
    story_id: 1047,
    word_count: 21,
    all_intersection_count: 15,
    new_words_count: 6,
  },
  {
    row_new_words: ["often", "washed", "yesterday", "gonna", "really", "sure"],
    words: [
      "I",
      "see",
      "you're",
      "washing",
      "your",
      "car",
      "yes",
      "am",
      "do",
      "you",
      "wash",
      "it",
      "very",
      "often",
      "washed",
      "yesterday",
      "I'm",
      "now",
      "and",
      "gonna",
      "tomorrow",
      "really",
      "like",
      "to",
      "sure",
      "every",
      "day",
    ],
    link: "https://www.youtube.com/watch?v=w24IRWsCL48&list=PLD7AA7B1BC72ACC28",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/washing-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jXQWqVbylqSFLbOBYZrQ0rXJyWtYW9K%2BD5hAGr%2FHJ9%2BgseT2aejDb7s6FLx1RG4n590mrXRZw7eYPF1U2VW%2BNgDOzMGUjjEkqkX%2BA%2FunxoNJLgAwSC7k%2BlAR1wymOuQQ4XfN22Dr6YrmxUqwsoFAF0rhscXF6XGFZkf2x5hdZAMMBLZr31Bf9ivqNGAZZ6g4QiYfPd%2Fs2K2atW83%2B7sjdj5H9ud47%2ByjheHW06casNKirM5WBDYDh3PvMfH0SsviFbaMmO6Q6rY4CwXxuH8id5oHMWSnQWc23iFGzEGigXIadb6FFlGb1hEnmiqdc%2Fpj6pJbRexvAPU9tr1UC3UMaQ%3D%3D",
    id: "I see you're washing your car.\nYes. I am.\nDo you wash it very often?\nYes. I washed it yesterday. I'm washing it now, and I'm gonna wash it tomorrow.\nYou really like to wash your car.\nI sure do. I wash it every day.",
    story_id: 1011,
    word_count: 27,
    all_intersection_count: 21,
    new_words_count: 6,
  },
  {
    row_new_words: ["hair", "roger", "Julie", "exercises", "Tom", "Susan", "painting", "garage"],
    words: [
      "are",
      "you",
      "busy",
      "yes",
      "I",
      "am",
      "I'm",
      "washing",
      "my",
      "hair",
      "is",
      "roger",
      "he",
      "he's",
      "cleaning",
      "his",
      "kitchen",
      "Julie",
      "she",
      "she's",
      "fixing",
      "her",
      "bicycle",
      "we",
      "we're",
      "doing",
      "our",
      "exercises",
      "Tom",
      "and",
      "Susan",
      "they",
      "they're",
      "painting",
      "their",
      "garage",
    ],
    link: "(2)https://www.youtube.com/watch?v=OLieQ0U3TA4&t=181s",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Continuous.%20Question%20form%205.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=mGwyDKiG2j8jvfnQGvR4n0S2y%2FC4Zogxw8deYnlqgjLB7xSNXJw%2FFOHfUgWUlaJlrB0w3PHXgUNAzK6tASaTYhQ%2BKBLJ%2B6jVmvFUygbvC3DjDtW%2BuqcXZwUXF%2FR8JGsmd3YZmEfJEp0%2FaGfiiJ2mcWRIGy8FUlY7sRYqRKY%2Be%2FK%2BukUk1KJSqRoqdwVkJB%2BvrLvi6HPwNSCW0OUT9N3dm2MrgEl5ax1xdc%2FqDpYFTR%2FEe0ot%2FsF%2BVB7%2B5HIsoNMpsOXkbSu%2FpEWeo1Z1bq5PR8jboVV7SRWaYD8RQdwSBsEpph1GAig8c6Bmsfteo9ecHxLVpl01GSHfsbW7ZG7XBQ%3D%3D",
    id: "Are you busy?\nYes, I am.\nI'm washing my hair.\nIs Roger busy.\nYes, he is. He's cleaning his kitchen.\nIs Julie busy. Yes, she is. She's fixing her bicycle\nAre you busy? Yes, we are.\nWe're doing our exercises.\nAre Tom and Susan busy.\nYes, they are. They're painting their garage.",
    story_id: 958,
    word_count: 36,
    all_intersection_count: 28,
    new_words_count: 8,
  },
  {
    row_new_words: ["glasses", "then", "put", "otherwise", "words", "done", "take", "away"],
    words: [
      "when",
      "I",
      "want",
      "to",
      "read",
      "something",
      "need",
      "get",
      "my",
      "glasses",
      "out",
      "and",
      "then",
      "put",
      "on",
      "otherwise",
      "can't",
      "see",
      "the",
      "words",
      "I'm",
      "done",
      "reading",
      "take",
      "off",
      "away",
    ],
    link: "https://www.youtube.com/shorts/cSkbwh0H76k",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "When I want to read something, I need to get my glasses out, and then I need to put my glasses on. Otherwise I can't see the words. When I'm done reading, I take my glasses off and then I put my glasses away.",
    story_id: 1096,
    word_count: 26,
    all_intersection_count: 18,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "shoulders",
      "shrug",
      "asks",
      "question",
      "answer",
      "rhymes",
      "hug",
      "put",
      "around",
    ],
    words: [
      "if",
      "you",
      "don't",
      "know",
      "something",
      "can",
      "use",
      "your",
      "shoulders",
      "to",
      "shrug",
      "the",
      "teacher",
      "asks",
      "a",
      "question",
      "and",
      "answer",
      "just",
      "say",
      "I",
      "other",
      "thing",
      "do",
      "that",
      "rhymes",
      "with",
      "is",
      "hug",
      "that's",
      "when",
      "go",
      "like",
      "this",
      "someone",
      "put",
      "arms",
      "around",
      "them",
      "so",
      "they",
    ],
    link: "(5)https://www.youtube.com/watch?v=vA-uEPEHU_M&list=PLQut38RVINERTw9It9-fkKY8jgNEJ5AHC",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/shrug.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=QD%2BdgtUIi6q8764pKu5Th1mZQiiouExIEP6pFVXXOcBPCOdq3%2FGh4v3hTiGaolYGqSePrkpWoVHrMTkI2bHL2hVCebOJ8LiPkTrCDUFMs96CRyNyNzsiW2Ew76KCrM%2Bh%2BmyFOR0w7tJ5mBURpS9fyitLe8yugsX0ewsJAGqb%2FtbYr43ePTLKxy4%2FAOMIsxAiB6qrrLkjHshQoZhLvErWgnjfU%2F%2F75VXeogIQweUPvJsB62qsqA9%2FP3%2F5QnKUwQCwFpjCfT7OFYipOIO9QNhFn0TYix32pIJcDJC4%2FfeVrsemdv4bYmjDK3ytB5%2FKCCj3F8QYpjIxcAHmoDRsrBq54g%3D%3D",
    id: "If you don't know something, you can use your shoulders to shrug. If the teacher asks you a question and you don't know the answer, you can just say, I don't know. The other thing you can do that rhymes with shrug is hug. That's when you go like this to someone and you just put your arms around them so that they know that you like.",
    story_id: 1116,
    word_count: 41,
    all_intersection_count: 32,
    new_words_count: 9,
  },
  {
    row_new_words: ["jog", "dan", "fog", "van", "man", "log", "over", "said", "lot"],
    words: [
      "this",
      "is",
      "a",
      "dog",
      "the",
      "can",
      "jog",
      "with",
      "dan",
      "they",
      "in",
      "fog",
      "by",
      "van",
      "man",
      "to",
      "log",
      "but",
      "too",
      "big",
      "walk",
      "jumps",
      "over",
      "good",
      "said",
      "you",
      "do",
      "lot",
    ],
    link: "(1)XofVFIIDLdk",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "This is a dog. The dog can jog. The dog can jog with Dan. They jog in the fog. They jog by a dog. They jog by a van. They jog by a man. They jog to a log. But the log is too big. Walk the dog jumps over the log. Good dog, said Dan. You can do a lot.",
    story_id: 1749,
    word_count: 28,
    all_intersection_count: 19,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "Bob",
      "cooking",
      "spaghetti",
      "Wednesday",
      "cooks",
      "Gary",
      "bathing",
      "Friday",
      "bathes",
    ],
    words: [
      "what's",
      "Bob",
      "doing",
      "he's",
      "cooking",
      "spaghetti",
      "why",
      "is",
      "he",
      "that",
      "it's",
      "Wednesday",
      "always",
      "cooks",
      "on",
      "Gary",
      "bathing",
      "his",
      "cat",
      "Friday",
      "bathes",
    ],
    link: "(3)https://www.youtube.com/watch?v=SzL6Ww7xUWc&list=PL31D7CB49ABAAB4DB&index=19",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/doing.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=YguFniex%2FpYXNP%2BSIjTtml0QLiRbQ7LHo9E0%2B3Gj%2FNO37vqvK%2BVMVHHd3G5xPUEAm5jj3KyvGIdqgULJahErHGHFxvyNXCXoKiitCzcme0Vkww%2FnE%2Fme1TW422cQziczNV1xs%2Bv7toHiwMx6MzdrWRPrGWw6rLDGX1D%2F5SJWmR%2BY9w8HBaPNoIbxoLTYAwh76yeDNUQC%2F89djjst%2Fx24zTvpt1EdskUKjbpqWZOUFJI5%2BhTLkkiP3CELiShZTai5pB6XIAulWBkhIrmx8jsawTCoZBkTf2QuTtSda0bpBd2ilaJq4%2BulrtZZSToYYbLxuu89qluTEQFIzsDQDkNCNQ%3D%3D",
    id: "What's Bob doing?\nHe's cooking spaghetti.\nCooking spaghetti? Why is he doing that?\nIt's Wednesday. He always cooks spaghetti on Wednesday.\n\nWhat's Gary doing?\nHe's bathing his cat.\nBathing his cat?\nWhy is he doing that?\nIt's Friday. He always bathes his cat on Friday.",
    story_id: 997,
    word_count: 21,
    all_intersection_count: 12,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "did",
      "yesterday",
      "went",
      "beach",
      "nobody",
      "myself",
      "yourself",
      "had",
      "great",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "yesterday",
      "I",
      "went",
      "to",
      "the",
      "beach",
      "oh",
      "who",
      "go",
      "with",
      "nobody",
      "by",
      "myself",
      "yourself",
      "yes",
      "had",
      "a",
      "great",
      "time",
    ],
    link: "https://www.youtube.com/watch?v=Yf5RXW3zAAk&list=PLD7AA7B1BC72ACC28&index=18",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/yourself-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=H45s39J3xVFjoUYQmJOu%2BpxCyj%2FMCEG1pn%2Frk4JXslscMDB4T1xo7R%2B9wLa2xrs9Wd9qxzUDjbmQYIcBBZYOSxfgUIDrP%2B%2Bi4qUZoL%2BVk7LKLgwaWCmslhvRWqxbVd5q%2BtiQLMx55N9dDdwyYZl8jX8d%2FJWzsOG8y4ALt24%2Fl8iiJx75U4pb7eLYaDoBHF8BVl3lNOLfvhEPZ4svTXKsJ4oCg0%2FwJzluWx3v1P4hYBK6tvpg2Wm%2FJwdXQNTfAgx0BMdXqSD2hLHhSzblsFULE224fIy1cQLnlxfbNduoslK3OGOC2qIMNOke7ptmXC4%2BbXog16DR%2BiwUnGVwnXX19Q%3D%3D",
    id: "What did you do yesterday?\nI went to the beach.\nOh, who did you go to the beach with?\nNobody.\nI went to the beach by myself.\nYou went by yourself?\nYes. I had a great time.",
    story_id: 1051,
    word_count: 23,
    all_intersection_count: 14,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "did",
      "washed",
      "floors",
      "all",
      "cleaned",
      "painted",
      "porch",
      "fixed",
      "worked",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "today",
      "I",
      "washed",
      "my",
      "floors",
      "your",
      "yes",
      "all",
      "day",
      "cleaned",
      "house",
      "painted",
      "porch",
      "fixed",
      "sink",
      "we",
      "worked",
      "at",
      "home",
    ],
    link: "(2)https://www.youtube.com/watch?v=xyvNu254A-Y&list=PL31D7CB49ABAAB4DB&index=24",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/doing-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=drYWU0J2dhyK32ZJiCY9tB0evxB7VhLTNclDMTT4Bu63Xc5f79rO0KtGQEr3847QGA0GXUQG3bGi0Qt1%2FAoee9tg2a%2BFjyBdqgDfTTZVPW8d4F6xGjCxCilTE7a%2B7mNEJO9qeNoHZzAdLkhTTNBGzfYviqm0nPEhU2XeSU2Wpqa5O2Shnpw5gN%2Fr5leQGkQ%2BBYEvJJVpL4EseQS5qBpqSIZxzBCTvFuH0N%2BHnkMxAOu%2Baj5cu4%2F%2BRWlFnTXoMSYnBC0o7GekEiQ0Icu8%2FbE%2B8u4JBkvUV8pcJ3UrEM20y3E7qOu%2F4kS1ic38B4VBfHcz%2FSCKHLCFJyIIAT2qMM2BYQ%3D%3D",
    id: "What did you do today?\nI washed my floors.\nYour floors?\nYes. I washed my floors all day.\n\nWhat did you do today?\nI cleaned my house.\nYour house?\nYes. I cleaned my house all day.\n\nWhat did you do today?\nI painted my porch.\nYour porch?\nYes. I painted my porch all day.\n\nWhat did you do today?\nI fixed my sink.\nYour sink?\nYes. I fixed my sink all day.\n\nI washed my floors.\nI cleaned my house.\nI painted my porch.\nI fixed my sink.\nWe worked at home all day.",
    story_id: 1010,
    word_count: 23,
    all_intersection_count: 14,
    new_words_count: 9,
  },
  {
    row_new_words: ["much", "lettuce", "cost", "95¢", "lot", "expensive", "pound", "dollar", "25"],
    words: [
      "how",
      "much",
      "does",
      "a",
      "head",
      "of",
      "lettuce",
      "cost",
      "95¢",
      "that's",
      "lot",
      "money",
      "you're",
      "right",
      "is",
      "very",
      "expensive",
      "this",
      "week",
      "pound",
      "apples",
      "dollar",
      "25",
      "are",
    ],
    link: "(2)https://www.youtube.com/watch?v=SBG0nsPuI6Q&list=PLD7AA7B1BC72ACC28&index=5",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/cost.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=B%2FGx8ZIk2KhoGSA71vIJk7WiUyVgS5UQ%2BWMebAO5DP1DTkgvB%2BxAi6NmkB3KmXh6osoFMPcLYQg%2F3A6e91zym%2FaH4Hg9oMMxm5TRAXN0doR9mNU1J8XGBd8pz%2B6Uv0vlseaRzlrV8G4DRI4kOA5jAN92rDLacKJqs57FeMXqcRPSEV%2BeGIGTkpZlXgon6hhpqfNTxXmBKeN6yXD5C99nDGq%2BPI2IYVafSFUkHGIqf%2FVSy%2B6GlRq54%2Bylne5tvYVWoNGWvqmipCpc4aFkgIXPTiYaN59fwDoYH0d2QFQ2nYHaVstIyunft3uR68sa3TRNcM6hJ9om7rJd9ySNVXdoZw%3D%3D",
    id: "How much does a head of lettuce cost?\n95¢.\n95¢? That's a lot of money.\nYou're right. Lettuce is very expensive this week.\n\nHow much does a pound of apples cost?\nA dollar 25.\nA dollar 25? That's a lot of money.\nYou're right. Apples are very expensive this week.",
    story_id: 1023,
    word_count: 24,
    all_intersection_count: 15,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "yawning",
      "there",
      "tired",
      "asking",
      "questions",
      "ask",
      "job",
      "leave",
      "angry",
      "goodbye",
    ],
    words: [
      "why",
      "are",
      "you",
      "yawning",
      "who's",
      "there",
      "it's",
      "me",
      "yes",
      "I'm",
      "because",
      "tired",
      "I",
      "always",
      "yawn",
      "when",
      "and",
      "asking",
      "questions",
      "ask",
      "my",
      "job",
      "well",
      "please",
      "leave",
      "right",
      "now",
      "go",
      "get",
      "out",
      "of",
      "here",
      "shouting",
      "angry",
      "shout",
      "goodbye",
    ],
    link: "(2)https://www.youtube.com/watch?v=vaq6pX7q15s&list=PL31D7CB49ABAAB4DB&index=18",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/yawning.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ejUZbj6TGArfRTG798HEbtqKgvB738rylDZ4tdP1untfCr%2FE%2BfQOxAwu5majHik3XHE9VlP85HR7mU2OMHWLaExz4or7NpCMwyQc%2BnKMEJQj9ZOTyYwF6f2DrGnzFGCrdi%2F%2BP0dcNCowdjxbszJVho2zohMuds8tsVTTEzCyoL%2FigapkGxk9%2FO9fwvhGBousaxlOqt6bDugVSZeVbrXwT5JY%2BO05sBE73PEC4sC%2BX%2BedHShUBLdh9XhIvx1jAAVdgDplTSckhz2AUvRE0UmfKVa9OcecZA7xdD2q1qRP%2BXw4J%2Bp57DzK6KnKHCGuZi%2FijnrEfbIMVtor7KJYYAfthA%3D%3D",
    id: "Why are you yawning?\nWho's there?\nIt's me.\nYou?\nYes.\nWhy are you yawning? I'm yawning because I'm tired. I always yawn when I'm tired. And why are you asking me questions?\nI always ask questions. It's my job.\nWell, please leave right now. Go. Get out of here.\nWhy are you shouting?\nI'm shouting because I'm angry. I always shout when I'm angry. Now please leave. Go. Goodbye.",
    story_id: 991,
    word_count: 36,
    all_intersection_count: 26,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "Johnny",
      "getting",
      "dressed",
      "any",
      "I'll",
      "glad",
      "himself",
      "Cathy",
      "fix",
      "herself",
    ],
    words: [
      "what's",
      "Johnny",
      "doing",
      "he's",
      "getting",
      "dressed",
      "does",
      "he",
      "need",
      "any",
      "help",
      "I'll",
      "be",
      "glad",
      "to",
      "him",
      "no",
      "that's",
      "okay",
      "can",
      "get",
      "by",
      "himself",
      "Cathy",
      "she's",
      "fixing",
      "her",
      "bicycle",
      "she",
      "mom",
      "fix",
      "herself",
      "oh",
    ],
    link: "https://www.youtube.com/watch?v=PQCB85N52QE&list=PLD7AA7B1BC72ACC28&index=24",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/pronoun.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=x1hhjZnrvY3F4NA2gHE9kKYseGabk%2Fr%2BM9SxaCAvnGo8RVj24VAcjAxZX5kxMYbaOgSxk28WkpOic%2FkF5SpaTRQiq%2FHrO%2FAm1BrUCr9YAJ8rm9j1SVhcTQmJUyhQYX6%2BNOEYlyFKMVd1WCJ7we0qqjcZ%2BQzAMSj9%2F7NgxVhFxqcTZyxuSbZurBpOz2xJGLY0eE35hPnhRlXpMtLGXaMKqXQQq5iB3S5GO7p%2F0Okv2z12KrzYcD%2BEGRXN4ZHv6LcaCARXHn8Z2v0I86Ff0%2BL7E4PAHHlGzDnCsGzhtnI9o86xKKGtvtSCDsW9BMCpVU50EsH8DmltAdZMsfqg%2BSOCCA%3D%3D",
    id: "What's Johnny doing?\nHe's getting dressed.\nDoes he need any help?\nI'll be glad to help him.\nNo, that's okay. He can get dressed by himself.\n\nWhat's Cathy doing?\nShe's fixing her bicycle.\nDoes she need any help? I'll be glad to help her.\nNo, that's okay, mom. She can fix her bicycle by herself.\nOh, okay.",
    story_id: 1068,
    word_count: 33,
    all_intersection_count: 23,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "hay",
      "elevator",
      "top",
      "moment",
      "we'll",
      "under",
      "underneath",
      "down",
      "over",
      "back",
    ],
    words: [
      "let's",
      "use",
      "this",
      "hay",
      "elevator",
      "here",
      "to",
      "talk",
      "about",
      "on",
      "and",
      "top",
      "of",
      "in",
      "a",
      "moment",
      "we'll",
      "under",
      "underneath",
      "right",
      "now",
      "I",
      "am",
      "the",
      "but",
      "if",
      "climb",
      "down",
      "go",
      "over",
      "so",
      "I'm",
      "it",
      "can",
      "back",
    ],
    link: "(10)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/10prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Dklb8k6kpS7uKjmRtgwJbmwSGvDfbwm3P4ysVbbE0eu89TgOVvGtegOR%2FU7V3PK24dFJ5ApwqdaE422Ws5GTSQm4fr4O0PI0vpFALnBwqhxDWmH6N%2B90krrshpI912Ab9MERSTEZq4EhBqTYPI42NjYK8qOAFSwv%2Bn70XA6kmhzTGvPPYsd8CDW%2BLOT3DNU%2FVTZQEGqbLT0GpAFtg%2FbUhz9Lm%2BUmDUaZYa%2F%2FcbqB6fjT900ll4n4xb99mQsvzmcuIhlPR3Rnvjc08ugc%2Fx%2FSS%2Bn%2Bvvghh1GNQkYrKesE6ZfOaOWT3vVmpVBt6Wz7E45BgBinUrhu0Kl%2BXGLZiXRWPQ%3D%3D",
    id: "Let's use this hay elevator here to talk about on and on top of and in a moment, we'll talk about under and underneath. Right now, I am on the hay elevator. I am on top of the hay elevator. But if I climb down and if I go over here, now, I am under the hay elevator. I am underneath the hay elevator. So right now, I'm under the hay elevator. I'm underneath it. If I go over here, I can go back on the hay elevator. I can go back on top of it.",
    story_id: 1265,
    word_count: 35,
    all_intersection_count: 25,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "gonna",
      "birthday",
      "necklace",
      "gave",
      "last",
      "year",
      "2",
      "years",
      "ago",
      "really",
    ],
    words: [
      "what",
      "are",
      "you",
      "gonna",
      "give",
      "your",
      "wife",
      "for",
      "her",
      "birthday",
      "I",
      "don't",
      "know",
      "can't",
      "a",
      "necklace",
      "gave",
      "last",
      "year",
      "how",
      "about",
      "flowers",
      "no",
      "2",
      "years",
      "ago",
      "well",
      "really",
      "have",
      "to",
      "think",
      "it",
    ],
    link: "https://www.youtube.com/watch?v=_lA-Min1MGk&list=PLD7AA7B1BC72ACC28&index=2",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/gift.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=JJ0J2p87LUDqyfS17iG5FnBU5VXMNPmXxGljXzYklRUoau72xAsFrw58dPEJopnbpwsq2o0rOTMGx%2FDig95kCNE2S2DosGuyyjPZA5QuVNWnH3J0zRRaRposbsE7ljhzGcguHe0ZnaCcjmR%2FZZpc1K23uCmKpO5Ace6tZCYEgt8qQMmL3XS198jCTdLCvjDuF%2BjteG7Piz%2BZPulPfgFbO9raUwrOH376RDaa52sv3ElDIqMMt0guIKB0s2%2FeAHshh8OMzdcRnz8JWLN64OrWk7mBr4bfCz91E%2BnKHZFY6w4d2FEk6vGseBesmgrzUYtJtmcynE88PJhT4I6YK6v9NA%3D%3D",
    id: "What are you gonna give your wife for her birthday?\nI don't know.\nI can't give her a necklace. I gave her a necklace last year.\nHow about flowers?\nNo. I can't give her flowers. I gave her flowers 2 years ago.\nWell, what are you gonna give her?\nI don't know. I really have to think about it.",
    story_id: 1012,
    word_count: 32,
    all_intersection_count: 22,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "difference",
      "between",
      "*to",
      "see*",
      "movie",
      "watch*",
      "come",
      "theater",
      "stay",
      "maybe",
      "netflix",
    ],
    words: [
      "so",
      "let's",
      "talk",
      "about",
      "the",
      "difference",
      "between",
      "*to",
      "see*",
      "a",
      "movie",
      "and",
      "watch*",
      "if",
      "I",
      "say",
      "that",
      "I'm",
      "going",
      "to",
      "go",
      "see",
      "it",
      "means",
      "probably",
      "come",
      "theater",
      "like",
      "this",
      "watch",
      "usually",
      "stay",
      "home",
      "maybe",
      "something",
      "on",
      "netflix",
      "here",
      "today",
      "at",
    ],
    link: "https://www.youtube.com/watch?v=MqvCqskYor8&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=5",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1seeamovie.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=iTMD0GBBdZEKuPK%2F4sPnw8wTx%2BRp2pWE0nn5vkis%2BsH9tLeli%2Fp8RrTNtaXk9ADEDXA9tfDUA7teBT5wnOXn%2FuPsZHxUIAUdqRDkFsnNGM%2BYkNf17NJUDmwDdZYa3A3fcQ1%2FO2TkP%2F2dGpDTVkjVX1ciXLOsO1mVwO%2FHe2zrSxBkpb%2BPGj%2FKW50VPKSFNM%2FlSi3wOwa3csVxO2461PYgfET1V55G%2BPa2CwtzbJ2H5ynu1hZ1toxOX1tvEuPHnxR1lShpp4QImKEVrpg%2FM9BAQAi86spjdOstU%2FVmJYf0OnUKq9pIS2uXIb3us%2FMYyTJuy5K3kg8FRog%2FAtFZSVlfXA%3D%3D",
    id: "So let's talk about the difference between *to see* a movie and *to watch* a movie. If I say that I'm going to go see a movie, it means I'm probably going to come to a theater like this to see that movie. If I say I'm going to watch a movie, it usually means I'm going to stay home and maybe watch something on Netflix. So I'm here today to see a movie. I'm at a theater *to see* a movie.",
    story_id: 1158,
    word_count: 40,
    all_intersection_count: 29,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "present",
      "continuous",
      "first",
      "situation",
      "would",
      "describe",
      "sitting",
      "chair",
      "spot",
      "hopping",
      "moment",
    ],
    words: [
      "so",
      "when",
      "do",
      "you",
      "use",
      "the",
      "present",
      "continuous",
      "well",
      "first",
      "situation",
      "where",
      "would",
      "is",
      "to",
      "describe",
      "things",
      "that",
      "are",
      "doing",
      "right",
      "now",
      "I'm",
      "sitting",
      "on",
      "a",
      "chair",
      "running",
      "spot",
      "hopping",
      "want",
      "in",
      "moment",
    ],
    link: "(1)https://www.youtube.com/watch?v=rFdhrR6Dpco",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1thepresentcontinuous.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=kXCH1Dh%2FglWW4%2B%2FQWc8LdNnalxDaSZWKdduJHVfL%2BZESiLSZBfDb6tXQL4jU7tU0VLCYZtivHw8cEHUITxzPEXxZOzVqiXuxgwrijp9S2eUXHscPEFyceUup3trq2DgVhn4EXPngH8gbrPXnQJJJr3xl8LIw2ipMjUFQ%2FONNd0wIuZzO3%2BSUzZmh98zr%2FrE%2FS0sDpd0pdXUo3PNZfbB433qAmVZKNeRomiO4RNlr7lL%2Fw4HlIVDgcjOmXgNRwwjGie5uBFnsvA%2FcxayJ5ZYvNNf9UiutoS0kgH%2Bu8JJJQiZlGXp5xykDxNyFOP04Ia5Jnj17v7RnzVpNyFElW93%2B8Q%3D%3D",
    id: "So when do you use the present continuous? Well, the first situation where you would use the present continuous is to describe the things that you are doing right now. So I'm sitting on a chair, I'm running on the spot, I'm hopping. So when you want to describe things that you are doing in the moment, things that you are doing right now, you would use the present continuous.",
    story_id: 1278,
    word_count: 33,
    all_intersection_count: 22,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "stick",
      "fetch",
      "throw",
      "I'll",
      "yell",
      "come",
      "Walter",
      "bring",
      "back",
      "pup",
      "apparently",
    ],
    words: [
      "if",
      "you",
      "have",
      "a",
      "ball",
      "or",
      "stick",
      "and",
      "dog",
      "can",
      "play",
      "fetch",
      "when",
      "throw",
      "the",
      "I'll",
      "yell",
      "come",
      "on",
      "Walter",
      "bring",
      "it",
      "back",
      "pup",
      "apparently",
      "doesn't",
      "want",
      "to",
      "today",
      "he",
      "just",
      "wants",
      "with",
      "okay",
      "give",
      "me",
    ],
    link: "https://www.youtube.com/shorts/od3sDMh3qus",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "If you have a ball or a stick and a dog, you can play fetch. When you play fetch, you throw the ball or stick. I'll throw the stick and you yell, fetch. Fetch. Come on, Walter. Bring it back. Come on, pup. Come on. Come on, pup. Apparently, Walter doesn't want to play fetch today. He just wants to play with the stick. Okay, you give me the stick. You give me the stick. Bring me the stick. You give me the stick. You give me the stick.",
    story_id: 1086,
    word_count: 36,
    all_intersection_count: 25,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "did",
      "John",
      "yesterday",
      "went",
      "movies",
      "nobody",
      "himself",
      "tina",
      "played",
      "cards",
      "herself",
    ],
    words: [
      "what",
      "did",
      "John",
      "do",
      "yesterday",
      "he",
      "went",
      "to",
      "the",
      "movies",
      "oh",
      "who",
      "go",
      "with",
      "nobody",
      "by",
      "himself",
      "tina",
      "she",
      "played",
      "cards",
      "play",
      "herself",
    ],
    link: "(2)https://www.youtube.com/watch?v=Yf5RXW3zAAk&list=PLD7AA7B1BC72ACC28&index=18",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/did.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=fgVT8XGndaJwI%2BhmMfhxvUlzzFU4FkKE1hBfYUCjZEeJiIgJ%2BuBtfPVNzXCmBOclsvMpedPjS8Ptaza%2FST679RV%2F5QDh5Lyxga21ojHyfkRVjTI8bcilp0XLe3Gr5yLlynbQ3R01srVoB8I05rx2P0U5Zy8FsIsSW5WNjcs%2FXteLKhdYvcqycXMHM39xWzrsf1YC0EVxoB2AujQSoVnxuJ%2BVNzgOjYxY%2BtVvkEshJEAS8bgXiOQBzMo9tfDutr8o3vK6aPlNVRWoad1K1Ddg9FEnnm9nccxheCNPKye%2BewqUNYlv5f7cbUTodUq5Al6XRFSxnDjgNiLKq9RupJuovQ%3D%3D",
    id: "What did John do yesterday?\nHe went to the movies.\nOh, who did he go to the movies with?\nNobody. He went to the movies by himself.\n\nWhat did Tina do yesterday?\nShe played cards.\nOh, who did she play cards with?\nNobody. She played cards by herself.",
    story_id: 1052,
    word_count: 23,
    all_intersection_count: 12,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "much",
      "salt",
      "put",
      "little",
      "many",
      "onions",
      "salad",
      "few",
      "pepper",
      "stew",
      "omelette",
    ],
    words: [
      "how",
      "much",
      "salt",
      "should",
      "I",
      "put",
      "in",
      "the",
      "soup",
      "just",
      "a",
      "little",
      "not",
      "too",
      "many",
      "onions",
      "salad",
      "few",
      "pepper",
      "stew",
      "eggs",
      "omelette",
      "one",
      "or",
      "two",
    ],
    link: "(4)https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/muchmany-4.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=SlpnSzj4%2BzrSfhetCbT8pve%2BsTnZMxajFdt4rBI8fjsMYZIoZBElVa6UDjNUPuvkfUcWhhTOQXO8GtlWglFd032VlgfYUmcx431lgYXTzMVVscdm6bNC5xWKB2HaA555Aoj8Aw8ujhGe%2FkQW1topS7rYzB%2Bp8xNBXkjCUzx3aLCq542w9DCJ0hd0YLAxiHsLIGKk42bhMpRi1QHDHfDcWdXWIvDKC0RsJBKG%2BPyxLAAn8ltWSk1tysqkFfw7wxVtJz3W4FefG7EYErRJuRhaq8VhNHNj5girvZ8C5uECGY3epWrty0ld%2Fg1PquvRCgJsepSDNrsWZ1jc1%2B4SVUcTSA%3D%3D",
    id: "How much salt should I put in the soup?\nJust a little, not too much.\nHow many onions should I put in the salad?\nJust a few, not too many.\nHow much pepper should I put in the stew?\nJust a little, not too much.\nHow many eggs should I put in the omelette?\nJust a few, not too many.\nSalt in the soup, pepper in the stew.\nEggs in the omelette.\nJust a few.\nJust a little, not too much.\nNot too many, just a few.\nJust a few, not too many, not too many.\nJust one or two.",
    story_id: 1020,
    word_count: 25,
    all_intersection_count: 14,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "hungarian",
      "romanian",
      "languages",
      "chinese",
      "Spanish",
      "japanese",
      "korean",
      "afraid",
      "portuguese",
      "anything",
      "laryngitis",
    ],
    words: [
      "can",
      "you",
      "speak",
      "hungarian",
      "no",
      "I",
      "can't",
      "but",
      "romanian",
      "what",
      "languages",
      "English",
      "and",
      "chinese",
      "Spanish",
      "japanese",
      "korean",
      "I'm",
      "afraid",
      "portuguese",
      "anything",
      "today",
      "have",
      "laryngitis",
      "sorry",
    ],
    link: "(3)https://www.youtube.com/watch?v=RPDHqfX4PZQ&list=PL31D7CB49ABAAB4DB&index=20",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/speak.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=TnUpaM8NKRFO7%2FWKjySpg041bL6fWksiCvSuM0hq4j30N7xCabq08YhEtajzCra5S3R6AuODU56QZBKjR1WLo4dx9kkRMlmaXSto%2B8eepOgC%2FFphqB7Fgepo%2FRnGyEdiNN%2FfS%2FNfJJ%2FU3nH3rKn8ymhS0mQ0VFscVSgBkRDfSnXckWWcpssp9psrCOm40pE0%2BUoovKCfRHRNcdCoJa4H9Q47Djl0V7RXjynu4jE34ZB3omY7oXR4cRoZFZ9pkpEAAckgmzmQN%2Fc5Nbq65b9Ximasz%2B0O%2Fy3zd8N4XaxbRnEZmzMuAbyTobUbyCWs%2B%2FwxtB3ta6Vgr74%2Fjupj8oV5gw%3D%3D",
    id: "Can you speak Hungarian?\nNo, I can't. But I can speak Romanian.\n\nWhat languages can you speak?\nI can speak English and Chinese.\n\nI can't speak Chinese, but I can speak Spanish.\n\nI can't speak Spanish, but I can speak Japanese.\n\nJapanese? No. I can't speak Japanese, but I can speak Korean.\n\nNo. I'm afraid I can't speak Korean, but I can speak Portuguese.\n\nI can't speak anything today. I have laryngitis. Sorry.",
    story_id: 1000,
    word_count: 25,
    all_intersection_count: 14,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "behind",
      "pretty",
      "easy",
      "figure",
      "especially",
      "wave",
      "move",
      "over",
      "though",
      "front",
      "back",
    ],
    words: [
      "you",
      "can't",
      "see",
      "me",
      "right",
      "now",
      "because",
      "I",
      "am",
      "behind",
      "something",
      "it's",
      "probably",
      "pretty",
      "easy",
      "to",
      "figure",
      "out",
      "what",
      "especially",
      "if",
      "wave",
      "the",
      "tree",
      "move",
      "over",
      "here",
      "though",
      "in",
      "front",
      "of",
      "and",
      "is",
      "go",
      "back",
    ],
    link: "(12)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/12prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=xS8G%2F1W69r4%2F9kA11zJ%2BYNLj%2Ful3KLHhW0zdZE%2FEs2UYVfW2Tg4arK%2Bf2y7RCMlWpf7ivtfoq1nYF9rWjhrhpIdh1e28FTN3SMQWY8SgQmr20obcf7kdkDtUFDdodB1x%2FHZllfY02W0Yg9B6gGIrznKQ8AcEht4eIsG1X8hRF3q0UnVdS%2F4Pns3%2BwWu9n2o6CZ8ZWGr3t28YFj%2B4N4g6gnbD2B3WXZaib43Jals6A3zy6Cq0TTh0hiVfNS2BUyTPx30cs48JgPbNqDWaeFZcBuR4XZYTRxMx3NvX1Dud3DgoPClH%2F9%2FCxCIWlgZFaU9Vv1GYe4HXIvgRVBJ77q%2BJUQ%3D%3D",
    id: "You can't see me right now because I am behind something. It's probably pretty easy to figure out what I am behind, especially if I wave. Right now, I am behind the tree. If I move over here, though, I am now in front of the tree. I am in front of the tree and the tree is behind me. If I go back over here, though, now I am behind the tree and the tree is in front of me.",
    story_id: 1267,
    word_count: 35,
    all_intersection_count: 24,
    new_words_count: 11,
  },
  {
    row_new_words: ["tap", "lap", "Sam", "taps", "cap", "map", "pan", "hat", "Pam", "jan", "nap"],
    words: [
      "I",
      "like",
      "to",
      "tap",
      "on",
      "my",
      "lap",
      "Sam",
      "likes",
      "he",
      "taps",
      "his",
      "cap",
      "we",
      "the",
      "map",
      "pan",
      "hat",
      "Pam",
      "dad",
      "but",
      "is",
      "mad",
      "no",
      "jan",
      "nap",
    ],
    link: "(1)ZqtW8NefbxA",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "I like to tap. I tap on my lap. Sam likes to tap. He taps on his cap. We tap, tap, tap. We tap on the map. We tap on the pan. We tap on the hat. We tap on Pam. We tap on dad. But dad is mad. No! Jan! No! Sam. I like to nap.",
    story_id: 1750,
    word_count: 26,
    all_intersection_count: 15,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "put",
      "shirt",
      "buttons",
      "button",
      "up",
      "end",
      "take",
      "unbutton",
      "sweater",
      "zip",
      "unzip",
    ],
    words: [
      "when",
      "I",
      "put",
      "on",
      "this",
      "shirt",
      "because",
      "it",
      "has",
      "buttons",
      "need",
      "to",
      "button",
      "up",
      "the",
      "at",
      "end",
      "of",
      "day",
      "want",
      "take",
      "off",
      "unbutton",
      "sweater",
      "a",
      "zipper",
      "zip",
      "unzip",
    ],
    link: "https://www.youtube.com/shorts/A9VjViMlyh4",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "When I put on this shirt because it has buttons, I need to button up the shirt at the end of the day, when I want to take off the shirt, I need to unbutton the shirt when I put on this sweater because it has a zipper, I need to zip up the sweater at the end of the day, when I need to take off the sweater, I need to unzip the sweater.",
    story_id: 1092,
    word_count: 28,
    all_intersection_count: 17,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "waited",
      "until",
      "fall",
      "also",
      "autumn",
      "english",
      "lesson",
      "hiking",
      "best",
      "year",
      "opinion",
    ],
    words: [
      "so",
      "I",
      "waited",
      "until",
      "fall",
      "or",
      "what",
      "we",
      "also",
      "call",
      "autumn",
      "to",
      "make",
      "this",
      "english",
      "lesson",
      "about",
      "hiking",
      "because",
      "is",
      "the",
      "best",
      "time",
      "of",
      "year",
      "in",
      "my",
      "opinion",
      "go",
    ],
    link: "(5)https://www.youtube.com/watch?v=hrGRog5BTjc&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=32",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/5LetLearnEnglishonaHikeBob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=x%2B1MFRAXIrEAAAsHY6zPnECq0UcSy5ytv1epR%2Fdm%2BEXKDjJNpiq3ncMe%2B6oKopRQ0pzWMBgYCrpv9HFd3Foy55b9xZkLnXbpWVJiKemKkE7loLj4YzJe2ZR4%2FdT8a%2Bsw4t19vrEeXZppmLbP5GODFIXgs2z7mvD1ZYvCDMprMmyA%2FcypR%2FQDBHFPMk0R9BR7rJ8XIMIIGuB6TadKOGWoBW3CmFeoyFqi54ySmZtt7F0FjUyqstlqDGfrQNYY0CAti%2BXgz2rI7asYkPwfgCv2Emx6lFDToQ4tOJUeJ1513thujENSCRSHV9HsspDPK4ATnnlX9mRQQz%2BdrQNqLTRQ9Q%3D%3D",
    id: "So I waited until fall, or what we also call autumn, to make this english lesson about hiking. I waited because this is the best time of year, in my opinion, to go hiking.",
    story_id: 1446,
    word_count: 29,
    all_intersection_count: 18,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "interestingly",
      "enough",
      "both",
      "sentences",
      "correct",
      "could",
      "next",
      "wednesday",
      "situations",
      "whether",
      "either",
    ],
    words: [
      "so",
      "interestingly",
      "enough",
      "I",
      "can",
      "say",
      "have",
      "a",
      "day",
      "off",
      "or",
      "the",
      "both",
      "sentences",
      "are",
      "correct",
      "use",
      "when",
      "I'm",
      "talking",
      "about",
      "could",
      "something",
      "like",
      "this",
      "next",
      "week",
      "wednesday",
      "you're",
      "you",
      "is",
      "one",
      "of",
      "situations",
      "where",
      "don't",
      "to",
      "worry",
      "whether",
      "either",
      "and",
      "it",
      "means",
      "same",
      "thing",
    ],
    link: "(9)https://www.youtube.com/watch?v=yW-YeEc_ULg&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=21",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/9dayoffbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jYBCLuSb5otzb3l1%2F4PDRXWv5ltNZaZatlH5S57Z3YB7MEq7zFYJlg1RFJrokoilIEADnLJHDAKCpJZFbQxl0Oc5KcJWeQzrzKOnUQMtvKzIgE7yySCGI3jsLxOPzKQhTL4RLY%2Fy16nZWAuOv1Yt3THDJ%2F8DZihk3zAF34b5flbaVoR6DYj3DQXV8UEsJ09uQuK%2BnTVGN4x24HxJaHheoUGNe7sbtptTo%2FbchVtps0ybZ8A4z8wSj97Sj%2BCdLtzAbEvAd54qaj3y9tl6YlQp2ARtu0KQOOUJTAjL1d09o0ZH3q3Axj696t%2FSZDEiT%2FlbFsCHYrOt4pu6kwocnJ26ww%3D%3D",
    id: "So, interestingly enough, I can say I have a day off or I can say I have the day off. Both sentences are correct. I can use a or the when I'm talking about a day off or the day off. So I could say something like this. Next week, Wednesday, I have a day off. Next week, Wednesday, I have the day off. So when you're talking about a day off, you can use the. This is one of the situations where you don't have to worry about whether you use a or the. Either you have a day off or you have the day off, and it means the same thing.",
    story_id: 1399,
    word_count: 45,
    all_intersection_count: 34,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "-",
      "father",
      "Paul",
      "paris",
      "standing",
      "front",
      "eiffel",
      "tower",
      "really",
      "photograph",
      "favorite",
    ],
    words: [
      "-",
      "who",
      "is",
      "he",
      "he's",
      "my",
      "father",
      "what's",
      "his",
      "name",
      "Paul",
      "where",
      "in",
      "paris",
      "doing",
      "standing",
      "front",
      "of",
      "the",
      "eiffel",
      "tower",
      "this",
      "a",
      "really",
      "nice",
      "photograph",
      "thank",
      "you",
      "it's",
      "favorite",
    ],
    link: "https://www.youtube.com/watch?v=KkV9MBLOomI&list=PL31D7CB49ABAAB4DB&index=8",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/photos.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=F7I1rptA9%2BBb707b%2B%2BhGsMUP6MkOvtr6vXknbHjN5oZyUm53DwXsNlcCxEEjzbCcmEAlv2AdFvQkvs6QeOpmGYkfsxXUL4w%2BFIqaClAmxrrWdCZX1uKG4ZtgHTCjV0OCbsj6%2FvNUYRXWaGE8Kdg%2BLjY5nLPn9sxOeM7%2FZ1XgFykbYGVtK%2BMhPlpDC4evzCEpo2YGrIquSsFlj87habe3bOOa1dFX00kqFezwSpvPzk4yH%2BalD3GUEzLRUg%2BrzlBb%2BNd0fOUTwPm%2BemXLxVfswWDBafnyDKvl05IEdCtmdQy6LQz8C2TmJBsNhwamNGS9ha6IIDeKTecmIIOO3zNsvA%3D%3D",
    id: "- Who is he?\n- He's my father.\n- What's his name?\n- His name is Paul.\n- Where is he?\n- He's in Paris.\n- What's he doing?\n- He's standing in front of the Eiffel Tower.\n- This is a really nice photograph.\n- Thank you.\n- It's my favorite photograph of my father.",
    story_id: 974,
    word_count: 30,
    all_intersection_count: 19,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "Ms",
      "taylor",
      "would",
      "I'd",
      "donald's",
      "course",
      "schedule",
      "next",
      "year",
      "latin",
      "useful",
    ],
    words: [
      "so",
      "Ms",
      "taylor",
      "what",
      "would",
      "you",
      "like",
      "to",
      "talk",
      "about",
      "today",
      "I'd",
      "donald's",
      "course",
      "schedule",
      "for",
      "next",
      "year",
      "alright",
      "should",
      "he",
      "study",
      "English",
      "or",
      "latin",
      "I",
      "think",
      "why",
      "do",
      "say",
      "that",
      "is",
      "more",
      "useful",
      "than",
    ],
    link: "(2)https://www.youtube.com/watch?v=1dW7V33qLwA&list=PLD7AA7B1BC72ACC28&index=10",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/should2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=dtQ3bbag%2FLpts1lCD3DhT1Y1cO6lHvQxfy6fHqCXXYMLEyiFzxcDHK335uIkzftSd5fM5gIL3sK%2BXQrSwyLYqwBqYy4LglH79MlxtG3yxz2b%2BXK0nVU2Vv8fEADfIa91hdr4OpFz21%2BVblJCd%2BngLVNrvbYULNYwBe91aZJ8iiK%2Bj1YflGIDU%2BxpVQpTS7hf3LTjjMnoCX1ezaapoVD4WZYbA11mcnENN%2Bv5RXEMDR%2F3dNLer8cJJ3lcrGM6%2Bxm%2FsoyVsfV%2B2a12Hy6saqMvcqQK9LaistVOdwNsljtSh4Sh4%2FwTaijMrrKRAN85PHHdSCF2u8Hj1GUsrptZGU0KQw%3D%3D",
    id: "So, Ms. Taylor, what would you like to talk about today?\nI'd like to talk about Donald's course schedule for next year.\nAlright.\nShould he study English or Latin?\nI think he should study English.\nWhy do you say that?\nEnglish is more useful than Latin.",
    story_id: 1041,
    word_count: 35,
    all_intersection_count: 24,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "there's",
      "bear",
      "patch",
      "lawn",
      "seed",
      "sprinkle",
      "spread",
      "little",
      "bit",
      "hope",
      "grows",
    ],
    words: [
      "so",
      "as",
      "you",
      "can",
      "see",
      "there's",
      "a",
      "bear",
      "patch",
      "in",
      "my",
      "lawn",
      "right",
      "here",
      "I",
      "have",
      "some",
      "grass",
      "seed",
      "I'm",
      "going",
      "to",
      "sprinkle",
      "spread",
      "is",
      "more",
      "like",
      "this",
      "where",
      "do",
      "little",
      "bit",
      "but",
      "and",
      "hope",
      "that",
      "it",
      "grows",
    ],
    link: "(2)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/2learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=byU4cP1tVfZqA04a6KuXD1LUvY5OlIIuIdtahHn4viu8o7hUkxcXubaGVYbWqCRLMXeDwS1KP5SaSe4xC4HWTotAT5s%2FTbccGD3ehcgr9KSmZAQ6BnxMH75D4eXXOW45u8R3gTIx%2FcqJ1IEUhtxlpnpwKimRC3oiZstofDiFRVBUDv%2FG1bc7ZPds69Dw7coiKfzWG3CKdGv0Fc8uhCePK616uTyolvJVbBwB7xeriwCU%2BGXZZtH84Hi9a1o1LXpO9MLsUXFkiwo3ibvk2tuDb0qzZDd36SnvOGQdLSGMvjFXg53D1V756qBTj8MzuCGBXWGV37FYMMsgx1xhRiU7Lw%3D%3D",
    id: "So as you can see, there's a bear patch in my lawn right here. So I have some grass seed. I'm going to sprinkle some grass seed here. I'm going to spread some grass seed. Sprinkle is more like this, where you do a little bit spread is more like this, where you do a bit more. But I'm going to sprinkle some grass seed here. I'm going to spread some grass seed here and hope that it grows.",
    story_id: 1327,
    word_count: 38,
    all_intersection_count: 27,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "May",
      "looking",
      "jacket",
      "here's",
      "purple",
      "jackets",
      "popular",
      "year",
      "pair",
      "gloves",
      "these",
      "green",
    ],
    words: [
      "May",
      "I",
      "help",
      "you",
      "yes",
      "please",
      "I'm",
      "looking",
      "for",
      "a",
      "jacket",
      "here's",
      "nice",
      "but",
      "this",
      "is",
      "purple",
      "that's",
      "okay",
      "jackets",
      "are",
      "very",
      "popular",
      "year",
      "can",
      "pair",
      "of",
      "gloves",
      "these",
      "green",
    ],
    link: "(6)https://www.youtube.com/watch?v=HVJNOeDvpME&list=PL31D7CB49ABAAB4DB&index=11",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/clothing6.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=P763AEOSvd7OaAfR63RreXV2JPNZ09Nl9tONRYesLdgznsjhE2xbjp00LUUVguOpFC7MY%2FZUJAF2Vh1CcA7sAG86G97CmkPCcKosQ2aWo%2FMgiROqZYGCR%2Bn35nJz%2BpTv49iID5h9a18xIb%2FxAof02Mw2ZB%2FK021xtVz1wpq2p6idzTppPaq%2FvBsM2hcc79cHcw3ys8sUimbEf17BTU2Q09PPM8tRxDdWavXIfztqgmBG9TYugnhuSnRQ2UU%2FJ%2BTB6qLLrSfK3RQFTASG2qkcBAuU8rvIqN2wM0AIgnTm%2FeKaV4SMKJBwXGAJOAm%2Bi6UlQBAAxTVmOLzKz%2Bcpbgn1FA%3D%3D",
    id: "May I help you?\nYes, please. I'm looking for a jacket.\nHere's a nice jacket.\nBut this is a purple jacket.\nThat's okay, purple jackets are very popular this year.\n\nCan I help you?\nYes, please. I'm looking for a pair of gloves.\nHere's a nice pair of gloves.\nBut these are green gloves.\nThat's okay. Green gloves are very popular this year.",
    story_id: 984,
    word_count: 30,
    all_intersection_count: 18,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "there's",
      "wrong",
      "machine",
      "anything",
      "machines",
      "anybody",
      "really",
      "sure",
      "you'll",
      "find",
      "somebody",
      "fix",
    ],
    words: [
      "there's",
      "something",
      "wrong",
      "with",
      "my",
      "washing",
      "machine",
      "I'm",
      "sorry",
      "I",
      "can't",
      "help",
      "you",
      "don't",
      "know",
      "anything",
      "about",
      "machines",
      "do",
      "anybody",
      "who",
      "can",
      "me",
      "not",
      "really",
      "should",
      "look",
      "in",
      "the",
      "phone",
      "book",
      "sure",
      "you'll",
      "find",
      "somebody",
      "fix",
      "it",
    ],
    link: "https://www.youtube.com/watch?v=kgWaWG1d06o&list=PLD7AA7B1BC72ACC28&index=25",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/something.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=b7sYvxekJn5%2F2Ky7uIgwElEEuP%2BmPV0PEB4YedzvNBGnux5%2BNH9i%2B8MEkpBdA%2F8JLcT33aOjMpY2ny9V6QHz%2FBiLVhcEjNygrcoOkC8yHLl2GVwDSJEU2VgG3noMtEnXN08PgjrKAsOz%2FNyo8Y2lc8M2e%2BPUGMNjqL0iytFUAZXKXjXYZ0tX1E8Qv9lScGvQ1W4xkHqQybVduCbpN4hruP0cjWPLpfwN%2FsoeC5tn94jhtiHIsSKiiw5Kw6LrU0e6Em7grN%2FM9pDq0tsEl%2B9fX0lMksd4TGY4fwFPv0EPfDHG01AgUhzjLaFKZxZcN7lR8yW3BBmnGY%2Fd3nSbxmicyg%3D%3D",
    id: "There's something wrong with my washing machine.\nI'm sorry. I can't help you. I don't know anything about washing machines.\nDo you know anybody who can help me?\nNot really. You should look in the phone book. I'm sure you'll find somebody who can fix it.",
    story_id: 1072,
    word_count: 37,
    all_intersection_count: 25,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "jen",
      "will",
      "ask",
      "wheel",
      "cart",
      "field",
      "these",
      "push",
      "wheels",
      "verb",
      "also",
      "drive",
    ],
    words: [
      "so",
      "sometimes",
      "jen",
      "will",
      "ask",
      "me",
      "to",
      "wheel",
      "a",
      "cart",
      "like",
      "this",
      "out",
      "the",
      "field",
      "she",
      "can",
      "plant",
      "these",
      "plants",
      "in",
      "English",
      "when",
      "you",
      "push",
      "something",
      "with",
      "wheels",
      "use",
      "verb",
      "talk",
      "about",
      "it",
      "also",
      "drive",
      "I",
      "for",
      "or",
    ],
    link: "(1)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=X4%2BTeSd6mTitRzbWbfoCNF0O7hlK%2BCB57B%2FkGexEKO0SThvI2fx1OeMSHcZLWORnhZF88Z%2Bsy2k6NtVvTBlYeok%2FLDfW74z0uc9YzXy66vJSN5Uz%2FWC1VOZPHykvomgOwccP4dHnRXbfiSDibKtRVnLTA7Y89fBdv%2FagH%2BAo3w78d43K3vKSyXEr3m3SCjWOjFGY5jInz0UVh%2BpLk1tBI4HuSvG%2Bwhma2tHMxgXGyEpuawKMUcsecdnI3NNojtfNS0SzzUslFEzivrOEUlEM7P82azNr1A3nC1TGgRnzQ0wNKxs2CFEV85oxTPtc%2BIRXNmNC1EPhL6Rb6uzdYoEVlQ%3D%3D",
    id: "So sometimes Jen will ask me to wheel a cart like this out to the field so she can plant these plants. In English, when you push something like this cart with wheels, you can use the verb to wheel to talk about it. You can also use the verb to drive. So I can wheel this cart out to the field for Jen, or I can drive it out to the field.",
    story_id: 1326,
    word_count: 38,
    all_intersection_count: 26,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "though",
      "needed",
      "level",
      "stone",
      "would",
      "rake",
      "garden",
      "little",
      "durable",
      "fan",
      "tougher",
      "pile",
    ],
    words: [
      "let's",
      "say",
      "though",
      "I",
      "needed",
      "to",
      "level",
      "out",
      "this",
      "stone",
      "would",
      "use",
      "a",
      "rake",
      "we",
      "sometimes",
      "call",
      "garden",
      "it's",
      "little",
      "more",
      "durable",
      "than",
      "fan",
      "tougher",
      "so",
      "if",
      "pile",
      "of",
      "it",
      "nice",
      "and",
    ],
    link: "(4)https://www.youtube.com/watch?v=Ug8YJCycq7g&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=35",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4LetLearnEnglishintheGardenBob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=MoAPTZbIg4ZJYtvJIsDkoDjkKmclxlOQd2rxXSRAphwkMxGs0je%2BnZDYmebHAxnC2eAThF2f56ZCY8MG7Cgowpj2F1vuFRyBbszlJne%2BCNuZ4HGkYSB%2BqFjeSVkUboxm4ZAkiKVt5jpCanBD4MHO%2F4zTDgQxcHbh4H9bztJeVzC9HhZNQIL%2BkBQj4ku%2Bi%2B9fchn14JspS0I3btOCfXOKHSagz4mVEFpz9EMYzM1yJ%2Fu5oj2kod9y%2BadAnml9T6rrM%2B48WK9fRIxR85%2BVUtb7IhyJMJh4k%2B6fjMigRvd7gHFjTdnovQ5%2Bfi%2BpH84fr%2FNJnzy9wjuLuz3cbAqCMCqcgQ%3D%3D",
    id: "Let's say, though I needed to level out this stone, I would use a rake. We sometimes call this a garden rake. It's a little more durable than a fan rake. It's a little tougher. So if I needed to level out this pile of stone, I would use this garden rake to rake it nice and level.",
    story_id: 1459,
    word_count: 32,
    all_intersection_count: 20,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "germs",
      "after",
      "toilet",
      "wet",
      "soap",
      "carefully",
      "count",
      "20",
      "sure",
      "turn",
      "tap",
      "dry",
    ],
    words: [
      "say",
      "no",
      "to",
      "germs",
      "wash",
      "your",
      "hands",
      "after",
      "you",
      "go",
      "the",
      "toilet",
      "wet",
      "with",
      "water",
      "use",
      "soap",
      "carefully",
      "count",
      "20",
      "as",
      "make",
      "sure",
      "turn",
      "tap",
      "off",
      "dry",
      "well",
    ],
    link: "https://learnenglishkids.britishcouncil.org/read-write/reading-practice/level-1-reading/bathroom-sign",
    type: "story",
    pdf_name: null,
    storage_link: null,
    id: "Say NO! To germs. Wash your hands after you go to the toilet!\n\nWet your hands with water.\nUse soap.\nWash your hands carefully.\nCount to 20 as you wash.\nMake sure you turn the tap off!\nDry your hands well.",
    story_id: 880,
    word_count: 28,
    all_intersection_count: 16,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "you'll",
      "rocking",
      "chair",
      "liked",
      "old",
      "was",
      "comfortable",
      "really",
      "try",
      "absolutely",
      "computer",
      "powerful",
    ],
    words: [
      "I",
      "think",
      "you'll",
      "like",
      "my",
      "new",
      "rocking",
      "chair",
      "but",
      "liked",
      "your",
      "old",
      "it",
      "was",
      "comfortable",
      "that's",
      "right",
      "is",
      "more",
      "really",
      "here",
      "try",
      "you're",
      "mom",
      "this",
      "absolutely",
      "computer",
      "powerful",
    ],
    link: "(4)https://www.youtube.com/watch?v=GSx9o9uZosQ&list=PLD7AA7B1BC72ACC28&index=9",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/was2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ao4rn3suZQLTSsEBPCVQpwbQoPZI5HwtSe2U3j%2FjqRssiy2Cc3b26EJF57yXu9HW7ZYqGdIL%2B%2FZQ4d%2BBRx%2Bxr0HkoL1B926%2FvPyCaejFWj0NhXbz%2B%2F%2BGP6WHrNU1osltgFXPXmhWsfeY7FzNexbFYExdcKY71hYQRpxAa48bdf6zKvGO2nrGyAJ%2FAWrQ4SDJdHf%2FgDsJEPqLdsW2XTd00D6jC7tXJCyI84lYEBS79VDISdP1C41uLXUkIj4rj56YCIeXCCn0fhcXMzJxMG2Y0OqZP04bTIn4EMOokiQ%2F8GXbwhwbz9ZQ57yklWIt%2FRGxIN9Ixo6Jb%2FIQFZz7MslPjA%3D%3D",
    id: "I think you'll like my new rocking chair.\nBut I liked your old rocking chair. It was comfortable.\nThat's right. But my new rocking chair is more comfortable.\nReally?\nHere, try it.\nYou're right, mom. This new rocking chair is more comfortable. Absolutely.\n\nI think you'll like my new computer.\nBut I liked your old computer. It was powerful.\nThat's right. But my new computer is more powerful.",
    story_id: 1048,
    word_count: 28,
    all_intersection_count: 16,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "much",
      "milk",
      "little",
      "thanks",
      "many",
      "cookies",
      "few",
      "would",
      "nothat's",
      "fine",
      "take",
      "sugar",
    ],
    words: [
      "how",
      "much",
      "milk",
      "do",
      "you",
      "want",
      "not",
      "too",
      "just",
      "a",
      "little",
      "okay",
      "here",
      "are",
      "thanks",
      "many",
      "cookies",
      "few",
      "would",
      "like",
      "some",
      "coffee",
      "yes",
      "please",
      "is",
      "that",
      "nothat's",
      "fine",
      "take",
      "in",
      "your",
      "but",
      "that's",
      "sugar",
      "so",
      "what's",
      "new",
      "with",
      "about",
      "oh",
    ],
    link: "https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/muchmany.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=vVz%2FIuuw4Dk2D%2FWmGI%2Fs3k1lfcDsF24y5BIEQtJCQ5pocOXp9eJdKSo%2B2e9ZhArQwdVY%2B8SEPySSFqgsFnDN%2Br0lXr2P3%2FpSq0BPDhwxfgRRSL1y9h4KSqZxrmRPcecc9bi%2F60H7ghBzTCPuJGglqAPX2g%2Fo24iPmpbke8cH%2BjvSdb9OaKlze2C2i%2FfRusObixPpfxTeiAQ8gzIOEByjFj%2FSs%2Bdaab%2B74Hq%2BhcCVyI2VTQ5MTMUT64rPon97uD4QLUhxeZ8svU6SpxqwlBnsH4mZcZPgeLXnsLm0vPqlaXC8jriTgAFoW5%2FRTUGwNxfk7U8KkHeRCA0PKZoQSbsdzg%3D%3D",
    id: "How much milk do you want?\nNot too much, just a little.\nOkay. Here you are.\nThanks.\n\nHow many cookies do you want?\nNot too many, just a few.\nOkay. Here you are.\nThanks.\n\nWould you like some coffee?\nYes, please. Just a little.\nIs that too much?\nNo.That's fine. Thanks.\nDo you take milk in your coffee?\nYes, please. But not too much.\nThat's fine.\nSugar?\nJust a little, please.\nSo what's new with you?\nNot too much. How about you?\nOh, not too much.",
    story_id: 1016,
    word_count: 40,
    all_intersection_count: 28,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "end",
      "phrase",
      "secret",
      "said",
      "hey",
      "bob",
      "keep",
      "would",
      "reply",
      "won't",
      "anyone",
      "else",
      "promise",
    ],
    words: [
      "so",
      "I'm",
      "going",
      "to",
      "end",
      "off",
      "with",
      "this",
      "phrase",
      "today",
      "and",
      "it's",
      "the",
      "your",
      "secret",
      "is",
      "safe",
      "me",
      "if",
      "you",
      "said",
      "hey",
      "bob",
      "can",
      "keep",
      "a",
      "I",
      "would",
      "probably",
      "reply",
      "say",
      "yes",
      "that",
      "it",
      "means",
      "tell",
      "won't",
      "anyone",
      "else",
      "promise",
    ],
    link: "(9)https://www.youtube.com/watch?v=KjynWAC2bLk&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=20",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/9keepsecretbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=odTSBxeaW9SriQ0A2L7yxxdFoqUiLU5Ph3jyNMTFstH2RbyZqC53OQEtQX5ITJ9YxWayJC9eGFLOJmueSnGXSSL0IvoEDH3qDCFu9PHp%2FHDR5jGBbm3%2BDKYLAqitpoubMVgbyRrIfpIVwPT6UGJIjSk4TFsWsLzWYNm5WQwoEuuF9jUJB%2FKmoeaxnVt5xAscJqrW2PcObMb7tIArl%2BCg2MCGhVQZ1%2Fhz0unuaCevtgneDWtnxN2IWB2Samron6gGEGKKnEw5vQtaqtskX9J3ewTxVXpP%2BEwUVWUnD3AYL%2F1NOKeOT4%2FM0pTlwzckdMSCrD%2B0xjOOYx4Myyqk25ym8A%3D%3D",
    id: "So I'm going to end off with this phrase today. And it's the phrase, your secret is safe with me. If you said to me, hey, Bob, can you keep a secret? I would probably reply and say, yes, your secret is safe with me. If I say that to you, it means you can tell me your secret and I won't tell anyone else, I promise.",
    story_id: 1390,
    word_count: 40,
    all_intersection_count: 27,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "stanley",
      "cook",
      "japanese",
      "food",
      "Sunday",
      "cooks",
      "Friday",
      "excuse",
      "ma'am",
      "question",
      "chinese",
      "monday",
      "Wednesday",
    ],
    words: [
      "does",
      "stanley",
      "cook",
      "japanese",
      "food",
      "on",
      "Sunday",
      "no",
      "he",
      "doesn't",
      "when",
      "cooks",
      "Friday",
      "excuse",
      "me",
      "yes",
      "ma'am",
      "I",
      "have",
      "a",
      "question",
      "chinese",
      "monday",
      "Wednesday",
      "see",
      "thank",
      "you",
    ],
    link: "(3)https://www.youtube.com/watch?v=X9QluYxyV00",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Simple%203.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Lg38UZccajDvbAhQP4Md2Ex4VraeTUvtyH%2BEcVZKsXSFctLqnbCEAj0oH4iLQXTJreZ5PeTS4E9ul7y218iAOGZbOatOyTK0QX9xWMM6Xa9kbCvypWsTVAIwvGvWuocBw%2FuthGL%2BmSPVSl1tC0pYX7K%2FENRIXsyDV5RRqHyUWeHJh9Hj3nvH2y5eZgh%2Bt0qIh1EnLEOLGFkj8naHIP3CIA9N9okwp3pOdmvnoHEqfNXuna6AXHKu8aOg6w5HO4kMDq89NKjClPFaLlF4JnjwHUWVW8Tg7F58AfFjyAgp6bHjr6oUSjGyTOqcVgey1pq9vI35XHH7h02FNSjuOwGP9A%3D%3D",
    id: "Does Stanley cook Japanese food on Sunday?\nNo, he doesn't.\nWhen does he cook Japanese food?\nHe cooks Japanese food on Friday.\n\nExcuse me?\nYes, ma'am?\nI have a question. Does Stanley cook Chinese food on Monday?\nNo, he doesn't. When does he cook Chinese food?\nHe cooks Chinese food on Wednesday.\nI see. Thank you.",
    story_id: 962,
    word_count: 27,
    all_intersection_count: 14,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "come",
      "close",
      "draw",
      "these",
      "phrases",
      "describe",
      "longer",
      "happening",
      "summer",
      "definitely",
      "coming",
      "drawing",
      "I'll",
    ],
    words: [
      "to",
      "come",
      "a",
      "close",
      "and",
      "draw",
      "these",
      "are",
      "phrases",
      "we",
      "use",
      "describe",
      "things",
      "that",
      "no",
      "longer",
      "happening",
      "so",
      "summer",
      "is",
      "definitely",
      "coming",
      "it's",
      "drawing",
      "tomorrow",
      "I'll",
      "need",
      "start",
      "teaching",
    ],
    link: "(10)https://www.youtube.com/watch?v=Xz6EEVVz1IA&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=3",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/tocometoclosetodrawtoclose.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=OTVfzEu1mQXdK%2FCbQv%2Bhz0nLQE4rP2MvPyK%2F72u6ORKmRS67khOHAG6gCt4Z4Mp0bqRSoH9WADHX1XE1U2QgYgcSSVpgX798L6KpJmo8tCrvSJIEjCNXMgnJlRJQmAwXoh4zNi53Avrm4ANHpaNuUNuqypfkewCR6hoJq8%2FPUQkIPan8UUopqus5we9dyONf4vk02ghhNxWJm3I5ddNdK8COu5xpTNJbRW6oG8JHSu7fa59R3aSjRCzrX1Qu2A2QS8HG3lFK32WdIYvGf9sPWBqq3mOaa4PyeT3jjksN1YLAZJ1HyV9HMlENUMTtThOsX1ql5ntfNHp%2B0jbG6R%2BeEA%3D%3D",
    id: "To come to a close and to draw to a close. These are phrases we use to describe things that are no longer happening. So summer is definitely coming to a close. It's drawing to a close. And tomorrow I'll need to start teaching.",
    story_id: 1145,
    word_count: 29,
    all_intersection_count: 16,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "away",
      "jungle",
      "mighty",
      "sleeps",
      "tonight",
      "quiet",
      "wee",
      "near",
      "village",
      "peaceful",
      "hush",
      "darling",
      "fear",
    ],
    words: [
      "away",
      "in",
      "the",
      "jungle",
      "mighty",
      "lion",
      "sleeps",
      "tonight",
      "quiet",
      "wee",
      "near",
      "village",
      "peaceful",
      "hush",
      "my",
      "darling",
      "don't",
      "fear",
    ],
    link: "cTxddPFnPlI,OQlByoPdG6c,HOIzRdDo_3I,N46J3ahqZOY",
    type: "am_song",
    pdf_name: null,
    storage_link: null,
    id: "Weeheeheehee dee heeheeheehee weeoh aweem away\nWeeheeheehee dee heeheeheehee weeoh aweem away\nIn the jungle, the mighty jungle\nThe lion sleeps tonight\nIn the jungle the quiet jungle\nThe lion sleeps tonight\nWee heeheehee weeoh aweem away\nWee heeheehee weeoh aweem away\nNear the village, the peaceful village\nThe lion sleeps tonight\nNear the village, the quiet village\nThe lion sleeps tonight\nWee heeheeheehee weeoh aweem away\nRrr, la la la weeoh aweem away\nHush, my darling, don't fear, my darling,\nThe lion sleeps tonight\nHush, my darling, don't fear, my darling,\nThe lion sleeps tonight\nWah oh oh, wah oh oh, wah oh wimoweh\nWeeheeheehee dee heeheeheehee weeoh aweem away\nWeeheeheehee dee heeheeheehee weeoh aweem away",
    story_id: 1807,
    word_count: 18,
    all_intersection_count: 5,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "different",
      "blue",
      "eyes",
      "brown",
      "hair",
      "both",
      "musical",
      "instruments",
      "black",
      "white",
      "lot",
      "friends",
      "sisters",
    ],
    words: [
      "my",
      "sister",
      "and",
      "I",
      "look",
      "very",
      "different",
      "have",
      "blue",
      "eyes",
      "she",
      "has",
      "brown",
      "short",
      "hair",
      "long",
      "I'm",
      "tall",
      "as",
      "you",
      "can",
      "see",
      "don't",
      "like",
      "we",
      "a",
      "house",
      "an",
      "apartment",
      "cat",
      "dog",
      "both",
      "musical",
      "instruments",
      "piano",
      "guitar",
      "car",
      "bicycle",
      "black",
      "white",
      "TV",
      "color",
      "lot",
      "of",
      "friends",
      "just",
      "one",
      "or",
      "two",
      "we're",
      "but",
      "sisters",
    ],
    link: "(2)https://www.youtube.com/watch?v=fvRabsiahk4&list=PL31D7CB49ABAAB4DB&index=17",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/describing.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=AgWPa8eKhlbq2OGKTIxNbI%2BCHG1iXow9WyEjrZ5uXVQm3oGEgI6dXI1%2FW3IQR8UpMMHcs5kOuBNSrdTMkmXuXW97Zgzm%2B14wXUF4wuIlW5cIuiOdwwWdrhgonqlaiVWy3MuoAgPGQNL2TbPjvjbwzozZkvcZXnsqfHM4dIbQGdD4UWgsag8LRr0euv17AJ9kH5frqqKeSRihLs7jOZE%2BO4mqrsgUlUAU3QWHkCJkAC0uDr5q%2BUufv6yPFC0EhBMcAsnqKuJf%2F%2Fd9MJe4Tb9wKDJv7AptyFSqJtoUnVg2jEA7zrKuJN7gdV2K7CnaLxZe6CVHE7%2BGjgO1vI%2FHCzAusA%3D%3D",
    id: "My sister and I look very different.\nI have blue eyes and she has brown eyes.\nI have short hair and she has long hair.\nI'm tall and I'm short.\nAs you can see, I don't look like my sister.\nWe look very different.\nI have a house and she has an apartment.\nShe has a cat and I have a dog.\nWe both have musical instruments.\nI have a piano and she has a guitar.\nShe has a car and I have a bicycle.\nI have a black and white TV and she has a color TV.\nShe has a lot of friends and I have just one or two.\nAs you can see, we're very different.\nBut we're sisters, and we're friends.",
    story_id: 989,
    word_count: 52,
    all_intersection_count: 39,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "reach",
      "branch",
      "set",
      "up",
      "step",
      "ladder",
      "then",
      "done",
      "though",
      "take",
      "down",
      "after",
      "using",
    ],
    words: [
      "so",
      "I",
      "can't",
      "reach",
      "this",
      "branch",
      "here",
      "but",
      "if",
      "set",
      "up",
      "my",
      "step",
      "ladder",
      "then",
      "can",
      "when",
      "I'm",
      "done",
      "though",
      "should",
      "take",
      "down",
      "after",
      "use",
      "it",
      "using",
    ],
    link: "(14)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/14learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=zYIZdVWehOLe8BnsmNF91VeAH7YJwxosGS4DppFqScavNS9%2Bkcj2fmflpVILwLgg9mV4fbIShhBUtmwqA%2Fc%2F33Ys2r0yFQQxo20Iwuyc8lKfeFf11StyQLtZo0%2ByGjdOiPp2%2BEUn84m6NxpBTDvpQdbQSVf7ZUm%2B3u2FTh324zTOt3mCrAv2XltaU9bKo6QPd0f%2FzCairsmRqYasfdH9p1sDUAtRTfOLVGDdJm9nWFeNc26GLCRXgDyk5bArVVvEKXEhSCA7nH%2BYUFF6m5xwEfAn4UWmkUQADb1RYhf2reOy98Djvep61RmgNXI4DyMp9mQwh5S0bnJZ8IfeUE4URA%3D%3D",
    id: "So I can't reach this branch here. But if I set up my step ladder, then I can reach this branch. So I can set up my step ladder so I can reach this branch when I'm done. Though I should take down my step ladder after I use my step ladder, I take it down when I'm done using it.",
    story_id: 1339,
    word_count: 27,
    all_intersection_count: 14,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "names",
      "Alice",
      "Fred",
      "gonna",
      "yard",
      "fix",
      "weather",
      "thanks",
      "clean",
      "basement",
      "Harry",
      "attic",
      "bye",
    ],
    words: [
      "what",
      "are",
      "your",
      "names",
      "I'm",
      "Alice",
      "and",
      "he's",
      "Fred",
      "tell",
      "me",
      "you",
      "gonna",
      "do",
      "tomorrow",
      "going",
      "to",
      "work",
      "in",
      "the",
      "yard",
      "what's",
      "fix",
      "his",
      "car",
      "yes",
      "my",
      "she's",
      "weather",
      "be",
      "like",
      "it's",
      "beautiful",
      "well",
      "have",
      "a",
      "nice",
      "day",
      "thanks",
      "too",
      "hello",
      "hi",
      "we're",
      "clean",
      "our",
      "house",
      "I",
      "see",
      "living",
      "room",
      "basement",
      "no",
      "Harry",
      "you're",
      "how",
      "about",
      "children",
      "they",
      "help",
      "oh",
      "they're",
      "attic",
      "happy",
      "cleaning",
      "bye",
      "who",
      "is",
      "that",
      "don't",
      "know",
    ],
    link: "https://www.youtube.com/watch?v=K03PIjwN8ls&list=PL31D7CB49ABAAB4DB&index=22",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/going.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=W7lB3Op9OKqh2DExVpFV3WJuLk6L9h3sYelrka0g6x7xbmVBaQ0Rx9AqsrXB7qjxCLORtCFZkSBNom%2FpB7wNF35Z3mCKnrHd6fioEBDLwJIrQL94EEtR6bnCVoVBwrXt3x0PbUjiR%2FMskEqHfHw8NL3kEpKsAvdyq2dm9L1Trhebv1jpmbrZY6h2xC%2F7yZR4BnnUpYFeNiNgoHpBQXfiS5tSjQZHqS4q2XKvbBp%2FYoXEwPHzx9kJHCYdptU0xV8kb%2BpPAISqihe4AYpdKlGkRn6p%2FMuDEo5zJVIEP10CGeHYPw5lBppOkTmS%2BDtjXI1e80ECZ4AJ7yMRxNePyOTxEg%3D%3D",
    id: "What are your names?\nI'm Alice, and he's Fred.\nTell me, Alice. What are you gonna do tomorrow?\nI'm going to work in the yard.\nWhat's Fred gonna do tomorrow?\nHe's gonna fix his car.\nYes. I'm gonna fix my car, and she's gonna work in the yard.\nWhat's the weather gonna be like?\nIt's gonna be beautiful.\nWell, have a nice day.\nThanks.\nYou too.\n\nHello.\nHi.\nTell me, what are you gonna do tomorrow?\nWe're gonna clean our house.\nI see.\nYes. I'm gonna clean the living room and she's gonna clean the basement.\nNo, Harry. You're gonna clean the basement and I'm gonna clean the living room.\nHe's gonna clean the basement.\nHow about your children? Are they gonna help?\nOh, yes. They're gonna clean the attic.\nWell, happy cleaning.\nThanks.\nBye.\nWho is that?\nI don't know.",
    story_id: 1006,
    word_count: 70,
    all_intersection_count: 57,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "batman",
      "secret",
      "identity",
      "real",
      "bruce",
      "wayne",
      "knew",
      "really",
      "batman's",
      "superman",
      "way",
      "clark",
      "kent",
    ],
    words: [
      "speaking",
      "of",
      "batman",
      "has",
      "a",
      "secret",
      "identity",
      "his",
      "real",
      "name",
      "is",
      "bruce",
      "wayne",
      "I",
      "don't",
      "know",
      "if",
      "you",
      "knew",
      "that",
      "but",
      "when",
      "have",
      "it",
      "means",
      "no",
      "one",
      "knows",
      "who",
      "really",
      "batman's",
      "superman",
      "by",
      "the",
      "way",
      "as",
      "well",
      "clark",
      "kent",
    ],
    link: "(4)https://www.youtube.com/watch?v=KjynWAC2bLk&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=20",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4keepsecretbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=y4V23nBFkOWhApNphRhRFqf8a2rPXApB6sthLInfvQVbPtBq9XeCSjim1BRl1Al1aE9myK%2FBdiGEC5FcR0TbSNvNv5PxoDHfUxqLHugQ1sY%2FsaN25tqAz60o6LdYO8w3WIQa4tOMio2jl%2BqeymY5eP2mH4L54p0Zmc5hOs5uS96f10PKreLoevcp3x0LoFk7ta10QAjmlxHAyFxt3RDmKB6zeUkPXMii9As50m9R30qG1x1Obih%2BVzkpm1UVhgUxcl%2BhdCiT7uFSE47u9qM7XayTBFPToXEIhmJfE6IrnxtccJqH9rEIXZEXFnLbizI1%2Bo6RiPj0SSI30fNslmR%2FXQ%3D%3D",
    id: "Speaking of Batman, Batman has a secret identity. His real name is Bruce Wayne. I don't know if you knew that, but when you have a secret identity, it means that no one knows who Batman really is. Batman's secret identity is Bruce Wayne. Superman, by the way, has a secret identity as well. His secret identity is Clark Kent.",
    story_id: 1385,
    word_count: 39,
    all_intersection_count: 26,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "nervous",
      "bite",
      "nails",
      "perspire",
      "giggle",
      "guess",
      "angry",
      "face",
      "turns",
      "red",
      "whistle",
      "easy",
      "smile",
    ],
    words: [
      "what",
      "do",
      "you",
      "when",
      "you're",
      "nervous",
      "I",
      "bite",
      "my",
      "nails",
      "I'm",
      "let",
      "me",
      "see",
      "perspire",
      "giggle",
      "guess",
      "now",
      "angry",
      "shout",
      "face",
      "turns",
      "red",
      "don't",
      "know",
      "never",
      "get",
      "happy",
      "whistle",
      "that's",
      "easy",
      "smile",
      "sing",
      "like",
      "this",
    ],
    link: "(5)https://www.youtube.com/watch?v=vaq6pX7q15s&list=PL31D7CB49ABAAB4DB&index=18",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/nervous.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=vl%2FQpQV3BgiUEkLw%2FP%2FWZQ%2BkOMvNYsJ5wRqOrkGH7FfsQc46na8uebpjVWMRsYOR2XpIA4LOOtsAZE43eblThgs6UH8p0%2F9yh5puBsjLO%2BVLYc3aUhZMIo27nmTIIYcOPNK6YY61%2Bs1yiTuMZrkKx9t%2FxagXmvHnrhrK4sELX14G4spc8R9r%2FG%2FF9OcVnOLF%2FvoruuIMiiTlgrfPXUEkNFmE9UB%2F808Um6g0MbpBeqmFc9859Ds5EdTMX%2Bahn4Ylho2qp%2BpJU%2BYCPt%2BC75i7UZJdDstXJYNtCZYHJBaqsEKH3i8jcDKdPS8fosce092cWGIsGZqR0Z4rFXwe9GJYRA%3D%3D",
    id: "What do you do when you're nervous?\nI bite my nails.\n\nWhen I'm nervous, let me see. I perspire.\n\nI giggle. I guess I'm nervous now.\n\nWhat do you do when you're angry?\nI shout.\n\nWhen I'm angry, my face turns red.\n\nI don't know.\n\nI never get angry.\n\nWhat do you do when you're happy?\nI whistle.\n\nWhat do I do when I'm happy? That's easy. I smile.\n\nWhen I'm happy, I sing, like this...",
    story_id: 993,
    word_count: 35,
    all_intersection_count: 22,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "might",
      "enough",
      "situation",
      "loan",
      "bank",
      "even",
      "dealership",
      "will",
      "lend",
      "then",
      "pay",
      "back",
      "maybe",
      "brand-new",
    ],
    words: [
      "now",
      "when",
      "you",
      "go",
      "to",
      "buy",
      "a",
      "car",
      "might",
      "not",
      "have",
      "enough",
      "money",
      "in",
      "that",
      "situation",
      "what",
      "need",
      "do",
      "is",
      "get",
      "loan",
      "the",
      "bank",
      "or",
      "even",
      "dealership",
      "will",
      "lend",
      "so",
      "can",
      "and",
      "then",
      "pay",
      "them",
      "back",
      "maybe",
      "brand-new",
      "but",
      "don't",
      "you're",
      "going",
      "it",
      "from",
    ],
    link: "(3)https://www.youtube.com/watch?v=hDGK1BCeP74&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=6",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/3enoughmoneyforcarcarloan.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=pTSMX9CIT5NDeJAHB%2BUhVB7hob%2B9F5N3dXDivBvYTQGB5Z2N1q2nKg%2FSnQjbsMzYL5yRPpHzJOJDvAPT%2FTwmEZdeUFNOrpIeg0I%2B0pL6Tm2KIungTIxY4V7U64IPdqHJ3ps7FpRSBgXSHG8zO6rzUV%2BG%2Fynz0TAR1055VvBA9ubSDx4xJv0Nh%2B8ffAuWjJ91KQ9VV56L4260rBz8Fz7pf7PHTkmaRq9yYo33yQNbUGyzdOcq1PCGMTwU0JlBQfa7tZTuE2d2ScK0wwVmwVbfP%2FIgEpKc2NT5%2BjmXTV3WjujMrTDBdiw6%2B1uE3X1MkDDldKzDrOhNY32utS3No%2FySHA%3D%3D",
    id: "Now, when you go to buy a car, you might not have enough money. In that situation, what you might need to do is you might need to get a car loan. A car loan is when the bank or even the car dealership will lend you money so that you can buy the car, and then you need to pay them back. So maybe you have enough money. Maybe you have enough money to buy a brand-new car, but maybe you don't. In that situation, you're going to need a car loan, and you're going to need to get it from the car dealership or from the bank.",
    story_id: 1172,
    word_count: 44,
    all_intersection_count: 30,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "afford",
      "enough",
      "take",
      "trip",
      "Europe",
      "blue",
      "jays",
      "game",
      "jen",
      "cost",
      "quite",
      "much",
      "able",
      "whatever",
    ],
    words: [
      "afford",
      "when",
      "you",
      "can",
      "something",
      "it",
      "means",
      "that",
      "have",
      "enough",
      "money",
      "to",
      "buy",
      "can't",
      "don't",
      "right",
      "now",
      "I",
      "take",
      "a",
      "trip",
      "Europe",
      "do",
      "but",
      "go",
      "blue",
      "jays",
      "game",
      "with",
      "jen",
      "because",
      "doesn't",
      "cost",
      "quite",
      "as",
      "much",
      "so",
      "say",
      "and",
      "are",
      "able",
      "whatever",
      "need",
      "use",
      "just",
      "for",
    ],
    link: "https://www.youtube.com/watch?v=DYpqs413zB8",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/afford.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ZRi10KELrOWBhjTwkz%2BSRyywbSHCsXNVDq4LobZAcb9jVeh2yw9Aop1jIioTchjBPO2jJjf4J2h7wOtEH3cW0UL%2BeYTP82PVHUeL3IZYUypLXKRv9FLtD%2F0fqKX%2B1zqAHEREOLm6RqS5isCIJiqrxThfCmTqyXWrVJzT3vhmxVT6%2BrHT8RmbyNoYz9awCuCzlo3Y7zcrzTYj90sF5DEa02AEOEVvYSOqcTpv6d%2BwvQppCu%2B1eTL2fUaGhuumOtWrP9QOuh75wFiWy5TqV1ZpMHKjE8uE1ceECy4YAsM7N%2FcL5w784195J%2FMIGB6iL%2BI3H%2F0tcFl8%2BNwrhmdyYETOdQ%3D%3D",
    id: "Afford. When you can afford something, it means that you have enough money to buy it. When you can't afford something, it means you don't have enough money to buy it. Right now, I can't afford to take a trip to Europe. I don't have enough money to do that. But I can afford to go to a Blue Jays game with Jen because that doesn't cost quite as much as a trip. So when you say you can afford something, it means you have enough money and you are able to do it. You can go and buy whatever you need to buy and you use it as you need to use it. And when you say you can't afford something, it means you just don't have enough money for it.",
    story_id: 1097,
    word_count: 46,
    all_intersection_count: 32,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "maybe",
      "still",
      "really",
      "shape",
      "trade",
      "dealership",
      "certain",
      "amount",
      "then",
      "pay",
      "low",
      "might",
      "consider",
      "trading",
    ],
    words: [
      "now",
      "maybe",
      "you",
      "have",
      "a",
      "car",
      "and",
      "it's",
      "still",
      "in",
      "really",
      "good",
      "shape",
      "but",
      "want",
      "to",
      "buy",
      "new",
      "can",
      "always",
      "trade",
      "your",
      "when",
      "it",
      "means",
      "go",
      "the",
      "dealership",
      "they",
      "give",
      "certain",
      "amount",
      "of",
      "money",
      "for",
      "then",
      "use",
      "that",
      "help",
      "pay",
      "so",
      "if",
      "an",
      "older",
      "has",
      "low",
      "kilometers",
      "might",
      "consider",
      "trading",
    ],
    link: "(4)https://www.youtube.com/watch?v=hDGK1BCeP74&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=6",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4youwanttobuyanewcartradeinacar.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=BGpPP8CHCAINLtB8l1I6JjGSfPm4KpaRXSsc4HLgZ%2FDRF1d33tYtxNwYh7CB6YUvQmrJju7XnYYOm8uT19GZIEvQpiYQcY8vsOfZ%2F0L0XfEXN27WdCISFKYlU2EFtjLXnQsM7kRoLpBBJPx3iWzlfolTUoYH5wSOPC4g9Mb%2FZek3PUIbMdl9wfY42K2OzjNyoEALfl4nbOUwLOSG%2BHNiIpeOABdw%2BUXAzmgYtKK%2FQjhvHiGOQven%2F%2BaxCgmaeNgqJwvgJEfAiE%2FMoCtT8YiuNHEI8gUBsbQXxuLUQ0P3gTek0bcRR1M%2B4uu57tRgSpYnpvfTK%2FH9aipmuDKlfAW54g%3D%3D",
    id: "Now, maybe you have a car and it's still in really good shape, but you want to buy a new car, you can always trade in your car. When you trade in a car, it means you go to the dealership and they give you a certain amount of money for your car. And then you can use that money to help pay for the new car that you want to buy. So if you have an older car and it has really low kilometers and it's still in really good shape, you might consider trading it in to help pay for the new car.",
    story_id: 1173,
    word_count: 50,
    all_intersection_count: 36,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "last",
      "hand",
      "holding",
      "phrase",
      "would",
      "I'll",
      "3",
      "seconds",
      "take",
      "guess",
      "toonie",
      "dollar",
      "coin",
      "had",
    ],
    words: [
      "okay",
      "last",
      "one",
      "what",
      "do",
      "you",
      "think",
      "I",
      "have",
      "in",
      "my",
      "hand",
      "when",
      "something",
      "and",
      "you're",
      "holding",
      "it",
      "like",
      "this",
      "use",
      "the",
      "phrase",
      "your",
      "or",
      "would",
      "say",
      "I'll",
      "open",
      "3",
      "seconds",
      "take",
      "some",
      "time",
      "to",
      "guess",
      "three",
      "two",
      "a",
      "toonie",
      "so",
      "is",
      "dollar",
      "coin",
      "here",
      "Canada",
      "that's",
      "had",
    ],
    link: "(9)https://youtu.be/O36OX5eg9cA?si=IYZ0t9JSSd_B6fs1",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/9somethingonsomethingbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=zHQ%2F2i8AQbxawxH%2B2oDGHcLwNs5LLPrGe2RL9fUBF%2FgH0v4Mllhtrskfbdh8lRBkwWx%2BHlmpGo1IdAwCYULhXLRFhqyD2rfaABGNpPITW%2FC4NIH9ggRQajHmtyBbWT5%2FJG7Yh7DBcYDbJo6YIhWkCFb94EcWSuT85yQx9Jr5CkEbc5Eun51NXdhfC0s5UugRL5UOMNsOmQHLOv5WzF7Pc32IaYTdrv7IIJ87vv8vLqD0ugk0RapS0UECiW2e6z7ZvrlZMAqNs1Pzb7NxOwbDtyyvufYdFp7EIfXTeS4rbhRrrXDTegOLNW2RGm5JPwdaGMhz7XYNgJq%2Bb6dnMDib%2BQ%3D%3D",
    id: "Okay, last one. What do you think I have in my hand? When you have something and you're holding it like this, you use the phrase in your hand, or I would say in my hand. What do you think I have in my hand? I'll open it in 3 seconds. You take some time to guess. Three, two, one. I have a toonie in my hand. So this is a two dollar coin here in Canada, that's what I had in my hand.",
    story_id: 1358,
    word_count: 48,
    all_intersection_count: 34,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "will",
      "mister",
      "henderson",
      "back",
      "soon",
      "he'll",
      "20",
      "minutes",
      "smith",
      "she'll",
      "half",
      "arrive",
      "it'll",
      "5",
    ],
    words: [
      "will",
      "mister",
      "henderson",
      "be",
      "back",
      "soon",
      "yes",
      "he",
      "he'll",
      "in",
      "about",
      "20",
      "minutes",
      "doctor",
      "smith",
      "here",
      "she",
      "she'll",
      "half",
      "an",
      "hour",
      "the",
      "train",
      "arrive",
      "it",
      "it'll",
      "5",
    ],
    link: "https://www.youtube.com/watch?v=5Nzr30SYy3g&list=PLD7AA7B1BC72ACC28&index=7",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/will-2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ru%2FybiDnjZCguUgkR%2FSMSNe%2FIF7UMR40M1PhGwS9h%2BDniq4WXEEEmTOIwUOgBpyk9lU0YVzCQE8Hla576aNwCqHj2tslmZz1nGCY4YzxypilzavCZCOaku%2BOmjw6xPkVksX6XedzMn3W4i9FQ7OOBqE2crDWp6Q0LxkS%2B1GxRAeo9%2BYfH%2FUj8v%2Bo%2FEI%2FXKEWbzCl%2BKMYLY5WdC%2Bb0ICCjm08GYPBhq6W721hHzgu55C6uWKH0IzO4QSpPLlGtojo2uFD0fmJoLk6Iqfk0Wgn%2F8150MtYZHtCcI2w2clhigq468V%2BDTiqLiOeJ9doqKPDCGzYf%2FztbC0au%2BajyF7gig%3D%3D",
    id: "Will mister Henderson be back soon?\nYes. He will.\nHe'll be back in about 20 minutes.\n\nWill doctor Smith be here soon?\nYes. She will. She'll be here in half an hour.\n\nWill the train arrive soon?\nYes, it will. It'll arrive in 5 minutes.",
    story_id: 1027,
    word_count: 27,
    all_intersection_count: 13,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "herbert",
      "depressed",
      "having",
      "party",
      "friends",
      "all",
      "herbert's",
      "clean",
      "dentist",
      "Michael",
      "come",
      "afraid",
      "stay",
      "understand",
    ],
    words: [
      "herbert",
      "is",
      "depressed",
      "he's",
      "having",
      "a",
      "party",
      "today",
      "but",
      "his",
      "friends",
      "can't",
      "go",
      "to",
      "they're",
      "all",
      "busy",
      "can",
      "you",
      "herbert's",
      "no",
      "I",
      "have",
      "work",
      "we",
      "clean",
      "our",
      "house",
      "well",
      "your",
      "children",
      "they",
      "do",
      "their",
      "homework",
      "yes",
      "the",
      "dentist",
      "she",
      "has",
      "that's",
      "right",
      "Michael",
      "he",
      "doctor",
      "I'm",
      "sorry",
      "come",
      "things",
      "oh",
      "my",
      "me",
      "afraid",
      "stay",
      "here",
      "and",
      "okay",
      "understand",
    ],
    link: "(2)https://www.youtube.com/watch?v=9ozhdznI3xU&list=PL31D7CB49ABAAB4DB&index=21",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/can2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=gu3MOnmloIUE3MxT46FYo1VNp1A0ujsNDyM40iz0dFX71nUfGCQjV%2FaBX6TWGQ%2FJdnTnaNtZCusoXD0UR4hqKABWa9jqVCWHx%2FSebGZAihtDF2qh%2Bd5xhL6tgy%2BzYpx8KVrSaVZfJrNPsjydtwPPPs7CsBRhjEFMZ5gRuV6PB%2BsMm%2B5%2By6QHs26F5H5HmFyBpjqVXSWhf%2BglFSaJw9whbrWqE3yHbjawqHhB1nKJtl5l0lVmDus%2FLNHHRBIqYlQcpPqpX1Ui4Mo69IIoVWg%2Fs394CJE896%2FLyfYwA3VkU0mu48G9a1ywhcFg8Uyyi2fno6aAmkcY69QUv5woZGEF3g%3D%3D",
    id: "Herbert is depressed. He's having a party today, but his friends can't go to his party. They're all busy.\n\nCan you go to Herbert's party?\nNo, I can't. I have to work.\n\nCan you go to Herbert's party?\nNo, we can't. We have to clean our house.\nWell, can your children go to Herbert's party?\nNo. They can't. They have to do their homework.\n\nCan you go to Herbert's party?\nYes. I can.\nNo. You can't.\nYou have to go to the dentist. She can't. She has to go to the dentist.\nThat's right. I can't.\n\nCan Michael go to Herbert's party?\nNo, he can't. He has to go to the doctor.\n\nHerbert?\nYes.\nI'm sorry, Herbert. Your friends can't come to your party. They all have things they have to do.\nOh. Well, can you come to my party?\nMe? Oh, no. I'm afraid I can't. I have to stay here and work. Sorry.\nThat's okay. I understand.",
    story_id: 1004,
    word_count: 58,
    all_intersection_count: 44,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "lesson's",
      "hurt",
      "clap",
      "versus",
      "slap",
      "you've",
      "watched",
      "show",
      "really",
      "appreciation",
      "ah",
      "yourself",
      "angry",
      "shouldn't",
    ],
    words: [
      "okay",
      "this",
      "lesson's",
      "going",
      "to",
      "hurt",
      "let's",
      "talk",
      "about",
      "clap",
      "versus",
      "slap",
      "when",
      "you",
      "it",
      "means",
      "you've",
      "watched",
      "a",
      "show",
      "and",
      "really",
      "like",
      "so",
      "your",
      "appreciation",
      "is",
      "ah",
      "it's",
      "something",
      "do",
      "someone",
      "not",
      "usually",
      "yourself",
      "if",
      "you're",
      "angry",
      "with",
      "them",
      "shouldn't",
      "remember",
      "good",
      "bad",
    ],
    link: "https://www.youtube.com/shorts/dzXQzxm3atI",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "Okay, this lesson's going to hurt. Let's talk about clap versus slap. When you clap, it means you've watched a show and you really, really like it. So you clap to show your appreciation. This is a slap. Ah. It's something you do to someone, not usually to yourself. It's something you do to someone if you're angry with them, and it's something you shouldn't do. So let's remember this. To clap is good. To slap is bad.",
    story_id: 1083,
    word_count: 44,
    all_intersection_count: 30,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "next",
      "question",
      "anonymous",
      "bob",
      "motto",
      "life",
      "easy",
      "kind",
      "way",
      "jerk",
      "each",
      "first",
      "through",
      "little",
    ],
    words: [
      "this",
      "next",
      "question",
      "is",
      "from",
      "an",
      "anonymous",
      "person",
      "I",
      "don't",
      "know",
      "their",
      "name",
      "but",
      "the",
      "hi",
      "bob",
      "what",
      "your",
      "motto",
      "in",
      "life",
      "oh",
      "easy",
      "one",
      "be",
      "kind",
      "way",
      "too",
      "short",
      "to",
      "mean",
      "people",
      "or",
      "a",
      "jerk",
      "each",
      "other",
      "when",
      "you",
      "see",
      "that",
      "first",
      "every",
      "day",
      "say",
      "and",
      "as",
      "go",
      "through",
      "do",
      "little",
      "things",
      "help",
      "so",
      "my",
    ],
    link: "https://www.youtube.com/watch?v=NKD9WSTnm2g&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=20",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1questionansweryourmottobob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=RuzDF79IcrjoTmmOjIsMhK8GmnSyox82XR22VpCss6MOxmNFYWva2EhyT6P1eNigo48Pjb9YctBMoXTRknnUzGFJbjBgvqyqD3HIO0yW%2BIbnK5AY32i9sIMQfSdj4c%2BK2GWgL%2FsJ4qPYWTn3zYkktnDZ%2BiIzViaykTEUo5%2BohVZSGxVbd0I%2FZuobuGIoQzjw5%2BKhHAmmOWH7B0QL2heuN19OvbPXdfy2hywkm%2B95K1XiCvPqzsbbRp635cNpw6ZjmraVESnGoVnBrct7%2BcAUEentdevOW0vsXeUIUpqDL5%2B4Irv6H1AyfRc0%2BCaP7UZX320f0QUDDC1Km3AUZIDoqg%3D%3D",
    id: "This next question is from an anonymous person. I don't know their name, but the question is, hi, Bob. What is your motto in life? Oh, this is an easy one. Be kind. Life is way too short to be mean to people or to be a jerk. Be kind to each other. When you see that first person that you see every day, say hi. And as you go through your day, do little things that help other people. So my motto, be kind.",
    story_id: 1375,
    word_count: 56,
    all_intersection_count: 42,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "names",
      "these",
      "items",
      "put",
      "around",
      "hook",
      "onto",
      "take",
      "kind",
      "hard",
      "called",
      "collar",
      "goes",
      "leash",
    ],
    words: [
      "do",
      "you",
      "know",
      "the",
      "names",
      "for",
      "these",
      "two",
      "items",
      "in",
      "English",
      "this",
      "is",
      "something",
      "that",
      "we",
      "put",
      "around",
      "neck",
      "of",
      "a",
      "dog",
      "or",
      "cat",
      "hook",
      "onto",
      "when",
      "want",
      "to",
      "take",
      "walk",
      "it's",
      "kind",
      "hard",
      "called",
      "collar",
      "if",
      "it",
      "on",
      "call",
      "goes",
      "leash",
      "and",
      "your",
    ],
    link: "https://www.youtube.com/shorts/kbOBQihvmmo",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/collar.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=nbQ2HmfsVvuCT3QQ3QZibcZ%2F2fIry6u3xYo3WEatgt47NCOO8v8kJcHxpFNhCVuIn0ko5bNhBknnPDNSuNCAe1jW23rnqzkBOSoju%2BUO9GFPlGBkuMxZ8fjdHhVLWaCFd36UsoeflMCLCNLPTRQGHIIJENG81BWOBn4dkqda1XAPR9spCQ4RNflbqTVZceSJL7NnSiG2q2o0HDcMH4MB36TVgGowmRrfaAR%2BfmFUr5pjLPVNKImcQ8gx5x%2FYxhaPFveyZ6fiC76uLYRzhhYvFQ9RXppmFCbQVSYJ7QQBJpihH9RY6hyKni7Kmv6rzswMbyELNlP9DXFhgbnXQxNZKg%3D%3D",
    id: "Do you know the names for these two items in English? This is something that we put around the neck of a dog or cat. This is something that we hook onto this when we want to take the dog for a walk. It's kind of hard to take a cat for a walk. This is called a collar. If you put it on a dog, we call it a dog collar. If it goes on a cat, we call it a cat collar. This is called a leash. And you hook the leash to the collar when you want to take your dog for a walk.",
    story_id: 1076,
    word_count: 44,
    all_intersection_count: 30,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "bucket",
      "dump",
      "ground",
      "verb",
      "um",
      "sure",
      "did",
      "though",
      "kind",
      "I'll",
      "fill",
      "pail",
      "again",
      "up",
    ],
    words: [
      "now",
      "I",
      "have",
      "water",
      "in",
      "the",
      "bucket",
      "but",
      "what",
      "if",
      "want",
      "out",
      "of",
      "can",
      "dump",
      "on",
      "ground",
      "or",
      "use",
      "verb",
      "to",
      "um",
      "I'm",
      "not",
      "sure",
      "why",
      "did",
      "that",
      "though",
      "because",
      "we",
      "kind",
      "need",
      "I'll",
      "fill",
      "this",
      "pail",
      "again",
      "up",
    ],
    link: "(4)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=OGNJH0hboljrfSPnILzpr9zuL0w1M3EJYnOGIkqnl9O7frT1v84gds6G4SrtGlunWcVlErcPsu2HfPATNh66TCylUhIOiU3ZKaBHXECAcgvjIo2BkT1%2BRPzPdI7eSx3wbmkqgHtUCHyEb%2FYgS7coGM7gzHWb%2B6%2FEQugzN5J88mz5WRD2a8UlS42Zoub3ZtShytCOzS9XgYjSO0lHrxpzeiXcKCUQnyq52UnqT80ESfAEUAknBAme%2FxC3TJt40kv2SLQslT7IYIixWM1LybceCE3CzI%2FfViObENtVPzvP8Hch0OJCKw2%2Fl9h0xOm1upRQDMjHzh7x87BT1uuYhVAYiw%3D%3D",
    id: "Now I have water in the bucket. But what if I want the water out of the bucket? I can dump the water on the ground. Or I can use the verb to dump out. I can dump the water. Or I can dump out the water. Um, I'm not sure why I did that, though, because we kind of need that water now. I'll have to fill this pail again. I'll have to fill up this pail again.",
    story_id: 1329,
    word_count: 39,
    all_intersection_count: 25,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "-",
      "Jack",
      "jim",
      "calling",
      "miami",
      "vacation",
      "weather",
      "sunny",
      "isn't",
      "raining",
      "hot",
      "cold",
      "having",
      "terrible",
    ],
    words: [
      "-",
      "hi",
      "Jack",
      "this",
      "is",
      "jim",
      "I'm",
      "calling",
      "from",
      "miami",
      "what",
      "are",
      "you",
      "doing",
      "in",
      "on",
      "vacation",
      "how's",
      "the",
      "weather",
      "it",
      "sunny",
      "no",
      "isn't",
      "it's",
      "raining",
      "hot",
      "cold",
      "having",
      "a",
      "good",
      "time",
      "not",
      "terrible",
      "here",
      "sorry",
      "to",
      "hear",
      "that",
    ],
    link: "(2)https://www.youtube.com/watch?v=yszaiqhK1Aw&list=PL31D7CB49ABAAB4DB&index=7",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/wheather2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Vu4BJHVFl5fL668rJCyj5xo4q4gLhDmHTnGCc3Fhk%2BgEq%2BqGQbK8YYvy0zz0idhGXeEpLg%2F8UA6Gd4q3zrHj33wkwBZw6Eqfe63IzLfDzCesyjFqPB9W%2BVjA46F2BCnsAZqtdpdr5ebN8skYRPSDE56p9x0vp8qNT7MDDge1BaKejpHmjdetJ%2FFkmHdh%2BtFylHZvQkjo77lqOFOYvGhKG0fb5N15A8JSLH0OCUPMw0P5LNAPyewkiFRlAWon4riNZTVT0Lc2oloX3ZGNW9WNL41TLLoAHbom6fBJn3XlWqY1JXptQb5DcCbq4s3hvJy22CaHIPUHg3OujzBjlID8yQ%3D%3D",
    id: "- Hi, Jack. This is Jim. I'm calling from Miami.\n- From Miami? What are you doing in Miami?\n- I'm on vacation.\n- How's the weather in Miami? Is it sunny?\n- No. It isn't. It's raining.\n- Is it hot?\n- No. It isn't. It's cold.\n- Are you having a good time?\n- No. I'm not. I'm having a terrible time. The weather is terrible here.\n- I'm sorry to hear that.",
    story_id: 973,
    word_count: 39,
    all_intersection_count: 25,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "flat",
      "pancake",
      "drove",
      "over",
      "bottle",
      "been",
      "chewed",
      "thin",
      "fry",
      "frying",
      "pan",
      "word",
      "describe",
      "being",
    ],
    words: [
      "as",
      "flat",
      "a",
      "pancake",
      "I",
      "drove",
      "over",
      "this",
      "bottle",
      "and",
      "it's",
      "been",
      "chewed",
      "on",
      "by",
      "the",
      "dogs",
      "well",
      "but",
      "now",
      "do",
      "you",
      "know",
      "what",
      "is",
      "very",
      "thin",
      "cake",
      "that",
      "fry",
      "in",
      "frying",
      "pan",
      "so",
      "sometimes",
      "we",
      "use",
      "word",
      "to",
      "describe",
      "things",
      "like",
      "being",
    ],
    link: "(7)https://www.youtube.com/watch?v=bJyW_s9p8vk",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/asflatas.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=uvXkBKD8aJKDzRA8a7je8JEUHWo4MWfyFaBjRAvW0bHlq5GgPql%2F1Ev91bi7Yqjg%2ByxyAv0olZf1v87C%2BzRnrKN7hK1RKumQxm42BPVPWoYIxzIa5QBjfP125K%2FqDs3J1DUL5srl9z7hr7pj04wzCbQBVya8cf1gaaDSMUL0wxg3x9IZnCBkRFn8AjBFqI116Zh30JowSdn%2B455TevKtONi8c6m2yj0ejP7Ku%2ByuriQKrncS5CGirc9kV1N7PvDoT%2BHkux5sK%2FvI4I3Oz0thWOGcZZuNjFWq71EAebpLXopzx4FQ2FFWZvBI9tqFWHhUPwXcoKwxfVFQAhmruBrX1g%3D%3D",
    id: "As flat as a pancake. I drove over this bottle and it's been chewed on by the dogs as well. But now it's as flat as a pancake. Do you know what a pancake is? It's a very thin cake that you fry in a frying pan. So sometimes we use the word as and the word flat to describe things like this as being as flat as a pancake.",
    story_id: 1134,
    word_count: 43,
    all_intersection_count: 29,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "isn't",
      "ridiculous",
      "yours",
      "much",
      "mine",
      "novels",
      "aren't",
      "interesting",
      "ernest",
      "hemingway's",
      "parents",
      "clean",
      "sister's",
      "cleaner",
      "hers",
    ],
    words: [
      "you",
      "know",
      "my",
      "dog",
      "isn't",
      "as",
      "friendly",
      "your",
      "don't",
      "be",
      "ridiculous",
      "yours",
      "is",
      "much",
      "friendlier",
      "than",
      "mine",
      "novels",
      "aren't",
      "interesting",
      "ernest",
      "hemingway's",
      "are",
      "more",
      "his",
      "parents",
      "say",
      "that",
      "room",
      "clean",
      "sister's",
      "I",
      "disagree",
      "think",
      "cleaner",
      "hers",
    ],
    link: "(4)https://www.youtube.com/watch?v=1dW7V33qLwA&list=PLD7AA7B1BC72ACC28&index=10",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/friendly.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=inIHKQFsvvhO1lSzY5ACVtnII3uiOERUSryhSMPPrF8RoW8w4jofsmgVPWFWTtr3tiDeE9qKYU9wjPHYkb83yJif7%2FahkrqHFj1%2BCkt9CvNighpNx894Z5NYzL6%2BclL0OacTfgBlWYGjuNX7Z4ZN62fkgvn7nTeEBmm3u44HSCiR9U2ArtiIIi12emVzGccSNTdaIgVFr1CqphNw68v22gmyihoFq6eS0pDwR%2FcpmtG6Z0heG2gB72GSLulOpW3qP%2BB%2FbfkLGBnhVwLSESJHrjFrJ4TZub9mjWmTcVXr9ue%2BaoZ0B3XpFsagnJfIc92N%2BwSYUxtZSPRZQzZA1op7fA%3D%3D",
    id: "You know, my dog isn't as friendly as your dog.\nDon't be ridiculous.\nYours is much friendlier than mine.\n\nYou know, my novels aren't as interesting as Ernest Hemingway's novels.\nDon't be ridiculous. Yours are much more interesting than his.\n\nMy parents say that my room isn't as clean as my sister's room.\nI disagree. I think yours is much cleaner than hers.",
    story_id: 1043,
    word_count: 36,
    all_intersection_count: 21,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "-",
      "task",
      "was",
      "appointed",
      "find",
      "way",
      "will",
      "then",
      "must",
      "afraid",
      "even",
      "smallest",
      "change",
      "course",
      "future",
    ],
    words: [
      "-",
      "this",
      "task",
      "was",
      "appointed",
      "to",
      "you",
      "and",
      "if",
      "do",
      "not",
      "find",
      "a",
      "way",
      "no",
      "one",
      "will",
      "then",
      "I",
      "know",
      "what",
      "must",
      "it's",
      "just",
      "I'm",
      "afraid",
      "it",
      "even",
      "the",
      "smallest",
      "person",
      "can",
      "change",
      "course",
      "of",
      "future",
    ],
    link: "https://www.youtube.com/watch?v=oRjMmvgwX8k",
    type: "movie video",
    pdf_name: null,
    storage_link: null,
    id: "- This task was appointed to you, and if you do not find a way, no one will.\n- Then I know what I must do. It's just... I'm afraid to do it.\n- Even the smallest person can change the course of the future.",
    story_id: 942,
    word_count: 36,
    all_intersection_count: 21,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "parts",
      "root",
      "part",
      "underground",
      "trunk",
      "around",
      "called",
      "bark",
      "up",
      "branch",
      "over",
      "down",
      "fallen",
      "dead",
      "stick",
    ],
    words: [
      "let's",
      "learn",
      "the",
      "parts",
      "of",
      "a",
      "tree",
      "in",
      "English",
      "this",
      "is",
      "root",
      "part",
      "underground",
      "trunk",
      "and",
      "around",
      "we",
      "have",
      "what's",
      "called",
      "bark",
      "up",
      "here",
      "branch",
      "on",
      "over",
      "leaf",
      "down",
      "I",
      "that's",
      "fallen",
      "off",
      "it's",
      "now",
      "dead",
      "call",
      "stick",
    ],
    link: "https://youtube.com/shorts/1XfSk8NKBRQ?si=2gDz1ZJOVScuf-EE",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/treeparts.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qGlvmiORCwgV5Epb5nBO3bBmB87PKu%2BOyqhtyuoC93qq4Exz7VGGSsQ1ILGDfcOtxVx9uMZc6w0oYLOPBZHEt12RvrbIHNflYgjWoJCqWmP0VRBFbZGkOtUfOj8pJuu17M8y4MKxfmQouLkekmMM5LNeEnYYkBBitJSNV1TyYGoZPE4cwdBFmdageo%2BLfwVGs2Glsy4Hc5ZsZO%2BaPMBmVPMzS8eD3gWDXxxsYEayUjsIkAXCIThQmqjWtGWKmY%2BpyK15IXgCvF6viED9RCsz7Tv46S7SEkva42gaUSz%2BltONjXtHCLfsz6iy2PNugIHXfbd%2BVskaegSAtw923bBi1g%3D%3D",
    id: "Let's learn the parts of a tree in English.\nThis is the root. The root is the part of the tree underground.\nThis is the trunk. And around the trunk we have what's called the bark.\nUp here we have a branch. This is a branch on the tree.\nAnd over here we have what's called a leaf. And down here I have a branch that's fallen off the tree and it's now dead. We call this a stick.",
    story_id: 1073,
    word_count: 38,
    all_intersection_count: 23,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "spring",
      "muddy",
      "end",
      "up",
      "getting",
      "ruts",
      "driveway",
      "there",
      "actually",
      "little",
      "bit",
      "part",
      "lawn",
      "wet",
      "drive",
    ],
    words: [
      "so",
      "this",
      "is",
      "something",
      "I",
      "don't",
      "like",
      "in",
      "the",
      "spring",
      "because",
      "it's",
      "muddy",
      "you",
      "end",
      "up",
      "getting",
      "ruts",
      "your",
      "driveway",
      "can",
      "see",
      "here",
      "that",
      "there",
      "are",
      "some",
      "actually",
      "a",
      "little",
      "bit",
      "part",
      "of",
      "lawn",
      "wet",
      "and",
      "if",
      "drive",
      "on",
      "it",
      "get",
    ],
    link: "(4)https://youtu.be/rodSOxxpvcE?si=9QPiUY4a7pLlQDmU",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4learnenglishonabeautifulspringdaybob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=FDbP3soAefXNnYNXVa8LQOpridXjIy7riZoxk5HHRCuQepW5x0wpLEuspOVfHYcXxnuSYe6P2BEEUA2qy08Ek3SDy%2B40RewUUAbuqbIYy9%2FQJ5iGVAuExrxfruIk%2BTuMEdKnQaN69EY4RPL0rRrDbTITYEmaDfA3pGWajwsC1CKoKlaNMs18fLimU9I1eCczgyPnqV6huO%2BPG7foTgu34EJ4pn5gJLkKd6YVpKaHiy5XWg2HBkhiDjpCDygUpeHajr0PJhOqJQECplF1sUAJA4Mj0vzcNzY6a%2FxN0J7%2BGmpuJm0SKZXMjEfjFPxSbJqA1B7tGaFM9crf3SF4%2BoRelQ%3D%3D",
    id: "So this is something I don't like in the spring because it's so muddy, you end up getting ruts in your driveway. You can see here that there are some ruts. The driveway, actually, this is a little bit part of the driveway, a little bit part of the lawn. It's a little bit wet and a little bit muddy, and if you drive on it, you get ruts.",
    story_id: 1362,
    word_count: 41,
    all_intersection_count: 26,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "had",
      "yearly",
      "checkup",
      "did",
      "told",
      "little",
      "heavy",
      "must",
      "lose",
      "weight",
      "ice",
      "cream",
      "mustn't",
      "much",
      "before",
    ],
    words: [
      "I",
      "had",
      "my",
      "yearly",
      "checkup",
      "today",
      "what",
      "did",
      "the",
      "doctor",
      "say",
      "she",
      "told",
      "me",
      "I'm",
      "a",
      "little",
      "too",
      "heavy",
      "and",
      "must",
      "lose",
      "some",
      "weight",
      "do",
      "you",
      "have",
      "to",
      "stop",
      "eating",
      "ice",
      "cream",
      "no",
      "but",
      "mustn't",
      "eat",
      "as",
      "much",
      "before",
    ],
    link: "(3)https://www.youtube.com/watch?v=DR75vAgVbqk&list=PLD7AA7B1BC72ACC28&index=20",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/doctor6.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=I6zPoo8VEFfNpAdjnmVaN96wpT5eFy8t7HFbGYh89dYHW3IGK6VePpOfEOBSMSujwH6x6SAi3shI7nHQo%2BJbN%2BuN5zOGoaItS0vDxMbK5lOEPQQ0fUGPGkNZHdIRWoZ3yMYgkyAyEC8P3ZtJjTds5UvA7%2FkzwiClxx9w3v%2B1Y0mvmE2ml5ZS2MZAVDMKtS48QXvr4k78bVcosnl00osowEBnkQr2AZX5bYA4A6XfYAEnaP45vBi0wNRGIss5kKg%2F7fRSjddWtx3NsSrJahMxX3FBXj%2BHqKUHQ3nfCqlZQ5lc5m8cmNbfN6t%2B3GipGL%2FBotYfDlDp1aT9t8AtWlwf7A%3D%3D",
    id: " I had my yearly checkup today.\nWhat did the doctor say?\nShe told me I'm a little too heavy and I must lose some weight.\nDo you have to stop eating ice cream?\nNo, but I mustn't eat as much ice cream as I did before.",
    story_id: 1057,
    word_count: 39,
    all_intersection_count: 24,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "bring",
      "must",
      "keep",
      "leash",
      "goes",
      "poop",
      "scoop",
      "up",
      "clean",
      "after",
      "makes",
      "much",
      "nicer",
      "experience",
      "clean up after",
    ],
    words: [
      "so",
      "if",
      "you",
      "bring",
      "your",
      "dog",
      "to",
      "the",
      "park",
      "must",
      "keep",
      "on",
      "a",
      "leash",
      "and",
      "goes",
      "poop",
      "scoop",
      "up",
      "I",
      "like",
      "it",
      "when",
      "people",
      "clean",
      "after",
      "their",
      "dogs",
      "makes",
      "for",
      "much",
      "nicer",
      "experience",
      "clean up after",
    ],
    link: "(11)gxMIeHmm3Rg",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "So if you bring your dog to the park, you must keep your dog on a leash. And if your dog goes poop, you must scoop up the poop. I like it when people clean up after their dogs. It makes for a much nicer park experience.",
    story_id: 1545,
    word_count: 34,
    all_intersection_count: 19,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "landscape",
      "fabric",
      "kind",
      "mess",
      "flatten",
      "before",
      "fold",
      "up",
      "all",
      "even",
      "smooth",
      "once",
      "done",
      "nicely",
      "verb",
    ],
    words: [
      "so",
      "I",
      "have",
      "some",
      "landscape",
      "fabric",
      "here",
      "and",
      "it's",
      "kind",
      "of",
      "a",
      "mess",
      "need",
      "to",
      "flatten",
      "this",
      "before",
      "fold",
      "it",
      "up",
      "when",
      "you",
      "something",
      "make",
      "all",
      "nice",
      "like",
      "can",
      "even",
      "say",
      "that",
      "I'm",
      "going",
      "smooth",
      "out",
      "once",
      "that's",
      "done",
      "nicely",
      "or",
      "use",
      "the",
      "verb",
    ],
    link: "(13)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/13learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=cTmDjkJzMcM9f7NaywX8NIsjZwqhJa1IAR6XLk%2Fh6ehbQUl%2FDArdgBJlxr7KI8qYyeFUPIO8Akdp8NJnCGE%2BthF1OvKFa9aLuQ87O78XC5%2Fn6g6Laoj4ek%2Fz0CRsO8warR3VFjxRKzIzhAPGkp%2F0u%2FtdQyJwjTw688od3GxpIbi99amRleRwG4Up3kKQcVCbM5NYLlKltwssjcQnzxPvo0%2BWYVZUxV1kkwfPH9UALgWzfsuKnLPycc8stBaGY8rQNBMYYyhtiqGa8gByQv4eioY52nh2CiFixFoAeWp%2B4vx5o8iGENwqNVvIOjV22qpSFQzZzGvymUo%2BuoUWg%2FdClg%3D%3D",
    id: "So I have some landscape fabric here, and it's kind of a mess. I need to flatten this before I fold it up. So when you flatten something, you make it all nice like this. I can even say that I'm going to smooth it out. Once that's done, I can nicely fold it, or I can use the verb to fold up. So I can flatten it. I can smooth it out, I can fold it, or I can fold it up.",
    story_id: 1338,
    word_count: 44,
    all_intersection_count: 29,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "away",
      "toward",
      "towards",
      "camera",
      "idea",
      "way",
      "could",
      "over",
      "there",
      "then",
      "also",
      "both",
      "versions",
      "word",
      "interchangeably",
    ],
    words: [
      "so",
      "let's",
      "talk",
      "about",
      "away",
      "from",
      "far",
      "toward",
      "and",
      "towards",
      "right",
      "now",
      "I",
      "am",
      "walking",
      "the",
      "camera",
      "think",
      "you",
      "get",
      "idea",
      "I'm",
      "this",
      "way",
      "if",
      "stop",
      "could",
      "say",
      "is",
      "over",
      "there",
      "here",
      "then",
      "walk",
      "can",
      "also",
      "by",
      "they",
      "mean",
      "same",
      "thing",
      "use",
      "both",
      "versions",
      "of",
      "word",
      "interchangeably",
      "be",
      "or",
    ],
    link: "(11)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/11prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=oYiqx7ZiSxlEYnccmi4ksX8P7TUL6fTG9QjObly6kSUMI1jOoFLM5qF0Ixwl7iJKgurR2MXWYt%2Fgypct%2Bv%2Fs0pGTaUkCecY9WyTEKUjqTyH9t2DCrVl1Lwd3MuNsktG71z9pFH1J5oWyZ5p3FeLKC1aGz5SwrI5o3qxVd9aRPqdoUzUrydHpkI3ulkOk58MH6ya2XCkMsd6%2B5Q4xNHlxzKvcE%2Fvci7azTOV8kWtV%2FzN7mFp0Kbp%2BTjZD7tvVtCjQdercXHfs8Y8LIwouBPFx1FwlkBsozNBKLon4cUVBxktAerJUKZM5cZNwzZosj3MZjf7AmMVXURpRmkDF%2B15rvw%3D%3D",
    id: "So let's talk about away from, far from, toward and towards. Right now. I am walking away from the camera. I think you get the idea. I'm walking this way. I'm walking away from the camera. Now, if I stop, I could say I'm far from the camera. The camera is way over there. I'm way over here. I am far from the camera. And then I could walk toward the camera. I can also say I'm walking towards the camera. By the way, they mean the same thing. And I use both versions of the word interchangeably. So I can walk away from the camera. I can be far from the camera. I can walk toward the camera. Or I can walk towards the camera.",
    story_id: 1266,
    word_count: 49,
    all_intersection_count: 34,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "another",
      "might",
      "around",
      "literally",
      "phrase",
      "small",
      "chores",
      "few",
      "put",
      "doorknob",
      "doors",
      "upstairs",
      "bathroom",
      "little",
      "done",
    ],
    words: [
      "another",
      "thing",
      "you",
      "might",
      "do",
      "on",
      "a",
      "day",
      "off",
      "is",
      "some",
      "things",
      "around",
      "the",
      "house",
      "that's",
      "literally",
      "phrase",
      "we",
      "use",
      "to",
      "talk",
      "about",
      "small",
      "chores",
      "and",
      "need",
      "in",
      "our",
      "or",
      "apartment",
      "today",
      "I",
      "few",
      "have",
      "put",
      "new",
      "doorknob",
      "one",
      "of",
      "doors",
      "upstairs",
      "bathroom",
      "other",
      "little",
      "as",
      "well",
      "so",
      "sometimes",
      "when",
      "you're",
      "working",
      "every",
      "don't",
      "time",
      "it's",
      "nice",
      "get",
      "done",
    ],
    link: "(5)https://www.youtube.com/watch?v=yW-YeEc_ULg&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=21",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/5dayoffbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=XnmFodlcYHrM%2FowJUp9IZ0xmMzJMuQGbt1a9NTcB5nb4jyGtulDDATetvH8e0cWcFzyayz2zE5%2FRqQQ1V2NZXMyeaV%2FpD2fjSbHEwR146sinKVP%2FYoJ%2F0Jx%2B2W6vChXppwLxhmkdgZeWjBc5e9NXFnwtcjtDg6AZcmerrc59wFZJh5kZq0X6kDiIhFTmYkD%2F7Rrfjjl%2FFC227%2B5Uo2ZYAZ6WmYcKHlCH8QAEfsOrItrnSC9uVcdWBUOExmROcrqb4Bnw5eCBUwPdBeGGgkaIQtvvB4MyiNoWV2MAiq3cuM5zE5MVuxrYF0dJq4skHXdZmOl%2BkgpU2XlZm1BrYO3wxg%3D%3D",
    id: "Another thing you might do on a day off is you might do some things around the house. That's literally the phrase we use to talk about small chores and things we need to do in our house or in our apartment. Today, I need to do a few things around the house. I have to put a new doorknob on one of the doors in our upstairs bathroom. I have a few other little things I need to do around the house as well. So sometimes when you're working every day, you don't have time to do things around the house. So when you have a day off, it's nice to get some things done around the house. It's nice to do things around the house.",
    story_id: 1395,
    word_count: 59,
    all_intersection_count: 44,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "really",
      "handy",
      "tap",
      "fill",
      "pail",
      "turn",
      "also",
      "verb",
      "up",
      "verbs",
      "then",
      "take",
      "over",
      "there",
      "jen",
    ],
    words: [
      "it's",
      "really",
      "handy",
      "to",
      "have",
      "this",
      "tap",
      "out",
      "here",
      "if",
      "I",
      "need",
      "fill",
      "pail",
      "with",
      "water",
      "can",
      "turn",
      "on",
      "and",
      "we",
      "also",
      "the",
      "verb",
      "up",
      "it",
      "means",
      "same",
      "thing",
      "don't",
      "know",
      "why",
      "two",
      "verbs",
      "but",
      "want",
      "or",
      "then",
      "take",
      "over",
      "there",
      "jen",
      "use",
      "flowers",
    ],
    link: "(3)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/3learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qrg0UfdkluiUivCnFb2Py6YTJ5KvuSzRzjIlKcEUC7XzW1U9kfHKe2n5i%2BjhusCPT8wHXEGV2wOyJoxQ59F659kndExeKV6riBAtI702DJw4DHOISy4E3eJisAm9Ue3AG2fuJTTdsSvjkea5X7ShdkW8XQcGicC8gsoARmBC4OSFV2IgMFrCzKYMIgAWPEHtcg6oFHlwbxCM9lQGSjZ4iGNZdE7p5wS5%2Bcy4%2BcyWeqgydiyefOmUfy91CvpCvJZfHLaojInFfmyMFSzJUiFnyIVs8mht8fQDg7TYy9WiKhJL53SlCgiOBjrLtd%2BmRKl53630fRJs0YsNL17mJP9%2Bow%3D%3D",
    id: "It's really handy to have this tap out here. If I need to fill this pail with water, I can turn this tap on and I can fill this pail with water. We also have the verb to fill up, and it means the same thing. I don't know why we have two verbs, but if I want, I can fill this pail with water. Or I can fill up this pail with water, and then I can take it over there and Jen can use it to water the flowers.",
    story_id: 1328,
    word_count: 44,
    all_intersection_count: 29,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "next",
      "daily",
      "routine",
      "through",
      "Friday",
      "were",
      "though",
      "would",
      "stays",
      "point",
      "might",
      "stay",
      "go to work",
      "stay home",
      "go to school",
    ],
    words: [
      "so",
      "the",
      "next",
      "thing",
      "I",
      "do",
      "in",
      "my",
      "daily",
      "routine",
      "is",
      "go",
      "to",
      "work",
      "every",
      "day",
      "at",
      "about",
      "Monday",
      "through",
      "Friday",
      "if",
      "you",
      "were",
      "someone",
      "who",
      "school",
      "though",
      "would",
      "say",
      "are",
      "stays",
      "home",
      "this",
      "point",
      "your",
      "might",
      "stay",
      "but",
      "for",
      "me",
      "time",
      "go to work",
      "stay home",
      "go to school",
    ],
    link: "(6)ecF1y2bI2T4",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "So the next thing I do in my daily routine is I go to work every day at about 730, Monday through Friday. I go to work. If you were someone who is in school, though, you would say, I go to school. If you are someone who stays home at this point in your routine, you might stay home. But for me, every day at this time I go to work.",
    story_id: 1583,
    word_count: 45,
    all_intersection_count: 30,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "-",
      "Billy",
      "everything",
      "everything's",
      "fine",
      "basement",
      "susie",
      "grandma",
      "grandpa",
      "yard",
      "everybody's",
      "all",
      "I'll",
      "soon",
      "bye",
    ],
    words: [
      "-",
      "hello",
      "hi",
      "Billy",
      "this",
      "is",
      "mom",
      "oh",
      "everything",
      "okay",
      "at",
      "home",
      "yes",
      "everything's",
      "fine",
      "where's",
      "dad",
      "he's",
      "in",
      "the",
      "basement",
      "and",
      "susie",
      "she's",
      "living",
      "room",
      "how",
      "about",
      "grandma",
      "grandpa",
      "they're",
      "yard",
      "where",
      "are",
      "you",
      "I'm",
      "kitchen",
      "so",
      "everybody's",
      "we're",
      "all",
      "that's",
      "good",
      "I'll",
      "see",
      "soon",
      "bye",
    ],
    link: "https://www.youtube.com/watch?v=8AEseSofHnI&list=PL31D7CB49ABAAB4DB&index=2",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Rooms.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=y3pLF7WcOt5Tbt8T7Jr%2Fbzx%2BDisUdUoDugUF60OhrFTo5whVNuuldPgCnx4nJyVU9ep2wVMyDEuEPP%2B0xFC3%2FBIap%2BWuDo%2FywT96lyybgQvE6dXrjs92jjfbpJ%2BZAdTdqx%2FM2%2BsjEi0J1W8SX3VbD2%2BxY5%2FjWMXkqaBkvfnIE4u1%2BImwTvfq60RyBhj2TbtyT0vOYpEUXPJCLnFlFTyph%2BFPekYak%2FbO%2BG96nGSSU4Ojg7xmP3Khwy%2F6%2FUKSEN6f%2FHeWdXNnCYlHpDIgJ%2FSc7Bd3oTdYJ0Lnw0RYYkMIcrhif0d9dtzKohTVOLKiScJbs9PvwqlTxmfOvkPUh69CEg%3D%3D",
    id: "- Hello? Hi, Billy. This is mom. Oh, hi, mom. Is everything okay at home?\n- Yes. Everything's fine.\n- Where's dad? He's in the basement.\n- And Susie?\n- She's in the living room.\n- How about grandma and grandpa?\n- They're in the yard.\n- And where are you?\n- I'm in the kitchen.\n- So everybody's fine?\n- Yes, mom. We're all fine.\n- That's good. I'll see you soon, Billy.\n- Okay. Bye. Bye.",
    story_id: 968,
    word_count: 47,
    all_intersection_count: 32,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "found",
      "yours",
      "isn't",
      "mine",
      "might",
      "Fred's",
      "lost",
      "few",
      "days",
      "ago",
      "really",
      "I'll",
      "away",
      "said",
      "will",
    ],
    words: [
      "I",
      "just",
      "found",
      "this",
      "watch",
      "is",
      "it",
      "yours",
      "no",
      "isn't",
      "mine",
      "but",
      "might",
      "be",
      "Fred's",
      "he",
      "lost",
      "his",
      "a",
      "few",
      "days",
      "ago",
      "really",
      "I'll",
      "call",
      "him",
      "right",
      "away",
      "when",
      "you",
      "talk",
      "to",
      "tell",
      "said",
      "hello",
      "okay",
      "will",
    ],
    link: "(3)https://www.youtube.com/watch?v=PQCB85N52QE&list=PLD7AA7B1BC72ACC28&index=24",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/pronoun-3.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=TlCZt9EjWMoO0Q%2Fef6dDqfNilhTPUFZipSnZHwZfVC3u7GWtn8cgSjvBMDnkB9eHOx%2FaS3qu9sOE2y5ZkvX0OeinltXvyY0XkmiXkyfOSjK75CxVaCxm5XVie9LMAoASY%2F3KtxM9NaEuU0YwhkgsVKRigQZpCUklECv%2BzCQ45OXUv2eB3eCL0l8Wg69jESFDJnQIhEe7A3O%2Bzd6Scio2KU7puN00RHaspD%2BAr4HFHcURDoieoOVK11zTdxjTSXRL8OEAHjed0xHHK%2FGJqlv4X0Hdw4t15XOeQguKME07pKroZjVhcqN5oUNGSUHzsDAUi7itVXxUAzhRdzcgiK4UKw%3D%3D",
    id: "I just found this watch. Is it yours?\nNo, it isn't mine. But it might be Fred's. He lost his a few days ago.\nReally? I'll call him right away.\nWhen you talk to him, tell him I said hello.\nOkay, I will.",
    story_id: 1070,
    word_count: 37,
    all_intersection_count: 22,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "really",
      "sunny",
      "wearing",
      "sunglasses",
      "clouds",
      "went",
      "over",
      "sun",
      "could",
      "put",
      "word",
      "up",
      "hat",
      "stuff",
      "phrase",
      "though",
    ],
    words: [
      "so",
      "it's",
      "really",
      "sunny",
      "out",
      "here",
      "today",
      "I'm",
      "wearing",
      "my",
      "sunglasses",
      "but",
      "if",
      "the",
      "clouds",
      "went",
      "over",
      "sun",
      "I",
      "could",
      "put",
      "on",
      "head",
      "we",
      "use",
      "word",
      "when",
      "things",
      "up",
      "you",
      "can",
      "wear",
      "a",
      "hat",
      "your",
      "stuff",
      "phrase",
      "is",
      "it",
      "though",
      "them",
    ],
    link: "(7)https://youtu.be/O36OX5eg9cA?si=IYZ0t9JSSd_B6fs1",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/7somethingonsomethingbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=nppdW4nwk3J2biy6UDui6tNVAlBGkL6X9nBzLuXstvh20P0qlEjZSYv5PaW2AdfBMBmbkwxL4fX0UhsjOPz4hbiwsgvcfpoVuZs411ozwmlzBu78VF%2Brri%2F83Qn9tPP9vSFQWbed8UbGltqQ9sFhgNstpaJBoMTPl4vxKbLzUCihpemgvg9T%2FGEztn5TjQyPS2vU7cfb5iGkpxqlnpUOrOsecm5TQ5BcvQNdb2KPecUkkzGhGwvWbDSiUyR09ZgclHMsNrxL9Z0%2F2oWG8Vc5ux0f2Lo7eiL14koWsdUNFyyVHbsnRgDL7846hJ87UWUuy660QQUoJEPwYdPdV2iPkQ%3D%3D",
    id: "So it's really sunny out here today. So I'm wearing my sunglasses. But if the clouds went over the sun, I could put my sunglasses on my head. We use the word on when we put things up here. You can wear a hat on your head. You can put your sunglasses on your head. When you put stuff up here, the phrase you use is on your head. So if it's sunny, I wear my sunglasses. If it clouds over, though, I can put them on my head.",
    story_id: 1356,
    word_count: 41,
    all_intersection_count: 25,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "-",
      "saw",
      "yesterday",
      "didn't",
      "really",
      "4",
      "o'clock",
      "were",
      "jogging",
      "through",
      "wasn't",
      "was",
      "tennis",
      "guess",
      "made",
      "mistake",
    ],
    words: [
      "-",
      "I",
      "saw",
      "you",
      "yesterday",
      "but",
      "didn't",
      "see",
      "me",
      "really",
      "when",
      "at",
      "about",
      "4",
      "o'clock",
      "were",
      "jogging",
      "through",
      "the",
      "park",
      "that",
      "wasn't",
      "was",
      "playing",
      "tennis",
      "guess",
      "made",
      "a",
      "mistake",
    ],
    link: "(2)https://www.youtube.com/watch?v=jkOXNxoMClA",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Past%20Continuous%202.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=lAgobq8ryMGCEUIBZDDHqPz7ebl4jbq%2B1K9nib3B5jPAzfWoMTRgt%2BC9rAUH%2BzlVJAnWOKDZKXLzq%2BufKSr0aZ%2B%2BTY9pEfRePClJlq2Vj1aCjgD2vfzF%2FBOSLyOHnuqhgu%2BxwI9Yglc1tBiJFlYT82KjmRbG117K16zzN9kuN9qyQ7jKUPROfK2lGTQZXe%2BvESOL%2BWuSiuMB57B5ETjYYXXr%2FfDnxRkfxL1QWxR62brLdCw0kcbZbpsTWaMCKyX8Tg55sghCFEf%2BiD%2BFn96CH9JlPfmxc6GAbKhhqo6%2BPek13RjIxW7D%2BIxQu1EY4q%2B%2FNtL%2BLq2tgfGk9KJBLGSUnQ%3D%3D",
    id: "- I saw you yesterday, but you didn't see me. Really?\n- When?\n- At about 4 o'clock. You were jogging through the park.\n- That wasn't me. Yesterday, at 4 o'clock, I was playing tennis.\n- I guess I made a mistake.",
    story_id: 967,
    word_count: 29,
    all_intersection_count: 13,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "lucky",
      "word",
      "action",
      "hammer",
      "nail",
      "drill",
      "hole",
      "board",
      "saw",
      "half",
      "variety",
      "also",
      "verbs",
      "could",
      "pound",
      "cut",
    ],
    words: [
      "sometimes",
      "in",
      "English",
      "you",
      "get",
      "lucky",
      "the",
      "name",
      "of",
      "thing",
      "you're",
      "talking",
      "about",
      "is",
      "same",
      "word",
      "as",
      "action",
      "for",
      "what",
      "do",
      "this",
      "a",
      "hammer",
      "I",
      "can",
      "use",
      "it",
      "to",
      "nail",
      "drill",
      "hole",
      "board",
      "saw",
      "half",
      "now",
      "if",
      "want",
      "some",
      "variety",
      "also",
      "other",
      "verbs",
      "could",
      "pound",
      "with",
      "make",
      "and",
      "cut",
    ],
    link: "https://www.youtube.com/shorts/fdFjUx8lJG8",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "Sometimes in English, you get lucky. Sometimes the name of the thing you're talking about is the same word as the action for what you do. This is a hammer. I can use it to hammer in this nail. This is a drill. I can use it to drill a hole in this board. This is a saw. I can use this to saw this board in half. Now, if you want some variety, you can also use other verbs. I could pound the nail in with the hammer. I could make a hole with the drill. And I could cut the board in half with this saw.",
    story_id: 1089,
    word_count: 49,
    all_intersection_count: 33,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "Maria",
      "found",
      "sweatband",
      "yours",
      "isn't",
      "mine",
      "might",
      "Jennifer's",
      "lost",
      "hers",
      "few",
      "days",
      "ago",
      "really",
      "I'll",
      "bye",
    ],
    words: [
      "Maria",
      "I",
      "just",
      "found",
      "this",
      "sweatband",
      "is",
      "it",
      "yours",
      "no",
      "isn't",
      "mine",
      "but",
      "you",
      "know",
      "think",
      "might",
      "be",
      "Jennifer's",
      "she",
      "lost",
      "hers",
      "a",
      "few",
      "days",
      "ago",
      "oh",
      "really",
      "I'll",
      "call",
      "her",
      "when",
      "get",
      "home",
      "see",
      "tomorrow",
      "bye",
    ],
    link: "(4)https://www.youtube.com/watch?v=PQCB85N52QE&list=PLD7AA7B1BC72ACC28&index=24",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/pronoun-4.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=sG23s%2FmlN6umlGUEBTHEogLALolEWKfVJw7LmwtH%2Bg%2B0H1kQRkDg2wvZWyX9w6NfUPODqc4lC%2BWIY7FoG6koZB3f3hD%2BK1sAcjwNB17w9uZpIQManexoDO1kK1owKPpaBcT2RYN1T1%2BBsUY%2BscYnY%2FvrWRl4d72FitNHbzoxjkr2mLNRVjnwbAGmJwew8GxEyQ3r36ViT8kQmrB35LW8TjZesUMmKSW0XsdVMw88hKRR%2FNPKE5%2FA1bZJ34ZkcvBjZIOz3Lz11Sho6Bqs48OtPQmNvioH1cOSYxlT37%2BLBu64vX8bUEP%2Bb0EQehiKqv3v8q3INWHV1H6%2BdpwBDvS2Gw%3D%3D",
    id: "Maria, I just found this sweatband. Is it yours?\nNo, it isn't mine. But, you know, I think it might be Jennifer's. She lost hers a few days ago.\nOh, really? I'll call her when I get home. See you tomorrow.\nBye.",
    story_id: 1071,
    word_count: 37,
    all_intersection_count: 21,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "pretty",
      "sure",
      "already",
      "verb",
      "also",
      "tiptoe",
      "really",
      "carefully",
      "quietly",
      "won't",
      "little",
      "kids",
      "will",
      "skip",
      "did",
      "earlier",
    ],
    words: [
      "I'm",
      "pretty",
      "sure",
      "you",
      "already",
      "know",
      "the",
      "verb",
      "to",
      "run",
      "and",
      "walk",
      "but",
      "we",
      "also",
      "have",
      "tiptoe",
      "when",
      "really",
      "carefully",
      "quietly",
      "so",
      "people",
      "won't",
      "hear",
      "sometimes",
      "little",
      "kids",
      "will",
      "skip",
      "it",
      "looks",
      "like",
      "this",
      "I",
      "did",
      "earlier",
    ],
    link: "(3)https://www.youtube.com/watch?v=vA-uEPEHU_M&list=PLQut38RVINERTw9It9-fkKY8jgNEJ5AHC",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/tiptoe.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=pJKybnicpf9fxqoX6oJYUn0El8Q27xCY3rZxHWF9RD5jh4ajaIkDsmneF3axKXPnmgGKyvwcFYROJV5WINYU5IjOMPVXsY7ZR0mBTaP4SadQpjEvBJ%2B3w%2Bc79gtFGAlRlqxfz%2BjesuNXkJrdYV0mC4wwG2o7MuP9z%2FjZcjAy%2FcnOpcs1G8iRFOVl%2BExE5X0Vocmn9rXhdz%2B0mTKgb3zgc1%2F%2BfjGo5tHpeykeTcFs6jf7rYXcte8HvPHd9MLVZSZh59hK7uFp7o8BadQTs%2Fob7PJFOlIDJb%2Bbq5fMS4aGZLSMNMlYt1lph8RsHZ22thXL778Phjafzk126IsYPy1mfg%3D%3D",
    id: "I'm pretty sure you already know the verb to run and to walk. But we also have the verb to tiptoe. When you tiptoe, you walk really carefully and quietly so people won't hear you. Sometimes little kids will also skip. It looks like this. I did it earlier.",
    story_id: 1114,
    word_count: 37,
    all_intersection_count: 21,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "Benny",
      "lenny",
      "Thomas",
      "adjectives",
      "act",
      "ready",
      "then",
      "begin",
      "nervous",
      "tired",
      "sick",
      "cold",
      "hot",
      "hungry",
      "thirsty",
      "angry",
    ],
    words: [
      "oh",
      "I",
      "remember",
      "you",
      "your",
      "name",
      "is",
      "Benny",
      "lenny",
      "Thomas",
      "alright",
      "I'm",
      "going",
      "to",
      "give",
      "some",
      "adjectives",
      "more",
      "yes",
      "and",
      "you're",
      "act",
      "them",
      "out",
      "okay",
      "ready",
      "then",
      "let's",
      "begin",
      "nervous",
      "sad",
      "happy",
      "tired",
      "sick",
      "cold",
      "hot",
      "hungry",
      "thirsty",
      "angry",
      "embarrassed",
    ],
    link: "(4)https://www.youtube.com/watch?v=vaq6pX7q15s&list=PL31D7CB49ABAAB4DB&index=18",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/adjectives.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ypeyvCMRj9%2FD1iIDam%2FnQirNEhDtQNV2O45dwf9MOzZGASM1MBHYWUxCe5ChGYPxjT2yS6YdS3xywSiYHoDyv%2FQm3Y4FtP2rC1Bns5VmFZ1ftRWqa67j4ITMYemT6uUdaQtUoludk8F8vzo7ZUME6Td054zo%2FXRUOrhAB1gT5HSHDZ1VjzOdjxR4PA9lswRCdr83BV%2BV14GeU0zZ9xdnA5ccPyqVYvRKfGi6MhTpcKbdq3K%2BFHQEfgJNbN1fcNTot1peT%2BO4D3gMMeR3V1173Y03cZbnlZLdloRo6i1N9hgchRo3AM%2FsG%2BiCIPLUW5%2F2dpZ9zbv%2B3B8OzwyWOEUqRw%3D%3D",
    id: "Oh, I remember you. Your name is Benny.\nLenny. Lenny Thomas.\nAlright, Lenny.\n\nI'm going to give you some adjectives.\nMore adjectives?\nYes. And you're going to act them out. Okay?\nYes. I'm ready.\nThen let's begin.\nNervous, sad, happy, tired, sick, cold, hot, hungry, thirsty, angry, embarrassed.",
    story_id: 992,
    word_count: 40,
    all_intersection_count: 24,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "really",
      "spring",
      "lay",
      "outside",
      "sun",
      "walter",
      "staying",
      "warm",
      "instead",
      "around",
      "stay",
      "winter",
      "walter's",
      "relaxing",
      "enjoying",
      "sunshine",
    ],
    words: [
      "the",
      "dogs",
      "really",
      "love",
      "spring",
      "it's",
      "a",
      "time",
      "where",
      "they",
      "can",
      "just",
      "lay",
      "outside",
      "in",
      "sun",
      "how",
      "you",
      "doing",
      "walter",
      "good",
      "enjoy",
      "staying",
      "warm",
      "instead",
      "of",
      "running",
      "around",
      "to",
      "stay",
      "that's",
      "usually",
      "what",
      "do",
      "winter",
      "but",
      "today",
      "it",
      "looks",
      "like",
      "walter's",
      "relaxing",
      "and",
      "enjoying",
      "sunshine",
    ],
    link: "(3)https://youtu.be/rodSOxxpvcE?si=9QPiUY4a7pLlQDmU",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/3learnenglishonabeautifulspringdaybob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=nzq%2FXp1F%2B2JryjlFQDIZdFgtm45JT3QrqC6kKjwuoIeHmLQxGx7B3Nl6MEDF55W%2B1c%2Fr0pf02OZyQwGIPdO3OTRkLrvNf3Mn072clyu7aYRJBWExClxb6xOk99BBVtEW607zCIsdth5zofavlXlV5Ga42D4SMiPwozzBL5BgtawZCBnJoXXOGgUvZTkJdpzmOtQ%2BZO6tAKzDa7JKhBVHC0L0NI1tzwcUpB%2BdUPrDQA5Ll%2BOwHxy6Fu1ZnB0dPhVDxtyNoTpJcuhgGn7eD%2FfXuMDmlDTsTX5NGcLi8burR9R7KIKlpYUkISTllAFckG2w2FUTYyC1fNQXw1TZ18yDzw%3D%3D",
    id: "The dogs really love spring. It's a time where they can just lay outside in the sun. How you doing, Walter? Good. They can just enjoy staying warm instead of running around to stay warm. That's usually what they do in the winter. But today it looks like Walter's just relaxing and enjoying the sunshine.",
    story_id: 1361,
    word_count: 45,
    all_intersection_count: 29,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "another",
      "way",
      "word",
      "charge",
      "classroom",
      "boss",
      "takes",
      "all",
      "responsibility",
      "jen",
      "farm",
      "small",
      "business",
      "director",
      "really",
      "decisions",
    ],
    words: [
      "another",
      "way",
      "we",
      "use",
      "the",
      "word",
      "running",
      "in",
      "English",
      "is",
      "to",
      "talk",
      "about",
      "someone",
      "who",
      "charge",
      "of",
      "something",
      "I",
      "work",
      "at",
      "a",
      "school",
      "I'm",
      "classroom",
      "but",
      "my",
      "boss",
      "he",
      "one",
      "takes",
      "all",
      "responsibility",
      "jen",
      "has",
      "flower",
      "farm",
      "she",
      "small",
      "business",
      "so",
      "it",
      "director",
      "or",
      "person",
      "really",
      "making",
      "decisions",
      "for",
      "our",
      "that",
    ],
    link: "(4)https://www. youtube.com/watch?v=g3GPNDe72UI",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4whenrunningisntrunningbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=QWvnp4JD0juDKg5nLBPwUuJruQC77Ax1z2HaRzk9sBzQNXa1DzBbfoeEZisU15f6fn9igljJI3gJfyRw5xy3Rr%2B8V993jFp%2FbREMtIshpmuMC1ImjylyDNGd1j1SeEL2wV%2FN8ei9fA%2FURl4qEs7swmq6lkgqNBK9MAl2ar37aDhglmAcJWOAq3BfzMyFUybeBddHbdjiLwdjBB44N1RCKFtNip6%2BJRxLt7w1NOWlDeiDM%2BqS3ftTnc8%2Bt7pQR3iSqbdlb91Q9kVPknE2fdW8g%2FVz3%2BxiX%2FWLHdQH7xmWCNTgHA7%2Buee1TLWAMozNQgumSsOaZpbqzRzeo3zaSBK1bg%3D%3D",
    id: "Another way we use the word running in English is to talk about someone who is in charge of something. I work at a school. I'm in charge of running the classroom. But my boss is in charge of running the school. He is the one who is in charge. He is the one who takes all the responsibility. Jen has a flower farm. She is running a small business. So another way we use the word running in English. We use it to talk about someone who is in charge of something. Someone who is the director or the boss. The person who is really making all the decisions for our farm. That person is Jen. She is running a small business.",
    story_id: 1231,
    word_count: 51,
    all_intersection_count: 35,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "these",
      "piles",
      "stone",
      "could",
      "beside",
      "each",
      "also",
      "next",
      "pile",
      "here's",
      "little",
      "bonus",
      "standing",
      "middle",
      "between",
      "then",
    ],
    words: [
      "so",
      "when",
      "you're",
      "talking",
      "about",
      "two",
      "things",
      "like",
      "these",
      "piles",
      "of",
      "stone",
      "you",
      "could",
      "say",
      "that",
      "they",
      "are",
      "beside",
      "each",
      "other",
      "also",
      "next",
      "to",
      "this",
      "pile",
      "is",
      "and",
      "here's",
      "a",
      "little",
      "bonus",
      "I'm",
      "standing",
      "in",
      "the",
      "middle",
      "between",
      "I",
      "can",
      "then",
    ],
    link: "(8)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/8prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Yv92EHiymGfATi%2FBT%2BWq%2FPaD%2FSYbQ%2BeXIlRQ1W%2FW2yHM4en%2BUaqRWfZ5RcLZbTil81Pkyg8iN88QVLe5k4sxanQp9cUL6p5uF2iJXtzXhmO2HV0gXWA%2BJlf7KROHbXVFdWa0yxz466lpteOA6NzbytNLf%2Bo6bq4fwq0kMR9mTWHtr%2Be%2FZ7nv4iApqJ12YFLgnZAqMc26c2cAacy3Yf1vbfQrjD%2BBh58ivhrL%2FUei1sF2s5COurzULra%2BjT4KgxzbuEPJBPp9%2FVg6ZzMPVUkzgXHwWbfyNEFHeC8oG%2FR2ZLLmIJjnmwfED8%2BMu4nTyiUytu58L7Httb52%2Fen2%2BG6H%2BA%3D%3D",
    id: "So when you're talking about two things like these two piles of stone, you could say that they are beside each other. You could also say that they are next to each other. This pile of stone is beside this pile of stone. This pile is next to this pile of stone. They are beside each other. They are next to each other. And here's a little bonus. I'm standing in the middle. I'm standing between this pile of stone and this pile of stone. I can then say that I'm in the middle.",
    story_id: 1263,
    word_count: 41,
    all_intersection_count: 25,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "also",
      "kind",
      "year",
      "lawn",
      "green",
      "up",
      "little",
      "bit",
      "throughout",
      "winter",
      "brown",
      "much",
      "life",
      "sunshine",
      "days",
      "warmer",
    ],
    words: [
      "it's",
      "also",
      "kind",
      "of",
      "nice",
      "at",
      "this",
      "time",
      "year",
      "because",
      "the",
      "lawn",
      "starts",
      "to",
      "green",
      "up",
      "a",
      "little",
      "bit",
      "throughout",
      "winter",
      "is",
      "brown",
      "and",
      "doesn't",
      "look",
      "like",
      "it",
      "has",
      "very",
      "much",
      "life",
      "in",
      "but",
      "as",
      "we",
      "get",
      "sunshine",
      "days",
      "are",
      "warmer",
    ],
    link: "(11)https://youtu.be/rodSOxxpvcE?si=9QPiUY4a7pLlQDmU",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/11learnenglishonabeautifulspringdaybob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=QGLjuORWiOeOLiQbkpUEb1HLwysEdw7%2FEGATe9eh%2BeNWTk5jqe2JyTCoOfzbEevAo%2FCY9UP7obHg%2Bl3BQbMfGZHLfVuRcwi7B%2FOEPAVP1IVxjxl035lBpw91m%2FrRnW1PoCbPBHWjP2H1bgbgYg8Csu6xRXnvxlOeLmnakinPqHy07TtXJOB47aJCDMPos4p%2BCJb8frA0V%2F3bsWjn7I94ROQY9VUx4EPitPWu9%2F%2BVm0aD%2BNPBzfPt%2FW1i6qhyqbHc%2BPEN9iBLFn%2B0rJr4Rsjg2MmnIte92hmzum8AdJO30h6csoE87%2BhQVeE4V5OC1XMuNyrXjdRVa%2F1rK0KCl1ojjg%3D%3D",
    id: "It's also kind of nice at this time of year because the lawn starts to green up a little bit throughout the winter. The lawn is kind of brown and doesn't look like it has very much life in it. But as we get a little bit of sunshine and the days are a bit warmer, the lawn starts to green up.",
    story_id: 1369,
    word_count: 41,
    all_intersection_count: 25,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "difference",
      "between",
      "trip",
      "slip",
      "was",
      "along",
      "there",
      "brick",
      "might",
      "into",
      "however",
      "ice",
      "slipped",
      "would",
      "verb",
      "slippery",
    ],
    words: [
      "let's",
      "talk",
      "about",
      "the",
      "difference",
      "between",
      "to",
      "trip",
      "and",
      "slip",
      "if",
      "I",
      "was",
      "walking",
      "along",
      "there",
      "something",
      "like",
      "this",
      "brick",
      "here",
      "might",
      "on",
      "it",
      "so",
      "when",
      "you",
      "means",
      "walk",
      "into",
      "however",
      "some",
      "ice",
      "slipped",
      "that",
      "would",
      "be",
      "how",
      "use",
      "verb",
      "you're",
      "slippery",
    ],
    link: "https://www.youtube.com/shorts/xcjk-SEc48s",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: 'Let\'s talk about the difference between "to trip" and "to slip". If I was walking along and there was something like this brick here, I might trip on it. So when you trip on something, it means you walk into it. If, however, there was some ice here and I was walking along and I slipped on it, that would be how you use the verb to slip. So "to trip" means you walk into something. "To slip" means you\'re on something slippery.',
    story_id: 1084,
    word_count: 42,
    all_intersection_count: 26,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "thought",
      "would",
      "come",
      "snowy",
      "spin",
      "versus",
      "tilt",
      "eventually",
      "direction",
      "spinning",
      "around",
      "camera",
      "forwards",
      "feet",
      "backwards",
      "sky",
      "dizzy",
    ],
    words: [
      "so",
      "I",
      "thought",
      "would",
      "come",
      "out",
      "here",
      "on",
      "this",
      "beautiful",
      "snowy",
      "day",
      "and",
      "talk",
      "about",
      "spin",
      "versus",
      "tilt",
      "if",
      "eventually",
      "you",
      "can",
      "see",
      "in",
      "every",
      "direction",
      "am",
      "spinning",
      "around",
      "but",
      "the",
      "camera",
      "forwards",
      "my",
      "feet",
      "backwards",
      "sky",
      "don't",
      "get",
      "dizzy",
    ],
    link: "https://www.youtube.com/shorts/5CSoxk5R5Ec",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "So I thought I would come out here on this beautiful, snowy day and talk about spin versus tilt. If I spin, eventually you can see in every direction I am spinning around. But if I tilt the camera forwards, eventually you can see my feet. And if I tilt the camera backwards, eventually you can see the sky. So if I spin, don't get dizzy. You can see in every direction. And if I tilt, you can see the sky, and eventually you can see my feet.",
    story_id: 1082,
    word_count: 40,
    all_intersection_count: 23,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "gym",
      "welcome",
      "lesson",
      "phrases",
      "describe",
      "regularly",
      "would",
      "shape",
      "fit",
      "exercise",
      "eventually",
      "body",
      "changes",
      "then",
      "in shape",
    ],
    words: [
      "let's",
      "learn",
      "English",
      "at",
      "the",
      "gym",
      "well",
      "welcome",
      "to",
      "this",
      "lesson",
      "I",
      "want",
      "start",
      "by",
      "teaching",
      "you",
      "two",
      "phrases",
      "that",
      "we",
      "use",
      "describe",
      "people",
      "who",
      "regularly",
      "go",
      "we",
      "would",
      "say",
      "they",
      "are",
      "in",
      "shape",
      "or",
      "fit",
      "if",
      "if",
      "exercise",
      "eventually",
      "your",
      "body",
      "changes",
      "and",
      "then",
      "saying",
      "in shape",
    ],
    link: "(1)8vuzfcVel6I",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "Let's learn English at the gym. Well, welcome to this English lesson at the gym. I want to start this lesson by teaching you two phrases that we use to describe people who regularly go to the gym. We would say that they are in shape or we would say that they are fit. If you go to the gym regularly, if you exercise regularly, eventually your body changes. And we would then describe you by saying that you are in shape or saying that you are fit.",
    story_id: 1477,
    word_count: 47,
    all_intersection_count: 30,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "-",
      "Ms",
      "carter",
      "Donald",
      "hopkins",
      "afraid",
      "won't",
      "cold",
      "better",
      "I'll",
      "come",
      "fine",
      "much",
      "hope",
      "soon",
      "goodbye",
      "bye",
    ],
    words: [
      "-",
      "Ms",
      "carter",
      "hello",
      "this",
      "is",
      "Donald",
      "hopkins",
      "good",
      "morning",
      "I'm",
      "afraid",
      "I",
      "won't",
      "be",
      "at",
      "work",
      "today",
      "have",
      "a",
      "very",
      "bad",
      "cold",
      "that's",
      "too",
      "sorry",
      "to",
      "hear",
      "that",
      "if",
      "feel",
      "better",
      "tomorrow",
      "I'll",
      "come",
      "don't",
      "probably",
      "go",
      "the",
      "doctor",
      "fine",
      "can't",
      "call",
      "you",
      "thank",
      "much",
      "hope",
      "soon",
      "goodbye",
      "bye",
    ],
    link: "https://www.youtube.com/watch?v=q6gilnxNTQY",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/If%201.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=J0GnCSZv4cjj0YHcaNz2wEvBlo7UU6H5odtHis8A2TigSZkymeFddkKFsxFOxq9bEwU9MsPb%2BBZRTAkvo3rS7CTopjGxX1lgLAAD5x0EZdzFkv6Y%2BAwKlaeZerPl%2Fi6Xa3Eg%2F2neqATCH4FU%2BrbF8BqkCLPkREB%2B7Xz5lBox5akm8OfP9gQIwQu4EclBk%2F6vevILWJbu2oo7XNAZPx7rDZozjjKro%2B9lSiBqYqPYUO2yBZ6Q17R6iP7dGSoRj%2BhpL%2Fm998COiW29oFHV7Czw3cblPQzC64KxR7v7rIX75uOlGIjt%2BseWTgYt8%2B%2BEB%2BroCo%2B9sOPITV12fU9asX2dgQ%3D%3D",
    id: "- Ms. Carter.\n- Hello, Ms. Carter. This is Donald Hopkins.\n- Good morning, Donald.\n- Ms. Carter, I'm afraid I won't be at work today. I have a very bad cold.\n- That's too bad, Donald. I'm sorry to hear that.\n- If I feel better tomorrow, I'll come to work. If I don't feel better, I'll probably go to the doctor.\n- That's fine, Donald.\n- If I can't come to work tomorrow, I'll call you.\n- Thank you very much, Donald. I hope you feel better soon.\n- Thank you, Ms. Carter. Goodbye.\n- Bye.",
    story_id: 963,
    word_count: 50,
    all_intersection_count: 33,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "put",
      "last",
      "fun",
      "colleagues",
      "co-workers",
      "end",
      "Friday",
      "might",
      "weekend",
      "monday",
      "workweek",
      "goes",
      "around",
      "430",
      "05:00",
      "would",
      "definitely",
    ],
    words: [
      "so",
      "I",
      "put",
      "this",
      "last",
      "one",
      "in",
      "here",
      "just",
      "for",
      "fun",
      "it's",
      "what",
      "to",
      "say",
      "your",
      "colleagues",
      "co-workers",
      "at",
      "the",
      "end",
      "of",
      "day",
      "on",
      "Friday",
      "you",
      "might",
      "two",
      "things",
      "have",
      "a",
      "good",
      "weekend",
      "or",
      "see",
      "monday",
      "if",
      "workweek",
      "goes",
      "from",
      "and",
      "around",
      "430",
      "05:00",
      "you're",
      "about",
      "go",
      "home",
      "would",
      "definitely",
    ],
    link: "(12)https://www.youtube.com/watch?v=yVWrxm6IU3w&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=4",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/12friday.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=pMHMoffOhK8Xg94Gn94RE7VvGGBWU84X3PC5Vim2V%2FspgyUyfW3ehSf7fjGzfLDzt8fSzJwg4zT%2BOd5RVqjulNRuWtZafnGuchcCQIteJt5%2Bp8ygoyfeczzljkDSylmrJKmztZkN9TeflFhU7OweWxAQg4LvbKkoH4UwxsOHqsrtiRONPSwgouE4d%2F611I6AoFD9nw537CaEzyFIxJYmVDvXPiEtpfAOsPCVcCtzdja5mgAEgloHmq16B8a0A%2Fs4e43LqMz9ztbyyLiKBreMIS2iLR1qGvFI1bmgteVYw%2BmTgYWS4PMlu7rww5g7Sa8ZsWlNSIpd7UAL0NTscy9TvQ%3D%3D",
    id: "So I put this last one in here just for fun. It's what to say to your colleagues, your co-workers at the end of the day on Friday, you might say one of two things. You might say, have a good weekend. Or you might say, see you on Monday. So if your workweek goes from Monday to Friday, and it's around 430 or 05:00 on Friday, and you're about to go home, you would definitely say, have a good weekend or see you on Monday.",
    story_id: 1157,
    word_count: 50,
    all_intersection_count: 33,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "next",
      "idiom",
      "wanted",
      "cracking",
      "started",
      "away",
      "got",
      "decided",
      "needed",
      "had",
      "lot",
      "little",
      "english",
      "lesson",
      "sit",
      "teachers",
      "much",
    ],
    words: [
      "the",
      "next",
      "idiom",
      "about",
      "work",
      "that",
      "I",
      "wanted",
      "to",
      "teach",
      "you",
      "is",
      "get",
      "cracking",
      "when",
      "need",
      "it",
      "means",
      "started",
      "on",
      "something",
      "right",
      "away",
      "got",
      "this",
      "morning",
      "decided",
      "needed",
      "had",
      "a",
      "lot",
      "of",
      "do",
      "good",
      "how",
      "are",
      "just",
      "making",
      "little",
      "english",
      "lesson",
      "here",
      "can",
      "say",
      "hi",
      "as",
      "walk",
      "by",
      "if",
      "want",
      "have",
      "day",
      "and",
      "sometimes",
      "sit",
      "drink",
      "cup",
      "tea",
      "with",
      "other",
      "teachers",
      "they",
      "coffee",
      "but",
      "too",
      "much",
    ],
    link: "(3)https://www.youtube.com/watch?v=hRA0cS2Xqck&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=31",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/3Learn8EnglishIdiomsYouCanUseAtWorkBob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=KQswv8mZHyHjSKCQlfZo1tECD5fO%2BdydL8VVOUIgEWYf1sBRz31tGyIzwI%2FjNSzso2RGDWdUkltGj0b59V%2FJakyRXEHMG%2BanN6nsRukeCTdxzy7hcLeW6sTbYplgZG7cxADjsg1w0SiwIclMd%2BuYYUgnXOViUpG8z8d5XTHeK1wOwkKEUUm%2Fp5zQiZ3AX8zfLrql7qoYTC8tAO%2B0GAzQYZdyME9PisgmEUVbpHlYnAJIs1A5GszF4iDcRKMbzxvYVPPm%2BJ8PL1aswhVlxHGljLbXicjJ3yh7w0EgUu2PSs8ihc5y3x9joNc55h0NEZNvYaqhpqpZX8nccxORBW0vSg%3D%3D",
    id: "The next idiom about work that I wanted to teach you is to get cracking. When you need to get cracking, it means you need to get started on something right away. When I got to work this morning, I decided I needed to get cracking. I had a lot of work to do. Good morning. How are you? Just making a little english lesson here. You can say hi as you walk by if you want. Have a good day. When I got to work, I decided I needed to get cracking. That means I had a lot of work to do and I wanted to get started. Started on it right away. Sometimes I sit and drink a cup of tea with the other teachers. They drink coffee, but I drink tea. But this morning I had too much to do. I needed to get started right away. I needed to get cracking.",
    story_id: 1436,
    word_count: 66,
    all_intersection_count: 49,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "above",
      "below",
      "pretend",
      "camera",
      "higher",
      "lower",
      "switch",
      "then",
      "would",
      "down",
      "up",
      "there",
      "another",
      "way",
      "describe",
      "ground",
      "balcony",
    ],
    words: [
      "so",
      "let's",
      "talk",
      "about",
      "above",
      "and",
      "below",
      "right",
      "now",
      "I",
      "am",
      "you",
      "if",
      "pretend",
      "that",
      "you're",
      "the",
      "camera",
      "are",
      "me",
      "higher",
      "than",
      "lower",
      "I'm",
      "but",
      "we",
      "switch",
      "then",
      "would",
      "say",
      "down",
      "here",
      "up",
      "there",
      "that's",
      "another",
      "way",
      "to",
      "describe",
      "it",
      "on",
      "ground",
      "balcony",
    ],
    link: "(3)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/3prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=YUYcdQ%2BRe7XsOTpwrXTxoy59ld2Hx8jRh4m0FV9ZAg3iVVobLwhNJ7m7LKT42kXXKLY61k5hCoSaxT16HaHDmtOCyRuvMJisYidk2O%2Bgj2R22YeUcw0UCsyVQZLPrqvoDfioT20TeKEZewm3ICwwYX0wacaXbgNgm9ffgqy6zzxZjOo3n%2B0vxhYwvnuyucbC7qGbgeiTVwCKmxKnivF1yb2TfxtVBNZ5e8Ww%2BSJ2DBTZbNp12ivSQ282yDoTRb8yWd9%2BNnlgHgCOtN6QClgYCQdt4WA1YBCTD9048hGtUqf4NB4fEAAyQf%2Bl8IzCNp5VTjFx9B%2FtVZLTgOH4nq8Zdg%3D%3D",
    id: "So let's talk about above and below. Right now, I am above you. If you pretend that you're the camera, you are below me. So I am above you. Right now, you are below me. I am higher than you. You are lower than me. So I'm above you and you're below me. But if we switch, then we would say that I'm below you and you're above me, I'm down here and you're up there. That's another way to describe it. So now that I am on the ground and you are up on the balcony, you are above me, I am below you, you are up there, and I am down here.",
    story_id: 1258,
    word_count: 43,
    all_intersection_count: 26,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "couple",
      "words",
      "action",
      "lesson",
      "being",
      "slide",
      "down",
      "hill",
      "slippery",
      "sure",
      "slip",
      "fall",
      "maybe",
      "did",
      "actually",
      "didn't",
      "whoa",
    ],
    words: [
      "okay",
      "just",
      "a",
      "couple",
      "of",
      "words",
      "for",
      "our",
      "English",
      "in",
      "action",
      "lesson",
      "today",
      "the",
      "being",
      "slide",
      "you",
      "can",
      "see",
      "me",
      "down",
      "hill",
      "I",
      "because",
      "it's",
      "slippery",
      "and",
      "I'm",
      "going",
      "to",
      "want",
      "make",
      "sure",
      "don't",
      "slip",
      "fall",
      "when",
      "out",
      "here",
      "should",
      "do",
      "that",
      "one",
      "more",
      "time",
      "maybe",
      "well",
      "did",
      "actually",
      "didn't",
      "whoa",
    ],
    link: "https://www.youtube.com/shorts/uPUeAtlKKQo",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "Okay, just a couple of words for our English in action lesson today. The words being \"slide\". You can see me slide down the hill. I can slide down the hill because it's slippery and I'm going to want to make sure I don't slip and fall when I'm out here. I should do that one more time, maybe. Well, I did actually slip and fall, didn't I? Whoa.",
    story_id: 1094,
    word_count: 51,
    all_intersection_count: 34,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "May",
      "wanna",
      "small",
      "radio",
      "you'll",
      "smaller",
      "afraid",
      "smallest",
      "anyway",
      "come",
      "again",
      "I'd",
      "cd",
      "player",
      "better",
      "best",
      "thanks",
    ],
    words: [
      "May",
      "I",
      "help",
      "you",
      "yes",
      "please",
      "wanna",
      "buy",
      "a",
      "small",
      "radio",
      "think",
      "you'll",
      "like",
      "this",
      "one",
      "it's",
      "very",
      "don't",
      "have",
      "smaller",
      "no",
      "I'm",
      "afraid",
      "not",
      "is",
      "the",
      "smallest",
      "we",
      "thank",
      "anyway",
      "sorry",
      "can't",
      "come",
      "again",
      "I'd",
      "to",
      "good",
      "cd",
      "player",
      "better",
      "best",
      "oh",
      "well",
      "thanks",
    ],
    link: "https://www.youtube.com/watch?v=vvunGPmEb24&list=PLD7AA7B1BC72ACC28&index=12",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/radio.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=W8%2FqNXonQwd2sH7yrwm%2Fmv3sGUd5kyiBjCIBLzep4mGufwbZSrvPCcyXdLpmZn2zFYChVW5UIKzIAkvf17%2FSgCGN4X7buaAJuiImTLD%2BpSwrMIDuoi%2BynO%2FWs24fpnMVfr1NkoeE2Nz3rUUSGJzJtENqc2Sdy8sdmSQqZjSebXLMlTEaOl9ObGw6HHp2Ot1ioGtOZhaKbFBKYeKYjfMYa4Z9mwp6iBT5xZMxOPXkqxBNJVnct50norsi91AVMV%2F6uvIPgplAP4LAKS5lqaEwSAy4WNzF9%2BFid7zIq5gny%2FaZKYEc6ZdOx0PMsSL3GnHZyGPf8pxKI6wsqMOMgwitLA%3D%3D",
    id: "May I help you?\nYes, please. I wanna buy a small radio.\nI think you'll like this one. It's very small.\nDon't you have a smaller one?\nNo, I'm afraid not. This is the smallest one we have.\nThank you, anyway.\nSorry, we can't help you. Please come again.\n\nMay I help you?\nYes, please. I'd like to buy a good CD player.\nI think you'll like this one. It's very good.\nDon't you have a better one? No, I'm afraid not. This is the best one we have.\nOh, well. Thanks anyway.\nSorry, we can't help you. Please come again.",
    story_id: 1037,
    word_count: 45,
    all_intersection_count: 28,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "next",
      "computer",
      "check",
      "email",
      "news",
      "also",
      "spend",
      "little",
      "bit",
      "checking",
      "social",
      "media",
      "accounts",
      "Facebook",
      "Instagram",
      "tiktok",
      "much",
    ],
    words: [
      "the",
      "next",
      "thing",
      "I",
      "do",
      "is",
      "use",
      "my",
      "computer",
      "to",
      "check",
      "email",
      "and",
      "read",
      "news",
      "like",
      "every",
      "morning",
      "also",
      "spend",
      "a",
      "little",
      "bit",
      "of",
      "time",
      "checking",
      "social",
      "media",
      "accounts",
      "Facebook",
      "Instagram",
      "tiktok",
      "probably",
      "too",
      "much",
      "doing",
      "that",
    ],
    link: "(3)ecF1y2bI2T4",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: ", the next thing I do is I use my computer to check my email and to read the news. I like to check my email every morning and I like to read the news every morning. I also spend a little bit of time checking my social media accounts like Facebook and Instagram and TikTok. I probably spend a little bit too much time doing that.",
    story_id: 1580,
    word_count: 37,
    all_intersection_count: 20,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "lot",
      "jewelry",
      "wedding",
      "ring",
      "finger",
      "mentioned",
      "earlier",
      "necklace",
      "around",
      "wearing",
      "word",
      "makes",
      "uneasy",
      "having",
      "was",
      "liked",
      "would",
    ],
    words: [
      "so",
      "I",
      "don't",
      "wear",
      "a",
      "lot",
      "of",
      "jewelry",
      "wedding",
      "ring",
      "on",
      "my",
      "finger",
      "like",
      "mentioned",
      "earlier",
      "but",
      "necklace",
      "around",
      "neck",
      "when",
      "we",
      "talk",
      "about",
      "wearing",
      "use",
      "the",
      "word",
      "you",
      "your",
      "because",
      "know",
      "it",
      "just",
      "makes",
      "me",
      "feel",
      "uneasy",
      "having",
      "something",
      "that's",
      "if",
      "was",
      "someone",
      "who",
      "liked",
      "would",
    ],
    link: "(8)https://youtu.be/O36OX5eg9cA?si=IYZ0t9JSSd_B6fs1",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/8somethingonsomethingbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=fvWjEpjC%2FSkoQBY8khv89r7fMZs28AIcLcTdJTh%2FZ2iaYpEFRT6thyQVCY1TtcJKDnEGyRXN8A6BQmXNsJNlL9BO%2FtGg%2F1WNatztFgq%2BEcMIyRQBLLamxqgi0on4uy%2BTfVtNbuQoOn5CFGUy22t9ImV0kW4RkXWgdaUjvXUv0B6L3cLOHpx12%2FNwbzh2dlKXYdv8awFZuQFowo9TcsCqFm7%2BuBiTwPfXdGOneNr3Mu9pYJIYJGVhmnO0VDtmsvcVQ1wcrvE5FVdFpbDU380K1n9VuoIuxiCcePhg3fXNOCrX9ztM%2FfBaOBTeJDmAfjCrXcBKdj%2BZcyyLHoG6aThYVw%3D%3D",
    id: "So I don't wear a lot of jewelry. I wear a wedding ring on my finger, like I mentioned earlier, but I don't wear a necklace around my neck. When we talk about wearing a necklace, we use the word around. You wear a necklace around your neck? I don't wear a necklace around my neck because. I don't know, it just makes me feel uneasy. I don't like having something around my neck. So that's the word you use around. If I was someone who liked wearing a necklace, I would wear it around my neck.",
    story_id: 1357,
    word_count: 47,
    all_intersection_count: 30,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "grandma",
      "Jimmy",
      "math",
      "certainly",
      "difficult",
      "myself",
      "I'd",
      "take",
      "yourself",
      "first",
      "you've",
      "got",
      "then",
      "gee",
      "terrific",
      "thanks",
      "pleasure",
    ],
    words: [
      "grandma",
      "yes",
      "Jimmy",
      "I",
      "need",
      "some",
      "help",
      "with",
      "my",
      "math",
      "homework",
      "can",
      "you",
      "me",
      "certainly",
      "problem",
      "number",
      "four",
      "is",
      "very",
      "difficult",
      "can't",
      "do",
      "it",
      "myself",
      "want",
      "to",
      "I'd",
      "be",
      "happy",
      "let's",
      "take",
      "a",
      "look",
      "know",
      "what",
      "by",
      "yourself",
      "here",
      "first",
      "you've",
      "got",
      "this",
      "and",
      "then",
      "oh",
      "now",
      "get",
      "gee",
      "you're",
      "terrific",
      "thanks",
      "it's",
      "pleasure",
    ],
    link: "(2)https://www.youtube.com/watch?v=PQCB85N52QE&list=PLD7AA7B1BC72ACC28&index=24",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/pronoun-2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=H0qkcTKnY23S2cR5%2FQHl8f1TH9ploPDs%2BabV8prdpsVWXIH5DfxwycA4lYXq16Z%2BWU%2BGcqrcK4OTDj9UdfCgBtaSkj%2B6hzW2uW8P48Wd0Mzhlvm8D%2B4kI4x8o4lRXQYYavIQ%2BWe%2F305aBx09q1BQuZSaYnoNgr9hxWmcQa8hbzUghA%2BKsWUYBxGxsOVUfyWW3cdmCSjl8JRCfN0SR5Quyn2n63n%2Feo5yTsLwpynZXPk4lSrh%2FvdswP62BMmy5nn%2FLNff%2BtkCBK%2BCZNJCNuxitQy2yRLHDhOv09RVgHVDVs0lkkwt9UebzS93i9LzN8uJxDYLeOejpWdPswSXv2gx8A%3D%3D",
    id: "Grandma?\nYes, Jimmy?\nI need some help with my math homework. Can you help me?\nCertainly, Jimmy.\nProblem number four is very difficult. I can't do it myself.\nDo you want me to help you? I'd be happy to. Let's take a look. You know what, Jimmy? You can do problem number four by yourself.\nI can?\nYes, you can. Here, look. First you've got to do this, and then you can do this.\nOh, now I get it, grandma. Gee, you're terrific. Thanks.\nIt's my pleasure, Jimmy.",
    story_id: 1069,
    word_count: 54,
    all_intersection_count: 37,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "which",
      "take",
      "beach",
      "wanna",
      "ours",
      "yours",
      "theirs",
      "much",
      "comfortable",
      "larger",
      "matter",
      "we'll",
      "pick",
      "up",
      "10",
      "minutes",
      "bye",
    ],
    words: [
      "which",
      "car",
      "should",
      "we",
      "take",
      "to",
      "the",
      "beach",
      "do",
      "you",
      "wanna",
      "ours",
      "or",
      "yours",
      "what",
      "they",
      "want",
      "know",
      "theirs",
      "let's",
      "go",
      "in",
      "is",
      "much",
      "more",
      "comfortable",
      "but",
      "larger",
      "oh",
      "well",
      "it",
      "doesn't",
      "matter",
      "me",
      "us",
      "than",
      "okay",
      "we'll",
      "pick",
      "up",
      "10",
      "minutes",
      "bye",
    ],
    link: "(5)https://www.youtube.com/watch?v=1dW7V33qLwA&list=PLD7AA7B1BC72ACC28&index=10",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/car.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=iPooFJxWoJFBOT%2Fnzp2bGR53yH78lSQs80m7NgfPAEZGfZ7fqX5wVaNomK%2Bupj5X%2Ffxj8xUCHjXJmJPUjHBLHZ%2Bf3sX%2B72mt%2ByZ%2FrvElUnnwgkeV12HNo4oD1g9wQxEWdNuZd5c4rwUMkrZyXjnG62Gn4rhFMFqub8II9jZzwBfBb64h3zVMEvpDbnJaBHiR5mYMsvpwSPEq0fZKVlxLssILUat2Aewn81IaA9ZJTdN8Hof7HxGbbKw83sDAQjChrHZiQnZ5BrZF1FXZ5Dv3uphoKbEitKCIfpd6VsM4zPdRs09v2y5khyqjfjlI1Nt2ZrudbqDqTNk4OUTVxxNJrQ%3D%3D",
    id: "Which car should we take to the beach? Do you wanna take ours or do you wanna take yours?\nWhat do they want to know?\nThey wanna know which car we wanna take to the beach, theirs or ours.\nLet's go in theirs. Theirs is much more comfortable.\nBut ours is larger.\nOh, well, it doesn't matter to me.\nIt doesn't matter to us. Yours is more comfortable than ours, but ours is larger than yours.\nLet's take yours.\nOkay. We'll pick you up in 10 minutes. Bye.\nBye.",
    story_id: 1044,
    word_count: 43,
    all_intersection_count: 26,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "everything",
      "wonderful",
      "Tim",
      "Jennifer",
      "glad",
      "invited",
      "dinner",
      "pleasure",
      "would",
      "meatballs",
      "I'd",
      "many",
      "few",
      "goodness",
      "guess",
      "isn't",
      "really",
    ],
    words: [
      "everything",
      "looks",
      "wonderful",
      "Tim",
      "thank",
      "you",
      "Jennifer",
      "I'm",
      "so",
      "glad",
      "invited",
      "me",
      "for",
      "dinner",
      "my",
      "pleasure",
      "would",
      "like",
      "some",
      "meatballs",
      "oh",
      "I'd",
      "love",
      "but",
      "not",
      "too",
      "many",
      "just",
      "a",
      "few",
      "alright",
      "here",
      "are",
      "goodness",
      "sorry",
      "I",
      "guess",
      "that's",
      "well",
      "it",
      "isn't",
      "really",
      "embarrassed",
      "okay",
      "don't",
      "worry",
      "about",
    ],
    link: "(2)https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/muchmany-2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=MztFgze8J6yawiKAvSnxYRaoKCC8RKjq8rrJ33cYRE22fViPiNwB1tKU37Bx7PDTMoJvdpg%2Bzr2OCoZvQDwXvq4ATqH5M4fOambnwkkaoVdknvxqkT7Kmc7afUKaN%2F4QNndKyUFq0%2FZ8u82ny%2FD8ZwiNI37PjMOSa3pbns8fU8DOremIwmgzpH5hExCgb%2FyTdLewtmVZsbdq%2BNPzKq6PUyx0XROKEAEjR%2BZu2yRbSjH4JisMybKTm9vGLDmQPJZTQ1qjSx4IbfrtvwWOXJmqmJXyg9hdJe%2FAZUTgbIq%2BeTsvH03f4Lnm01vF5PFRjDucLgLW4OBHXNBe5tw%2BSKKTUA%3D%3D",
    id: "Everything looks wonderful, Tim.\nThank you, Jennifer.\nI'm so glad you invited me for dinner.\nMy pleasure.\nWould you like some meatballs?\nOh, I'd love some, but not too many. Just a few.\nAlright. Here you are. Oh my goodness. I'm so sorry. I guess that's too many meatballs.\nWell, it isn't a few.\nI'm really sorry, Jennifer. I'm so embarrassed.\nThat's okay, Tim. Don't worry about it.",
    story_id: 1017,
    word_count: 47,
    all_intersection_count: 30,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "peonies",
      "starting",
      "pop",
      "ground",
      "kind",
      "cool",
      "come",
      "up",
      "red",
      "then",
      "slowly",
      "turn",
      "green",
      "obviously",
      "warm",
      "enough",
      "growing",
    ],
    words: [
      "the",
      "peonies",
      "are",
      "just",
      "starting",
      "to",
      "pop",
      "out",
      "of",
      "ground",
      "as",
      "well",
      "they're",
      "always",
      "kind",
      "cool",
      "because",
      "they",
      "come",
      "up",
      "red",
      "but",
      "then",
      "do",
      "slowly",
      "turn",
      "green",
      "so",
      "obviously",
      "it's",
      "warm",
      "enough",
      "for",
      "them",
      "start",
      "growing",
    ],
    link: "(9)https://youtu.be/rodSOxxpvcE?si=9QPiUY4a7pLlQDmU",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/9learnenglishonabeautifulspringdaybob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=TYvgwCMdrCooiXbzEkquAGmjzjKAP%2Bj1kyTnS9xTgDTQaWqy2DJWLGHxuWkfJoEpz%2FTfyyW0OdfeGlkyiA0BEKMBaoNpidpO7VJHhyyBniv223lVZei7lqHS%2F92ZLxEi33dvGdo2Tnjc6hTQREDqAeylDmmSQRpfPRdTVGI7MnC%2FzYgpsJ2Z0WdwjmKTO3YNb3GEO0vAUbHJxSSYFRudd8sIfe3gD8Zdd570k6dpQWNA6%2FhGdZxXltpwZ4NLF4dQW3xwsA1kOKzGI14xDHxw9muCSYtVdSFecOSXWo9G6af1FmR4M71MJrdOpybWP5TwvcIBP2Xq9gczcZLBI00E3Q%3D%3D",
    id: "The peonies are just starting to pop out of the ground as well. They're always kind of cool because they come up red, but then they do slowly turn green, so obviously it's warm enough for them to start growing.",
    story_id: 1367,
    word_count: 36,
    all_intersection_count: 19,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "-",
      "excuse",
      "jacket",
      "guess",
      "made",
      "mistake",
      "those",
      "gloves",
      "these",
      "isn't",
      "sure",
      "brown",
      "black",
      "boots",
      "aren't",
      "dirty",
      "clean",
    ],
    words: [
      "-",
      "excuse",
      "me",
      "I",
      "think",
      "that's",
      "my",
      "jacket",
      "don't",
      "so",
      "this",
      "is",
      "oh",
      "you're",
      "right",
      "guess",
      "made",
      "a",
      "mistake",
      "those",
      "are",
      "gloves",
      "these",
      "your",
      "umbrella",
      "no",
      "it",
      "isn't",
      "you",
      "sure",
      "yes",
      "that",
      "brown",
      "and",
      "black",
      "let",
      "see",
      "boots",
      "they",
      "aren't",
      "dirty",
      "clean",
    ],
    link: "https://www.youtube.com/watch?v=J3f53a-cmcs&list=PL31D7CB49ABAAB4DB&index=12",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/clothing.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=C6%2BB%2Bikf0GLeXOOuYJ0yjBlS6AJkrLukpzBSZadSe0Z76BD70hkc37v77Bw0MapJB7onswMLDOjeb7EmbnT9116e7qVJtatWCZSRA78CmsXm%2FNEFKIyqOtc9wKAFYqx0tgx6wcDdekyUlHKQJPvNQDRKhNfmCb6RVO0PajAU8hbA2FNTStMRw5L%2FK9RCfMIIKKdnpNTlzxrHJNUiMXvxxzI6p1JWP7qFHBnlpnUZS54IsocwtMDnc08Zj%2Ba9pTn48%2BX2suxJwQtTTZcUcxbP5xhlnSgtbrHg4%2FPB4Bdr285iu4nL1Df0V1iZFJwqQd7OoHYsit%2F6SPU8dPAwaW7zqQ%3D%3D",
    id: "- Excuse me. I think that's my jacket.\n- I don't think so. I think this is my jacket.\n- Oh, you're right. I guess I made a mistake.\n\n- Excuse me. I think those are my gloves.\n- I don't think so. I think these are my gloves.\n- Oh, you're right. I guess I made a mistake.\n\n- Is this your umbrella?\n- No, it isn't.\n- Are you sure?\n- Yes. That umbrella is brown, and my umbrella is black.\n- Let me see.\n\n- Are these your boots?\n- No, they aren't.\n- Are you sure?\n- Yes, those boots are dirty, and my boots are clean.",
    story_id: 977,
    word_count: 42,
    all_intersection_count: 25,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "next",
      "phrase",
      "describe",
      "rolling",
      "maybe",
      "really",
      "rich",
      "simply",
      "wonder",
      "again",
      "pay",
      "bills",
      "life",
      "certainly",
      "extremely",
      "could",
      "loaded",
    ],
    words: [
      "so",
      "this",
      "next",
      "phrase",
      "does",
      "not",
      "describe",
      "me",
      "it's",
      "the",
      "to",
      "be",
      "rolling",
      "in",
      "it",
      "or",
      "money",
      "maybe",
      "you",
      "know",
      "someone",
      "who's",
      "really",
      "rich",
      "who",
      "is",
      "they",
      "are",
      "simply",
      "means",
      "that",
      "have",
      "more",
      "than",
      "what",
      "do",
      "with",
      "I",
      "wonder",
      "that's",
      "like",
      "again",
      "am",
      "doing",
      "okay",
      "can",
      "pay",
      "my",
      "bills",
      "live",
      "a",
      "very",
      "nice",
      "life",
      "but",
      "I'm",
      "certainly",
      "if",
      "extremely",
      "could",
      "say",
      "they're",
      "loaded",
    ],
    link: "(3)https://www.youtube.com/watch?v=DYpqs413zB8",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/rolling.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=sZMTo1br0tOab36KOEpZ1VRYtIATevwGB80DwfQzzKZciTR%2BZ%2BPH8BW23P0CcB%2FHDTWpda2Mg3qJ593F6p7urGouae%2Bc4La8Pjyv%2B74WKlx%2BW7mwQAkYQbuLCJbzkdPQkJx8nM30X0uCGAi5J3XZxNsnuhVBW7mP5FKbww2Fqlz8zejHI0QfuXi0tYREaxi24BF9pUWrmfNmoOp6fOM%2B4Ffelm2Ji6p6p8e6Zvj53sA382c9hdz%2B%2Fwl0BgUGHRcsSDHbynkEfpup8Zr6ddIDx5O4bDnfVYu2wDsuPz3zDT9AmzI0Cq7GbRo3SlOz57D9IHDSV9ZhoF7KJSXyR2I1ng%3D%3D",
    id: "So this next phrase does not describe me. It's the phrase to be rolling in it or to be rolling in money. Maybe you know someone who's really, really rich. Maybe you know someone who is rolling in it. They are rolling in money. This simply means that they have more money than they know what to do with. I really wonder what that's like. Again, I am not rolling in it. I am doing okay. I can pay my bills. I live a very nice life, but I'm certainly not rolling in it. So if you know someone who's extremely rich, you could say they're loaded. Or you could say they're rolling in it.",
    story_id: 1101,
    word_count: 63,
    all_intersection_count: 46,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "having",
      "problems",
      "I'll",
      "glad",
      "come",
      "over",
      "5",
      "o'clock",
      "afraid",
      "won't",
      "baseball",
      "6",
      "able",
      "dinner",
      "7",
      "fine",
      "then",
    ],
    words: [
      "I'm",
      "having",
      "some",
      "problems",
      "with",
      "the",
      "homework",
      "for",
      "tomorrow",
      "I'll",
      "be",
      "glad",
      "to",
      "help",
      "when",
      "can",
      "you",
      "come",
      "over",
      "I",
      "at",
      "5",
      "o'clock",
      "is",
      "that",
      "okay",
      "afraid",
      "won't",
      "home",
      "playing",
      "baseball",
      "how",
      "about",
      "6",
      "no",
      "able",
      "dinner",
      "7",
      "fine",
      "see",
      "then",
    ],
    link: "(2)https://www.youtube.com/watch?v=R4FIFAVDrE8&list=PLD7AA7B1BC72ACC28&index=22",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/future3.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=SHs3ZqvaAnLNpVVBYct0ZhqidSkQ4PU3TBkXpsgnh9ZDTZhR1WIi17lZkOcA8vt81zhrAdmIJ9zKeLrOVrpYfMdRRNwOa3eX3%2FQGcUQxpt7dRFD20VTZ19gzFtnj6eVFUK3fIzV49MoZtNvHMNb4fwoUMXomUemXj32Lt8kxA7jfkFCv233kANOlIxiXyaL1b6n8unZPLDYM691G1gre4%2FcsYHXkkxCILICZjG5yBNw6KjUD%2Bp8dbXNKkdhOrYqW%2BH9c9X2JpXFgNijE8auTPhTPhFuv6k1RwDxcp44%2BPLCp1uALHgFiCLxY2mEoJetfig%2BmE9f7BJKd9fKWKXAukw%3D%3D",
    id: "I'm having some problems with the homework for tomorrow.\nI'll be glad to help. When can you come over?\nI can come over at 5 o'clock. Is that okay?\nI'm afraid I won't be home at 5 o'clock. I'll be playing baseball.\nHow about 6 o'clock?\nNo, I won't be able to come over at 6 o'clock. I'll be having dinner.\nHow about 7 o'clock?\nFine. I'll see you then.",
    story_id: 1063,
    word_count: 41,
    all_intersection_count: 24,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "another",
      "way",
      "might",
      "spend",
      "little",
      "bit",
      "yourself",
      "sit",
      "couch",
      "computer",
      "game",
      "different",
      "family",
      "spent",
      "all",
      "kinds",
      "whereas",
    ],
    words: [
      "another",
      "way",
      "you",
      "might",
      "spend",
      "time",
      "on",
      "a",
      "day",
      "off",
      "is",
      "with",
      "little",
      "bit",
      "of",
      "me",
      "when",
      "say",
      "that",
      "need",
      "some",
      "it",
      "means",
      "to",
      "yourself",
      "sometimes",
      "I",
      "just",
      "sit",
      "couch",
      "and",
      "read",
      "book",
      "play",
      "computer",
      "game",
      "where",
      "I'm",
      "not",
      "talking",
      "other",
      "people",
      "so",
      "it's",
      "different",
      "than",
      "family",
      "spent",
      "all",
      "kinds",
      "in",
      "your",
      "whereas",
      "doing",
      "something",
      "enjoy",
    ],
    link: "(4)https://www.youtube.com/watch?v=yW-YeEc_ULg&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=21",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4dayoffbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jAAGndZ2RZQS5DLgtsVbIpfxWpqMSbp9Jryb2YFCuvtgdTL%2FFlofdTEiobEA5DAmrRf3dT0NWUGBRasKfD4XzkSUc0YdhI8W6yQjre46EPWLS33p4Koy%2B5YWVk8i79vAz%2FkXrimIlVJtbkxpolpEiAMVkBk7fxUq9OGuitx16qcJix7lgVxJ5MQueuvpcJz5UfIDHweJze0YHffOoZJn5vSRo9QqBvO99SJfEzhYBvOUGYxuNp1439uSE8IbCH5ZCNa6B7Qvx1MEKq%2FAABp5QgpqP1ZhB1vnjQUMh7IM4VMp8xYRrvfEHTPgmAQzm5IBDle5D3F%2F3d0%2F8JmzGfUMsg%3D%3D",
    id: "Another way you might spend time on a day off is with a little bit of me time. When you say that you need some me time, it means you need some time to yourself. Sometimes I need a little bit of me time. I just sit on a couch and I read a book. Sometimes I just play a computer game. I need some time where I'm not talking to other people. I need a little bit of me time. So it's a little different than family time. Family time is spent with all kinds of people in your family. Whereas me time, you spend time just with yourself doing something you enjoy doing.",
    story_id: 1394,
    word_count: 56,
    all_intersection_count: 39,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "10th",
      "mistake",
      "english",
      "spend",
      "lot",
      "avoid",
      "really",
      "forget",
      "also",
      "writing",
      "having",
      "conversations",
      "vocabulary",
      "little",
      "bit",
      "grammar",
      "only",
    ],
    words: [
      "a",
      "10th",
      "mistake",
      "that",
      "people",
      "sometimes",
      "make",
      "when",
      "learning",
      "the",
      "english",
      "language",
      "is",
      "they",
      "spend",
      "lot",
      "of",
      "time",
      "doing",
      "things",
      "like",
      "and",
      "avoid",
      "don't",
      "this",
      "you're",
      "if",
      "you",
      "are",
      "someone",
      "really",
      "likes",
      "reading",
      "in",
      "English",
      "or",
      "loves",
      "listening",
      "to",
      "music",
      "forget",
      "should",
      "also",
      "be",
      "some",
      "writing",
      "having",
      "conversations",
      "vocabulary",
      "studying",
      "little",
      "bit",
      "grammar",
      "as",
      "well",
      "so",
      "it's",
      "only",
      "do",
    ],
    link: "(10)https://www.youtube.com/watch?v=rwePYcJteE0",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/10tenmistakesthatenglishlearnersmakebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qq3meo8PHbPKqZpJ7RUEDd4hL7a5WE50Fh%2Fe73mblbXu8jOP1Ejw6ISlQ%2BpnVOAhklOL7%2BYs4otOqr3IIO5UAixw2pZ4EgXSpi3iSRCgHZ07y1eTqaE2yh2Dgbx%2F3xVlXr8bBDY8QiZqoAtE9ddedXX%2Fr7dGqj9EpQUKfL0epkvB5dp6BLXQwg9Mt5L6PpZsY6wxV0iKwZm9eA0p4sb0FWpavkESf04CNrToelhEdzMZyKhOb6AKhkOjn3UWtMVUKueF%2Bu1aTlJFqcJj7BDHGAAF2VuhOIehQhj3ikIBFjgvEt%2FYNQLiiFyBjWq1scFtjBy2faYxuHKRswOjqxhjpw%3D%3D",
    id: "A 10th mistake that people sometimes make when learning the english language is that they spend a lot of time doing the things they like and they avoid doing the things that they don't like. This is a mistake when you're learning a language. If you are someone that really likes reading in English or loves listening to music in English, don't forget that you should also be doing some writing, you should be having some english conversations, you should be learning some vocabulary and studying a little bit of grammar as well. So it's a mistake to only do the things you like.",
    story_id: 1304,
    word_count: 59,
    all_intersection_count: 42,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "hop",
      "lot",
      "up",
      "down",
      "blue",
      "mop",
      "red",
      "top",
      "bag",
      "little",
      "rag",
      "hot",
      "pot",
      "pop",
      "brown",
      "wag",
      "away",
    ],
    words: [
      "I",
      "like",
      "to",
      "hop",
      "a",
      "lot",
      "up",
      "down",
      "blue",
      "mop",
      "red",
      "top",
      "big",
      "bag",
      "little",
      "rag",
      "hot",
      "pot",
      "pop",
      "brown",
      "dog",
      "wag",
      "stop",
      "away",
    ],
    link: "(1)KVU8dFsBhtU",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "I like to hop. I hop a lot. I hop up. I hop down. I hop to a blue mop. I hop to a red top. I hop to a big bag. I hop to a little rag. I hop to a hot pot. Pop, pop, pop. I hop to a brown dog. Wag wag wag. Stop stop, stop. I hop away. Hop hop hop hop.",
    story_id: 1752,
    word_count: 24,
    all_intersection_count: 7,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "feeling",
      "really",
      "matter",
      "headache",
      "did",
      "drank",
      "all",
      "great",
      "wrong",
      "terrible",
      "backache",
      "sat",
      "desk",
      "awful",
      "sore",
      "throat",
      "sang",
      "glad",
    ],
    words: [
      "are",
      "you",
      "feeling",
      "okay",
      "not",
      "really",
      "what's",
      "the",
      "matter",
      "I",
      "have",
      "a",
      "headache",
      "that's",
      "too",
      "bad",
      "how",
      "did",
      "get",
      "it",
      "drank",
      "coffee",
      "all",
      "morning",
      "so",
      "great",
      "wrong",
      "terrible",
      "backache",
      "I'm",
      "sorry",
      "to",
      "hear",
      "that",
      "what",
      "do",
      "sat",
      "at",
      "my",
      "desk",
      "day",
      "today",
      "feel",
      "awful",
      "very",
      "sore",
      "throat",
      "sang",
      "and",
      "glad",
    ],
    link: "https://www.youtube.com/watch?v=xyvNu254A-Y&list=PL31D7CB49ABAAB4DB&index=24",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/pain.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=E7U4uiqgr%2BLyhJ9Go%2F3BVOHqbyt3GfnEbN7nGJmZt1%2FBNE9DPgcSuYsdepQgzwCOHskU1yeJRU9p3JoQpEYA19qr43prkb7iRaFkTBfmNHf3%2BdybgHYBmqPFh6u7FHrFFMNEz7bwVPUmSt%2FfUvXFLbudFoIzCgY%2FCxcv2xB5XA5jj8AUaJPFGTMSnXwG19u2zF9X2jvIXVEcEvoSJ2N42wrfqvRaZPADwZRX4U%2BgbHV1ASvyxVQGRM2n8%2BdjHydtpFeoDgmB7Np8fO1YYv9LxzDyBkgNZDcFwbEL0xB%2BqGmRQX041Ro0pq6blEBUNaxquGP3%2Bp8t6hKAM5JiCmWWQA%3D%3D",
    id: "Are you feeling okay?\nNot really.\nWhat's the matter?\nI have a headache.\nA headache? That's too bad. How did you get it?\nI drank coffee all morning.\n\nHow are you feeling?\nNot so great.\nWhat's wrong?\nI have a terrible backache.\nI'm sorry to hear that.\nWhat did you do?\nI sat at my desk all day today.\n\nHow do you feel today?\nAwful.\nWhat's the matter?\nI have a very bad sore throat.\nI'm sorry to hear that.\nHow did you get it?\nI sang all day today. And how do you feel today?\nI feel great.\nI'm glad to hear that.",
    story_id: 1009,
    word_count: 50,
    all_intersection_count: 32,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "there",
      "couple",
      "ways",
      "advertise",
      "movies",
      "create",
      "movie",
      "posters",
      "poster",
      "scary",
      "simply",
      "colorful",
      "piece",
      "paper",
      "goes",
      "wall",
      "advertises",
      "upcoming",
    ],
    words: [
      "so",
      "there",
      "are",
      "a",
      "couple",
      "of",
      "ways",
      "that",
      "they",
      "advertise",
      "movies",
      "one",
      "is",
      "create",
      "movie",
      "posters",
      "poster",
      "oh",
      "looks",
      "scary",
      "simply",
      "big",
      "colorful",
      "piece",
      "paper",
      "goes",
      "on",
      "the",
      "wall",
      "and",
      "advertises",
      "upcoming",
    ],
    link: "(5)https://www.youtube.com/watch?v=MqvCqskYor8&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=5",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/5moviesadvertize.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jFcOpHhBreaTccrYuY7IBEk7nlWwflIrNDzMk50CAivs145mIg0u5ho9F2GfOfNsWkPKO6uQA6SJpYVOrCRcrES37oLDWrAptjQQFZZXtqSmda6QiThjWs0Zq29QvlQbXsqK05RxJVC0N00Uyl7aeaWwS%2BUHhC44fLtSZEIqPakn%2BueG2gdvl3B7vM9ps9EF6njs5mstDf58GGzgwfRwxtf0dav0Qzoe6D2G9S4IKyFUF8f%2FfmxCwbQaAVIaHIYb%2B5MPBbvobwchOiqB9hfMEvyICC3xpNN1G8rhtOIlVHmbkYiq9qfyz8qMQ0J3nSkr6uQulwqO0QE9Et3u1k2kbw%3D%3D",
    id: "So there are a couple of ways that they advertise movies. One is that they create movie posters. A movie poster? Oh, that looks scary. A movie poster is simply a poster, a big, colorful piece of paper that goes on the wall, and that poster advertises upcoming movies.",
    story_id: 1163,
    word_count: 32,
    all_intersection_count: 14,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "clearly",
      "much",
      "possible",
      "yelling",
      "whispering",
      "mumbling",
      "even",
      "stutter",
      "trouble",
      "word",
      "slur",
      "words",
      "mumble",
      "stumble",
      "over",
      "really",
      "speak clearly",
      "stumble over their words",
    ],
    words: [
      "the",
      "one",
      "thing",
      "you",
      "want",
      "to",
      "do",
      "is",
      "speak",
      "clearly",
      "as",
      "much",
      "possible",
      "so",
      "we",
      "have",
      "yelling",
      "and",
      "whispering",
      "mumbling",
      "people",
      "sometimes",
      "even",
      "stutter",
      "that's",
      "when",
      "they",
      "trouble",
      "saying",
      "word",
      "slur",
      "their",
      "words",
      "mumble",
      "stumble",
      "over",
      "what",
      "really",
      "speak clearly",
      "stumble over their words",
    ],
    link: "(6)ktD2HkgRt4Y",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "The one thing you want to do is you want to speak clearly as much as possible. So we have yelling and whispering and mumbling and people sometimes even stutter. That's when they have trouble saying the word. Um, people slur their words, people mumble, uh, people stumble over their words. What you really want to do is you want to speak clearly.",
    story_id: 1532,
    word_count: 40,
    all_intersection_count: 22,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "jen",
      "asked",
      "put",
      "dolly",
      "wagon",
      "side",
      "will",
      "was",
      "though",
      "without",
      "mentioning",
      "would",
      "had",
      "said",
      "however",
      "left",
      "way",
      "over",
    ],
    words: [
      "so",
      "jen",
      "asked",
      "me",
      "to",
      "put",
      "this",
      "dolly",
      "the",
      "right",
      "of",
      "wagon",
      "is",
      "my",
      "side",
      "I",
      "will",
      "if",
      "was",
      "talk",
      "about",
      "where",
      "it",
      "though",
      "without",
      "mentioning",
      "would",
      "say",
      "on",
      "now",
      "had",
      "said",
      "however",
      "please",
      "left",
      "go",
      "way",
      "and",
      "over",
      "here",
      "have",
    ],
    link: "(5)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/5prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=tLmj1lzcnvzgGEYxasF48S2n%2BRlUMVzSYONdLX7J1%2FVTjyxW03QhuzZCM6P6DpKltCjVBqfO3tFWoIgrC9Ut4ybNKMueZDKhkpK5%2FtT5pJjlBklLgD0K5CiePO06WtcA%2FOnaO2UhWgvw4lSa6K8Mwa4rrR9eRNk5oCvcBxFEHO9Jtt0dqe1BdgtnyCQuTxZdYvUPqr5WIxt03nkBIldbSIXodATLQGbY08mO%2BOnpQbkA8PmoylnjMrLom1wqVrGb2SVvV2U6KIFY8p7BlfTNIHJkwlGjNX7ajvUcv%2F5Ptoh0FCeN0Tq5lP52bYgqn4qliDZ2aF7YYR%2FQpw12bOxl8g%3D%3D",
    id: "So Jen asked me to put this dolly to the right of the wagon. So this is my right side. I will put the dolly to the right of the wagon. If I was to talk about where it is, though, without mentioning the wagon, I would say, the dolly is on the right. I put the dolly to the right of the wagon. It is now on the right. If Jen had said, however, please put the dolly to the left of the wagon, I would go this way and I would put it over here. Now I have put the dolly to the left of the wagon. The dolly is on the left.",
    story_id: 1260,
    word_count: 41,
    all_intersection_count: 23,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "mentioned",
      "lot",
      "sports",
      "played",
      "night",
      "might",
      "wondering",
      "lights",
      "diamonds",
      "soccer",
      "fields",
      "little",
      "utility",
      "turn",
      "allows",
      "turn on",
      "turn off",
      "play at night",
    ],
    words: [
      "so",
      "I",
      "mentioned",
      "that",
      "a",
      "lot",
      "of",
      "our",
      "sports",
      "are",
      "played",
      "at",
      "night",
      "and",
      "you",
      "might",
      "be",
      "wondering",
      "how",
      "do",
      "people",
      "see",
      "to",
      "play",
      "well",
      "this",
      "park",
      "has",
      "lights",
      "one",
      "the",
      "ball",
      "diamonds",
      "soccer",
      "fields",
      "have",
      "is",
      "little",
      "utility",
      "building",
      "where",
      "they",
      "can",
      "turn",
      "on",
      "off",
      "what",
      "does",
      "it",
      "allows",
      "turn on",
      "turn off",
      "play at night",
    ],
    link: "(10)gxMIeHmm3Rg",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "So I mentioned that a lot of our sports are played at night. And you might be wondering, how do people see at night to play sports? Well, this park has lights, so one of the ball diamonds and one of the soccer fields have lights. This is the little utility building where they can turn the lights on and off. And what that does is it allows people to play sports at night.",
    story_id: 1544,
    word_count: 53,
    all_intersection_count: 35,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "bug",
      "little",
      "fell",
      "into",
      "hole",
      "dug",
      "said",
      "saw",
      "had",
      "will",
      "tug",
      "beg",
      "hard",
      "all",
      "gave",
      "pulled",
      "hug",
      "end",
    ],
    words: [
      "big",
      "bug",
      "and",
      "little",
      "one",
      "day",
      "a",
      "very",
      "fell",
      "into",
      "hole",
      "he",
      "dug",
      "help",
      "me",
      "said",
      "the",
      "I",
      "saw",
      "had",
      "will",
      "you",
      "give",
      "your",
      "leg",
      "tug",
      "please",
      "beg",
      "do",
      "not",
      "hard",
      "on",
      "my",
      "just",
      "that",
      "is",
      "all",
      "need",
      "gave",
      "pulled",
      "out",
      "thank",
      "hug",
      "end",
    ],
    link: "https://www.englishcentral.com/video/29852",
    type: "story",
    pdf_name: null,
    storage_link: null,
    id: 'Big bug and little bug.\nOne day, a very little bug fell into a hole he dug.\n"Help me, help me", said the bug.\n"I fell into a hole I dug."\nA big bug saw the little bug.\nHe saw the hole the bug had dug.\n"I will help you", said the big bug.\n"I will give your leg a tug."\nThe little bug said, "Please, I beg."\n"Do not tug hard on my leg."\n"Just give one tug, one little tug."\n"That is all I need, big bug."\nThe big bug gave the leg a tug.\nHe pulled out the little bug.\n"Thank you," said the little bug.\nHe gave the big bug one big hug.\nThe end.',
    story_id: 941,
    word_count: 44,
    all_intersection_count: 26,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "there's",
      "couple",
      "might",
      "garden",
      "pair",
      "gardening",
      "gloves",
      "protect",
      "also",
      "boots",
      "really",
      "dewy",
      "after",
      "rains",
      "wet",
      "feet",
      "stay",
      "dry",
    ],
    words: [
      "so",
      "there's",
      "a",
      "couple",
      "things",
      "you",
      "might",
      "wear",
      "when",
      "work",
      "in",
      "the",
      "garden",
      "pair",
      "of",
      "gardening",
      "gloves",
      "it's",
      "sometimes",
      "nice",
      "to",
      "protect",
      "your",
      "hands",
      "also",
      "boots",
      "really",
      "dewy",
      "out",
      "here",
      "this",
      "morning",
      "after",
      "it",
      "rains",
      "grass",
      "is",
      "wet",
      "as",
      "well",
      "and",
      "that",
      "feet",
      "stay",
      "dry",
    ],
    link: "(10)https://www.youtube.com/watch?v=Ug8YJCycq7g&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=35",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/10LetLearnEnglishintheGardenBob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=m3ZMOHuwr30jkVr2nuCf4qpScdK8kVsV8P93bxrhauek2rKPfTS3y7%2FROskDUcalcuMD94upzib7xQi9u4XefzMjXKbBO3L24b2BCyL3gf723aKUJ7ZBgQhDNEUPVh0DjEFpIzoCWiHY3C%2BykEP277JqvGzvKwNuMKxWTacVy5S87TWNBKd8%2BZJyQOGexLUF1Pej%2FwMGc2Fvp%2FVp07UKTRcw%2BVKYpV8DcGRGmHysJnvxFmAp9ei39B8XOjbgDXT0Pv9mbP3xZR7c%2BCDw7SjjyQGdXCNXqO%2ByBMHDdmylkt2Th0%2Fc1w2EC7LbTpw2OqRJyhEyx8DC0yu0LLCw6rPKWw%3D%3D",
    id: "So there's a couple things you might wear when you work in the garden. You might wear a pair of gardening gloves when you work in the garden, it's sometimes nice to protect your hands. You might also wear boots. It's really dewy out here this morning. Sometimes after it rains, the grass is wet as well. And it's nice to wear boots so that your feet stay dry.",
    story_id: 1465,
    word_count: 45,
    all_intersection_count: 27,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "fifth",
      "question",
      "ask",
      "did",
      "back",
      "great",
      "someone's",
      "trip",
      "went",
      "maybe",
      "away",
      "weekend",
      "could",
      "shows",
      "curious",
      "telling",
      "interested",
      "conversation",
    ],
    words: [
      "a",
      "fifth",
      "question",
      "you",
      "can",
      "ask",
      "is",
      "when",
      "did",
      "get",
      "back",
      "this",
      "great",
      "to",
      "someone's",
      "talking",
      "about",
      "trip",
      "that",
      "they",
      "just",
      "went",
      "on",
      "or",
      "maybe",
      "away",
      "for",
      "day",
      "the",
      "weekend",
      "could",
      "say",
      "it",
      "shows",
      "you're",
      "curious",
      "what",
      "they're",
      "telling",
      "interested",
      "in",
      "conversation",
      "so",
      "if",
      "someone",
    ],
    link: "(5)https://www.youtube.com/watch?v=9QzSVPhLKsM",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/510questionsyoumustknowbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jAWA06SBHPEfOpPFmaNviv3MulUCFpMwlaIcK9Luw9xxLRtFJMTDs3rrEqnmvxnuEAO5nbC1CBp6ZQXeagfbf5Mn2%2BNWQF1W0eTy0eYFZOtb0vfuVK2RY9cT%2FPNp6%2BUUM82JMDSGc0tEFjFnhc0f%2F4zXDK5dRF4WexC3dmfZzwBq27773oOuiAATq3LqoMFCZkyGCTGqklQX%2BSTlCuut2WHqos%2FzVTqGmfnPKFjJSaN5VowSt9mWdMA%2F9tzDC59oYOcW%2BpHjAoeMmCbyLVQraInGn7EVjps8fkAcG0xE%2F3%2FZhTx6xVCD%2FCVaaUjTHjPmLm9xBO5pIx9yZ0IbbkK7Ng%3D%3D",
    id: "A fifth question you can ask is, when did you get back? This is a great question to ask when someone's talking about a trip that they just went on or maybe they just went away for a day or for the weekend. You could say, when did you get back? It shows that you're curious about what they're telling you about. It shows that you're interested in what they're talking about in the conversation. So if someone is talking about a trip that they just went on, a great question to ask, when did you get that?",
    story_id: 1216,
    word_count: 45,
    all_intersection_count: 27,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "common",
      "ask",
      "questions",
      "using",
      "present",
      "continuous",
      "invert",
      "verb",
      "subject",
      "response",
      "would",
      "having",
      "fun",
      "question",
      "sure",
      "beginning",
      "sentence",
      "hope",
    ],
    words: [
      "in",
      "English",
      "it's",
      "very",
      "common",
      "to",
      "ask",
      "questions",
      "using",
      "the",
      "present",
      "continuous",
      "but",
      "when",
      "we",
      "do",
      "that",
      "usually",
      "invert",
      "be",
      "verb",
      "and",
      "subject",
      "so",
      "say",
      "things",
      "like",
      "this",
      "are",
      "you",
      "learning",
      "response",
      "would",
      "I'm",
      "having",
      "fun",
      "yes",
      "a",
      "question",
      "make",
      "sure",
      "remember",
      "beginning",
      "of",
      "sentence",
      "I",
      "hope",
    ],
    link: "(5)https://www.youtube.com/watch?v=rFdhrR6Dpco",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/5thepresentcontinuous.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=iuwrdUBGiWhSTkfQAr%2BSNp4w1epYUpKy0OdCZF9cV3v96QpQoeZzoff0AHU3F3sUSTApYKtOSIo3%2BmgCKUF9LvGW8ky7jXRKtbKYkczB7FeImyjBqk3EMuVeny0lAC%2FrLEuCjluphCNu8ay9FIwyQbQhf4XZ%2FQx88b0vcr%2BEcDyvH7QZcNlFFvpqCaLEBB6NCFkawpERlZZtciY7W4F4HtbYzLes1vU4a5wc4F12ke6xRGjQMw8dJVCz70o8dJb8jMBz2wGrqRsp%2Bttsew%2BBN%2BZIC1sOoE3ULGnioD4S8t9Q3E%2B9wAr8GwaEqZdOJDBwTqC6XlqUQKlzMO5ZzS%2Bs8g%3D%3D",
    id: "In English, it's very common to ask questions using the present continuous, but when we do that, we usually invert the to be verb and the subject. So we say things like this, are you learning English? The response would be, I'm learning English. Are you having fun? The response would be, yes, I'm having fun. So when you ask a question using the present continuous, make sure you remember to invert the beginning of the sentence. So I hope you are having fun. Are you having fun?",
    story_id: 1284,
    word_count: 47,
    all_intersection_count: 29,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "course",
      "tired",
      "up",
      "past",
      "Saturday",
      "was",
      "had",
      "got",
      "went",
      "mix",
      "extra",
      "son",
      "needed",
      "somewhere",
      "order",
      "bring",
      "actually",
      "both",
    ],
    words: [
      "so",
      "of",
      "course",
      "it's",
      "not",
      "just",
      "going",
      "to",
      "bed",
      "late",
      "that",
      "can",
      "make",
      "you",
      "tired",
      "sometimes",
      "have",
      "get",
      "up",
      "early",
      "in",
      "the",
      "morning",
      "this",
      "past",
      "Saturday",
      "I",
      "was",
      "had",
      "got",
      "went",
      "and",
      "a",
      "bad",
      "mix",
      "think",
      "that's",
      "why",
      "I'm",
      "extra",
      "my",
      "son",
      "needed",
      "be",
      "somewhere",
      "on",
      "order",
      "bring",
      "him",
      "where",
      "he",
      "go",
      "you're",
      "because",
      "too",
      "actually",
      "both",
    ],
    link: "(2)https://youtu.be/aElZ9Y81vFA?si=Nx1x_h_7RJn6iIBA",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/2howtotalkaboutbeingtiredbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=mNzmAzGXSnhsyBtyjSaMQDXycneWZAdAUqHh5sSOGambeNMiK9sXkn82JxtxerEjBazqN83EiIWcPH3HD4DQ8dk3r%2Ft%2FkOAtxGj3LhW5uhmEQvddFmKkZlyTLuFGzpTLUupKkv92px56LuWwukpuYSgcs3qcLBW5pNxcW0ZA9kL%2BepqGJbtUAcvRG6JHdcrJJ7%2B8cvsVZfHhXzkyBA95k4uWCrY5N72cIEecyFtCyYsehuQWkaZZZtjyBykFEKU8hUN%2B2rwEGUcFnPjqbnR04M4frl51ywQCSqxtRtny8jTXacJn%2BJY7i4o9s9sHMaq6RNNwZWT9BKitkxjatqg9iA%3D%3D",
    id: "So of course, it's not just going to bed late that can make you tired. Sometimes you have to get up early in the morning. This past Saturday, I was up early. This past Saturday, I had to get up early. This past Saturday, I got up early. I went to bed late and I got up early. It's a bad mix. So I think that's why I'm extra tired. My son needed to be somewhere, so I got up early. I had to get up early. I was up early on Saturday in order to bring him to where he needed to go. Sometimes you're tired because you go to bed too late. Sometimes you're tired because you have to get up early. And sometimes it's actually both.",
    story_id: 1309,
    word_count: 57,
    all_intersection_count: 39,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "there's",
      "lot",
      "will",
      "till",
      "10:00",
      "normally",
      "up",
      "around",
      "630",
      "730",
      "really",
      "much",
      "course",
      "longer",
      "alarm",
      "clock",
      "goes",
      "certain",
    ],
    words: [
      "there's",
      "something",
      "a",
      "lot",
      "of",
      "people",
      "do",
      "on",
      "day",
      "off",
      "that",
      "I'm",
      "not",
      "very",
      "good",
      "at",
      "and",
      "that's",
      "to",
      "sleep",
      "in",
      "will",
      "till",
      "eight",
      "or",
      "nine",
      "10:00",
      "the",
      "morning",
      "just",
      "I",
      "normally",
      "get",
      "up",
      "around",
      "630",
      "seven",
      "730",
      "don't",
      "really",
      "much",
      "so",
      "course",
      "means",
      "longer",
      "than",
      "you",
      "when",
      "have",
      "go",
      "work",
      "alarm",
      "clock",
      "goes",
      "certain",
      "time",
      "but",
      "can",
    ],
    link: "(7)https://www.youtube.com/watch?v=yW-YeEc_ULg&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=21",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/7dayoffbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=SBsUl8el8s%2FniHws%2FroYF4E%2FpGFTDRGVqwUuxUeC8c4yQGeRuKT3OO1GIrciMg8cCI07KwfCJIQpsoykoWnKCLTIFZWhFtk4yG6QTzAFFD%2ByJ05O6GZU%2BIfHMBKisnmQdkHKslc6Fm1LxkvvozbGKVIIOV98ebkYMCP7vBDQqmt9S0MTHu86i2Eb7sNIcaN6ltk%2FMlXUDgUyi8Ii21nA0UNs93jbmbzsDXjBPykk5%2F%2FMYwdnpZwHSFFsyPY1ool%2FGNJLxU9YjsB6iF3jXfX1D%2BvPIb%2BM7o0ht8hZhmVS6%2FASbuyo79fJlKf1q%2BchQ%2FDQYABJKjjGE2XnvIf%2BBPnk1Q%3D%3D",
    id: "There's something a lot of people do on a day off that I'm not very good at, and that's to sleep in. A lot of people on a day off will sleep in till eight or nine or 10:00 in the morning. I'm just not very good at that. I normally get up around 630 or seven. On a day off, I get up around 730 or eight. I don't really sleep in very much. So to sleep in, of course, means to sleep longer in the morning than you normally do. When you have to go to work, the alarm clock goes off and you get up at a certain time. But on a day off you can sleep in the.",
    story_id: 1397,
    word_count: 58,
    all_intersection_count: 40,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "stanley's",
      "international",
      "restaurant",
      "kind",
      "food",
      "stanley",
      "cook",
      "monday",
      "cooks",
      "italian",
      "Wednesday",
      "chinese",
      "Greek",
      "Tuesday",
      "puerto",
      "rican",
      "Thursday",
      "thanks",
    ],
    words: [
      "stanley's",
      "international",
      "restaurant",
      "what",
      "kind",
      "of",
      "food",
      "does",
      "stanley",
      "cook",
      "on",
      "monday",
      "he",
      "cooks",
      "italian",
      "thank",
      "you",
      "Wednesday",
      "chinese",
      "yes",
      "that's",
      "right",
      "Greek",
      "Tuesday",
      "puerto",
      "rican",
      "Thursday",
      "thanks",
    ],
    link: "(2)https://www.youtube.com/watch?v=X9QluYxyV00",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Simple%202.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=JOczgkAvWXTR6Tta0SoKe9AkrOqMOLhnPgCxXjYNjJTEF0jdHshl1BkVOEhmI3GTy6pwzHnaEKOF7A89qesiuD2fhFIWOts3hITx%2ByvUDgrUphJzQ1dts456PqM2%2BAAhb3Dj5HPqERpqoUBp3txo6Si0aK3435gyETfGRKlgFBGiDr3SgOqOEShmjZgO%2BXSdW38Sa%2BtmfTOZJB5H3QC6hCqG5tCGUhhm0PD6h47OiPpr3YGp7OiZ2767f8NczRQy0rkP9Mu2s0eBJ5RNuSDTfOxWnOIHRYxLgMEg%2F7hfhzVDf3NRuLO3grhIvXEVfZYGIHD2BOdm5w1sAWp0wuRf%2Fw%3D%3D",
    id: "Stanley's International Restaurant.\nWhat kind of food does Stanley cook on Monday?\nOn Monday, he cooks Italian food.\nThank you.\n\nStanley's International Restaurant.\nWhat kind of food does Stanley cook on Wednesday?\nOn Wednesday, he cooks Chinese food.\nChinese food?\nYes. That's right. \nThank you.\n\nStanley's International Restaurant.\nDoes Stanley cook Greek food on Tuesday?\nYes. He does.\nThank you.\n\nStanley's restaurant.\nDoes Stanley cook Puerto Rican food on Thursday?\nYes, he does.\nThanks.",
    story_id: 961,
    word_count: 28,
    all_intersection_count: 10,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "Richard",
      "Julie",
      "return",
      "tennis",
      "racket",
      "borrowed",
      "last",
      "will",
      "5",
      "o'clock",
      "I'll",
      "cooking",
      "dinner",
      "then",
      "won't",
      "come",
      "over",
      "disturb",
      "you'll",
    ],
    words: [
      "hello",
      "Richard",
      "this",
      "is",
      "Julie",
      "I",
      "want",
      "to",
      "return",
      "the",
      "tennis",
      "racket",
      "borrowed",
      "from",
      "you",
      "last",
      "week",
      "will",
      "be",
      "home",
      "today",
      "at",
      "about",
      "5",
      "o'clock",
      "yes",
      "I'll",
      "cooking",
      "dinner",
      "oh",
      "then",
      "won't",
      "come",
      "over",
      "five",
      "why",
      "not",
      "don't",
      "disturb",
      "you'll",
      "worry",
      "me",
      "okay",
      "see",
    ],
    link: "(2)https://www.youtube.com/watch?v=O7Jg2oYAwyQ&list=PLD7AA7B1BC72ACC28&index=23",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/future6.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=m5%2Fm83veep35xOvrXg%2BGc6cvw7V57AHWsX3UARdEnX20Xik%2BbqDSnF7IQuwn0nB3XdE481Vu13SufHBHbnmiQBJwMMihilzDM1SbDPXL9s%2B9gJyMsxQiPnY7SJNHffcVPCIUURhIGlwScHyZtGk%2B4YsaIbwN6yGtebWo5YfGBHYRp3%2BvQEC%2BTf1zyuXd1%2F2r4p%2BDVZGrXndwTU%2BSBNAq%2BFaAHuzSnjnnJreUbLVmAuw%2BhW01OSUK%2Bs9fvEyPZ%2F9u9lIhseki1yqMb6xnnrDHyErWIboVyDgi1Xz4LqYkPNlsHAI9Zjl3cfaQoYonSJkE8UQQygUDgAAAvctNDl2JEw%3D%3D",
    id: "Hello, Richard? This is Julie. I want to return the tennis racket I borrowed from you last week. Will you be home today at about 5 o'clock.\nYes, I will.\nI'll be cooking dinner.\nOh, then I won't come over at five.\nWhy not?\nI don't want to disturb you. You'll be cooking dinner.\nDon't worry, you won't disturb me.\nOkay. See you at five.",
    story_id: 1066,
    word_count: 44,
    all_intersection_count: 25,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "isn't",
      "super",
      "common",
      "garden",
      "tool",
      "might",
      "called",
      "fork",
      "pitchfork",
      "verb",
      "pile",
      "clippings",
      "wanted",
      "put",
      "these",
      "into",
      "wheelbarrow",
      "move",
      "would",
    ],
    words: [
      "so",
      "this",
      "isn't",
      "a",
      "super",
      "common",
      "garden",
      "tool",
      "but",
      "you",
      "might",
      "have",
      "one",
      "it's",
      "called",
      "fork",
      "we",
      "sometimes",
      "call",
      "it",
      "pitchfork",
      "and",
      "use",
      "to",
      "things",
      "that's",
      "the",
      "verb",
      "when",
      "I",
      "big",
      "pile",
      "of",
      "grass",
      "clippings",
      "here",
      "if",
      "wanted",
      "put",
      "these",
      "into",
      "my",
      "wheelbarrow",
      "or",
      "move",
      "them",
      "would",
      "oh",
      "not",
      "going",
      "very",
      "good",
      "with",
    ],
    link: "(5)https://www.youtube.com/watch?v=Ug8YJCycq7g&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=35",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/5LetLearnEnglishintheGardenBob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=zSEtTrLX79orNeoP3r4ew0df5zkBH7NYick5efOabL%2FWLE25bhi%2FCM2JDq9VcYcit5do0IfeijvOS39UlWMGsVmeaYkZ5rJhXMliE6q43uDKzI8If96B6cnSyRCkQAWCcqoLDmSLh8ZYsj7HOmMxQSauupTgT7TnxOxFyZFA2bGuwfTDc1uI4Z9ThTyESnbd91ehN7du5MgGGkan9UKCxHsPatj4ZnIES1c7U0xVPA2HsURWUmEtdzzRLx9uIYivtQ56qG5CmjohNuJG4uhgIBHe6kDMvmMqJe5grv%2B2xUHCd7T0%2Flb9jovI9y%2FB4NIU3SOZSzj3Hjxq8DwUaPzibQ%3D%3D",
    id: "So this isn't a super common garden tool, but you might have one. It's called a fork. We sometimes call it a pitchfork. And you use it to fork things. That's the verb you use when you use this tool. I have a big pile of grass clippings here. If I wanted to put these into my wheelbarrow or if I wanted to move them, I would use a fork. I would fork them. Oh, it's not going very good. I would fork them with my fork.",
    story_id: 1460,
    word_count: 53,
    all_intersection_count: 34,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "driving",
      "swerve",
      "avoid",
      "hitting",
      "road",
      "front",
      "turn",
      "instead",
      "might",
      "hit",
      "brakes",
      "press",
      "brake",
      "pedal",
      "really",
      "quickly",
      "order",
      "vehicle",
      "away",
    ],
    words: [
      "oh",
      "sometimes",
      "when",
      "you're",
      "driving",
      "you",
      "need",
      "to",
      "swerve",
      "avoid",
      "hitting",
      "something",
      "or",
      "someone",
      "on",
      "the",
      "road",
      "in",
      "front",
      "of",
      "so",
      "means",
      "turn",
      "usually",
      "but",
      "don't",
      "have",
      "time",
      "and",
      "instead",
      "might",
      "hit",
      "brakes",
      "press",
      "brake",
      "pedal",
      "really",
      "quickly",
      "order",
      "stop",
      "your",
      "vehicle",
      "right",
      "away",
    ],
    link: "https://www.youtube.com/shorts/gbO62QfqNAc",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "Oh. Sometimes when you're driving, you need to swerve to avoid hitting something or someone on the road in front of you. So to swerve means to turn, usually to avoid hitting something or someone. But sometimes you don't have time to swerve, and instead you might have to hit the brakes. To hit the brakes means to press the brake pedal really quickly in order to stop your vehicle right away. So sometimes you swerve, sometimes you hit the brakes.",
    story_id: 1087,
    word_count: 44,
    all_intersection_count: 25,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "isn't",
      "quite",
      "mud",
      "puddle",
      "old",
      "crocs",
      "picture",
      "there's",
      "definitely",
      "down",
      "sure",
      "signs",
      "spring",
      "everywhere",
      "little",
      "further",
      "I'll",
      "find",
      "actual",
    ],
    words: [
      "so",
      "this",
      "isn't",
      "quite",
      "a",
      "mud",
      "puddle",
      "you",
      "can",
      "see",
      "my",
      "old",
      "crocs",
      "here",
      "in",
      "the",
      "picture",
      "but",
      "there's",
      "definitely",
      "some",
      "down",
      "is",
      "one",
      "of",
      "sure",
      "signs",
      "spring",
      "everywhere",
      "let's",
      "go",
      "little",
      "further",
      "and",
      "I'll",
      "find",
      "an",
      "actual",
    ],
    link: "(1)https://youtu.be/rodSOxxpvcE?si=9QPiUY4a7pLlQDmU",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1learnenglishonabeautifulspringdaybob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=T%2BT9Z19C2Wwd4Gl4oyK62Rcydi5vaYt%2BHgAeP6tAsCTjQMV9Is%2FUBfzcaL9jcCPgbCQcA7Jzi8QM5e7G2IbUg%2Fu5wFYiG7IxKbZV82nG9fnZ2yUbr0Cdfu%2BS6zpXtlANOxVPiAQ76xjksY3XDyfI2nCenNJABM1M%2F3WeKUYtnNBcmFkLQhBAbfEU%2FubWe4CpEjf2VjW9BA18mRpdX1IUd2EMKbeLKMfnqzHGknCFa07KYXEBbTFZYebNlMxnvKpxi4F5Ru5AXJyqwrcMxp8JscxQYNEf8t3UnWtVKHzVhK7LaxF9Y7asF8IpDUIVAO1TUW%2BvuM5dO0x3Il9CBRoThg%3D%3D",
    id: "So this isn't quite a mud puddle. You can see my old crocs here in the picture, but there's definitely some mud down here. This is definitely one of the sure signs of spring. There's definitely mud everywhere. Let's go a little further and I'll find an actual puddle.",
    story_id: 1359,
    word_count: 38,
    all_intersection_count: 19,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "shopping",
      "loaf",
      "bread",
      "jar",
      "jam",
      "box",
      "cookies",
      "pound",
      "ham",
      "bottle",
      "ketchup",
      "cheese",
      "dozen",
      "peas",
      "lettuce",
      "half",
      "bunch",
      "bag",
      "ice",
    ],
    words: [
      "shopping",
      "we",
      "need",
      "a",
      "loaf",
      "of",
      "bread",
      "and",
      "jar",
      "jam",
      "box",
      "cookies",
      "pound",
      "ham",
      "bottle",
      "ketchup",
      "cheese",
      "dozen",
      "eggs",
      "can",
      "peas",
      "head",
      "lettuce",
      "half",
      "rice",
      "bunch",
      "bananas",
      "bag",
      "ice",
    ],
    link: "https://www.youtube.com/watch?v=SBG0nsPuI6Q&list=PLD7AA7B1BC72ACC28&index=5",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/shoping.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=caHxdpZoYJDCotke3NvDePQcEBQsJIXn8usnzRj6s6WYyQ6il6C%2F5gJ%2Bsp%2FPe57u%2FQVYNL7QJk53lyE8vhhio7w4KfaUvF4vz7rFj1TdAZLg5QVfTKt4gVrW7BlThnITxVes%2Fmmwq%2FFGUNgBONh%2BiN9MGNJsVbw2lgQUWGAf1Vmh7Ufb0yNtMF6yRmdc9nl3%2F7jks%2Ftm5zh0kHBwtKdlxMXqgLyo4FehaOwUHW5oeddbmMKgrKYnqlMFmhNEY5CiNtBLrwQOQNrG3CDqYFEc%2B7PtDp4%2F2NL4XnQ%2FKML%2BXHA2tcF3a5nnPhXYpoKcvLC5Y721vXTzSmC2ovXJiLNC9w%3D%3D",
    id: "Shopping, shopping, shopping, shopping.\nShopping, shopping, shopping, shopping.\nWe need a loaf of bread.\nAnd a jar of jam.\nA box of cookies.\nAnd a pound of ham.\nA bottle of ketchup.\nA pound of cheese.\nA dozen eggs.\nAnd a can of peas.\nA head of lettuce.\nHalf a pound of rice.\nA bunch of bananas.\nAnd a bag of ice.\nShopping, shopping, shopping, shopping.\nShopping, shopping, shopping, shopping.",
    story_id: 1022,
    word_count: 29,
    all_intersection_count: 10,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "year",
      "I'll",
      "trays",
      "field",
      "follow",
      "trail",
      "eventually",
      "jen's",
      "already",
      "planting",
      "little",
      "baby",
      "ground",
      "pretty",
      "fun",
      "guess",
      "variety",
      "cold",
      "tolerant",
    ],
    words: [
      "sometimes",
      "at",
      "this",
      "time",
      "of",
      "year",
      "I'll",
      "see",
      "some",
      "trays",
      "out",
      "in",
      "the",
      "field",
      "and",
      "if",
      "I",
      "follow",
      "trail",
      "eventually",
      "that",
      "jen's",
      "already",
      "planting",
      "little",
      "baby",
      "plants",
      "ground",
      "so",
      "pretty",
      "fun",
      "guess",
      "variety",
      "is",
      "very",
      "cold",
      "tolerant",
      "here",
      "them",
    ],
    link: "(13)https://youtu.be/rodSOxxpvcE?si=9QPiUY4a7pLlQDmU",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/13learnenglishonabeautifulspringdaybob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=kR0a%2BgWWeZ6CUmyrcKhWQdyg6Ul%2BjQPaM8%2FQV1S0py0Azl1SCQTCyIeEiUGrmRFuWcE0uCbr4%2FTN6nh1S9%2FECjGtluXpOS8SlF9pZBhY8S4OhonZknz80vP3sYKmVc2lQ1D3L1KRx18nmjYJQXKDrW1vIdYMO0De9u%2FwIyPy9OnTu3DVELjgPE%2BLvwTnNNUD%2F%2FA0tt7DteRN43b9%2F460x7fKbA1tPdHP9i1OGG6t6YDfGCle9HiMGr%2BAKBJ05G%2BRPrX7tWxM3EPJsd0%2B62GciuZu%2FA362djJkDZWDEA0tBeUrnfcyyMrok2eAZYvKjZqyp4oLWGeu8hjnZTUBArhBw%3D%3D",
    id: "Sometimes at this time of year, I'll see some trays out in the field. And if I follow the trail of trays, eventually I'll see that Jen's already planting some little baby plants in the ground. So pretty fun, I guess. This variety is very cold tolerant, and Jen's out here planting them in the ground.",
    story_id: 1371,
    word_count: 39,
    all_intersection_count: 20,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "garden",
      "tool",
      "called",
      "hoe",
      "down",
      "you'll",
      "there",
      "weeds",
      "soil",
      "standing",
      "up",
      "won't",
      "able",
      "rid",
      "those",
      "these",
      "grow",
      "beds",
      "then",
    ],
    words: [
      "so",
      "this",
      "garden",
      "tool",
      "is",
      "called",
      "a",
      "hoe",
      "and",
      "we",
      "use",
      "to",
      "if",
      "you",
      "don't",
      "know",
      "what",
      "that",
      "look",
      "down",
      "here",
      "you'll",
      "see",
      "there",
      "are",
      "some",
      "weeds",
      "in",
      "the",
      "soil",
      "I",
      "can't",
      "do",
      "standing",
      "up",
      "or",
      "won't",
      "be",
      "able",
      "but",
      "can",
      "it",
      "get",
      "rid",
      "of",
      "those",
      "these",
      "things",
      "grow",
      "where",
      "want",
      "them",
      "have",
      "our",
      "flowers",
      "flower",
      "beds",
      "then",
      "sometimes",
      "need",
    ],
    link: "(2)https://www.youtube.com/watch?v=Ug8YJCycq7g&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=35",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/2LetLearnEnglishintheGardenBob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=thT82t73ENZmzxub8ipaklvwnSvPjROpN0N6IIRNmpNHXhUDnlvMExX8AXB0huQW6clLKGebwls3XDGADzMXyN7Zgm%2FSmewaJook0lha9SQxlKfRCgeYjgAc%2BNdmftxClzYqgd8OuiiehZnhgI%2BOqhvHsFH28P1EebfU%2F3VrA3c%2BTPCGJ5aoUTHOZAr7nEV9avFMc7oefMMhkwi0%2F2LuRW31HkdyfDhwKamg4A578pud9Uq%2Fzy1dTDUAleJZ9FkBBv2AoZPtl7%2BbUhX3Ll49Qygc6IWCWFl0Q%2BnKaWtklXpOFPIszM5LzzF9wwtsr5pFjyLRvTIm3C6jV%2BLkMOXmcw%3D%3D",
    id: "So this garden tool is called a hoe, and we use this to hoe. If you don't know what that is, if you look down here, you'll see that there are some weeds in the soil. I can't do this standing up or you won't be able to see. But if I use the hoe, I can use it to get rid of those weeds. Weeds are these things that grow where you don't want them to grow. We have our flowers in the flower beds, and then sometimes we have weeds and we need to use a hoe to get rid of them.",
    story_id: 1457,
    word_count: 60,
    all_intersection_count: 41,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "farm",
      "put",
      "posts",
      "ground",
      "hammer",
      "hit",
      "post",
      "actually",
      "really",
      "loud",
      "verb",
      "into",
      "pound",
      "fact",
      "normally",
      "called",
      "pounder",
      "special",
      "tool",
    ],
    words: [
      "so",
      "sometimes",
      "on",
      "the",
      "farm",
      "we",
      "have",
      "to",
      "put",
      "posts",
      "like",
      "this",
      "in",
      "ground",
      "use",
      "a",
      "hammer",
      "hit",
      "post",
      "I",
      "don't",
      "want",
      "do",
      "it",
      "it's",
      "actually",
      "really",
      "loud",
      "or",
      "can",
      "verb",
      "into",
      "pound",
      "fact",
      "that's",
      "how",
      "normally",
      "something",
      "called",
      "pounder",
      "is",
      "special",
      "tool",
      "that",
      "you",
    ],
    link: "(11)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/11learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=KjgVWsuh%2BnmPxp5IpC6A1CHc5mxRmM11kxNvnlWPS5kxiQuFkjxQuc1pmHgVuarblNsMh4FNdqcUYCScHh41VRVwwuChHvBFVxLu8Y%2BGrCzw0v6OxYuzJYy232i0gNOoRIf5ffQnkuiSB%2BXs%2Bw3kKlPhmC88zEW8Nzm1BClkomGBbOosQW0DWo1E2Gj3kvlsNReBdSPjO1V%2F2hq%2B6siRhmiSAStUHqKQx%2FZk59fOnFF8VlHIlOIoKyfz%2BY9R9mIf2VNk08%2BF%2FHj2ASsNQtE2u6zRKhMBQ1X1upQQ0eS5CT7%2Fc4iBSJN7hFqh01c%2B%2FPXwfH1oCUTojUCf88XSMXpBLA%3D%3D",
    id: "So sometimes on the farm, we have to put posts like this in the ground. So we use a hammer to hit the post. I don't want to do it. It's actually really loud. Or we can use the verb to hammer. I can hammer this post into the ground. Or we use the verb to pound. I can pound this post into the ground. In fact, that's how we normally do it. We use something called a post pounder. A post pounder is a special tool that you use to pound posts into the ground.",
    story_id: 1336,
    word_count: 45,
    all_intersection_count: 26,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "thirsty",
      "fresh",
      "lemonade",
      "sounds",
      "pretty",
      "Jimmy",
      "afraid",
      "there",
      "aren't",
      "any",
      "lemons",
      "guess",
      "isn't",
      "gonna",
      "milkshake",
      "milk",
      "after",
      "supermarket",
      "idea",
    ],
    words: [
      "dad",
      "I'm",
      "thirsty",
      "can",
      "we",
      "make",
      "some",
      "fresh",
      "lemonade",
      "that",
      "sounds",
      "pretty",
      "good",
      "Jimmy",
      "but",
      "afraid",
      "there",
      "aren't",
      "any",
      "lemons",
      "oh",
      "well",
      "I",
      "guess",
      "if",
      "isn't",
      "gonna",
      "be",
      "not",
      "orange",
      "juice",
      "don't",
      "think",
      "so",
      "oranges",
      "how",
      "about",
      "a",
      "milkshake",
      "milk",
      "you",
      "know",
      "what",
      "just",
      "have",
      "glass",
      "of",
      "water",
      "me",
      "too",
      "and",
      "do",
      "we're",
      "after",
      "no",
      "go",
      "to",
      "the",
      "supermarket",
      "idea",
    ],
    link: "(2)https://www.youtube.com/watch?v=Srglu-yI9Ow&list=PLD7AA7B1BC72ACC28&index=3",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/juice.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=yV0Bnp3xL4ZgVI3CDZeHPqR4%2B9n4qRznmKiclZoLJS%2FkEwKsQ2vSysxDTFgFZTiRcqXMUJx5NOWL9s90C8ZzccHkads23ImKn2vEtoEbbqfNuNSli2%2Bqr4pXWkMLh3oVpNG988N7Fdvg27DcZJs5V0eWKYfZecsBRdgrABn5%2BEpMWsiPHOgNb%2F7%2BsfY2W8mXXuvBOgsACoSNAGuMKmNx8ykztCoqpjkmxQ5Oo2QCkGcstIkuDHaaI9UA178tQ5%2BhLrOJwQah%2FPNw22fbJP%2B52jZSztQJEhJNXlaaUFPbVnsvliyJ5pApj1J5FxvAPt6PlTPRgVH2glYMSHLZy%2FD7BQ%3D%3D",
    id: "Dad, I'm thirsty. Can we make some fresh lemonade?\nFresh lemonade? That sounds pretty good, Jimmy, but I'm afraid there aren't any lemons.\nOh, well. I guess if there aren't any lemons, there isn't gonna be any lemonade.\nI'm afraid not. Can we make some fresh orange juice?\nI don't think so, Jimmy. There aren't any oranges.\nHow about a milkshake?\nThere isn't any milk.\nYou know what, dad? I'm just gonna have a glass of water.\nMe too.\nAnd do you know what we're gonna do after that?\nNo.\nWhat?\nWe're gonna go to the supermarket.\nGood idea, dad.",
    story_id: 1015,
    word_count: 60,
    all_intersection_count: 41,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "sit",
      "sitting",
      "chair",
      "push",
      "pushing",
      "cart",
      "pull",
      "pulling",
      "wagon",
      "song",
      "brush",
      "brushing",
      "hair",
      "dry",
      "drying",
      "job",
      "ask",
      "answer",
      "ready",
    ],
    words: [
      "what",
      "are",
      "you",
      "doing",
      "sit",
      "sitting",
      "I'm",
      "in",
      "a",
      "chair",
      "push",
      "pushing",
      "cart",
      "pull",
      "pulling",
      "wagon",
      "sing",
      "singing",
      "song",
      "read",
      "reading",
      "book",
      "wash",
      "washing",
      "my",
      "hands",
      "brush",
      "brushing",
      "hair",
      "dry",
      "drying",
      "very",
      "good",
      "job",
      "now",
      "this",
      "time",
      "going",
      "to",
      "ask",
      "and",
      "answer",
      "ready",
      "here",
      "we",
      "go",
    ],
    link: "https://www.youtube.com/watch?v=Dl8g2pZ82ME",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "\nWhat are you doing? \nsit\nsitting\nI'm sitting in a chair. \n\nWhat are you doing? \nI'm sitting in a chair. \n\npush\npushing\nI'm pushing a cart.\n\nWhat are you doing? \nI'm pushing a cart.\n\npull \npulling \nI'm pulling a wagon.\n\nWhat are you doing? \nI'm pulling a wagon.\n\nsing\nsinging \nI'm singing a song.\n\nWhat are you doing? \nI'm singing a song.\n\nread\nreading\nI'm reading a book.\n\nWhat are you doing? \nI'm reading a book. \n\nwash\nwashing \nI'm washing my hands.\n\nWhat are you doing? \nI'm washing my hands.\n\nbrush\nbrushing\nI'm brushing my hair.\n \nWhat are you doing?\nI'm brushing my hair. \n\ndry \ndrying \nI'm drying my hair.\n\nWhat are you doing? \nI'm drying my hair.\n\nVery good job.\nNow this time I'm going to ask you \"What are you doing?\" and you answer.\nAre you ready?\nHere we go.",
    story_id: 946,
    word_count: 46,
    all_intersection_count: 27,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "sweater",
      "shirt",
      "blue",
      "jacket",
      "pink",
      "skirt",
      "hat",
      "looking",
      "all",
      "clothes",
      "laundromat",
      "these",
      "mittens",
      "boots",
      "those",
      "bathing",
      "suits",
      "pantyhose",
      "hey",
    ],
    words: [
      "is",
      "this",
      "your",
      "sweater",
      "shirt",
      "that's",
      "my",
      "blue",
      "jacket",
      "pink",
      "skirt",
      "I",
      "think",
      "new",
      "hat",
      "we're",
      "looking",
      "for",
      "and",
      "that",
      "washing",
      "all",
      "our",
      "clothes",
      "at",
      "the",
      "laundromat",
      "are",
      "these",
      "mittens",
      "boots",
      "those",
      "socks",
      "bathing",
      "suits",
      "where",
      "pantyhose",
      "hey",
      "give",
      "me",
    ],
    link: "(3)https://www.youtube.com/watch?v=J3f53a-cmcs&list=PL31D7CB49ABAAB4DB&index=12",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/clothing3.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=kZC7cZMKJZddDbOUN7hQ9mUyVBo%2BR9jGveGzlSbrRREHC5R37nTUlmKHQLBKLp9J53PG8f9jI3u2YukYW430wwEMCK7BbvDJKTEjNXJ8fkwxBoAo2etXxQygH2pff9TSJp%2BnkRx3eC%2BAHp5aGxAbU8bZgPCt9Fo7E36tYp0W9N%2BY31lrC55y90ASS3yqAoRnrIafCvAN54Hm6EW4nnipG4J8Su1YwgBRq9jB%2BpxSFS8IJIgThXg0TX%2BKkgMCKzq6SqhOtW7IoVQFk1KqKrFRrl8jv97M%2BYaW6FkAOPyX12Agtd1oHAiZ%2FVXqic%2F0fJ2xWBYqgrTUw9KdQadPCiGEqQ%3D%3D",
    id: "Is this your sweater?\nIs this your shirt?\nThat's my blue jacket.\nThat's my pink skirt.\nI think this is my new hat.\nWe're looking for this and that.\nWe're washing all our clothes at the laundromat.\nThis and that at the laundromat.\nThis and that at the laundromat.\nThis and that at the laundromat.\nAre these your mittens?\nAre these your boots?\nThose are my socks.\nThose are my bathing suits.\nWhere are my pantyhose?\nWe're looking for these and those.\nWe're washing all our clothes at the laundromat.\nThis and that.\nWashing all our clothes.\nThese and those at the laundromat.\nAt the laundromat.\nHey, give me that.\nAt the laundromat.",
    story_id: 979,
    word_count: 40,
    all_intersection_count: 21,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "secrets",
      "generally",
      "phrase",
      "keep",
      "secret",
      "anyone",
      "else",
      "could",
      "also",
      "several",
      "only",
      "most",
      "common",
      "fact",
      "might",
      "ask",
      "hey",
      "then",
      "trust",
    ],
    words: [
      "so",
      "when",
      "we're",
      "talking",
      "about",
      "secrets",
      "generally",
      "we",
      "use",
      "the",
      "phrase",
      "to",
      "keep",
      "a",
      "secret",
      "you",
      "it",
      "means",
      "know",
      "something",
      "and",
      "don't",
      "tell",
      "anyone",
      "else",
      "could",
      "also",
      "say",
      "that",
      "have",
      "I",
      "several",
      "not",
      "going",
      "what",
      "they",
      "are",
      "but",
      "only",
      "talk",
      "most",
      "common",
      "is",
      "in",
      "fact",
      "might",
      "ask",
      "someone",
      "hey",
      "can",
      "then",
      "if",
      "yes",
      "trust",
      "them",
      "your",
    ],
    link: "(1)https://www.youtube.com/watch?v=KjynWAC2bLk&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=20",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1keepsecretbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=tOLX5rb3m7DgIS6%2F7PEbES7oqUom2CcnWGR38Tp7OXH9LOaQM6gDk9JB%2Fwu83pkX4hzZTDDtW%2BBGtAl%2B7myLif3dgVVgh%2FQrylPRVyYfx25Syd9ztm5n1ltiIQzIqIzY4ukX5yc3zelNsWdBYVCOey%2F%2Fmu6LEl53M6aq%2FqwFE7UzMcoN5a64mx7APASPWCmRZM2bAzdLR3T5D8Vuzsg0uZumP2rTAlNSYG4MHYmqNE8yJO8%2BjzLD0bXHlkCvrGlFwtxBekGqkWZDHA00w%2F1d%2Bl9UZXJHlVoNNHRkp36SlMOC0p%2Fr8WL%2BN%2B49tbXmgShkbLlYtwQawDFIYTXfI5RJdw%3D%3D",
    id: "So when we're talking about secrets, generally we use the phrase to keep a secret. When you keep a secret, it means you know something and you don't tell anyone else. You could also say that you have a secret. You know, I have several secrets. Not going to tell you what they are, but I have several secrets that only I know about. So when you talk about secrets, the most common phrase you use is that you keep a secret. In fact, you might ask someone, you might say to someone, hey, can you keep a secret? And then if they say yes and you trust them, you might tell them what your secret is. So when you have a secret, you keep that secret.",
    story_id: 1376,
    word_count: 56,
    all_intersection_count: 37,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "favorite",
      "food",
      "cheese",
      "all",
      "delicious",
      "ice",
      "cream",
      "french",
      "fries",
      "aren't",
      "come",
      "much",
      "really",
      "chocolate",
      "chip",
      "cookies",
      "great",
      "many",
      "awesome",
    ],
    words: [
      "what's",
      "your",
      "favorite",
      "food",
      "my",
      "is",
      "cheese",
      "I",
      "love",
      "eat",
      "it",
      "all",
      "the",
      "time",
      "like",
      "bananas",
      "think",
      "they're",
      "delicious",
      "and",
      "very",
      "good",
      "for",
      "you",
      "ice",
      "cream",
      "it's",
      "thing",
      "to",
      "have",
      "every",
      "day",
      "let",
      "me",
      "see",
      "oh",
      "yes",
      "french",
      "fries",
      "know",
      "they",
      "aren't",
      "but",
      "coffee",
      "drink",
      "come",
      "of",
      "probably",
      "too",
      "much",
      "really",
      "chocolate",
      "chip",
      "cookies",
      "great",
      "mom",
      "dad",
      "say",
      "many",
      "can",
      "never",
      "are",
      "awesome",
    ],
    link: "(5)https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/food.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=n%2FdCgOwFwUKHMI%2BKu8YXvp1fXsdmKCPWyi2C6PMV%2F31r1xyWDgFUHQo9H6NSC4gc128VEREcB1kF1sKDGYD3PcbzWeGhI3iN7vTCTd8C2dnpHoq48FyfqYDNo1MPzC69aBS6%2FE%2BhCc5p8%2FlUqFXAoSQsBd38cP%2Bg0QKMulDC%2BJtoyKnm%2B64tPpAdiCENP3JzHWBqWPFG8ECy%2BaK3rtM621NIYCdHiFqOptArYQJO9aC8K3JlBoY1okDmOaXPQFPEPOFcnnXlLbxV96F5%2BxLtD%2FOkJ1uaLBZR6NZTmq7hMgu6aDvEWxMALeNRqT9VMauNl4%2FbzxWRVbv8dkgAssvLow%3D%3D",
    id: "What's your favorite food?\nMy favorite food is cheese. I love cheese. I eat it all the time.\n\nI like bananas. I think they're delicious, and they're very good for you.\n\nI like ice cream. It's my favorite thing to eat. I have ice cream every day.\n\nMy favorite food... Let me see. Oh, yes. French fries. I love french fries. I know they aren't very good for me, but I think they're delicious.\n\nCoffee. I drink it all the time. Come to think of it, I probably drink too much coffee, but I really like it.\n\nI love chocolate chip cookies. I think they're great. My mom and dad say I eat too many, but I say you can never eat too many chocolate chip cookies. They are awesome.",
    story_id: 1021,
    word_count: 63,
    all_intersection_count: 44,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "best",
      "communicate",
      "clearly",
      "practice",
      "pronunciation",
      "again",
      "over",
      "age",
      "accent",
      "pronouncing",
      "possible",
      "little",
      "slide",
      "remind",
      "way",
      "shadow",
      "work on your pronunciation",
      "over the age of",
      "shadow people",
    ],
    words: [
      "one",
      "is",
      "of",
      "the",
      "best",
      "things",
      "to",
      "do",
      "when",
      "you",
      "want",
      "communicate",
      "clearly",
      "practice",
      "your",
      "pronunciation",
      "again",
      "if",
      "you're",
      "an",
      "older",
      "person",
      "and",
      "over",
      "age",
      "ten",
      "are",
      "going",
      "have",
      "accent",
      "speak",
      "English",
      "but",
      "work",
      "on",
      "pronouncing",
      "as",
      "possible",
      "so",
      "here",
      "just",
      "a",
      "little",
      "slide",
      "remind",
      "that",
      "good",
      "way",
      "it",
      "shadow",
      "people",
      "work on your pronunciation",
      "over the age of",
      "shadow people",
    ],
    link: "(8)ktD2HkgRt4Y",
    type: "video",
    pdf_name: null,
    storage_link: null,
    id: "One is one of the best things to do when you want to communicate clearly is to practice your pronunciation. Again, if you're an older, uh, person and you're over the age of ten, you are going to have an accent when you speak English. But you do want to work on pronouncing things as clearly as possible. So you want to work on your pronunciation. So, uh, here is just a little slide to remind you of that. Um, one good way to do it is to shadow people.",
    story_id: 1533,
    word_count: 54,
    all_intersection_count: 35,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "eyes",
      "ocean",
      "which",
      "ship",
      "chest",
      "iceberg",
      "dream",
      "crash",
      "dark",
      "chocolate",
      "melt",
      "sight",
      "soul",
      "forest",
      "place",
      "matter",
      "will",
      "future",
      "used",
    ],
    words: [
      "his",
      "eyes",
      "are",
      "like",
      "the",
      "ocean",
      "in",
      "which",
      "my",
      "ship",
      "wants",
      "to",
      "sink",
      "chest",
      "is",
      "an",
      "iceberg",
      "I",
      "dream",
      "crash",
      "as",
      "dark",
      "chocolate",
      "melt",
      "at",
      "sight",
      "of",
      "him",
      "your",
      "soul",
      "a",
      "forest",
      "place",
      "want",
      "live",
      "no",
      "matter",
      "what",
      "will",
      "be",
      "future",
      "how",
      "we",
      "look",
      "you",
      "they",
      "used",
    ],
    link: "https://www.kidspiritonline.com/explore/poetry/eyes-like-the-ocean",
    type: "story",
    pdf_name: null,
    storage_link: null,
    id: "His eyes are like the ocean\nIn which my ship wants to sink\nHis chest is like an iceberg\nIn which I dream to crash\nMy eyes are as dark as chocolate\nwhich melt at the sight of him.\nYour soul is like a forest\nThe place I want to live in.\nNo matter what will be in future,\nNo matter how we will look,\nMy eyes will look at you\nAs they used to look at you.",
    story_id: 909,
    word_count: 47,
    all_intersection_count: 28,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "maybe",
      "own",
      "vehicle",
      "take",
      "miss",
      "exactly",
      "excuse",
      "would",
      "arrived",
      "missed",
      "didn't",
      "station",
      "saw",
      "pulling",
      "away",
      "had",
      "next",
      "then",
      "hey",
    ],
    words: [
      "maybe",
      "you",
      "don't",
      "have",
      "your",
      "own",
      "vehicle",
      "take",
      "the",
      "bus",
      "or",
      "train",
      "to",
      "work",
      "school",
      "and",
      "sometimes",
      "when",
      "are",
      "running",
      "late",
      "miss",
      "that's",
      "exactly",
      "excuse",
      "would",
      "give",
      "arrived",
      "at",
      "say",
      "I'm",
      "because",
      "I",
      "missed",
      "my",
      "just",
      "didn't",
      "get",
      "stop",
      "station",
      "on",
      "time",
      "saw",
      "pulling",
      "away",
      "had",
      "next",
      "one",
      "so",
      "then",
      "hey",
      "sorry",
    ],
    link: "(2)https://youtu.be/4YX2HU-lUXM?si=cYMTAgg1CzmiG21J",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/2howtomakeexcusesforbeinglateinenglish.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=xyAY55tFDg%2FroyFP2dGSmEVBozxsdJjyN45%2FSIHMuVaPq%2BzrZ5HPMZjZOJ6VqCGjVoBAXZLtWGHs2c0zmDUmLMzHuVhPX9jRxjGmx92MJOeQYTPK6XJWkTbVwjDoW%2BiB94%2B5fnPQJzSHkHbc5BVE4Qo8ELfjyT60tKyP9dCVRmvE8jupEC%2FduEWuAOleCcglWerXZHrgeCiAYk06%2F8ySrU%2BcCjucI0Ldr9IIwkFh%2B7AF5ZWi2jPJ02%2Bzq5CAu8Zf7nCPVFi3fPcpgKDK8M4ObYFPSstEwIsPVjjgz4azLPbmil%2BHr14PaUKDQLbpx0iBl7raoG%2FsvJzBNNT6IGewug%3D%3D",
    id: "Maybe you don't have your own vehicle. Maybe you take the bus or you take the train to work or school. And sometimes when you are running late, you miss the bus or you miss the train. And that's exactly the excuse you would give when you arrived at work or when you arrived at school. You would say, I'm late because I missed my bus, or, I'm late because I missed my train. Maybe you just didn't get to the bus stop or to the train station on time, and you saw your bus pulling away or your train pulling away, and you had to take the next one. So then when you get to work or when you get to school, you would say, hey, sorry I missed my bus. Or, hey, sorry I missed my train.",
    story_id: 1341,
    word_count: 52,
    all_intersection_count: 33,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "news",
      "baby",
      "would",
      "congratulations",
      "first",
      "often",
      "shares",
      "exciting",
      "then",
      "situation",
      "might",
      "ask",
      "question",
      "due",
      "told",
      "born",
      "third",
      "makes",
      "simply",
    ],
    words: [
      "let's",
      "start",
      "by",
      "talking",
      "about",
      "some",
      "happy",
      "news",
      "say",
      "someone",
      "says",
      "to",
      "you",
      "that",
      "they're",
      "going",
      "have",
      "a",
      "baby",
      "would",
      "congratulations",
      "that's",
      "the",
      "first",
      "thing",
      "we",
      "often",
      "when",
      "shares",
      "exciting",
      "with",
      "us",
      "but",
      "then",
      "in",
      "this",
      "situation",
      "might",
      "ask",
      "question",
      "is",
      "due",
      "has",
      "just",
      "told",
      "and",
      "like",
      "know",
      "be",
      "born",
      "third",
      "I'm",
      "so",
      "for",
      "it",
      "makes",
      "other",
      "people",
      "sometimes",
      "simply",
      "if",
    ],
    link: "https://www.youtube.com/watch?v=yVWrxm6IU3w&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=4",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1happynews.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ju2lT7AUaCTwjClnSU4%2BTYjDmZoSGEAxk7ec4THcLVE50X6j32gfP9tYUxIitW7%2F5%2FjQfJeyaHTFx7we1lKmcUn2n3PdOJ36asC1Qh8LfLWMSrXcZVRcVpLUc3wut1BwfL0fVTUJCWMFH%2BEgmHB8719Jy5dFwaFyQd4hXBkGyZoHGN5YfU8AHzR%2Fchp3%2BiQW2c%2FmNtH8mIZGUMLV66m9Wj2l85LoihxSnHCcgTy3GiSzcsRiyRqF4GEl9lYsSduqAbPXstxA874mhjU%2BaO%2FhWninbrgMPEundbut9o61KvR2tBNCgHFF5BVyOBSY%2FHGg5P%2FDHU8aqYoPmHMqX1aTMA%3D%3D",
    id: "Let's start by talking about some happy news. Let's say someone says to you that they're going to have a baby. You would say congratulations. That's the first thing we often say when someone shares exciting, happy news with us. But then, in this situation, you might ask this question. You might say: when is the baby due? Someone has just told you they're going to have a baby, and you would like to know when the baby is going to be born. And, and then the third thing you might say is, I'm so happy for you. When someone shares happy news, exciting news, it makes you happy. It makes other people happy. And sometimes we just simply say, I'm so happy for you. So if someone shares the news with you that they're going to have a baby, you would say, congratulations. When is the baby due? And I'm so happy for.",
    story_id: 1146,
    word_count: 61,
    all_intersection_count: 42,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "cook",
      "dinner",
      "might",
      "stew",
      "bake",
      "really",
      "choices",
      "decide",
      "planning",
      "vacation",
      "France",
      "Spain",
      "Mexico",
      "there",
      "buying",
      "present",
      "gloves",
      "boots",
      "sweater",
    ],
    words: [
      "I",
      "want",
      "to",
      "cook",
      "some",
      "dinner",
      "don't",
      "know",
      "what",
      "make",
      "might",
      "stew",
      "eggs",
      "just",
      "bake",
      "a",
      "cake",
      "really",
      "the",
      "choices",
      "are",
      "so",
      "wide",
      "this",
      "that",
      "can't",
      "decide",
      "I'm",
      "planning",
      "my",
      "vacation",
      "where",
      "go",
      "see",
      "France",
      "Spain",
      "Mexico",
      "here",
      "there",
      "buying",
      "mom",
      "present",
      "get",
      "her",
      "buy",
      "gloves",
      "boots",
      "sweater",
      "we",
      "do",
      "very",
      "nice",
      "thank",
      "you",
    ],
    link: "(3)https://www.youtube.com/watch?v=mP-l6xlCjUU&list=PLD7AA7B1BC72ACC28&index=8",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/cook-2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=wakt9mtyDIwHaVAwVyfHBYIU3RLPnCDj%2F61F5Uyftw8h2mfMdGRSQO6Sl%2BsBVKAWvhgEZZUMoLEI3cs9n9mW3AUDENYhYh9GXCdlfnn3o76QDtiXvVPI5ZvMQW4W6kj5NaxXXSK%2F7kApfYnE1XHIQceM2rUsbiFdDO8kIHs6QQYgoZ8sck7vCgmTQ1AfCBqX%2BF3AdssIAlL6xVkmR4ktcbXnMz2yr%2BeuuSS23UFFljvEYPA3wBj2S3bdXNGtb9HlArlKI9nLrATsnlh%2FCzel36ZhPhhNI44eCWFnq37cLehVyJTVoYGuXQp6lbhcwioqbhwgCgPAdUai5Vs%2F5kQTSg%3D%3D",
    id: "I want to cook some dinner.\nI don't know what to make.\nI might make stew.\nI might make eggs.\nI might just bake a cake.\nI really don't know what to cook.\nThe choices are so wide.\nI might cook this.\nI might cook that.\nI really can't decide.\n\nI'm planning my vacation.\nI don't know where to go.\nI might see France.\nI might see Spain.\nI might see Mexico.\nI really don't know where to go.\nThe choices are so wide.\nI might go here.\nI might go there.\nI really can't decide.\n\nI'm buying mom a present.\nI don't know what to get her.\nI might buy gloves, I might buy boots.\nI might get her a sweater.\nI really don't know what to get.\nThe choices are so wide.\nI might get this.\nI might get that.\nI really can't decide.\n\nWe really don't know what to do.\nThe choices are so wide.\n\nVery nice.\nThank you.",
    story_id: 1032,
    word_count: 54,
    all_intersection_count: 35,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "gonna",
      "there",
      "afternoon",
      "chemistry",
      "class",
      "forget",
      "over",
      "thanks",
      "reminding",
      "welcome",
      "evening",
      "concert",
      "friends",
      "then",
      "all",
      "dinner",
      "tired",
      "tonight",
      "sure",
    ],
    words: [
      "what",
      "are",
      "you",
      "gonna",
      "do",
      "today",
      "I'm",
      "going",
      "to",
      "the",
      "library",
      "this",
      "morning",
      "yes",
      "there",
      "right",
      "now",
      "afternoon",
      "my",
      "chemistry",
      "class",
      "I",
      "see",
      "don't",
      "forget",
      "your",
      "book",
      "over",
      "oh",
      "thanks",
      "for",
      "reminding",
      "me",
      "you're",
      "welcome",
      "tell",
      "evening",
      "a",
      "concert",
      "with",
      "some",
      "friends",
      "and",
      "then",
      "we're",
      "all",
      "out",
      "late",
      "dinner",
      "have",
      "ticket",
      "here",
      "it",
      "is",
      "know",
      "be",
      "tired",
      "tonight",
      "that's",
      "sure",
      "well",
      "nice",
      "day",
      "enjoy",
    ],
    link: "(2) https://www.youtube.com/watch?v=K03PIjwN8ls&list=PL31D7CB49ABAAB4DB&index=22",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/going2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=CtTnk5bsm802w4XCC4VjhMoksjnb8WkRujKrlOErrO3HNGQ9cpxSoeF5chTzS4%2FCtVM5OTP%2FSO1hpORhKJTVe4inhwc9ojMX%2B%2BPrQ2TYaXtKcJd4InTOGEUnKthyI0eHGtXoSce08M%2BKOELeGjv%2FSiwEofiDHcOXzvbLBaIadNWTd644SzFURqOHBD5Lf9bJTceLp3pgd%2Bmf2NE%2FRLhfN8EkZngmSOxD385uFIlX7Dwu8utQAV81cQ%2BUOx5a%2BJn%2BQNmN7u6rfeT%2FdG%2BPqgSHFdIki98l5EJ4bHgzaPuSAb5dct6Fg3bXGiW6LewBqY9c%2FtQL18vIR%2FUU8DItwju3Bw%3D%3D",
    id: "What are you gonna do today?\nI'm going to the library.\nAre you going to the library this morning?\nYes. I'm going there right now.\nWhat are you gonna do this afternoon?\nI'm going to my chemistry class.\nI see. Don't forget your chemistry book over there.\nMy chemistry book?\nOh, yes. Thanks for reminding me.\nYou're welcome.\nTell me, what are you gonna do this evening?\nI'm going to a concert with some friends, and then we're all going out for a late dinner.\nDo you have your concert ticket?\nYes. Here it is.\nYou know, you're gonna be tired tonight.\nThat's for sure.\nWell, have a nice day, and enjoy the concert.\nThanks.",
    story_id: 1007,
    word_count: 64,
    all_intersection_count: 45,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "trouble",
      "ends",
      "having",
      "enough",
      "pay",
      "everything",
      "situation",
      "would",
      "rent",
      "groceries",
      "paid",
      "quickly",
      "obviously",
      "sure",
      "anything",
      "fun",
      "struggling",
      "little",
      "bit",
    ],
    words: [
      "so",
      "sometimes",
      "people",
      "have",
      "trouble",
      "making",
      "ends",
      "meet",
      "in",
      "English",
      "when",
      "we",
      "say",
      "that",
      "someone",
      "is",
      "having",
      "it",
      "means",
      "they",
      "don't",
      "enough",
      "money",
      "to",
      "pay",
      "for",
      "everything",
      "this",
      "situation",
      "would",
      "mean",
      "rent",
      "groceries",
      "get",
      "paid",
      "use",
      "the",
      "very",
      "quickly",
      "probably",
      "things",
      "need",
      "and",
      "obviously",
      "sure",
      "too",
      "do",
      "anything",
      "fun",
      "you",
      "has",
      "they're",
      "struggling",
      "a",
      "little",
      "bit",
      "of",
      "help",
    ],
    link: "(6)https://www.youtube.com/watch?v=DYpqs413zB8",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/trouble.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=p%2B6b24CtNSSFlr43eW94YOFzXYZnaOhnaiZriafiTT9Dl4QmBKltK5ZAvWthpsFHV0oq%2BbiL0Lq27TAwRLNtEC2z5Tx0JLrZJBnbCJlWCHuPVYuQMQ4PDWHCvHQ7hqipJpEodN9GGz1a45SoCbzJ00gnAKjWHTYHMjvYfcwtWougs8xwzNQelTODQYGGazQy9Obz3X0Km%2B0vRmxczGEF0lkaXo6%2BcF07v5QBs6Yimfj5UZKuZ1IShOxoJ9XMvx7itp4whRB4dvqHNqc0hdTNSpsU6S8nOu9xJo5%2BI6x3nxaKitiT7P%2BKQzZW87b6F3Sfu8nybgLtgYg1sRlLfiLp0Q%3D%3D",
    id: "So sometimes people have trouble making ends meet. In English, when we say that someone is having trouble making ends meet, it means that they don't have enough money to pay for everything. In this situation, it would mean they don't have enough money for rent, they don't have enough money for groceries. When they get paid, they use the money very quickly, probably for things they need. And they obviously, and for sure don't have money too. To do anything fun. So when you say someone has trouble making ends meet, it means that they're struggling. They probably need a little bit of help.",
    story_id: 1104,
    word_count: 58,
    all_intersection_count: 39,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "difference",
      "between",
      "among",
      "standing",
      "these",
      "rows",
      "there's",
      "row",
      "path",
      "was",
      "move",
      "over",
      "stand",
      "there",
      "all",
      "around",
      "front",
      "left",
      "behind",
    ],
    words: [
      "so",
      "let's",
      "talk",
      "about",
      "the",
      "difference",
      "between",
      "among",
      "and",
      "right",
      "now",
      "I",
      "am",
      "standing",
      "these",
      "two",
      "flower",
      "rows",
      "there's",
      "a",
      "row",
      "of",
      "flowers",
      "here",
      "path",
      "but",
      "if",
      "was",
      "to",
      "move",
      "over",
      "stand",
      "there",
      "are",
      "all",
      "around",
      "me",
      "in",
      "front",
      "left",
      "behind",
      "I'm",
    ],
    link: "(4)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Q4l02JSRVQifidErfUAgJD54U1QLZapq5IyqPLsrEhz%2FylNEhRmtXMbYNMSAuxTbu6R2h0Ggj%2Bq2HYEkmVcFiLWYUcDp0WKnskBN6UqSOWlr%2FIeDvEXe7vXc4U4EQOtFOtXFVjmxcySRhXR7405UoB1VWobNY02z63vaKVfkUuY5fROoHNhU4RXPyWATjn5hoyE6kVu7GvDnXW9VkVmqaLT3WQPYCe7%2BlTRozJS5V%2BwEA5WfcvJC0PGAoiYZAh7JMpepEnRpuR%2FZUl1dA8TaiW9pX1hlSdhtntxaPNIIC69Ekjc%2FWIoJS12h5hihiZkx%2BroAQcphk8NM6CmbsAnblw%3D%3D",
    id: "So let's talk about the difference between among and between. Right now, I am standing between these two flower rows. There's a row of flowers here. There's a row of flowers here. And there's a path right here. I am standing between the flower rows. But if I was to move over here. If I was to stand here. Now, there are flowers all around me. I am standing among the flowers. There are flowers in front. There's flowers to the left, to the right, and behind me. I am standing among the flowers. So if I'm here, I'm standing between the flower rows. If I stand over here, I'm standing among the flowers.",
    story_id: 1259,
    word_count: 42,
    all_intersection_count: 23,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "everybody",
      "left",
      "song",
      "side",
      "over",
      "those",
      "up",
      "great",
      "raise",
      "hand",
      "ready",
      "salamander",
      "woo-hoo",
      "job",
      "try",
      "again",
      "change",
      "order",
      "listen",
    ],
    words: [
      "oh",
      "hi",
      "ok",
      "everybody",
      "let's",
      "do",
      "the",
      "left",
      "and",
      "right",
      "song",
      "your",
      "side",
      "is",
      "over",
      "here",
      "now",
      "get",
      "those",
      "hands",
      "up",
      "great",
      "when",
      "I",
      "say",
      "raise",
      "hand",
      "high",
      "ready",
      "we",
      "go",
      "salamander",
      "woo-hoo",
      "good",
      "job",
      "try",
      "it",
      "again",
      "but",
      "change",
      "order",
      "please",
      "listen",
    ],
    link: "https://www.englishcentral.com/video/38851",
    type: "story",
    pdf_name: null,
    storage_link: null,
    id: "Oh, hi! OK everybody, let's do the left and right song!\nYour left side is over here, and your right side is over here.\nNow get those hands up.\nOK, great!\nWhen I say left, raise your left hand high.\nWhen I say right, raise your right hand high.\nEverybody ready?\nOK, here we go!\nLeft, right.\nLeft, right.\nLeft, right.\nLeft, right.\nSalamander!\nWoo-hoo! Good job!\nLet's try it again, but we change the order.\nPlease listen!\nLeft, left. Right, right.\nLeft, left. Right, right.\nSalamander!",
    story_id: 940,
    word_count: 43,
    all_intersection_count: 24,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "these",
      "items",
      "called",
      "sliver",
      "splinter",
      "pull",
      "instead",
      "dental",
      "floss",
      "clean",
      "between",
      "teeth",
      "simpler",
      "easier",
      "tweezers",
      "again",
      "flosser",
      "newer",
      "version",
    ],
    words: [
      "do",
      "you",
      "know",
      "what",
      "these",
      "two",
      "items",
      "are",
      "called",
      "in",
      "English",
      "something",
      "we",
      "use",
      "when",
      "have",
      "a",
      "sliver",
      "or",
      "splinter",
      "and",
      "need",
      "to",
      "pull",
      "it",
      "out",
      "this",
      "is",
      "instead",
      "of",
      "dental",
      "floss",
      "clean",
      "between",
      "our",
      "teeth",
      "because",
      "it's",
      "simpler",
      "easier",
      "tweezers",
      "again",
      "flosser",
      "newer",
      "version",
    ],
    link: "https://www.youtube.com/shorts/WXrV4fi34E8",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/tweezers.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ATxr4cmBqvXtdCOreZ5pxttyfQwGS2c3GELc5Uq7JS%2BHLuqxRZWPZrTj%2BL0erK5o0pne6ylQKVarNi2kgIQWXcQerYTMHxrgfx7rb%2Fo%2Fj7%2FJIpV6bYF0tdsifNFyGawZSlGHD0kU%2Frc4AZ%2BA%2BxrEGyk1FR0NsvyDmX5Yco3UouYh%2F7pIxBMpup0KZb3gubwmQN0NiA59wzAxUcncdfHmdzct%2B%2FKOmwv8eH33wzOJ7XUk3O2epysdRJRwVZvViq1RPyXjqDuZBxUpfCmnuWcpjnmZB3U1Is5HmavmvobltaJBMozsB75pjsZ5kVSpEgIfi25hnoYfJ4z4R%2Fo%2FaQrnqg%3D%3D",
    id: "Do you know what these two items are called in English? These are something we use when we have a sliver or splinter, and we need to pull it out. This is something we use instead of dental floss to clean in between our teeth because it's simpler and easier to use. These are called tweezers. Again, we use these when we have a splinter or sliver, and we need to pull it out. This is called a flosser. It's a newer version of dental floss. It's simpler and easier to use, and we use it to clean in between our teeth.",
    story_id: 1077,
    word_count: 45,
    all_intersection_count: 26,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "last",
      "before",
      "inside",
      "snow",
      "angel",
      "sure",
      "you've",
      "ever",
      "seen",
      "kids",
      "often",
      "angels",
      "lay",
      "ground",
      "back",
      "up",
      "there's",
      "kind",
      "shape",
    ],
    words: [
      "so",
      "the",
      "last",
      "thing",
      "I'm",
      "going",
      "to",
      "do",
      "before",
      "I",
      "go",
      "inside",
      "is",
      "make",
      "a",
      "snow",
      "angel",
      "not",
      "sure",
      "if",
      "you've",
      "ever",
      "seen",
      "one",
      "but",
      "kids",
      "in",
      "Canada",
      "often",
      "angels",
      "and",
      "this",
      "how",
      "you",
      "them",
      "lay",
      "on",
      "ground",
      "when",
      "get",
      "back",
      "up",
      "there's",
      "kind",
      "of",
      "shape",
      "an",
      "can",
      "see",
      "it",
    ],
    link: "https://www.youtube.com/shorts/s6G8S87qka4",
    type: "story",
    pdf_name: null,
    storage_link: "",
    id: "So the last thing I'm going to do before I go inside is to make a snow angel. I'm not sure if you've ever seen one, but kids in Canada often make snow angels. And this is how you make them. You lay on the ground and you do this. And when you get back up, there's kind of the shape of an angel on the ground. Can you see it?",
    story_id: 1090,
    word_count: 50,
    all_intersection_count: 31,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "little",
      "bit",
      "difference",
      "between",
      "*lend*",
      "*borrow*",
      "situation",
      "getting",
      "mortgage",
      "bank",
      "will",
      "lend",
      "borrow",
      "lender",
      "then",
      "borrower",
      "take",
      "course",
      "pay",
      "back",
    ],
    words: [
      "so",
      "I",
      "just",
      "want",
      "to",
      "talk",
      "a",
      "little",
      "bit",
      "about",
      "the",
      "difference",
      "between",
      "*lend*",
      "and",
      "*borrow*",
      "in",
      "situation",
      "of",
      "getting",
      "mortgage",
      "bank",
      "will",
      "lend",
      "you",
      "money",
      "borrow",
      "from",
      "do",
      "see",
      "is",
      "lender",
      "give",
      "are",
      "then",
      "borrower",
      "take",
      "course",
      "have",
      "pay",
      "it",
      "back",
    ],
    link: "(8)https://www.youtube.com/watch?v=hDGK1BCeP74&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=6",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/8lendborrow.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=mlszWSyrWSoBJSI6KDbyaKwxNnZdBzJ0LSN4aWhD3PQl0I7Bt4841E1QV8LrAVvW0OiV8cxB%2BWfrY0TYxkyofimXSZDV0NCJz%2BpS4Z2PKUVw5Fn6Bu44CT5WUNcfEWvY3BxRYMKLysPfVO5WuSF7akkX5F2Hp%2BqYs77uohs5keK1Ge88JuHDX4CmooDgDZaIdDJI%2FLzVSjIVS3p3eU%2FhoIQB0Bwh9sxsPN%2BfKzg%2B0umfZU9kz%2FKOrY0%2B0NnxY6RogNRQL27RY7q25YnD9Wsxsl2bnJIxnXZZ1L7Kw%2Btj3Io6FsOkSMGY1o5Y2nD8o%2Fxwm2KbQoPhY9SiknuegMeF9g%3D%3D",
    id: "So I just want to talk a little bit about the difference between *lend* and *borrow*. In the situation of getting a mortgage, the bank will lend you money and you will borrow money from the bank. So do you see the difference? The bank is the lender. The bank will give you money. The bank will lend you money. You are then the borrower. You will take money from the bank. You will borrow money from the bank. And then of course you do have to pay it back.",
    story_id: 1177,
    word_count: 42,
    all_intersection_count: 22,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "oops",
      "dropped",
      "paper",
      "ground",
      "choices",
      "bend",
      "over",
      "pick",
      "piece",
      "up",
      "stoop",
      "down",
      "kind",
      "flex",
      "waist",
      "could",
      "though",
      "knees",
      "little",
      "bit",
    ],
    words: [
      "oops",
      "I",
      "dropped",
      "my",
      "paper",
      "on",
      "the",
      "ground",
      "have",
      "two",
      "choices",
      "now",
      "can",
      "bend",
      "over",
      "to",
      "pick",
      "this",
      "piece",
      "of",
      "up",
      "or",
      "stoop",
      "down",
      "usually",
      "means",
      "kind",
      "flex",
      "at",
      "waist",
      "so",
      "could",
      "just",
      "and",
      "it",
      "when",
      "you",
      "though",
      "your",
      "knees",
      "a",
      "little",
      "bit",
    ],
    link: "(3)https://youtu.be/kNoCpWUksMw?si=4DfQ1mZ9TLzbcqFU",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/3learn30bodypositionsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Pwmo2%2BJP5XQJ6jTXcdU7%2B7g%2BRKkedHyvs5MFTLa1DrfdaHj8uUcP9vO1iqh777eN%2BWbdBz2HM%2FkXOReP5QpC6WjCeuzA6ZZZl%2F%2BD9VZORFoeUR4s4rH2Je7%2B%2BRyHE1r5jMlHOcOYNHr5JFTYfB3MsJBWIZALgTLl%2Fpssy4M%2B2mEuGC3d3i5w8dgIGCQdOdsaDddMcLCSMq1exMgWzMKV3RKn0n3PNLqryaOgNnChuAWx933PhIJccTnJ3RLY3%2FW4bo1x99iM6aCgrYhFAsYxRnpMs3W17XtGxMWzyB2LipudgwlgRNr0igJRRiI4R45edlmPWUFkl7158m7E%2B4pR2Q%3D%3D",
    id: "Oops. I dropped my paper on the ground. I have two choices now. I can bend over to pick this piece of paper up, or I can stoop down to pick this up. Bend over usually means to kind of flex at the waist, so I could just bend over and pick it up. When you stoop down, though, it usually means you bend your knees a little bit. So I could stoop down and pick this up, or I could bend over and pick it up.",
    story_id: 1319,
    word_count: 43,
    all_intersection_count: 23,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "wearing",
      "wrist",
      "also",
      "wedding",
      "ring",
      "finger",
      "describe",
      "these",
      "up",
      "shower",
      "ready",
      "before",
      "leave",
      "put",
      "word",
      "actually",
      "fitbit",
      "mentioned",
      "fitness",
      "tracker",
    ],
    words: [
      "so",
      "you",
      "can",
      "see",
      "that",
      "I'm",
      "wearing",
      "a",
      "watch",
      "on",
      "my",
      "wrist",
      "I",
      "also",
      "have",
      "wedding",
      "ring",
      "finger",
      "this",
      "is",
      "how",
      "we",
      "describe",
      "these",
      "two",
      "things",
      "wear",
      "in",
      "the",
      "morning",
      "when",
      "get",
      "up",
      "usually",
      "shower",
      "ready",
      "to",
      "go",
      "work",
      "but",
      "before",
      "leave",
      "put",
      "and",
      "use",
      "word",
      "talking",
      "about",
      "watches",
      "or",
      "actually",
      "fitbit",
      "should",
      "mentioned",
      "fitness",
      "tracker",
    ],
    link: "(4)https://youtu.be/O36OX5eg9cA?si=IYZ0t9JSSd_B6fs1",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4somethingonsomethingbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=FhT4D8ri2tyKYedadjzyjIXzocCqF3gwYHRqagvIv8NhaD9TpcYp%2FWKxxfV9f1C%2BQxRF1ba4M%2BdyKk9xlX4rfL8OgaludQZTwulL7L5EPb4ShX%2B0pPcOncpgoWk2QTt137Bs2CPXyepZ5gA40Ea0tX9JS9zm%2B0dWXZea3VOdOeD90PgvQ9yYOzQoN962HFn22Aei7FfU3OTU0e%2FZUfvFpHNnf7WaJ2yY76E9AHIjfXl%2Bsm6%2BV5CaLjbZbVAFq0osya16HWvE1MnzwffUDXDhmhof6q%2FpWJGrvu8ruzCV0z7Q8pp6bsiH76sFvlJBNuZemFgo2EZB7HNLCkDwBbC%2BDw%3D%3D",
    id: "So you can see that I'm wearing a watch on my wrist. I also have a wedding ring on my finger. This is how we describe these two things. I wear a watch on my wrist. I wear a wedding ring on my finger. In the morning when I get up, I usually have a shower, get ready to go to work. But before I leave, I put my watch on my wrist and I put my wedding ring on my finger. So we use the word on when talking about watches. Or this is actually a fitbit. I should have mentioned that a fitness tracker. I put this on my wrist and I put this ring on my finger.",
    story_id: 1353,
    word_count: 56,
    all_intersection_count: 36,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "favorite",
      "red",
      "guess",
      "green",
      "yeah",
      "blue",
      "definitely",
      "purple",
      "maybe",
      "black",
      "question",
      "again",
      "suppose",
      "silver",
      "pink",
      "white",
      "gold",
      "I've",
      "got",
      "brown",
    ],
    words: [
      "what's",
      "your",
      "favorite",
      "color",
      "my",
      "is",
      "red",
      "orange",
      "I",
      "don't",
      "know",
      "I'm",
      "sorry",
      "let",
      "me",
      "see",
      "yellow",
      "guess",
      "it's",
      "green",
      "yeah",
      "blue",
      "definitely",
      "purple",
      "that's",
      "it",
      "maybe",
      "no",
      "black",
      "the",
      "question",
      "again",
      "suppose",
      "silver",
      "pink",
      "love",
      "gray",
      "yes",
      "white",
      "gold",
      "I've",
      "got",
      "brown",
    ],
    link: "(5)https://www.youtube.com/watch?v=HVJNOeDvpME&list=PL31D7CB49ABAAB4DB&index=11",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/clothing5.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ubcsdFBtQsH86SfWka0qMUMP6UlgPjv5zK3HgkfX2MKGIj1BLCboBpgXejqjGMF6ONnzyu%2BlVVZDpLI48lONuVDcDvtMnoYnvcuA2EOd%2Bo2XkUXcHBZ15Am1hSjXL6leovZyllmPX0qCe17SzTqEaIbj2c8J3LHeFHXCJ%2FisveEafI6XaAvXnJ8Mst13FeHjojo2F5QvDKFlRsKLeSbzl3Rfsmf7gTn%2B4z6W2DqvpO4GRnuh8b9fEFVsKE%2FV0rSRgRrVLmyxcpBbuDkmhFBs%2Fd0m9kIhamaMYbMuNHM%2FOPEWuni%2FJOLEHvaP4Vrrr2wk9dtMtvDpLCZoRn0nM56wtA%3D%3D",
    id: "\nWhat's your favorite color?\nMy favorite color?\nMy favorite color is red.\n\nWhat's your favorite color?\nOrange. My favorite color is orange.\n\nMy favorite color? I don't know. I'm sorry. I don't know.\n\nMy favorite color? Let me see.\nMy favorite color is yellow.\n\nMy favorite color? I don't know. I guess it's green. Yeah. My favorite color is green.\n\nBlue. It's definitely blue.\n\nMy favorite color is, purple. That's it. Purple.\n\nWhat's my favorite color? Maybe it's... no. I'm sorry. I don't know.\n\nBlack. My favorite color is black.\n\nWhat's the question again?\nWhat's your favorite color?\nI suppose it's silver. Yeah. It's silver.\n\nPink. I love pink.\n\nI guess my favorite color is gray. Yes. Gray.\n\nWhite. My favorite color is white.\n\nMy favorite color? I don't know. Maybe it's gold.\n\nMy favorite color... I've got it. It's brown. My favorite color is brown.",
    story_id: 983,
    word_count: 43,
    all_intersection_count: 23,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "friend",
      "broke",
      "soon",
      "paycheck",
      "spends",
      "another",
      "loaded",
      "lots",
      "whenever",
      "paid",
      "puts",
      "bank",
      "spend",
      "welcome",
      "lesson",
      "there's",
      "first",
      "words",
      "any",
      "lot",
    ],
    words: [
      "I",
      "have",
      "a",
      "friend",
      "and",
      "he's",
      "always",
      "broke",
      "he",
      "never",
      "has",
      "money",
      "as",
      "soon",
      "gets",
      "paycheck",
      "spends",
      "it",
      "another",
      "who's",
      "loaded",
      "lots",
      "of",
      "does",
      "whenever",
      "paid",
      "puts",
      "the",
      "in",
      "bank",
      "doesn't",
      "spend",
      "so",
      "welcome",
      "to",
      "this",
      "English",
      "lesson",
      "about",
      "there's",
      "your",
      "first",
      "two",
      "words",
      "when",
      "someone",
      "is",
      "they",
      "don't",
      "any",
      "lot",
    ],
    link: "(8)https://www.youtube.com/watch?v=DYpqs413zB8&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/broke.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=vgQLtu1c3JsJfaqelU%2F9MzQVq5Foi9YMiPgPq4ugnw9lmYVCLOuosJ2FYY%2FEViiFWcvf%2FpM%2BLseDnP6y0ka4rSSnags56EFD8%2FsH7rNRSDsPqtSDS846UWzSH%2BW9suA0vMqaf81wNQV0JG4DTueu0UJb5cDUWzfrl7J3jTvzp0ixowu1jLjwn5KOXBVG3sZzzJ4J5%2BXsuQWHE1QoE7cXnujSZG7SHjKVnE%2BPt56sb6P2QtWalQGgs3yQivhNn4661E7ka5%2Fpzpl9%2F4nuBDSTlLSJA9pc0qXN8QlZrjU8w5BiJPCbHzujvpqv6ZOOYLuSnb5ia4fLoQld76%2FNhj7O1A%3D%3D",
    id: "I have a friend, and he's always broke. He never has money. As soon as he gets a paycheck, he spends it. And I have another friend who's loaded. He has lots of money. He always does. Whenever he gets paid, he puts the money in the bank and he doesn't spend it. So, welcome to this English lesson about money. And there's your first two words. When someone is broke, they don't have any money. When someone is loaded, they have a lot of it.",
    story_id: 1135,
    word_count: 51,
    all_intersection_count: 31,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "-",
      "weather",
      "sunny",
      "cloudy",
      "wait",
      "minute",
      "raining",
      "isn't",
      "snowing",
      "snow",
      "anymore",
      "hot",
      "maybe",
      "warm",
      "cool",
      "wrong",
      "cold",
      "first",
      "then",
      "excuse",
    ],
    words: [
      "-",
      "how's",
      "the",
      "weather",
      "today",
      "it's",
      "sunny",
      "oh",
      "good",
      "no",
      "cloudy",
      "okay",
      "wait",
      "a",
      "minute",
      "raining",
      "I'm",
      "sorry",
      "it",
      "isn't",
      "that's",
      "snowing",
      "yes",
      "I",
      "like",
      "snow",
      "anymore",
      "hot",
      "very",
      "well",
      "maybe",
      "warm",
      "you",
      "know",
      "cool",
      "wrong",
      "cold",
      "first",
      "then",
      "now",
      "excuse",
      "me",
      "have",
      "nice",
      "day",
      "thank",
    ],
    link: "https://www.youtube.com/watch?v=yszaiqhK1Aw&list=PL31D7CB49ABAAB4DB&index=7",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/weather.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=LUAXihclw2mIn8KECbFRGPiBreck3qRpGk3Wdyp9Uvo%2FpPJCexexEQrLW3Tjz7wxDMmQIDvB6sfz9iCI5M%2B40utGiX7xcmSWIvoYpBOUDO5FjiS39mseDXshJZEjmqV7BIDhIc%2Bu1FrDcVPq9oDxnR8d0plYPFxUwcARvbN1AItA7%2FeDywInBUgiKkLqbD4VGgxKnzsoB%2Fu3bJiGb5BEs3gSi3EYz13I0YEOtyAJfnmkRnOOl%2BU3H4GyVrXcpvhAUYQCAVwrtkxCVrRQD%2FgfaSpPWEDgy%2FNt9q%2BIhH4M3mNgwDNB0cOt1Q5CQbUxjADtJQbv8JHiV%2F3y54DeiPp%2BXA%3D%3D",
    id: "- How's the weather today?\n- It's sunny.\n- Oh, good.\n- No. It's cloudy.\n- Oh, okay.\n- Oh, wait a minute. It's raining.\n- It's raining? It's raining? It's raining.\n- Wait. I'm sorry. It isn't raining.\n- Oh, it isn't raining.\n- Oh, that's good.\n- It's snowing.\n- It's snowing?\n- Yes.\n- Okay. I like snow.\n- Oh, wait a minute. It isn't snowing anymore.\n- It isn't?\n- No. It's hot.\n- It's hot?\n- Yes.\n- It's very hot.\n- Okay. It's hot.\n- Well, maybe it isn't hot. It's warm.\n- Oh. It's warm. Okay.\n- You know, it isn't very warm. It's cool.\n- You know, it isn't very warm. It's cool.\n- I'm sorry. I'm wrong. It isn't cool. It's cold.\n- It's cold?\n- Yes. It's very cold.\n- First, it's sunny, then it's cloudy, then it's raining. Now, it's snowing, then it's hot, then it's warm. Then it's cool. Now it's cold.\n- Excuse me.\n- Yes?\n- Have a nice day.\n- Thank you.",
    story_id: 972,
    word_count: 46,
    all_intersection_count: 26,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "really",
      "lot",
      "around",
      "phrase",
      "simply",
      "errands",
      "Saturday",
      "quiet",
      "then",
      "realize",
      "actually",
      "might",
      "little",
      "bit",
      "generally",
      "drive",
      "different",
      "places",
      "certainly",
      "would",
    ],
    words: [
      "sometimes",
      "I'm",
      "really",
      "busy",
      "because",
      "I",
      "have",
      "a",
      "lot",
      "of",
      "running",
      "around",
      "to",
      "do",
      "in",
      "English",
      "when",
      "use",
      "the",
      "phrase",
      "simply",
      "talking",
      "about",
      "errands",
      "things",
      "that",
      "day",
      "think",
      "Saturday",
      "is",
      "going",
      "be",
      "nice",
      "quiet",
      "and",
      "then",
      "realize",
      "now",
      "you're",
      "not",
      "actually",
      "you",
      "this",
      "mean",
      "might",
      "little",
      "bit",
      "generally",
      "it",
      "means",
      "drive",
      "different",
      "places",
      "need",
      "but",
      "certainly",
      "if",
      "where",
      "doing",
      "would",
      "probably",
      "say",
    ],
    link: "(7)https://www. youtube.com/watch?v=g3GPNDe72UI",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/7whenrunningisntrunningbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=xQU0dzJgA0JXR3PsThfMW7oLOqiqcfosaGBDEW9x%2Bbz4ghNOsr5e%2FSbPPymqQyNEqBCNGAZIRIwRD6pGPmWW4UzWbbQFijqO35Evie%2BQtWzBPj9DD52koCRQr73PBpYAy4y7gmRT%2BqXDX4IG1gsrQu3g8lZEQCHOJb6n621rOX5hcEoh9abj5Z1OgYnvAVLMCAbtfo7bCpCVc0Ga7ezsTvvZwdDVe%2F6TxRoSLEaJQTa4tfKdMnuVoKqigEBJR9djnqqMPlo3EO84OkTsQyinsObg5EshMoNfh5TRqq0mxiVzu3TqV7aArf%2Bnmx2D3054lXIpM05g8YGIXfcGOAENNQ%3D%3D",
    id: "Sometimes I'm really busy because I have a lot of running around to do. In English, when I use the phrase running around, I'm simply talking about errands. I'm talking about things I have to do that day. Sometimes I think a Saturday is going to be a nice, quiet day, and then I realize I have a lot of errands to do. I have a lot of things to do. I have a lot of running around to do. Now, you're not actually running when you do this. I mean, you might be a little bit. Generally, it means I'm going to drive to different places to do different things that I need to do. But certainly if I have a day where I'm really, really busy doing a lot of errands, I would probably say I have a lot of running around to do.",
    story_id: 1234,
    word_count: 62,
    all_intersection_count: 42,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "took",
      "soda",
      "pop",
      "dry",
      "shook",
      "would",
      "gently",
      "possible",
      "kind",
      "shoot",
      "everywhere",
      "carefully",
      "slowly",
      "then",
      "reason",
      "did",
      "explode",
      "face",
      "away",
      "quickly",
    ],
    words: [
      "if",
      "I",
      "took",
      "this",
      "can",
      "of",
      "soda",
      "pop",
      "is",
      "Canada",
      "dry",
      "and",
      "shook",
      "it",
      "would",
      "want",
      "to",
      "open",
      "as",
      "gently",
      "possible",
      "because",
      "it's",
      "going",
      "kind",
      "shoot",
      "everywhere",
      "carefully",
      "slowly",
      "then",
      "for",
      "some",
      "reason",
      "did",
      "explode",
      "in",
      "my",
      "face",
      "probably",
      "run",
      "away",
      "quickly",
    ],
    link: "(2)https://www.youtube.com/watch?v=bJyW_s9p8vk",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/asgentlyas.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=aMySH9fXzIGxyXqnPK%2BIsmBuv2iqsqFanRYt0TButaVP7OyAwSlon4X5aGlCQBSdyQSbun9TXogJpyt3117EWdmLjf7UShcqdRvBLwiMCe69m57VLgvkfplHNtocgqzDDS5YAvmfXL%2F%2F8yMnTxmMmtJEszblTtz%2BrWYMKyqXBhe8d5Whia9pxIdW8uH%2B%2FdRcFcwdnU9NM7V8PtGME3cWxMp%2FzVQ0nHoheUZ2aTeeyw0w4m37fGHIVEm3dLkuWce9ivoL8VdHnAojUbHWIoUXJMbVZOgUGp9pqPCSAWZi5UpN3X08KhcMC2DeSoegd9W5myBiQ%2FPGipHdhVuZfJjNsA%3D%3D",
    id: "   If I took this can of soda, this can of pop, this is Canada dry. And if I shook it, I would want to open it as gently as possible because it's going to kind of shoot everywhere. I would want to open it as carefully as possible. I would want to open it as slowly as possible. And then if for some reason it did explode in my face, I would probably run away as quickly as possible.",
    story_id: 1130,
    word_count: 42,
    all_intersection_count: 22,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "next",
      "phrases",
      "wait",
      "hardly",
      "these",
      "excited",
      "happen",
      "jen",
      "were",
      "expecting",
      "first",
      "child",
      "would",
      "was",
      "arrival",
      "described",
      "really",
      "hard",
      "express",
      "excitement",
    ],
    words: [
      "the",
      "next",
      "two",
      "phrases",
      "are",
      "I",
      "can't",
      "wait",
      "or",
      "can",
      "hardly",
      "and",
      "these",
      "you",
      "use",
      "when",
      "you're",
      "excited",
      "about",
      "something",
      "that's",
      "going",
      "to",
      "happen",
      "jen",
      "were",
      "expecting",
      "our",
      "first",
      "child",
      "what",
      "would",
      "say",
      "people",
      "was",
      "so",
      "for",
      "arrival",
      "of",
      "that",
      "how",
      "described",
      "it",
      "really",
      "hard",
      "express",
      "excitement",
    ],
    link: "(5)https://www.youtube.com/watch?v=lLVqselymX8&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=10",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/5describingfeelingsaboutthefutureinenglish.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=CV9xu%2BbJ4HrdNdbM2zSg%2F6yXKxU3Bs4QtBVJh5UDDm6xW15ZDOBoeIiaBX6FBLoj7HpkpavOxQfdRBh9BWQqvxSa9Bf5%2FMLE4L4NYJNgdNFMfKTyAyHbORfzG9m76oO2oYVV%2BkRs7TQ4acCXgOwVJQ8R0WroVNpBDA2t7SJwa3inzLlPKj1djtx9wrd9VP93JZKbQwXz%2Bel3F%2Ba4x3X9cBYdCk8KpX%2FnyagynJv2yXg7trTEkvAroYf3APFUaOquRg%2Fn3peCKUa9E%2Fl4YUpJ9ZYx8IFY5%2BeeYy85L7JL0ku4mJIO0nMRAHhEtShQ85YcrG7%2BExbwsRjOIkm4btT42g%3D%3D",
    id: "The next two phrases are the phrases I can't wait or I can hardly wait. And these are phrases you use when you're excited about something that's going to happen. When Jen and I were expecting our first child, that's what I would say to people. I would say I can't wait or I can hardly wait. I was so excited for the arrival of our first child that that's how I described it. It was really hard to wait. So I would say I can't wait or I can hardly wait to express that excitement.",
    story_id: 1272,
    word_count: 47,
    all_intersection_count: 27,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "french",
      "fries",
      "delicious",
      "glad",
      "would",
      "many",
      "few",
      "health",
      "anybody",
      "else",
      "I'll",
      "Nancy",
      "David",
      "wonderful",
      "dinner",
      "everything",
      "terrific",
      "thanks",
      "inviting",
      "pleasure",
    ],
    words: [
      "how",
      "do",
      "you",
      "like",
      "the",
      "french",
      "fries",
      "I",
      "think",
      "they're",
      "delicious",
      "I'm",
      "glad",
      "them",
      "would",
      "care",
      "for",
      "some",
      "more",
      "yes",
      "please",
      "but",
      "not",
      "too",
      "many",
      "just",
      "a",
      "few",
      "my",
      "doctor",
      "says",
      "that",
      "are",
      "bad",
      "health",
      "here",
      "thank",
      "does",
      "anybody",
      "else",
      "want",
      "I'll",
      "have",
      "Nancy",
      "about",
      "David",
      "no",
      "very",
      "full",
      "it's",
      "wonderful",
      "dinner",
      "everything",
      "is",
      "terrific",
      "thanks",
      "inviting",
      "us",
      "pleasure",
    ],
    link: "(3)https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    type: "video",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/muchmany-3.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jcLqHon04HWKSysdieNypkvicPgHlyAS%2FPX1uliHfAobRGkQtb80WUqjbkutoY1477yp8utoF1BAIreKbQ2WKx9A5JmhyXXBnHI2wSykD1MrGwWnFjNB2hk2LeS7MlMZiyT285S4Jd5z%2F6C82XeNwx5cfz37FDYbufWiCEQe%2F59FLfyyCPa6qvbGI%2BWU8k1L5SGOyhN8k37ZSaLjnNiejzIg6vPMATz5N2a2bcIaLIlk5hDnoMxsn8UcC2VQYqa%2Fj0nOLykhfpDEXlr2yTlt7rL92r90igOudMPwFJeveWRDE5R0Zm0jBwYlHXXl0y1pOrb3t%2FrDmZPT9OnXQha8yg%3D%3D",
    id: "How do you like the French fries?\nI think they're delicious.\nI'm glad you like them. Would you care for some more?\nYes, please. But not too many. Just a few. My doctor says that too many French fries are bad for my health.\nHere you are.\nThank you.\nDoes anybody else want some more French fries?\nI'll have some more, Nancy. But please, not too many. Just a few.\nHow about you, David?\nNo. Thank you, Nancy. They're delicious, but I'm very full.\nIt's a wonderful dinner, Nancy.\nEverything is terrific.\nThanks for inviting us.\nMy pleasure.",
    story_id: 1019,
    word_count: 59,
    all_intersection_count: 39,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "job",
      "pay",
      "bills",
      "life",
      "save",
      "retirement",
      "budget",
      "manage",
      "though",
      "complete",
      "opposite",
      "spend",
      "constantly",
      "into",
      "debt",
      "owe",
      "lot",
      "loans",
      "credit",
      "card",
    ],
    words: [
      "so",
      "some",
      "people",
      "are",
      "good",
      "with",
      "money",
      "and",
      "bad",
      "when",
      "you're",
      "it",
      "means",
      "that",
      "you",
      "make",
      "at",
      "your",
      "job",
      "pay",
      "bills",
      "live",
      "a",
      "nice",
      "life",
      "save",
      "for",
      "retirement",
      "know",
      "how",
      "to",
      "budget",
      "manage",
      "though",
      "it's",
      "the",
      "complete",
      "opposite",
      "spend",
      "more",
      "than",
      "constantly",
      "going",
      "into",
      "debt",
      "probably",
      "owe",
      "lot",
      "of",
      "have",
      "loans",
      "credit",
      "card",
      "not",
      "thing",
    ],
    link: "(7)https://www.youtube.com/watch?v=DYpqs413zB8",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/goodwithmoney.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=fzeC1NtdGyZ5eWuAmDgeJFKW1SCZ1GA9u1L4VLctutY%2F9ya%2BvXcS16F9Ikk97WiRPX1I3r6jjdJtI0MO0Xu45YSp5%2F2rnFLxUyRF9cvofW9wJG8PVqyxWSM6hKIR%2BP5S2OnDQXraAWF9THzpmlbGZgeUuzDy%2BCiVW%2BId9O%2F78gpy8FXLocRwlDf8JX65OOMPD5w5lXVWFLA1UviX%2BxrUHoFB8APhULhLykucZDXe8dHYnBadGZMEDo06zZ53CU5rhYoVTBPeTRN7oZ9LqV4CutStnD68kKIjnGCIVvKYh1IBAQnd%2FiOstgw1mS%2BBmcLzrikx3h6TSqqAQqw5eDF%2BVw%3D%3D",
    id: "So some people are good with money, and some people are bad with money. When you're good with money, it means that you make money at your job, you pay your bills, you live a nice life, you save for retirement, you are good with money. You know how to budget. You know how to manage your money. When you're bad with money, though, it's the complete opposite. It means that you spend more than you make. You're constantly going into debt. You probably owe a lot of money. You probably have loans and credit card debt. When you're bad with money, it's not a good thing.",
    story_id: 1105,
    word_count: 55,
    all_intersection_count: 35,
    new_words_count: 20,
  },
  {
    row_new_words: [
      "8th",
      "question",
      "ask",
      "did",
      "great",
      "after",
      "goes",
      "trip",
      "fun",
      "could",
      "show",
      "curious",
      "would",
      "were",
      "went",
      "might",
      "answer",
      "even",
      "all",
      "myself",
    ],
    words: [
      "the",
      "8th",
      "question",
      "you",
      "can",
      "ask",
      "is",
      "who",
      "did",
      "go",
      "with",
      "this",
      "a",
      "great",
      "to",
      "after",
      "someone",
      "goes",
      "on",
      "trip",
      "or",
      "does",
      "something",
      "fun",
      "could",
      "just",
      "say",
      "want",
      "show",
      "that",
      "you're",
      "curious",
      "would",
      "like",
      "know",
      "were",
      "people",
      "went",
      "are",
      "thing",
      "so",
      "and",
      "they",
      "might",
      "answer",
      "by",
      "saying",
      "I",
      "my",
      "brother",
      "even",
      "oh",
      "all",
      "myself",
    ],
    link: "(8)https://www.youtube.com/watch?v=9QzSVPhLKsM",
    type: "story",
    pdf_name: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/810questionsyoumustknowbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=dqRkpgaFMsy4rM%2FOAf5VBUCHfpU2x7VTeeTSbkKLoITi8kTBFCZHQWVDli1uiJQgmbYcPzaQTSYwKjMOdaftKq51ltW3EDIqWcgf%2FGr1HNKV%2BN9pnZRCE9do2R5Fus9y3RAAdjh253EoIL%2BZAuc2jqMLhFCYl%2F9UvcOW7%2ByVRldPz7%2F%2FgMWOyD4dIZvKomtgHMzbB6OnW7j7A5U8DgIkTxUOV7sSx0G51gtQJHAqPthMvD7ka9b%2FOoLsLwzmUAWJmoOl6YUj7Q85uDIjvN2UkG1XTe%2B51qfCYzf0Sp3gAotH6hczbPBXNsdQpehF3uAk75yVjKoxNi3DKPTJS7b%2Bsg%3D%3D",
    id: "The 8th question you can ask is, who did you go with? This is a great question to ask after someone goes on a trip or after someone does something fun, you could just say, who did you go with? You want to show that you're curious? You would like to know who were the people who went on the trip with you? Who are the people who did that fun thing with you? So who did you go with? And they might answer by saying, I went with my brother. Or they might even say, oh, I just went all by myself. So a great question to ask. Who did you go with?",
    story_id: 1219,
    word_count: 54,
    all_intersection_count: 34,
    new_words_count: 20,
  },
];

export default storiesData;
