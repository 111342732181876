const B2Words = [
  "abandon",
  "absolute",
  "absorb",
  "abstract",
  "academic",
  "accent",
  "acceptable",
  "accidentally",
  "accommodate",
  "accommodation",
  "accompany",
  "accomplish",
  "account",
  "accountant",
  "accuracy",
  "accurate",
  "accurately",
  "accuse",
  "acknowledge",
  "acquire",
  "acre",
  "activate",
  "actual",
  "adapt",
  "addiction",
  "additional",
  "additionally",
  "address",
  "adequate",
  "adequately",
  "adjust",
  "administration",
  "adopt",
  "advance",
  "affair",
  "affordable",
  "afterwards",
  "aged",
  "agency",
  "agenda",
  "aggressive",
  "agriculture",
  "aid",
  "AIDS",
  "aircraft",
  "alarm",
  "alien",
  "alongside prep",
  "alter",
  "altogether",
  "ambitious",
  "ambulance",
  "amount",
  "amusing",
  "analyst",
  "ancestor",
  "anger",
  "angle",
  "animation",
  "anniversary",
  "annual",
  "annually",
  "anticipate",
  "anxiety",
  "anxious",
  "apology",
  "apparent",
  "apparently",
  "appeal",
  "applicant",
  "approach",
  "appropriate",
  "appropriately",
  "approval",
  "approve",
  "arise",
  "armed",
  "arms",
  "arrow",
  "artificial",
  "artistic",
  "artwork",
  "ashamed",
  "aspect",
  "assess",
  "assessment",
  "asset",
  "assign",
  "assistance",
  "associate",
  "associated",
  "association",
  "assume",
  "assumption",
  "assure",
  "astonishing",
  "athletic",
  "attachment",
  "attempt",
  "audio",
  "awareness",
  "awkward",
  "back",
  "bacteria",
  "badge",
  "balanced",
  "ballet",
  "balloon",
  "bar",
  "barely",
  "bargain",
  "barrier",
  "basement",
  "basically",
  "basket",
  "bat",
  "battle",
  "bear (deal with)",
  "beat",
  "beg",
  "being",
  "beneficial",
  "bent",
  "beside prep",
  "besides prep",
  "bet",
  "beyond",
  "bias",
  "bid",
  "bill",
  "biological",
  "bitter",
  "blame",
  "blanket",
  "blind",
  "blow",
  "bold",
  "bombing",
  "bond",
  "boost",
  "border",
  "bound",
  "breast",
  "brick",
  "brief",
  "briefly",
  "broad",
  "broadcast",
  "broadcaster",
  "broadly",
  "buck",
  "budget",
  "bug",
  "bullet",
  "bunch",
  "burn",
  "bush",
  "but (prep)",
  "cabin",
  "cable",
  "calculate",
  "canal",
  "cancel",
  "cancer",
  "candle",
  "capable",
  "capacity",
  "capture",
  "carbon",
  "cast",
  "castle",
  "casual",
  "catch",
  "cave",
  "cell",
  "certainty",
  "certificate",
  "chain",
  "chair",
  "chairman",
  "challenge",
  "challenging",
  "championship",
  "characteristic",
  "charming",
  "chart",
  "chase",
  "cheek",
  "cheer",
  "chief",
  "chop",
  "circuit",
  "circumstance",
  "cite",
  "citizen",
  "civil",
  "civilization",
  "clarify",
  "classic",
  "classify",
  "cliff",
  "clinic",
  "clip",
  "close",
  "closely",
  "coincidence",
  "collapse",
  "collector",
  "colony",
  "colorful",
  "combination",
  "comfort",
  "comic",
  "command",
  "commander",
  "commission",
  "commitment",
  "committee",
  "commonly",
  "comparative",
  "completion",
  "complex",
  "complicated",
  "component",
  "compose",
  "composer",
  "compound",
  "comprehensive",
  "comprise",
  "compulsory",
  "concentration",
  "concept",
  "concern",
  "concerned",
  "concrete",
  "conduct",
  "confess",
  "confidence",
  "conflict",
  "confusing",
  "confusion",
  "conscious",
  "consequently",
  "conservation",
  "conservative",
  "considerable",
  "considerably",
  "consideration",
  "consistent",
  "consistently",
  "conspiracy",
  "constant",
  "constantly",
  "construct",
  "construction",
  "consult",
  "consultant",
  "consumption",
  "contemporary",
  "contest",
  "contract",
  "contribute",
  "contribution",
  "controversial",
  "controversy",
  "convenience",
  "convention",
  "conventional",
  "convert",
  "convey",
  "convinced",
  "convincing",
  "cop",
  "cope",
  "core",
  "corporate",
  "corporation",
  "corridor",
  "council",
  "counter (flat surface)",
  "county",
  "courage",
  "coverage",
  "cowboy",
  "crack",
  "crash",
  "creation",
  "creativity",
  "creature",
  "credit",
  "crew",
  "crisis",
  "criterion",
  "critic",
  "critical",
  "critically",
  "criticism",
  "criticize",
  "crop",
  "crucial",
  "cruise",
  "cry",
  "cue",
  "cure",
  "curious",
  "current",
  "curriculum",
  "curve",
  "curved",
  "cute",
  "dairy",
  "dare",
  "darkness",
  "database",
  "date",
  "deadline",
  "deadly",
  "dealer",
  "debate",
  "debt",
  "decent",
  "deck",
  "declare",
  "decline",
  "decoration",
  "decrease",
  "deeply",
  "defeat",
  "defence",
  "defend",
  "defender",
  "delay",
  "delete",
  "deliberate",
  "deliberately",
  "delight",
  "delighted",
  "delighted",
  "delivery",
  "demand",
  "democracy",
  "democratic",
  "demonstrate",
  "demonstration",
  "deny",
  "depart",
  "dependent",
  "deposit",
  "depressed",
  "depressing",
  "depression",
  "depth",
  "derive",
  "desert",
  "deserve",
  "desire",
  "desperate",
  "desperately",
  "destruction",
  "detail",
  "detailed",
  "detect",
  "determination",
  "devote",
  "differ",
  "dig",
  "dime",
  "disability",
  "disabled",
  "disagreement",
  "disappoint",
  "disappointment",
  "disc",
  "discipline",
  "discount",
  "discourage",
  "dishonest",
  "dismiss",
  "disorder",
  "display",
  "distant",
  "distinct",
  "distinguish",
  "distract",
  "distribute",
  "distribution",
  "district",
  "disturb",
  "dive",
  "diverse",
  "diversity",
  "divide",
  "division",
  "divorce",
  "document",
  "domestic",
  "dominant",
  "dominate",
  "donation",
  "dot",
  "downwards",
  "dozen",
  "draft",
  "drag",
  "dramatic",
  "dramatically",
  "drought",
  "dull",
  "dump",
  "duration",
  "dynamic",
  "eager",
  "economics",
  "economist",
  "edit",
  "edition",
  "editorial",
  "efficient",
  "efficiently",
  "elbow",
  "elderly",
  "elect",
  "electronics",
  "elegant",
  "elementary",
  "eliminate",
  "elsewhere",
  "embrace",
  "emerge",
  "emission",
  "emotional",
  "emotionally",
  "emphasis",
  "emphasize",
  "empire",
  "enable",
  "encounter",
  "engage",
  "enhance",
  "enjoyable",
  "enquiry",
  "ensure",
  "entertaining",
  "enthusiasm",
  "enthusiastic",
  "entire",
  "entirely",
  "entrepreneur",
  "envelope",
  "equal",
  "equip",
  "equivalent",
  "era",
  "erupt",
  "essentially",
  "establish",
  "estate",
  "estimate",
  "ethic",
  "ethical",
  "ethnic",
  "evaluate",
  "evaluation",
  "even",
  "evident",
  "evil",
  "evolution",
  "evolve",
  "examination",
  "exceed",
  "exception",
  "excessive",
  "exclude",
  "excuse",
  "executive",
  "existence",
  "exotic",
  "expansion",
  "expectation",
  "expedition",
  "expense",
  "expertise",
  "exploit",
  "exploration",
  "expose",
  "exposure",
  "extend",
  "extension",
  "extensive",
  "extensively",
  "extent",
  "external",
  "extract",
  "extraordinary",
  "extreme",
  "fabric",
  "fabulous",
  "facility",
  "faculty",
  "failed",
  "failure",
  "faith",
  "fake",
  "fame",
  "fantasy",
  "fare",
  "fault",
  "favour",
  "feather",
  "fee",
  "feed",
  "feedback",
  "feel",
  "fellow",
  "figure",
  "file",
  "finance",
  "finding",
  "firefighter",
  "firework",
  "firm",
  "firm",
  "firmly",
  "fix",
  "flame",
  "flash",
  "flavor",
  "flexible",
  "float",
  "fold",
  "folding",
  "following",
  "fond",
  "fool",
  "forbid",
  "forecast",
  "forgive",
  "format",
  "formation",
  "former",
  "formerly",
  "fortunate",
  "fortune",
  "forum",
  "forward",
  "fossil",
  "found",
  "foundation",
  "founder",
  "fraction",
  "fragment",
  "framework",
  "fraud",
  "free",
  "freedom",
  "freely",
  "frequency",
  "frequent",
  "fuel",
  "fulfill",
  "full-time/adv",
  "fully",
  "function",
  "fund",
  "fundamental",
  "fundamentally",
  "funding",
  "furious",
  "furthermore",
  "gain",
  "gallon",
  "gaming",
  "gang",
  "gay",
  "gender",
  "gene",
  "generate",
  "genetic",
  "genius",
  "genre",
  "genuine",
  "genuinely",
  "gesture",
  "globalization",
  "globe",
  "golden",
  "goodness",
  "gorgeous",
  "govern",
  "grab",
  "grade",
  "gradually",
  "grand",
  "grant",
  "graphic",
  "graphics",
  "greatly",
  "greenhouse",
  "guarantee",
  "guideline",
  "habitat",
  "handle",
  "harbor",
  "harm",
  "harmful",
  "headquarters",
  "heal",
  "healthcare",
  "hearing",
  "heaven",
  "heel",
  "hell",
  "helmet",
  "hence",
  "herb",
  "hesitate",
  "hidden",
  "high",
  "hilarious",
  "hip",
  "hire",
  "historian",
  "hold",
  "hollow",
  "holy",
  "homeless",
  "honesty",
  "honey",
  "honour",
  "hook",
  "hopefully",
  "host",
  "house",
  "household",
  "housing",
  "humorous",
  "humour",
  "hunger",
  "hunt",
  "hunting",
  "hurt",
  "hypothesis",
  "icon",
  "ID",
  "ideal",
  "identical",
  "illusion",
  "illustrate",
  "illustration",
  "imagination",
  "immigration",
  "immune",
  "impatient",
  "implement",
  "implication",
  "imply",
  "impose",
  "impress",
  "impressed",
  "incentive",
  "inch",
  "incident",
  "income",
  "incorporate",
  "incorrect",
  "increasingly",
  "independence",
  "index",
  "indication",
  "industrial",
  "inevitable",
  "inevitably",
  "infection",
  "infer",
  "inflation",
  "info",
  "inform",
  "infrastructure",
  "inhabitant",
  "inherit",
  "initial",
  "initially",
  "initiative",
  "ink",
  "inner",
  "innovation",
  "innovative",
  "input",
  "insert",
  "insight",
  "insist",
  "inspector",
  "inspire",
  "install",
  "installation",
  "instance",
  "instant",
  "instantly",
  "institute",
  "institution",
  "insurance",
  "integrate",
  "intellectual",
  "intended",
  "intense",
  "interact",
  "interaction",
  "internal",
  "interpret",
  "interpretation",
  "interrupt",
  "interval",
  "invade",
  "invasion",
  "investigation",
  "investment",
  "investor",
  "isolate",
  "isolated",
  "issue",
  "jail",
  "jet",
  "joint",
  "journalism",
  "joy",
  "judgement",
  "junior",
  "jury",
  "justice",
  "justify",
  "kindergarten",
  "kit",
  "labour",
  "ladder",
  "landing",
  "landscape",
  "lane",
  "largely",
  "lately",
  "latest",
  "launch",
  "leadership",
  "league",
  "lean",
  "leave",
  "legend",
  "lens",
  "level",
  "licence",
  "lifetime",
  "lighting",
  "likewise",
  "limitation",
  "limited",
  "line",
  "literally",
  "literary",
  "litter",
  "lively",
  "load",
  "loan",
  "logical",
  "logo",
  "long-term",
  "loose",
  "lord",
  "lottery",
  "low",
  "lower",
  "loyal",
  "lung",
  "lyric",
  "magnificent",
  "maintain",
  "majority",
  "make",
  "makeup",
  "making",
  "manufacture",
  "manufacturing",
  "map",
  "marathon",
  "margin",
  "marker",
  "martial",
  "mass",
  "massive",
  "master",
  "matching",
  "mate",
  "material",
  "maximum",
  "means",
  "measurement",
  "mechanic",
  "mechanical",
  "mechanism",
  "medal",
  "medication",
  "medium",
  "melt",
  "membership",
  "memorable",
  "metaphor",
  "military",
  "miner",
  "mineral",
  "minimum",
  "minister",
  "minor",
  "minority",
  "miserable",
  "mission",
  "mistake",
  "mixed",
  "mode",
  "model",
  "modest",
  "modify",
  "monitor",
  "monster",
  "monthly",
  "monument",
  "moral",
  "moreover",
  "mortgage",
  "mosque",
  "mosquito",
  "motion",
  "motivate",
  "motivation",
  "motor",
  "mount",
  "moving",
  "multiple",
  "multiply",
  "mysterious",
  "myth",
  "naked",
  "narrow",
  "nasty",
  "national",
  "navigation",
  "nearby",
  "neat",
  "necessity",
  "negative",
  "negotiate",
  "negotiation",
  "nerve",
  "neutral",
  "nevertheless",
  "newly",
  "nickel",
  "nightmare",
  "norm",
  "notebook",
  "notion",
  "novelist",
  "nowadays",
  "numerous",
  "nursing",
  "nutrition",
  "obesity",
  "obey",
  "object",
  "objective",
  "obligation",
  "observation",
  "observe",
  "observer",
  "obstacle",
  "obtain",
  "occasionally",
  "occupation",
  "occupy",
  "offence",
  "offend",
  "offender",
  "offensive",
  "official",
  "ongoing",
  "opening",
  "openly",
  "opera",
  "operate",
  "operator",
  "opponent",
  "oppose",
  "opposed",
  "opposition",
  "optimistic",
  "orchestra",
  "organ",
  "organic",
  "origin",
  "otherwise",
  "outcome",
  "outer",
  "outfit",
  "outline",
  "output",
  "outstanding",
  "overall",
  "overcome",
  "overnight",
  "owe",
  "ownership",
  "oxygen",
  "pace",
  "package",
  "packet",
  "palm",
  "panel",
  "panic",
  "parade",
  "parallel",
  "parliament",
  "part-time/adv",
  "participant",
  "participation",
  "partly",
  "partnership",
  "passage",
  "passionate",
  "password",
  "patch",
  "patience",
  "patient",
  "pause",
  "peer",
  "penalty",
  "pension",
  "perceive",
  "perception",
  "permanent",
  "permanently",
  "permit",
  "perspective",
  "pharmacy",
  "phase",
  "phenomenon",
  "philosophy",
  "physician",
  "pick",
  "picture",
  "pile",
  "pill",
  "pitch",
  "pity",
  "placement",
  "plain",
  "plot",
  "plus",
  "pointed",
  "popularity",
  "portion",
  "pose",
  "position",
  "positive",
  "possess",
  "potential",
  "potentially",
  "power",
  "praise",
  "precede",
  "precious",
  "precise",
  "precisely",
  "predictable",
  "preference",
  "pregnant",
  "preparation",
  "presence",
  "preserve",
  "presidential",
  "price",
  "pride",
  "primarily",
  "prime",
  "principal",
  "principle",
  "print",
  "prior",
  "priority",
  "privacy",
  "probability",
  "probable",
  "procedure",
  "proceed",
  "process",
  "produce",
  "professional",
  "programming",
  "progress",
  "progressive",
  "prohibit",
  "project",
  "promising",
  "promotion",
  "prompt",
  "proof",
  "proportion",
  "proposal",
  "propose",
  "prospect",
  "protection",
  "protein",
  "protester",
  "psychological",
  "psychologist",
  "psychology",
  "publication",
  "publicity",
  "publishing",
  "punk",
  "pupil",
  "purchase",
  "pure",
  "purely",
  "pursue",
  "pursuit",
  "puzzle",
  "questionnaire",
  "racial",
  "racism",
  "racist",
  "radiation",
  "rail",
  "random",
  "range",
  "rank",
  "rapid",
  "rapidly",
  "rat",
  "rate",
  "rating",
  "raw",
  "reach",
  "realistic",
  "reasonable",
  "reasonably",
  "rebuild",
  "recall",
  "receiver",
  "recession",
  "reckon",
  "recognition",
  "recover",
  "recovery",
  "recruit",
  "reduction",
  "referee",
  "refugee",
  "regard",
  "regional",
  "register",
  "registration",
  "regret",
  "regulate",
  "regulation",
  "reinforce",
  "relatively",
  "relevant",
  "relief",
  "relieve",
  "relieved",
  "rely",
  "remark",
  "remarkable",
  "remarkably",
  "reporting",
  "representative",
  "reputation",
  "requirement",
  "rescue",
  "reserve",
  "resident",
  "resign",
  "resist",
  "resolution",
  "resolve",
  "resort",
  "restore",
  "restrict",
  "restriction",
  "r�sum�",
  "retail",
  "retain",
  "retirement",
  "reveal",
  "revenue",
  "revision",
  "revolution",
  "reward",
  "rhythm",
  "rid",
  "ridiculous",
  "risky",
  "rival",
  "rob",
  "robbery",
  "rocket",
  "romance",
  "root",
  "rose",
  "roughly",
  "round",
  "routine",
  "rub",
  "rubber",
  "ruin",
  "rural",
  "rush",
  "sample",
  "satellite",
  "satisfaction",
  "satisfied",
  "satisfy",
  "saving",
  "scale",
  "scandal",
  "scare",
  "scenario",
  "schedule",
  "scheme",
  "scholar",
  "scholarship",
  "scratch",
  "scream",
  "screen",
  "screening",
  "seat",
  "sector",
  "secure",
  "seek",
  "seeker",
  "select",
  "selection",
  "self",
  "seminar",
  "senior",
  "sense",
  "sensitive",
  "sentence",
  "sequence",
  "session",
  "settle",
  "settler",
  "severe",
  "severely",
  "sexy",
  "shade",
  "shadow",
  "shallow",
  "shame",
  "shape",
  "shaped",
  "shelter",
  "shift",
  "ship",
  "shock",
  "shocked",
  "shocking",
  "shooting",
  "shore",
  "short-term",
  "shortage",
  "shortly",
  "shot",
  "sibling",
  "sidewalk",
  "signature",
  "significance",
  "significant",
  "significantly",
  "silence",
  "silk",
  "sincere",
  "skilled",
  "skull",
  "slave",
  "slide",
  "slight",
  "slip",
  "slogan",
  "slope",
  "so-called",
  "solar",
  "somehow",
  "sometime",
  "somewhat",
  "sophisticated",
  "soul",
  "spare",
  "specialist",
  "specialise",
  "species",
  "specify",
  "spectacular",
  "spectator",
  "speculate",
  "speculation",
  "speed",
  "spice",
  "spill",
  "spiritual",
  "spite",
  "split",
  "spoil",
  "spokesman",
  "spokesperson",
  "spokeswoman",
  "sponsor",
  "sponsorship",
  "spot",
  "spread",
  "stable",
  "stage",
  "stall",
  "stance",
  "stand",
  "stare",
  "starve",
  "status",
  "steadily",
  "steady",
  "steam",
  "steel",
  "steep",
  "step",
  "sticky",
  "stiff",
  "stimulate",
  "stock",
  "stream",
  "strengthen",
  "stretch",
  "strict",
  "strictly",
  "strike",
  "striking",
  "stroke",
  "structure",
  "struggle",
  "stuff",
  "stunning",
  "subject",
  "submit",
  "subsequent",
  "subsequently",
  "suburb",
  "suffering",
  "sufficient",
  "sufficiently",
  "sum",
  "super",
  "surgeon",
  "surgery",
  "surround",
  "surrounding",
  "survey",
  "survival",
  "survivor",
  "suspect",
  "suspend",
  "sustainable",
  "swallow",
  "swear",
  "sweep",
  "switch",
  "sympathetic",
  "sympathy",
  "tag",
  "tale",
  "tank",
  "tap",
  "target",
  "tear",
  "technological",
  "teen",
  "temple",
  "temporarily",
  "temporary",
  "tendency",
  "tension",
  "term",
  "terminal",
  "terms",
  "terribly",
  "terrify",
  "territory",
  "terror",
  "terrorism",
  "terrorist",
  "testing",
  "textbook",
  "theft",
  "therapist",
  "therapy",
  "thesis",
  "thorough",
  "thoroughly",
  "threat",
  "threaten",
  "thumb",
  "thus",
  "time",
  "timing",
  "tissue",
  "title",
  "tone",
  "tough",
  "tournament",
  "trace",
  "track",
  "trading",
  "tragedy",
  "tragic",
  "trait",
  "transfer",
  "transform",
  "transition",
  "transmit",
  "trap",
  "treasure",
  "trial",
  "tribe",
  "trigger",
  "trillion",
  "trip",
  "troop",
  "tropical",
  "trouble",
  "truly",
  "trust",
  "try",
  "tsunami",
  "tune",
  "tunnel",
  "ultimate",
  "ultimately",
  "unacceptable",
  "uncertainty",
  "unconscious",
  "undergo",
  "undertake",
  "unexpected",
  "unfold",
  "unfortunate",
  "unique",
  "unite",
  "unity",
  "universal",
  "universe",
  "unknown",
  "upper",
  "upwards",
  "urban",
  "urge",
  "urgent",
  "usage",
  "useless",
  "valid",
  "value",
  "variation",
  "vary",
  "vast",
  "venue",
  "vertical",
  "very",
  "via",
  "victory",
  "viewpoint",
  "violence",
  "virtual",
  "visa",
  "visible",
  "vision",
  "visual",
  "vital",
  "vitamin",
  "volume",
  "voluntary",
  "voting",
  "wage",
  "wander",
  "warming",
  "way",
  "weakness",
  "wealth",
  "wealthy",
  "weekly",
  "weird",
  "welfare",
  "wheat",
  "whereas",
  "wherever",
  "whisper",
  "whoever",
  "whom",
  "widely",
  "widespread",
  "wildlife",
  "willing",
  "wind",
  "wire",
  "wisdom",
  "wise",
  "withdraw",
  "witness",
  "wolf",
  "workforce",
  "workplace",
  "workshop",
  "worm",
  "worse",
  "worst",
  "worth",
  "wound",
  "wrap",
  "wrist",
  "wrong",
  "yet",
  "zone",
];

export default B2Words;
