import { Box, Button, Link as MuiLink, Typography } from "@mui/material";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import getRandomGreetings from "../../../common/getRandomGreetings";
import isAdmin from "../../../common/isAdmin";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import {
  EnglishQuote,
  EnglishStory,
  Module,
  Sentence,
  UserSentence,
  Word,
} from "../../../types/supabase";
import AudioPlayer from "../../Audio/AudioPlayer";
import WordAudio from "../../Audio/WordAudio";
import MenuLayout from "../../Layout/MenuLayout";
import MicSentences from "../../Mic/MicSentences";
import SentencePazleItem from "../../Sentence/SentencePazleItem";
import UkrainianWord from "../../Sentence/UkrainianWord";
import HStack from "../../Unknown/HStack";
import Loader from "../../Unknown/Loader";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import useGlobalWords from "../../hooks/getGlobalWords";
import { lessons } from "../LearnToReadScreen";
import BuildWords from "../BuildWords";
import WordsCarousel from "../../Grammar/GrammarLesson/tabs/WordsCarousel";
import LettersCarousel from "../LettersCarousel";
import Stories from "../../Grammar/Stories";
import ReactPlayer from "react-player";
import Description from "../Description";
import getSentenceWords from "../../../common/getSentenceWords";
import YouTubePlayer from "../../Unknown/YouTubePlayer";
import ImageCarouselModal from "../../ImageCarouselModal";
import LessonTasks from "../LessonTasks";
import { TrainersConfig } from "../UkTrainers/constants";
import TrainersModalWithButton from "../UkTrainers/TrainersModalWithButton";
import LetterA1 from "../../Grammar/GrammarLesson/tabs/ReadBlocks/LetterA1";

const LearnToReadLesson: React.FC = () => {
  const { lessonId, lessonType } = useParams();
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const setLessonNumber = useLessonsStore((state) => state.setLessonNumber);

  useEffect(() => {
    if (lessonId) setLessonNumber(+lessonId);
  }, [lessonId]);

  const lesson = useMemo(() => {
    return lessonId ? lessons[lessonId] : null;
  }, [lessonId]);
  console.log("🚀 ~ lesson:", lesson);

  const globalWords = useLessonsStore((state) => state.globalWords);
  const [curentModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const setLessonData = useLessonsStore((state) => state.setLessonData);
  const [stories, setStories] = useState<EnglishStory[]>([]);
  const authUser = useLessonsStore((state) => state.authUser);

  const { lessonWords, allLessonWords, lessonLetters } = useMemo(() => {
    const initial = {
      lessonWords: Array.from(new Set([...((lessonId && lessons[lessonId]?.words) || [])])),
      allLessonWords: Array.from(
        new Set([
          ...((lessonId && lessons[lessonId]?.words) || []),
          ...(
            (lessonId &&
              lessons[lessonId].letters?.map((letter) => [letter.letter, letter.word]).flat()) ||
            []
          ).filter(Boolean),
        ]),
      ),
      lessonLetters: (lessonId && lessons[lessonId]?.letters) || [],
    };

    if (lessonId && lessons[lessonId]?.storyWithText) {
      initial.allLessonWords = Array.from(
        new Set([...initial.allLessonWords, ...stories.map((s) => s.words).flat()]),
      );
    }

    return initial;
  }, [stories]);

  const wordsGreeting = useMemo(() => {
    return getRandomGreetings("Всі слова складені.");
  }, []);

  const [wordIndex, setWordIndex] = useState(0);

  const { prevWords, currentWord } = useMemo(() => {
    const words = [...lessonWords];

    words.length = wordIndex;

    const currentWord = lessonWords[wordIndex];

    return { prevWords: words, currentWord };
  }, [lessonWords, wordIndex]);

  const areThereStories = !!(lessonId && lessons[lessonId].storyIds.length);
  const storyWithText = !!(lessonId && lessons[lessonId].storyWithText);

  const options = useMemo(() => {
    return { words: allLessonWords };
  }, [allLessonWords]);

  useGlobalWords(options);

  useEffect(() => {
    if (allLessonWords?.length) {
      const map = globalWords
        .filter(
          (w) => allLessonWords.includes(w.name) || allLessonWords.includes(w.name.toLowerCase()),
        )
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
          };
        }, {});

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, lessonWords]);

  useMountedWaitAsyncEffect(async () => {
    if (!lessonId) return;

    let stories = lessons[lessonId].storyIds;
    if (!stories?.length) return;
    if (stories && typeof stories[0] === "object") {
      stories = stories.map((story: any) => story.id);
    }

    const { data } = await supabaseClient.from("english_verses").select().in("id", stories);

    if (data) setStories(data);
  }, [lessons, lessonId]);

  const playerRef = useRef(null);

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (stories.length) {
      const [story] = stories;
      if (story.clip_start) setCurrentTime(story.clip_start);
    }
  }, [stories]);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const timeUpdateLoop = () => {
    const [story] = stories;
    if (playerRef.current && story?.clip_start && story?.duration) {
      const start = story.clip_start;
      const duration = story.duration;
      const newCurrentTime = (playerRef.current as any).getCurrentTime();
      console.log("🚀 ~ newCurrentTime:", newCurrentTime);

      if (newCurrentTime > start + duration) {
        setCurrentTime(Math.ceil(start));
        setPlaying(false); // Pause after the duration
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else if (newCurrentTime < start) {
        // setPlaying(false); // Pause after the duration
        setCurrentTime(Math.ceil(start));
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else {
        setCurrentTime(newCurrentTime);
      }
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    timer = setInterval(() => {
      if (playing) {
        timeUpdateLoop();
      }
    }, 500);
    // }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentTime, stories, playing]);

  return (
    <MenuLayout isFluid>
      <Box width="100%" overflow="hidden" position="relative">
        <Box sx={{ backgroundColor: "white" }} py={3} px={3} mb={2} width="100%">
          <VStack gap={1}>
            <MuiLink color="secondary.dark" component={Link} to={`/learn-to-read`}>
              <Typography>Усі уроки</Typography>
            </MuiLink>
            <VStack>
              <Typography variant="h3">Урок {lessonNumber}</Typography>
              {isAdmin(authUser?.id) && lessonId && Number(lessonId) > 1 && (
                <Description curentModuleWordsMap={curentModuleWordsMap} />
              )}
              {isAdmin(authUser?.id) && (
                <LettersCarousel
                  letters={lessonLetters}
                  newLettersCount={lesson?.newLettersCount}
                  newSounsCount={lesson?.newSounsCount}
                />
              )}
              <WordsCarousel currentWord={currentWord} words={lessonWords || []} isReadToLearn />
              {/* {lessonId && lessonId === "7" && (
                <WordsCarousel
                  buttonLabel="Повторити слова"
                  currentWord={currentWord}
                  words={[
                    
                  ]}
                  isReadToLearn
                />
              )} */}
              <BuildWords
                curentModuleWordsMap={curentModuleWordsMap}
                wordsGreeting={wordsGreeting}
                prevWords={prevWords}
                moduleWords={lessonWords || []}
                wordsLength={lessonWords?.length}
              />

              {/* <TrainersModalWithButton
                trainer={TrainersConfig.UA_SORT_VOWELS_AND_CONSONANTS}
                buttonLabel="Тренажери"
              /> */}
              {/* {lessonId && lessonId === "7" && (
                <LetterA1 />
              )} */}
              {lessonId && lessonId === "6" && (
                <>
                  <TrainersModalWithButton trainer={TrainersConfig.UA_SORT_VOWELS_AND_CONSONANTS} />
                  <TrainersModalWithButton
                    trainer={TrainersConfig.EN_SORT_VOWELS_AND_CONSONANTS}
                    consonants={[
                      "n",
                      "t",
                      "p",
                      "r",
                      "d",
                      "s",
                      "b",
                      "l",
                      "g",
                      "c(k)",
                      "m",
                      "h",
                      "ng(ŋ)",
                      // "y(й)",
                      "y(j)",
                      "f",
                      "w",
                      "k",
                      "th(ð)",
                    ]}
                    vowels={[
                      "a(æ)",
                      "a(ɑː)",
                      "a(eɪ)",
                      "o(əʊ)",
                      "o(ɒ)",
                      "oo(ʊ)",
                      "oo(uː)",
                      "o(uː)",
                      "ou(uː)",
                      "ow(aʊ)",
                      "e(ɪ)",
                      "e(e)",
                      "i(i)",
                      "i(aɪ)",
                      "y(i)",
                      "u(ʌ)",
                    ]}
                    // buttonLabel="Тренажери"
                  />
                </>
              )}

              {authUser?.uuid !== "74aeedab-4b23-4b3f-b398-63c987775dc7" && <LessonTasks />}
              {lessonId === "999994" && (
                <Stories
                  buttonLabel="Читати"
                  stories={[
                    {
                      id: 1,
                      verse: `
                      
Letter l as <l> in leg, little, lamb and apple.
Letter e as <e> in pen, ten, red, test, bed, pet and little.
Letter e as <ə> in sister, apple, continent and letter.
Letter t as <t> in ten, note, not, pet, tennis, test, poet, stone, little, hat, cat, top and sport.
Letter r as <r> in red, sport, sister, star, car, arm, art, card, yard and Mary.
Letter i as <i> in big, little, continent, sister and tennis.
Letter i as <ə> in family.
Letter n as <n> in not, ten, pen, end, no, note, nose, hand, continent, tennis.
Letter p as <p> in pen, poet, apple, pet, sport and top.
Letter m as <m> in arm, lamb and Mary.
Letter g as <g> in leg, egg, go and big.
Letter h as <h> in hand, hat, had and hobby.
Letter y as <j> in yes and yard.
Letter y as <i> in hobby and Mary.
Letter c as <k> in cat, card, continent and cats.
Letter b as <b> in bed, big and hobby.
Letter d as <d> in dog, dogs, red, bed, card, yard, hand, end.
Letter o as <ɒ> in not, continent and hobby.
Letter o as <ɔː> in sport.
Letter o as <əʊ> in stone, no, go, note, poet, nose, rose and roses.
Letter a as <a:> in car, star, arm, art, card.
Letter a as <æ> in apple, hand, hat, lamb, family, fact.
Letter s as <s> in sister, test, tennis, test, stone, sport and star.
Letter s as <z> in dogs, rose, roses, nose and as.
Letter f as <f> in frog, family, fact and finger.
Letter n as <ŋ> in finger, ring and song.
                      `,
                      words: [
                        "letter",
                        "l",
                        "as",
                        "<l>",
                        "in",
                        "leg",
                        "little",
                        "lamb",
                        "and",
                        "apple",
                        "e",
                        "<e>",
                        "pen",
                        "ten",
                        "red",
                        "test",
                        "bed",
                        "pet",
                        "<ə>",
                        "sister",
                        "continent",
                        "t",
                        "<t>",
                        "note",
                        "not",
                        "tennis",
                        "poet",
                        "stone",
                        "hat",
                        "cat",
                        "top",
                        "sport",
                        "r",
                        "<r>",
                        "star",
                        "car",
                        "arm",
                        "art",
                        "card",
                        "yard",
                        "mary",
                        "i",
                        "<i>",
                        "big",
                        "family",
                        "n",
                        "<n>",
                        "end",
                        "no",
                        "nose",
                        "hand",
                        "p",
                        "<p>",
                        "m",
                        "<m>",
                        "g",
                        "<g>",
                        "egg",
                        "go",
                        "h",
                        "<h>",
                        "had",
                        "hobby",
                        "y",
                        "<j>",
                        "yes",
                        "c",
                        "<k>",
                        "cats",
                        "b",
                        "<b>",
                        "d",
                        "<d>",
                        "dog",
                        "dogs",
                        "o",
                        "<ɒ>",
                        "<ɔː>",
                        "<əʊ>",
                        "rose",
                        "roses",
                        "a",
                        "<a>",
                        "<æ>",
                        "fact",
                        "s",
                        "<s>",
                        "<z>",
                        "f",
                        "<f>",
                        "frog",
                        "finger",
                        "<ŋ>",
                        "ring",
                        "song",
                      ],
                      link: "",
                      type: "song",
                      grammar: false,
                    },
                  ]}
                />
              )}

              {Boolean(lesson?.bookImages?.length) && lesson?.bookImages && (
                <ImageCarouselModal images={lesson.bookImages} />
              )}

              {areThereStories && (
                <Box py={3} width="100%" maxWidth={500}>
                  {stories.map((story) => {
                    console.log("🚀 ~ story:", story);
                    if (!story.youtube_id) return null;

                    return (
                      <>
                        <YouTubePlayer
                          id={story.youtube_id}
                          start={story.clip_start || 0}
                          duration={story.duration || 10}
                        />
                        {storyWithText &&
                          story.verse.split("\n").map((line, index) => (
                            <HoverDetails
                              // isCenter
                              key={index}
                              words={
                                line.split(" ").map((w: any, index: number) => {
                                  const cleanWord = removePunctuationFromString(w).replace(
                                    "__",
                                    " ",
                                  );

                                  const word =
                                    curentModuleWordsMap[cleanWord] ||
                                    curentModuleWordsMap[cleanWord.toLowerCase()];
                                  // console.log("🚀 ~ cleanWord:", w, cleanWord, word);

                                  return {
                                    ...(word ? word : {}),
                                    word: word?.name || cleanWord,
                                    label: w.replace("__", " "),
                                  };
                                }) as any
                              }
                            />
                          ))}
                      </>
                    );
                  })}
                </Box>
              )}

              {/* {areThereStories && storyWithText && (
                <Stories stories={stories} buttonLabel="Відео" />
              )} */}
            </VStack>
          </VStack>
        </Box>
      </Box>
    </MenuLayout>
  );
};

export default LearnToReadLesson;
