import { Container, Grid, Typography } from "@mui/material";
import Box from "../../Box";
import filterRules from "./utils/filterRules";
import AccentWord from "../../../Difference/components/AccentWord";
import { RuleType } from "../../Root/grammarMap";
import { useLessonsStore } from "../../../../store/lessonsStore";
import AccordionRule from "./AccordionRule";
import HStack from "../../HStack";
import { PropsWithChildren } from "react";

const LineThroughTypography: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography fontWeight={600} fontSize={17} sx={{ textDecoration: "line-through" }}>
      {children}
    </Typography>
  );
};

export const differenceWords: RuleType[] = [
  {
    name: "Різниця між словами",
    rules: [
      {
        title: "climb / grow",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              зростати, підніматися
            </Typography>
          </Typography>,
        ],
        lesson: [5000],
        examples: [
          <>
            <AccentWord word="Climb" /> a ladder.
            <br />
            <AccentWord word="Climb" /> the corporate ladder.
            <br />
            <AccentWord word="Climb" /> Mount Everest.
            <br />
          </>,
          <>
            <AccentWord word="Grow" /> a beard.
            <br />
            <AccentWord word="Grow" /> old.
            <br />
            <AccentWord word="Grow" /> a business.
            <br />
            <AccentWord word="Grow" /> in confidence.
          </>,
        ],
      },
      {
        title: "like / as",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              як
            </Typography>
          </Typography>,
        ],
        lesson: [5000],
        examples: [
          <>
            <Typography component="span" fontWeight={700}>
              Використовується для порівняння.
            </Typography>
            <Typography component="span" fontWeight={700}>
              Використовується для перерахунку. Можна замінити словом "наприклад"
            </Typography>
            <br />
            <AccentWord word="Climb" /> a ladder.
            <br />
            <AccentWord word="Climb" /> the corporate ladder.
            <br />
            <AccentWord word="Climb" /> Mount Everest.
            <br />
          </>,
          <>
            <AccentWord word="Grow" /> a beard.
            <br />
            <AccentWord word="Grow" /> old.
            <br />
            <AccentWord word="Grow" /> a business.
            <br />
            <AccentWord word="Grow" /> in confidence.
          </>,
        ],
      },

      {
        title: "this / that",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              цe, цей
            </Typography>
          </Typography>,
        ],
        lesson: [113],
        examples: [
          <>
            <Typography component="span">
              Вказівний займенник який вказує на те що знаходиться близько до нас.
              <br />
              Часто використовується з поточним днем. (цей день) <br /> Може вказувати на те що було
              тільки що сказано.
            </Typography>
            <br />
            <AccentWord word="This" /> is a beautiful day.
            <br />
            <AccentWord word="This" /> tea tastes delicious.
            <br />
          </>,
          <>
            <Typography component="span">
              Вказівний займенник який вказує на те що знаходиться вдалині від нас. <br /> Також
              може вказувати на те що було тільки що сказано.
            </Typography>
            <br />
            Do you see <AccentWord word="that" />?
            <br />
            Please give me <AccentWord word="that" /> candy.
            <br />
          </>,
        ],
      },
      {
        title: "speak to / speak with",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              цe, цей
            </Typography>
          </Typography>,
        ],
        lesson: [31],
        examples: [
          <>
            <Typography component="span">
              Коли хтось говорить в направленні до іншого/iнших
            </Typography>
            <br />
            The president <AccentWord word="speaks to" /> the people.
            <br />
          </>,
          <>
            <Typography component="span">
              Коли хтось говорить з кимось. Коли є дискусія/діалог.
            </Typography>
            <br />
            They're <AccentWord word="speaking with" /> the doctor.
            <br />
          </>,
        ],
      },
      {
        title: "animal / creature",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              тварина, істота
            </Typography>
          </Typography>,
        ],
        lesson: [19],
        examples: [
          <Grid container>
            <Grid item xs={12}>
              <AccentWord word="animal" />
              <Typography>- ссавці, птахи, рептилії, риби, комахи</Typography>
            </Grid>
            <Grid item xs={12}>
              <AccentWord word="creature" />
              <Typography>
                - ширший термін, що охоплює будь-яку живу істоту, включаючи (іноді) міфічних звірів
                <br />- може бути більш загальним або навіть охоплювати рослини в деяких контекстах.
              </Typography>
            </Grid>
          </Grid>,
        ],
      },
      {
        title: "on / upon",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              на
            </Typography>
          </Typography>,
        ],
        lesson: [19],
        examples: [
          <Grid container>
            <Grid item xs={12}>
              <AccentWord word="on" />
              <Typography>- використовується у більшості випадків</Typography>
            </Grid>
            <Grid item xs={12}>
              <AccentWord word="upon" />
              <Typography>
                - звучить формально, дуже рідко використовується в повсякденному мовленні
                <br />- часто використовується в поезії
                <br />- використовується в деяких сталих фразах
              </Typography>
            </Grid>
          </Grid>,
        ],
      },
      {
        title: "do / make",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              робити
            </Typography>
          </Typography>,
        ],
        lesson: [18],
        examples: [
          <Grid container>
            <Grid item xs={6}>
              <AccentWord word="do" />
              <Typography>
                - зазвичай використовується для дій, роботи та загальної діяльності
              </Typography>
              <Typography>- акцент на дію, активність, процес</Typography>
            </Grid>
            <Grid item xs={6}>
              <AccentWord word="make" />
              <Typography>
                - зазвичай використовується для створення або конструювання чогось нового.
              </Typography>
              <Typography>- акцент на результат</Typography>
              {/* <Typography>разом з чимось або кимось</Typography> */}
            </Grid>
            <Grid item xs={6}>
              What are you <AccentWord word="doing" />?
              <br />
              I need to make a list of things to <AccentWord word="do" />.
            </Grid>
            <Grid item xs={6}>
              I want to <AccentWord word="make" /> a cake.
              <br />
              I need to <AccentWord word="make" /> a phone call.
            </Grid>
            {/* <Grid item xs={6}>
            </Grid> */}
          </Grid>,
        ],
      },
      {
        title: "talk / tell / say / speak",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              говорити, казати, розмовляти, спілкуватися
            </Typography>
          </Typography>,
        ],
        lesson: [18],
        examples: [
          <>
            <Typography component="span">Використовується для звернення до людини.</Typography>
            <br />
            <AccentWord word="Tell" /> me more about that.
            <br />
            She doesn't want to <AccentWord word="tell" /> me about that.
            <br />
          </>,
          <>
            <Typography component="span">
              Використовується у загальному значенні без звернення до людини.
              <br />
              Також використовується у прямій мові. (сказав, сказала, сказали)
            </Typography>
            <br />
            I want to <AccentWord word="say" /> something.
            <br />
            Do you want to <AccentWord word="say" /> something?
            <br />
          </>,
          <>
            <Typography component="span">Вміння або здатність говорити.</Typography>
            <br />
            I <AccentWord word="speak" /> English.
            <br />
            She can't <AccentWord word="speak" /> English.
            <br />
          </>,
          <>
            <Typography component="span">
              Коли є двоє і більше людей. Коли ми спілкуємося з кимось.
            </Typography>
            <br />
            Can I <AccentWord word="talk" /> to the manager, please?
            <br />
            Can we <AccentWord word="talk" /> about that later?
            <br />
          </>,
        ],
      },
      {
        title: "from / with",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              з
            </Typography>
          </Typography>,
        ],
        lesson: [12],
        examples: [
          <Grid container>
            <Grid item xs={6}>
              <AccentWord word="from" />
              <Typography>з (відки?), з (якого місця?)</Typography>
            </Grid>
            <Grid item xs={6}>
              <AccentWord word="with" />
              <Typography>з (ким?), з (чим?)</Typography>
              <Typography>разом з чимось або кимось</Typography>
            </Grid>
            <Grid item xs={6}>
              I'm <AccentWord word="from" /> Ukraine.
            </Grid>
            <Grid item xs={6}>
              I want to go <AccentWord word="with" /> you.
            </Grid>
            <Grid item xs={6}>
              Where are you <AccentWord word="from" />?
            </Grid>
            <Grid item xs={6}>
              Please let me know if you need help <AccentWord word="with" /> English.
            </Grid>
          </Grid>,
        ],
      },
      {
        title: "good / nice",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              хороший, гарний, гарно
            </Typography>
          </Typography>,
        ],
        lesson: [12],
        examples: [
          <Grid container>
            <Grid item xs={6}>
              <AccentWord word="good" />
              <Typography>
                - щось добре у значенні: корисне, правильно зроблено, справи йдуть по плану, хороша
                поведінка...
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <AccentWord word="nice" />
              <Typography>- щось приємне</Typography>
              <Typography>- те що справляє на нас позитивне враження</Typography>
              <Typography>- те з чим нам приємно перебувати разом</Typography>
            </Grid>
            <Grid item xs={6}>
              I'm <AccentWord word="good" />.<Typography>У мене все добре.</Typography>
            </Grid>
            <Grid item xs={6}>
              <LineThroughTypography>
                I'm <AccentWord word="nice" />.
              </LineThroughTypography>
              <Typography>Дуже дивно. Не варто так говорити.</Typography>
            </Grid>
            <Grid item xs={6}>
              It's <AccentWord word="good" />.<Typography>- щось корисне</Typography>
              <Typography>- щось хороше</Typography>
            </Grid>
            <Grid item xs={6}>
              It's <AccentWord word="nice" />.<Typography>- щось приємне</Typography>
              <Typography>- щось хороше</Typography>
            </Grid>
            <Grid item xs={6}>
              She's a <AccentWord word="good" /> girl.
              <Typography>- вона чесна</Typography>
              <Typography>- вона добра</Typography>
              <Typography>- вона робить щось хороше</Typography>
            </Grid>
            <Grid item xs={6}>
              She's a <AccentWord word="nice" /> girl.
              <Typography>- вона приємна</Typography>
              <Typography>- з не приємно бути поруч</Typography>
              <Typography>- вона просто хороша</Typography>
            </Grid>
            <Grid item xs={6}>
              It's <AccentWord word="good" /> to meet you.
              <Typography>- дуже неформально</Typography>
              <Typography>- може означати що ми просто раді знайомству</Typography>
              <Typography>- може означати що ми раді знайомству з якоїсь причини</Typography>
            </Grid>
            <Grid item xs={6}>
              It's <AccentWord word="nice" /> to meet you.
              <Typography>- вічлива форма сказати що тобі приємно познайомитися</Typography>
              <Typography>
                - використовується як в формальному так і в неформальному контексті
              </Typography>
            </Grid>
            <Grid item xs={6}>
              It's <AccentWord word="good" /> to see you.
              <Typography>- може означати що ми просто раді побачитися</Typography>
              <Typography>
                - може означати що ми раді побачити людину з якоїсь причини, можливо хотіли щось
                запитати або ж сказати
              </Typography>
            </Grid>
            <Grid item xs={6}>
              It's <AccentWord word="nice" /> to see you.
              <Typography>- універсальний спосіб сказати що нам приємно бачити людину</Typography>
            </Grid>
          </Grid>,
        ],
      },
      {
        title: "go / walk",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              ходити, йти
            </Typography>
          </Typography>,
        ],
        lesson: [8],
        examples: [
          <Grid container>
            <Grid item xs={6}>
              <AccentWord word="go" />
              <Typography>- йти в направленні до чогось</Typography>
              <Typography>- просто йти десь</Typography>
            </Grid>
            <Grid item xs={6}>
              <AccentWord word="walk" />
              <Typography>- гуляти, ходити без кінцевої точки</Typography>
              <Typography>- бути на прогулянці</Typography>
            </Grid>
            <Grid item xs={6}>
              Let's <AccentWord word="go" /> to the park.
              <Typography>Давай підемо (куди?) до парку</Typography>
              <LineThroughTypography>
                Let's <AccentWord word="go" /> in the park.
              </LineThroughTypography>
              <Typography>
                Давай походимо (де?) в парку
                <br />
                (звучить дуже дивно).
              </Typography>
            </Grid>
            <Grid item xs={6}>
              Let's <AccentWord word="walk" /> to the park.
              <Typography>Давай прогуляємося (куди?) до парку</Typography>
              Let's <AccentWord word="walk" /> in the park.
              <Typography>Давай погуляємо (де?) в парку</Typography>
            </Grid>
          </Grid>,
        ],
      },
      {
        title: "like / love",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              любити
            </Typography>
          </Typography>,
          "Важливо зазначити що кожна людина вкладає свій власний зміст у ці слова залежно від свого досвіду та цінностей.",
        ],
        lesson: [3],
        examples: [
          <>
            I <AccentWord word="like" /> you.
            <br />
            <Typography fontSize={14}>
              Симпатія та прив'язаність: Ця фраза виражає позитивні почуття до людини, інтерес до
              неї, симпатію та прихильність.
              <br />
              Дружба та романтика: Вона може використовуватись як в дружньому, так і в романтичному
              контексті, але на початкових етапах стосунків.
              <br />
              Повага до особистих меж: "I like you" не передбачає глибокої емоційної залежності та
              поваги до особистих меж.
            </Typography>
          </>,
          <>
            I <AccentWord word="love" /> you.
            <br />
            <Typography fontSize={14}>
              Глибоке та сильне почуття: Ця фраза вказує на глибоке, всеосяжне та тривале кохання.
              Вона виходить за рамки простої симпатії.
              <br />
              Відданість та близькість: "I love you" говорить про емоційну близькість, відданість,
              бажання бути разом у довготривалій перспективі.
              <br />
              Серйозність намірів: Використання цієї фрази часто свідчить про серйозність намірів та
              глибину почуттів.
            </Typography>
          </>,
        ],
      },
      {
        title: "study / learn",
        description: [
          <Typography component="span">
            Різниця у значенні:{" "}
            <Typography component="span" fontWeight={700}>
              навчатися
            </Typography>
          </Typography>,
        ],
        lesson: [1],
        examples: [
          <>
            I <AccentWord word="learn" /> English.
            <br />
            <Typography fontSize={14}>
              Підкреслює сам процес засвоєння мови, набуття знань та навичок. Можете означати
              вивчення англійської пасивно, наприклад, дивлячись фільми. Часто вивчення мови
              самостійно.
            </Typography>
          </>,
          <>
            I <AccentWord word="study" /> English.
            <br />
            <Typography fontSize={14}>
              Акцент на свідомому та цілеспрямованому зусиллі вивчення мови. Часто вивчення мови у
              навчальному закладі.
            </Typography>
          </>,
        ],
      },
    ],
  },
];

const WordsDifference = () => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  return (
    <>
      {differenceWords.map((rule, index) => {
        if (rule.isVisible === false) return null;
        return (
          <Box key={index} mb={7}>
            <Container>
              <Typography
                sx={{
                  marginBottom: 4,
                }}
                variant="h3"
              >
                {rule.name}
              </Typography>
            </Container>
            <Box
              sx={{
                position: "relative",
                overflowX: "auto",
                padding: 2,
              }}
            >
              {filterRules(rule.rules, lessonNumber).map((rule, index, rules) => (
                <Box minWidth={600} key={index}>
                  <AccordionRule
                    raw={rule.raw}
                    isActive={rule.lesson.includes(lessonNumber)}
                    title={rule.title}
                    description={rule.description}
                    examples={rule.examples}
                  />
                  {/* {index + 1 !== rules?.length ? ", " : ""} */}
                </Box>
              ))}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default WordsDifference;
