import { useEffect, useMemo, useState } from "react";
import { Drawer, Link as MuiLink } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import LearnWordSection from "../../../Unknown/LearnWordsSection";
import Box from "../../../Unknown/Box";
import Loader from "../../../Unknown/Loader";
import { Sentence, Word } from "../../../../types/supabase";
import getRandomGreetings from "../../../../common/getRandomGreetings";
import Typography from "../../../Unknown/Typography";
import LinearProgress from "../../../User/LinearProgress";
import SentencePazleItem from "../../../Sentence/SentencePazleItem";
import VStack from "../../../Unknown/VStack";
import HoverDetails from "../../../Word/HoverDetails";
import removePunctuationFromString from "../../../../common/removePunctuationFromString";
import Button from "../../../Unknown/Button";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";

const BuildSentences: React.FC<{
  lessonSentences: Sentence[];
  userSentences: string[];
  curerntModuleWordsMap: Record<string, Word>;
}> = ({ lessonSentences, userSentences, curerntModuleWordsMap }) => {
  const { lessonId, lessonType } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
    // if (onDrawerClose) onDrawerClose();
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Скласти речення ({lessonSentences.length})
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9999999,
        }}
      >
        <DrawerContent
          lessonSentences={lessonSentences}
          userSentences={userSentences}
          curerntModuleWordsMap={curerntModuleWordsMap}
          onClose={handleDrawerClose}
        />
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const DrawerContent: React.FC<{
  lessonSentences: Sentence[];
  userSentences: string[];
  curerntModuleWordsMap: Record<string, Word>;
  onClose: () => void;
}> = ({ lessonSentences, userSentences, curerntModuleWordsMap, onClose }) => {
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const prevSentences = useMemo(() => {
    const s = [...lessonSentences];
    s.length = sentenceIndex;
    return s;
  }, [lessonSentences, sentenceIndex]);

  const currentSentence = useMemo(() => {
    const s = lessonSentences[sentenceIndex];
    if (!s) return null;

    return s;
  }, [lessonSentences, sentenceIndex]);

  const allSentencesAreBuilt = !!lessonSentences.length && currentSentence === null;

  const sentenceGreeting = useMemo(() => {
    return getRandomGreetings("Всі реченя складені.");
  }, []);

  return (
    <Box sx={drawerContentStyle}>
      <ModalCloseButton onClose={onClose} />
      <LinearProgress
        height={7}
        elementsCount={lessonSentences.length}
        currentValue={sentenceIndex}
      />
      <Box>
        <Box sx={{ backgroundColor: "white" }} py={15} px={3} mb={2} width="100%">
          {!lessonSentences.length && <Loader />}
          {allSentencesAreBuilt && (
            <Box py={4}>
              <Typography color="primary" textAlign="center">
                {sentenceGreeting}
              </Typography>
              <Typography textAlign="center">
                Тепер прочитай всі складені речення та переклади.
              </Typography>
            </Box>
          )}
          {currentSentence !== null && (
            <Box>
              <SentencePazleItem
                sentence={currentSentence}
                belongsToUser={userSentences.includes(currentSentence.en)}
                handleNext={() => {
                  setSentenceIndex((prev) => prev + 1);
                }}
                moduleWordsMap={curerntModuleWordsMap}
              />
            </Box>
          )}
        </Box>

        {!!prevSentences.length && (
          <VStack sx={{ backgroundColor: "white" }}>
            <Box pt={5} pb={allSentencesAreBuilt ? 0 : 5} px={3}>
              {prevSentences.map((s, index) => {
                return (
                  <HoverDetails
                    // isCenter
                    key={index}
                    words={
                      s.en.split(" ").map((w: any, index: number) => {
                        const cleanWord = removePunctuationFromString(w);
                        const word =
                          curerntModuleWordsMap[cleanWord] ||
                          curerntModuleWordsMap[cleanWord.toLowerCase()];

                        return {
                          ...(word ? word : {}),
                          word: word?.name || cleanWord,
                          label: w,
                        };
                      }) as any
                    }
                  />
                );
              })}
            </Box>
            {allSentencesAreBuilt && (
              <VStack pb={5}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSentenceIndex(0);
                  }}
                >
                  Почати з початку
                </Button>
              </VStack>
            )}
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default BuildSentences;
