import { PropsWithChildren } from "react";
import Typography from "../Typography";

const H3Title: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography gutterBottom variant="h3">
      {children}
    </Typography>
  );
};

export default H3Title;
