import { useState } from "react";
import Box from "../../Unknown/Box";
import { Button, TextField } from "@mui/material";
import HStack from "../../Unknown/HStack";

const Questions: React.FC<{ onQaSubmit: (qa: Record<string, string>) => void }> = ({
  onQaSubmit,
}) => {
  const [question1, setQuestion1] = useState<{ title: string; text: string } | null>(null);
  const [question2, setQuestion2] = useState<{ title: string; text: string } | null>(null);
  const [question3, setQuestion3] = useState<{ title: string; text: string } | null>(null);

  return (
    <Box>
      <HStack>
        {question1 && (
          <Box>
            <TextField
              fullWidth
              color="primary"
              value={question1.title}
              multiline={true}
              placeholder="Question title"
              onChange={(event) => {
                if (question1) {
                  setQuestion1({
                    ...question1,
                    title: event.target.value,
                  });
                }
              }}
              type="text"
              variant="outlined"
            />
            <TextField
              fullWidth
              color="primary"
              value={question1.text}
              multiline={true}
              placeholder="Question answer"
              onChange={(event) => {
                if (question1) {
                  setQuestion1({
                    ...question1,
                    text: event.target.value,
                  });
                }
              }}
              type="text"
              variant="outlined"
            />
          </Box>
        )}
      </HStack>
      {!question3 && (
        <Button
          variant="outlined"
          onClick={() => {
            if (!question1) {
              setQuestion1({
                title: "",
                text: "",
              });
            } else if (!question2) {
              setQuestion2({
                title: "",
                text: "",
              });
            } else if (!question3) {
              setQuestion3({
                title: "",
                text: "",
              });
            }
          }}
        >
          Add question
        </Button>
      )}
      {question1?.title && (
        <Box>
          <Button
            onClick={() => {
              const qa: Record<string, string> = {
                [question1!.title]: question1!.text,
              };

              if (question2?.title) {
                qa[question2.title] = question2.text;
              }
              if (question3?.title) {
                qa[question3.title] = question3.text;
              }

              onQaSubmit(qa);
            }}
          >
            Submit answers
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Questions;
