import { Typography } from "@mui/material";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import VStack from "../../../Unknown/VStack";
import { TrainersConfig } from "../constants";
import { useMemo } from "react";
import AccentStrong, {
  AccentType,
} from "../../../Description/ModuleDescription/utils/AccentString";
import ItalicTitle from "../../../Title/ItalicTitle";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import formatLetterWithSound from "../../../../common/formatLetterWithSound";
// import { DragDropContext, Droppable, Draggable,  } from "react-beautiful-dnd";

const ErrorMessage: React.FC<{ text: any; type: TrainersConfig; onExcept: () => void }> = ({
  text,
  type,
  onExcept,
}) => {
  const message = useMemo(() => {
    if (type === TrainersConfig.UA_SORT_VOWELS_AND_CONSONANTS) {
      const [initialLetter, ...isNotSomething] = text.split(" ");

      const letter = formatLetterWithSound(initialLetter);

      const [letterWithoutSound, possibleSound] = initialLetter.split(/[()]/).filter(Boolean);
      const sound = possibleSound || letter;

      return (
        <Box>
          <ItalicTitle text="Неправильно" color="red" />
          <Typography>
            Літера <ItalicTitle text={letter} /> {isNotSomething.join(" ")}
          </Typography>
          {text.includes("не голосна") ? (
            <Typography>
              Літера{" "}
              <ItalicTitle
                text={
                  possibleSound
                    ? formatLetterWithSound(`${initialLetter} приголосна`)
                    : `${letter} приголосна`
                }
              />
              <br />
              Голосні літери утворюються за допомогою тільки голосу.
              <br />
              <ItalicTitle text="Голосні літери можна проспівати." />
              <br />
              Наприклад літера <ItalicTitle text="a" />: <ItalicTitle text="aaaaa" />.
              <br />
              Літеру <ItalicTitle text={letter} /> не можна проспівати.
            </Typography>
          ) : (
            <Typography>
              Літера{" "}
              <ItalicTitle
                text={
                  possibleSound
                    ? formatLetterWithSound(`${initialLetter} голосна`)
                    : `${letter} голосна`
                }
              />
              <br />
              Приголосні літери утворюються за допомогою{" "}
              <ItalicTitle text="шуму: губи, язик, зуби" /> та <ItalicTitle text="голосу" />.
              <br /> Приголосні літери <ItalicTitle text="не можна проспівати" />.
              <br />
              Літеру <ItalicTitle text={letter} /> можна проспівати.{" "}
              <ItalicTitle text={[sound, sound, sound, sound, sound].join("")} />
            </Typography>
          )}
        </Box>
      );
    }
  }, [type]);

  return (
    <VStack
      sx={{
        position: "fixed",
        width: "100%",
        top: 0,
        left: 0,
        minHeight: "100%",
        alignItems: "center",
        zIndex: 999999,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 0,
          backgroundColor: "black",
          opacity: 0.5,
        }}
      ></Box>
      <VStack
        maxWidth={400}
        zIndex={2}
        position="relative"
        p={10}
        sx={{
          backgroundColor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ModalCloseButton onClose={onExcept} />
        <Typography
          sx={{
            // color: "text.secondary",
            textAlign: "center",
            fontSize: 30,
          }}
        >
          {message}
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            onExcept();
          }}
        >
          Зрозуміло
        </Button>
      </VStack>
    </VStack>
  );
};

export default ErrorMessage;
