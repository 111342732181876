import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import Drawer from "../../../Unknown/Drawer";
import LinearProgress from "../../../User/LinearProgress";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import shuffle from "../../../../common/shuffle";
import VStack from "../../../Unknown/VStack";
import { Typography } from "@mui/material";
import HStack from "../../../Unknown/HStack";
import getRandomGreetings from "../../../../common/getRandomGreetings";
import CheckAnim from "../CheckAnim";
// import { DragDropContext, Droppable, Draggable,  } from "react-beautiful-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { useDrag, useDrop, DndProvider } from "react-dnd";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const letters = [
  "б",
  "в",
  "г",
  "з",
  "ж",
  "й",
  "л",
  "м",
  "н",
  "р",
  "д",
  "ґ",
  "п",
  "т",
  "к",
  "ф",
  "с",
  "ш",
  "щ",
  "ч",
  "х",
  "ц",
];

const config = [
  {
    correctLetter: "a",
    letters: shuffle(["a", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "e",
    letters: shuffle(["e", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "и",
    letters: shuffle(["и", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "i",
    letters: shuffle(["i", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "o",
    letters: shuffle(["o", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "y",
    letters: shuffle(["y", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "ю",
    letters: shuffle(["ю", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "я",
    letters: shuffle(["я", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "ї",
    letters: shuffle(["ї", ...shuffle(letters).slice(0, 9)]),
  },
  {
    correctLetter: "є",
    letters: shuffle(["є", ...shuffle(letters).slice(0, 9)]),
  },
];

// Типи та інтерфейси
interface Position {
  x: number;
  y: number;
}

interface Item {
  id: number;
  content: string;
  position: Position;
}

interface DragItem {
  index: number;
  id: number;
  originalPosition: Position;
}

interface DraggableItemProps {
  item: Item;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  position: Position;
  onPositionChange: (id: number, newPosition: Position) => void;
}

const ItemTypes = {
  BUTTON: "button" as const,
};

const DraggableItem: React.FC<DraggableItemProps> = ({ item, onPositionChange }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [translate, setTranslate] = useState<Position>({ x: 0, y: 0 });
  const [origin, setOrigin] = useState<Position>(item.position);

  const handleStart = (e: React.TouchEvent | React.MouseEvent) => {
    if (!elementRef.current) return;

    const event = "touches" in e ? e.touches[0] : e;

    setIsDragging(true);
    setOrigin({
      x: event.clientX - translate.x,
      y: event.clientY - translate.y,
    });

    e.preventDefault();
  };

  const handleMove = (e: TouchEvent | MouseEvent) => {
    if (!isDragging) return;

    const event = "touches" in e ? e.touches[0] : e;

    const newTranslate = {
      x: event.clientX - origin.x,
      y: event.clientY - origin.y,
    };

    setTranslate(newTranslate);
    onPositionChange(item.id, {
      x: newTranslate.x,
      y: newTranslate.y,
    });

    e.preventDefault();
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      handleMove(e);
      e.preventDefault();
    };

    const handleMouseMove = (e: MouseEvent) => {
      handleMove(e);
      e.preventDefault();
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove, { passive: false });
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("mouseup", handleEnd);
      window.addEventListener("touchend", handleEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("mouseup", handleEnd);
      window.removeEventListener("touchend", handleEnd);
    };
  }, [isDragging, origin]);

  useEffect(() => {
    setTranslate(item.position);
  }, [item.position]);

  const style: React.CSSProperties = {
    position: "absolute",
    transform: `translate(${translate.x}px, ${translate.y}px)`,
    cursor: isDragging ? "grabbing" : "grab",
    padding: "10px",
    backgroundColor: "#f0f0f0",
    borderRadius: "4px",
    boxShadow: isDragging ? "0 8px 16px rgba(0,0,0,0.2)" : "0 2px 4px rgba(0,0,0,0.1)",
    userSelect: "none",
    WebkitUserSelect: "none",
    touchAction: "none",
    transition: isDragging ? "none" : "box-shadow 0.2s ease",
    zIndex: isDragging ? 1000 : 1,
  };

  return (
    <div ref={elementRef} style={style} onMouseDown={handleStart} onTouchStart={handleStart}>
      {item.content}
    </div>
  );
};

const DragComponent: React.FC = () => {
  const [items, setItems] = useState<Item[]>([
    { id: 1, content: "button 1", position: { x: 50, y: 50 } },
    { id: 2, content: "button 2", position: { x: 50, y: 150 } },
    { id: 3, content: "button 3", position: { x: 50, y: 250 } },
  ]);

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      const dragItem = newItems[dragIndex];
      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, dragItem);
      return newItems;
    });
  }, []);

  const handlePositionChange = (id: number, newPosition: Position) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, position: newPosition } : item)),
    );
  };

  const containerStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    // height: "100vh",
    padding: "20px",
  };

  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <div style={containerStyle}>
        {items.map((item, index) => (
          <DraggableItem
            key={item.id}
            item={item}
            index={index}
            moveItem={moveItem}
            position={item.position}
            onPositionChange={handlePositionChange}
          />
        ))}
      </div>
    </DndProvider>
  );
};

const FindVowelLetter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [letterIndex, setLetterIndex] = useState(0);
  const [showGreetings, setShowGreetings] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setIsSuccess(false);
  }, [letterIndex]);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const letterConfig = useMemo(() => {
    return shuffle([...config, ...config, ...config]);
    // return shuffle([...config]);
  }, []);

  const currentLetter = letterConfig[letterIndex];

  const greeting = useMemo(() => {
    return getRandomGreetings("Всі голосні літери знайдені.");
  }, []);

  return (
    <Box>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Знайти голосну
      </Button>

      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <Box sx={drawerContentStyle}>
          <LinearProgress elementsCount={letterConfig.length - 1} currentValue={letterIndex} />
          <ModalCloseButton onClose={handleDrawerClose} />

          <VStack height="100%" py={20}>
            {/* <DragDropContext onDragEnd={handleOnDragEnd} backend={TouchBackend}> */}
            <DragComponent />
            {showGreetings && (
              <VStack py={5}>
                <Typography gutterBottom variant="h3">
                  {greeting}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleDrawerClose();
                    setShowGreetings(false);
                    setLetterIndex(0);
                  }}
                >
                  Завершити
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    setLetterIndex(0);
                    setShowGreetings(false);
                  }}
                >
                  Почати з початку
                </Button>
              </VStack>
            )}

            {!showGreetings && (
              <VStack>
                <Typography variant="h3">Знайди голосну літеру</Typography>

                <Box>
                  <HStack maxWidth={360}>
                    {currentLetter.letters.map((letter: string) => {
                      return (
                        <Letter
                          key={letter}
                          letter={letter}
                          goToNext={() => {
                            setIsSuccess(true);
                            if (letterIndex + 1 < letterConfig.length) {
                              setTimeout(() => {
                                setLetterIndex((prev) => prev + 1);
                              }, 1500);
                            } else {
                              setTimeout(() => {
                                setShowGreetings(true);
                              }, 1500);
                            }
                          }}
                          correctLetter={currentLetter.correctLetter}
                        />
                      );
                    })}
                  </HStack>
                </Box>
              </VStack>
            )}

            {isSuccess && (
              <Box py={5}>
                <CheckAnim />
              </Box>
            )}
          </VStack>
        </Box>
      </Drawer>
    </Box>
  );
};

const Letter: React.FC<{ letter: string; correctLetter: string; goToNext: () => void }> = ({
  letter,
  correctLetter,
  goToNext,
}) => {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  console.log("🚀 ~ isSuccess:", isSuccess);

  useEffect(() => {
    let timer: any = null;

    if (isError) {
      timer = setTimeout(() => {
        setIsError(false);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  useEffect(() => {
    let timer: any = null;

    if (isSuccess) {
      timer = setTimeout(() => {
        setIsSuccess(false);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isSuccess]);

  return (
    <Box>
      <Button
        color={isError ? "error" : isSuccess ? "primary" : "info"}
        variant={isSuccess ? "contained" : "text"}
        size="small"
        sx={{ fontSize: 22 }}
        onClick={() => {
          if (correctLetter === letter) {
            setIsSuccess(true);
            goToNext();
          } else {
            setIsError(true);
          }
        }}
      >
        {letter}
      </Button>
    </Box>
  );
};

export default FindVowelLetter;
