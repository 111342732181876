import {
  Badge,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { PropsWithChildren, useMemo, useState } from "react";
import ModalCloseButton from "../../../ModalCloseButton";
import useGlobalWords from "../../../../hooks/getGlobalWords";
import WordAudio from "../../../../Audio/WordAudio";
import VStack from "../../../VStack";
import WordTranscription from "../../../../Word/WordTranscription";
import useLessonWords from "../../../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import { fullScreenModalStyle } from "../../../../../common/constants";
import AccentStrong from "../../../../Description/ModuleDescription/utils/AccentString";
import AccentWord from "../../../../Difference/components/AccentWord";
import removePunctuationFromString from "../../../../../common/removePunctuationFromString";
import HStack from "../../../HStack";

type CreateDataProp = {
  title?: any;
  en: any;
  lesson: number;
};

function createData(item1: CreateDataProp, item2: CreateDataProp, item3: CreateDataProp) {
  return {
    item1,
    item2,
    item3,
  };
}

const Title: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography sx={{ textDecoration: "underline" }} component="span" fontSize={25} display="block">
      {children}
    </Typography>
  );
};
const Sentence: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography component="span" fontSize={20} display="block" fontStyle="italic">
      {children}
    </Typography>
  );
};
const SubTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      fontStyle="italic"
      // color="secondary.dark"
      sx={{ textDecoration: "underline", color: "grey", fontSize: 16 }}
    >
      {children}
    </Typography>
  );
};
const Caption: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      fontStyle="italic"
      variant="caption"
      sx={{ color: "grey", fontSize: 16, lineHeight: 0.9 }}
    >
      {children}
    </Typography>
  );
};

const rows = (lessonNumber: number) => ({
  with: {
    lesson: 26,
    sentences: [
      {
        key: (
          <>
            <Title>agree with</Title>
            <SubTitle>мати рацію щодо</SubTitle>
          </>
        ),
        lesson: 26,
        sentence: (
          <>
            <Sentence>
              I <strong>agree with</strong> you.
            </Sentence>
            <Sentence>
              I <strong>agree with</strong> him.
            </Sentence>
            <Sentence>
              She <strong>agrees with</strong> me.
            </Sentence>
            <Sentence>
              I <strong>agree with</strong> her about learning English.
            </Sentence>
          </>
        ),
      },
    ],
  },
  eat: {
    lesson: 14,
    sentences: [
      {
        key: <Title>eat lunch</Title>,
        lesson: 14,
        sentence: (
          <Sentence>
            I'm <strong>eating lunch</strong> with my brother.
          </Sentence>
        ),
      },
    ],
  },
  in: {
    lesson: 8,
    sentences: [
      {
        key: <Title>in the park</Title>,
        lesson: 8,
        sentence: (
          <Sentence>
            She walks <strong>in the park</strong> every day.
          </Sentence>
        ),
      },
      {
        key: <Title>in bed</Title>,
        lesson: 13,
        sentence: (
          <Sentence>
            Do you like to drink coffee <strong>in bed</strong>?
          </Sentence>
        ),
      },
      {
        key: <Title>in the kitchen</Title>,
        lesson: 14,
        sentence: (
          <Sentence>
            Where's your dog? It's <strong>in the kitchen</strong>.
          </Sentence>
        ),
      },
    ],
  },
  about: {
    lesson: 4,
    sentences: [
      {
        key: <Title>be right about</Title>,
        lesson: 29,
        sentence: (
          <>
            <Sentence>
              You<strong>'re right about</strong> that.
            </Sentence>
            <Sentence>
              <strong>Am</strong> I <strong>right about</strong> this?
            </Sentence>
          </>
        ),
      },
      {
        key: <Title>think about</Title>,
        lesson: 4,
        sentence: (
          <Sentence>
            What do you <strong>think about</strong>?
          </Sentence>
        ),
      },
      {
        key: <Title>worry about</Title>,
        lesson: 4,
        sentence: (
          <Sentence>
            Don't <strong>worry about</strong> it.
          </Sentence>
        ),
      },
      {
        key: <Title>care about</Title>,
        lesson: 4,
        sentence: (
          <Sentence>
            Do you <strong>care about</strong> what other people think?
          </Sentence>
        ),
      },
      {
        key: <Title>tell about</Title>,
        lesson: 18,
        sentence: (
          <>
            <Sentence>
              He wants to <strong>tell</strong> me <strong>something</strong>.
            </Sentence>
            <Sentence>
              She doesn't want to <strong>tell</strong> me <strong>about</strong> that.
            </Sentence>
          </>
        ),
      },
      {
        key: <Title>talk about</Title>,
        lesson: 18,
        sentence: (
          <>
            <Sentence>
              Let's <strong>talk</strong> later <strong>about</strong> that.
            </Sentence>
            <Sentence>
              Let's <strong>talk</strong> <strong>about</strong> your day.
            </Sentence>
          </>
        ),
      },
      {
        key: <Title>chat about</Title>,
        lesson: 18,
        sentence: (
          <>
            <Sentence>
              Let's <strong>chat</strong> later <strong>about</strong> that.
            </Sentence>
          </>
        ),
      },
    ],
  },
  "to get": {
    lesson: 32,
    sentences: [
      {
        key: (
          <>
            <Title>to get to</Title>
            <SubTitle>дістатися до місця</SubTitle>
          </>
        ),
        lesson: 32,
        sentence: (
          <>
            <Sentence>
              I need <strong>to get to</strong> work early today.
            </Sentence>
            <Sentence>
              Do you <strong>get to</strong> work by bus?
            </Sentence>
            <Sentence>
              I usually <strong>get to</strong> bed late.
            </Sentence>
          </>
        ),
      },
    ],
  },
  speak: {
    lesson: 31,
    sentences: [
      {
        key: <Title>speak in</Title>,
        lesson: 31,
        sentence: (
          <>
            <Sentence>
              She is good at <strong>speaking in</strong> public.
            </Sentence>
            <Sentence>
              Do you enjoy <strong>speaking in</strong> public?
            </Sentence>
          </>
        ),
      },
      {
        key: <Title>speak with</Title>,
        lesson: 31,
        sentence: (
          <>
            <Sentence>
              He is <strong>speaking with</strong> his hands.
            </Sentence>
            <Sentence>
              They're <strong>speaking with</strong> the doctor.
            </Sentence>
          </>
        ),
      },
      {
        key: <Title>speak with</Title>,
        lesson: 31,
        sentence: (
          <>
            <Sentence>
              The president <strong>speaks to</strong> the people.
            </Sentence>
          </>
        ),
      },
    ],
  },
  at: {
    lesson: 19,
    sentences: [
      {
        key: (
          <>
            <Title>at home</Title>
            <SubTitle>дома</SubTitle>
          </>
        ),
        lesson: 35,
        sentence: (
          <>
            <Sentence>I'm at home.</Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>to be good at</Title>
            <SubTitle>бути добрим в</SubTitle>
          </>
        ),
        lesson: 31,
        sentence: (
          <>
            <Sentence>
              She <strong>is good at</strong> playing the piano.
            </Sentence>
            <Sentence>
              I want to <strong>be good at</strong> speaking English.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            {" "}
            <Title>at</Title>
            <SubTitle>на території або всередині</SubTitle>
          </>
        ),
        lesson: 19,
        sentence: (
          <>
            <Sentence>
              I meet you <strong>at</strong> the library.
            </Sentence>
            <Sentence>
              See you <strong>at</strong> work!
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            {" "}
            <Title>at</Title>
            <SubTitle>о (про час)</SubTitle>
          </>
        ),
        lesson: 19,
        sentence: (
          <>
            <Sentence>
              I'm going to the park <strong>at</strong> five.
            </Sentence>
            <Sentence>
              The meeting is <strong>at</strong> two.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            {" "}
            <Title>look at</Title>
            <SubTitle>подивитися на</SubTitle>
          </>
        ),
        lesson: 19,
        sentence: (
          <>
            <Sentence>
              <strong>Look at</strong> that cat.
            </Sentence>
            <Sentence>
              <strong>Look at</strong> me.
            </Sentence>
            <Sentence>
              <strong>Look at</strong> him.
            </Sentence>
          </>
        ),
      },
    ],
  },
  of: {
    lesson: 5,
    sentences: [
      {
        key: <Title>cup of tea</Title>,
        lesson: 5,
        sentence: (
          <Sentence>
            Please give me a <strong>cup of tea</strong>.
          </Sentence>
        ),
      },
      {
        key: <Title>cup of coffee</Title>,
        lesson: 5,
        sentence: (
          <Sentence>
            Do you want a <strong>cup of coffee</strong>?
          </Sentence>
        ),
      },
      {
        key: <Title>glass of water</Title>,
        lesson: 11,
        sentence: (
          <Sentence>
            Please give me a <strong>glass of water</strong>.
          </Sentence>
        ),
      },
      {
        key: <Title>day of the week</Title>,
        lesson: 15,
        sentence: (
          <>
            <Sentence>
              What <strong>day of the week</strong> is today?
            </Sentence>
            <Sentence>
              What <strong>day of the week</strong> do you work?
            </Sentence>
          </>
        ),
      },
      {
        key: <Title>list of things</Title>,
        lesson: 18,
        sentence: (
          <>
            <Sentence>
              I need to make a <strong>list of things</strong> to do?
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>tons of work</Title>
            <SubTitle>купа роботи (в переносному значенні)</SubTitle>
          </>
        ),
        lesson: 19,
        sentence: (
          <>
            <Sentence>
              I have <strong>tons of work</strong>.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>out of the house</Title>
            <SubTitle>поза домом</SubTitle>
          </>
        ),
        lesson: 19,
        sentence: (
          <>
            <Sentence>
              She is <strong>out of the house</strong>.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>out of luck</Title>
            <SubTitle>не пощастило</SubTitle>
          </>
        ),
        lesson: 19,
        sentence: (
          <>
            <Sentence>
              He is <strong>out of luck</strong>.
            </Sentence>
          </>
        ),
      },
    ],
  },
  "to make": {
    lesson: 18,
    sentences: [
      {
        key: <Title>make a cake</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            I want to <strong>make a cake</strong>.
          </Sentence>
        ),
      },
      {
        key: <Title>make a sandwich</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            Can you <strong>make</strong> me <strong>a sandwich</strong>.
          </Sentence>
        ),
      },
      {
        key: <Title>make a phone call</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            I need to <strong>make</strong> <strong>a phone call</strong>.
          </Sentence>
        ),
      },
      {
        key: <Title>make a list</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            I need to <strong>make</strong> <strong>a list</strong> of things to do.
          </Sentence>
        ),
      },
      {
        key: <Title>make people happy</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            She likes to <strong>make</strong> <strong>people happy</strong>.
          </Sentence>
        ),
      },
    ],
  },
  home: {
    lesson: 35,
    sentences: [
      // {
      //   key: <Title>work from home</Title>,
      //   lesson: 18,
      //   sentence: (
      //     <Sentence>
      //       She <strong>works from home</strong>.
      //     </Sentence>
      //   ),
      // },
      {
        key: <Title>go home</Title>,
        lesson: 35,
        sentence: (
          <>
            <Sentence>
              I need to <strong>go home</strong>.
            </Sentence>
            <Sentence>
              <strong>Go home!</strong>
            </Sentence>
          </>
        ),
      },
    ],
  },
  "to catch": {
    lesson: 18,
    sentences: [
      {
        key: <Title>catch a train</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            I have to <strong>catch a train</strong>.
          </Sentence>
        ),
      },
      {
        key: <Title>catch a plane</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            I have to <strong>catch a plane</strong>.
          </Sentence>
        ),
      },
    ],
  },
  on: {
    lesson: 18,
    sentences: [
      {
        key: <Title>on the phone</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            They talk <strong>on the phone</strong> every day.
            <br />
            They chat <strong>on the phone</strong> every day.
          </Sentence>
        ),
      },
    ],
  },
  "to call": {
    lesson: 18,
    sentences: [
      {
        key: <Title>call a dog</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            How do you <strong>call</strong> your <strong>dog</strong>?
          </Sentence>
        ),
      },
      {
        key: <Title>call for</Title>,
        lesson: 18,
        sentence: (
          <Sentence>
            Can you <strong>call</strong> him <strong>for</strong> me?
          </Sentence>
        ),
      },
    ],
  },
  "to say": {
    lesson: 5,
    sentences: [
      {
        key: <Title>to say hello</Title>,
        lesson: 5,
        sentence: (
          <Sentence>
            I want <strong>to say hello</strong> to the parrot.
          </Sentence>
        ),
      },
    ],
  },
  "to let": {
    lesson: 5,
    sentences: [
      {
        key: <Title>let go</Title>,
        lesson: 6,
        sentence: (
          <Sentence>
            <strong>Let</strong> it <strong>go.</strong> / <strong>Let go</strong> of the rope.
          </Sentence>
        ),
      },
      {
        key: <Title>let's go</Title>,
        lesson: 6,
        sentence: (
          <Sentence>
            <strong>Let's go</strong> to the zoo to see the animals.
          </Sentence>
        ),
      },
    ],
  },
  "to climb": {
    lesson: 7,
    sentences: [
      {
        key: <Title>climb a tree</Title>,
        lesson: 7,
        sentence: (
          <Sentence>
            A monkey can <strong>climb</strong> a tree, but a goat can't.
          </Sentence>
        ),
      },
    ],
  },
  thank: {
    lesson: 9,
    sentences: [
      {
        key: (
          <>
            <Title>thank for</Title>
            <SubTitle>дякувати за</SubTitle>
          </>
        ),
        lesson: 9,
        sentence: (
          <>
            <Sentence>
              <strong>Thank</strong> you <strong>for</strong> the tea.
            </Sentence>
            <Sentence>
              Please <strong>thank</strong> him <strong>for</strong> me.
            </Sentence>
          </>
        ),
      },
    ],
  },
  alright: {
    lesson: 9,
    sentences: [
      {
        key: (
          <>
            <Title>it's alright</Title>
            <SubTitle>нічого страшного / все гаразд</SubTitle>
          </>
        ),
        lesson: 9,
        sentence: (
          <Sentence>
            <strong>It's alright</strong> if you can't play the violin.
          </Sentence>
        ),
      },
      {
        key: (
          <>
            <Title>that's alright</Title>
            <SubTitle>нічого страшного / все гаразд</SubTitle>
          </>
        ),
        lesson: 9,
        sentence: (
          <Sentence>
            Sorry. I can't do that. - <strong>That's alright.</strong>
          </Sentence>
        ),
      },
    ],
  },
  look: {
    lesson: 25,
    sentences: [
      {
        key: (
          <>
            <Title>look like</Title>
            <SubTitle>виглядати як</SubTitle>
          </>
        ),
        lesson: 25,
        sentence: (
          <>
            <Sentence>
              You <strong>look like</strong> a prisoner.
            </Sentence>
            <Sentence>
              She <strong>looks like</strong> a teacher.
            </Sentence>
          </>
        ),
      },
    ],
  },
  as: {
    lesson: 25,
    sentences: [
      {
        key: (
          <>
            <Title>as (професія)</Title>
            {/* <SubTitle></SubTitle> */}
          </>
        ),
        lesson: 25,
        sentence: (
          <>
            <Sentence>
              She works <strong>as</strong> a teacher.
            </Sentence>
            <Sentence>
              She works <strong>as</strong> a doctor.
            </Sentence>
          </>
        ),
      },
    ],
  },
  "to be": {
    lesson: 11,
    sentences: [
      {
        key: (
          <>
            <Title>to be careful</Title>
            <SubTitle>бути обережним</SubTitle>
          </>
        ),
        lesson: 25,
        sentence: (
          <>
            <Sentence>
              <strong>Be careful</strong>, don't say that.
            </Sentence>
            <Sentence>
              <strong>Be careful</strong> with that glass.
            </Sentence>
            <Sentence>
              I will <strong>be careful</strong>.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>to be okay</Title>
            <Title>to be ok</Title>
            <SubTitle>все впорядку / все добре</SubTitle>
          </>
        ),
        lesson: 11,
        sentence: (
          <>
            <Sentence>
              It<strong>'s okay</strong>.
            </Sentence>
            <Sentence>
              I<strong>'m okay</strong>.
            </Sentence>
            <Sentence>
              He <strong>is ok</strong>.
            </Sentence>
            <Sentence>
              <strong>Are</strong> they <strong>ok</strong>?
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>to be full</Title>
            <SubTitle>бути ситим</SubTitle>
          </>
        ),
        lesson: 11,
        sentence: (
          <>
            <Sentence>
              Do you want tea? Thank you, I<strong>'m full</strong>.
            </Sentence>
            <Sentence>
              I don't want to eat. I<strong>'m full</strong>.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>to be careful</Title>
            <SubTitle>бути обережним / уважним</SubTitle>
          </>
        ),
        lesson: 12,
        sentence: (
          <>
            <Sentence>
              <strong>Be careful</strong> with that glass.
            </Sentence>
            <Sentence>
              I<strong>'m careful</strong> with the glass.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>to be late</Title>
            <SubTitle>бути пізно / запізнюватися</SubTitle>
            <SubTitle>передає факт запізнення</SubTitle>
          </>
        ),
        lesson: 15,
        sentence: (
          <>
            <Sentence>
              Why <strong>are</strong> you <strong>late</strong>?
            </Sentence>
            <Sentence>
              Sorry for <strong>being late</strong>.
            </Sentence>
            <Caption>більш формально, вказівка на свою відповідальність</Caption>
            <Sentence>
              Sorry about <strong>being late</strong>.
            </Sentence>
            <Caption>розмовно без вказівки на свою відповідальність</Caption>
          </>
        ),
      },
    ],
  },
  "to listen": {
    lesson: 12,
    sentences: [
      {
        key: (
          <>
            <Title>to listen to</Title>
            <SubTitle>слухати (когось/щось)</SubTitle>
          </>
        ),
        lesson: 12,
        sentence: (
          <>
            <Sentence>
              Can you <strong>listen to</strong> me?
            </Sentence>
            <Sentence>
              I'm <strong>listening</strong> to a podcast.
            </Sentence>
          </>
        ),
      },
    ],
  },
  "to wait": {
    lesson: 12,
    sentences: [
      {
        key: (
          <>
            <Title>wait for</Title>
            <SubTitle>чекати на (когось/щось)</SubTitle>
          </>
        ),
        lesson: 12,
        sentence: (
          <>
            <Sentence>
              Please <strong>wait for</strong> me.
            </Sentence>
            <Sentence>
              Let's <strong>wait for</strong> him.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>can't wait</Title>
            <SubTitle>неможу дочекатися</SubTitle>
          </>
        ),
        lesson: 12,
        sentence: (
          <>
            <Sentence>
              I <strong>can't wait</strong> to see you again.
            </Sentence>
            <Sentence>
              She <strong>can't wait</strong> to go to school.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>wait a moment</Title>
            <SubTitle>зачекай / зажди</SubTitle>
          </>
        ),
        lesson: 12,
        sentence: (
          <>
            <Sentence>
              Can you <strong>wait a moment</strong>, please?
            </Sentence>
            <Sentence>
              <strong>Wait a moment!</strong> I'm thinking.
            </Sentence>
          </>
        ),
      },
    ],
  },
  "to see": {
    lesson: 12,
    sentences: [
      {
        key: (
          <>
            <Title>see you</Title>
            <Title>see you again</Title>
          </>
        ),
        lesson: 12,
        sentence: (
          <>
            <Sentence>
              <strong>See you!</strong>
            </Sentence>
            <Sentence>
              <strong>See you again.</strong>
            </Sentence>
            <Sentence>
              <strong>See you again</strong> tomorrow.
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>see you</Title>
            <SubTitle>зустрінемося / побачимося</SubTitle>
          </>
        ),
        lesson: 15,
        sentence: (
          <>
            <Sentence>
              <strong>See you</strong> later.
            </Sentence>
            <Sentence>
              <strong>See you</strong> on Monday.
            </Sentence>
          </>
        ),
      },
    ],
  },
  time: {
    lesson: 32,
    sentences: [
      {
        key: (
          <>
            <Title>have time</Title>
            <SubTitle>мати час</SubTitle>
          </>
        ),
        lesson: 32,
        sentence: (
          <>
            <Sentence>
              I don't <strong>have time</strong> for that.
            </Sentence>
            <Sentence>
              Do you <strong>have time</strong> to talk?
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>it's time</Title>
            <SubTitle>час настав</SubTitle>
          </>
        ),
        lesson: 32,
        sentence: (
          <>
            <Sentence>
              <strong>It's time</strong> to go to bed.
            </Sentence>
            <Sentence>
              <strong>It's time</strong> to go to school.
            </Sentence>
          </>
        ),
      },
    ],
  },
  "to/a run": {
    lesson: 15,
    sentences: [
      {
        key: (
          <>
            <Title>to go for a run</Title>
            <SubTitle>піти на пробіжку</SubTitle>
          </>
        ),
        lesson: 36,
        sentence: (
          <>
            <Sentence>
              Do you want <strong>to go for a run</strong>?
            </Sentence>
            <Sentence>
              Let's <strong>go for a run</strong> in the park!
            </Sentence>
          </>
        ),
      },
      {
        key: (
          <>
            <Title>running out</Title>
            <SubTitle>закінчуватися</SubTitle>
          </>
        ),
        lesson: 32,
        sentence: (
          <>
            <Sentence>
              I'm <strong>running out</strong> of time.
            </Sentence>
            <Sentence>
              The car is <strong>running out</strong> of gas.
            </Sentence>
          </>
        ),
      },
      {
        key: <Title>running late</Title>,
        lesson: 15,
        sentence: (
          <>
            <Sentence>
              Sorry about <strong>running late</strong>.
            </Sentence>
            <Sentence>
              I'm <strong>running late</strong> for the meeting.
            </Sentence>
            <Sentence>
              We're <strong>running late</strong> for work.
            </Sentence>
            <Sentence>
              I'm sorry, I'm <strong>running late</strong>.
            </Sentence>
          </>
        ),
      },
    ],
  },
  "to ask": {
    lesson: 12,
    sentences: [
      {
        key: <Title>to ask</Title>,
        lesson: 12,
        sentence: (
          <Sentence>
            She wants to <strong>ask</strong> something.
          </Sentence>
        ),
      },
      {
        key: <Typography>to ask for, використовується з грошима</Typography>,
        lesson: 10000,
        sentence: (
          <Sentence>
            She wants to <strong>ask</strong> you <strong>for</strong> something.
          </Sentence>
        ),
      },
      {
        key: <Typography>to ask about</Typography>,
        lesson: 12,
        sentence: (
          <Sentence>
            I want to <strong>ask</strong> you <strong>about</strong> that thing.
          </Sentence>
        ),
      },
      {
        key: <Typography>to ask to</Typography>,
        lesson: 12,
        sentence: (
          <>
            <Sentence>
              I want to <strong>ask</strong> you <strong>to</strong> do that today.
            </Sentence>
            <Sentence>
              I want to <strong>ask</strong> <strong>to</strong> do that.
            </Sentence>
          </>
        ),
      },
    ],
  },
});

const ColorcationsTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any>({});
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);

  const [word] = useGlobalWords(globalWordsOptions);

  return (
    <Box>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start" overflow="hidden">
              <Typography fontWeight={500} variant="h3">
                Cталі словосполучення з <strong>{activeExamples.label}</strong>
              </Typography>
              {activeExamples?.sentences
                ?.filter((sentence: any) => {
                  return sentence.lesson <= lessonNumber;
                })
                .sort((a: any, b: any) => b.lesson - a.lesson)
                ?.map((sentence: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        pl: lessonNumber === sentence.lesson ? 3 : 0,
                        borderLeft: lessonNumber === sentence.lesson ? "3px solid #4aa805" : "none",
                        mb: 5,
                      }}
                    >
                      <Box mb={3}>
                        <Typography
                          fontWeight={700}
                          color={lessonNumber === sentence.lesson ? "primary" : "black"}
                        >
                          {sentence.key}
                        </Typography>
                      </Box>
                      <Typography fontWeight={700} variant="h3">
                        {sentence.sentence}
                      </Typography>
                    </Box>
                  );
                })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>

      <HStack>
        {Object.entries(rows(lessonNumber)).map(([label, { lesson, sentences }]) => {
          console.log("🚀 ~ label:", label);
          if (lessonNumber < lesson) return null;
          const sentencesLessonNumbers = sentences.map((s: any) => s.lesson);

          return (
            <Box
              sx={{
                color: sentencesLessonNumbers.includes(lessonNumber) ? "green" : "black",
                fontWeight: 700,
                fontSize: 22,
                fontStyle: "italic",
                px: 2,
                textDecoration: "underline",
                cursor: "pointer",
                // border: "1px solid",
              }}
              onClick={() => {
                setActiveExamples({
                  label,
                  sentences,
                  lesson,
                });
                setIsCellModalOpen(true);
              }}
            >
              {label}
            </Box>
          );
        })}
      </HStack>

      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          ></TableHead>
          <TableBody>
            {rows(lessonNumber)
              .filter((r) => r.item1.lesson <= lessonNumber)
              .map((row, index) => (
                <TableRow
                  key={row.item1?.title || ""}
                  sx={
                    {
                      // "&:last-child td, &:last-child th": { border: 0 }
                    }
                  }
                >
                  <CustomTableCell
                    lesson={row.item1.lesson}
                    en={row.item1.en}
                    onClick={() => {
                      console.log("row", row);
                      // setActiveExamples(row.item1)
                      setIsCellModalOpen(true);
                    }}
                  />
                  <CustomTableCell lesson={row.item2.lesson} en={row.item2.en}></CustomTableCell>
                  <CustomTableCell lesson={row.item3.lesson} en={row.item3.en}></CustomTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </Box>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  lesson: number;
  onClick?: () => void;
}> = ({ en, lesson, onClick }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady = lesson <= lessonNumber;

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={() => {
        if (onClick) onClick();
      }}
      sx={{
        m: 0,
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        py: 0.5,
      }}
    >
      {en}
    </TableCell>
  );
};

export default ColorcationsTable;
