import { Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import getRandomGreetings from "../../../../common/getRandomGreetings";
import shuffle from "../../../../common/shuffle";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import Drawer from "../../../Unknown/Drawer";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import VStack from "../../../Unknown/VStack";
import LinearProgress from "../../../User/LinearProgress";
import CheckAnim from "../CheckAnim";
import { TrainersConfig } from "../constants";
import getButtonLabel from "./getButtonLabel";
import SortVowelsAndConsonants from "../SortVowelsAndConsonants";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const TrainersModalWithButton: React.FC<{
  trainer: TrainersConfig;
  buttonLabel?: string;
  consonants?: string[];
  vowels?: string[];
}> = ({ buttonLabel: inputButtonLabel, consonants, vowels, trainer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [letterIndex, setLetterIndex] = useState(0);
  const [showGreetings, setShowGreetings] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const buttonLabel = inputButtonLabel || getButtonLabel(trainer);

  useEffect(() => {
    setIsSuccess(false);
  }, [letterIndex]);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const greeting = useMemo(() => {
    return getRandomGreetings("Всі голосні літери знайдені.");
  }, []);

  const trainerComponent = useMemo(() => {
    if (
      trainer === TrainersConfig.UA_SORT_VOWELS_AND_CONSONANTS ||
      trainer === TrainersConfig.EN_SORT_VOWELS_AND_CONSONANTS
    ) {
      return (
        <SortVowelsAndConsonants
          type={TrainersConfig.EN_SORT_VOWELS_AND_CONSONANTS ? "en" : "ua"}
          consonants={consonants}
          vowels={vowels}
          onTaskClose={() => {
            setIsOpen(false);
          }}
        />
      );
    }
  }, [trainer, consonants, vowels]);

  return (
    <Box>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        {buttonLabel}
      </Button>

      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />

          {trainerComponent}

          {isSuccess && (
            <Box py={5}>
              <CheckAnim />
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default TrainersModalWithButton;
