import { useEffect, useMemo, useState } from "react";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import Drawer from "../../../Unknown/Drawer";
import LinearProgress from "../../../User/LinearProgress";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";
import shuffle from "../../../../common/shuffle";
import VStack from "../../../Unknown/VStack";
import { Typography } from "@mui/material";
import HStack from "../../../Unknown/HStack";
import getRandomGreetings from "../../../../common/getRandomGreetings";
import CheckAnim from "../CheckAnim";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const consonantLetters = [
  "б",
  "в",
  "г",
  "з",
  "ж",
  "й",
  "л",
  "м",
  "н",
  "р",
  "д",
  "ґ",
  "п",
  "т",
  "к",
  "ф",
  "с",
  "ш",
  "щ",
  "ч",
  "х",
  "ц",
];

const vowelLetters = ["а", "е", "и", "і", "о", "у", "ю", "я", "ї", "є"];

const config = Array(20)
  .fill("")
  .map((_, index) => {
    const selectedVowelLetters = shuffle(vowelLetters).slice(0, 6);
    const selectedConsonantLetters = shuffle(consonantLetters).slice(0, 4);

    return {
      correctLetters: selectedConsonantLetters,
      letters: shuffle([...selectedConsonantLetters, ...selectedVowelLetters]),
    };
  });

const FindConconantLetter = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [letterIndex, setLetterIndex] = useState(0);
  const [showGreetings, setShowGreetings] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  const letterConfig = useMemo(() => {
    return shuffle([...config, ...config, ...config]);
    // return shuffle([...config]);
  }, []);

  const currentLetter = useMemo(() => {
    return letterConfig[letterIndex];
  }, [letterIndex]);

  const greeting = useMemo(() => {
    return getRandomGreetings("Всі приголосні літери знайдені.");
  }, []);

  const [selectedLetters, setSelectedLetters] = useState<string[]>([]);
  console.log("🚀 ~ selectedLetters:", selectedLetters);

  // useEffect(() => {
  //   setSelectedLetters([]);
  // }, [letterIndex]);

  useEffect(() => {
    let timer: any = null;

    if (selectedLetters.length === 4) {
      if (letterIndex + 1 < letterConfig.length) {
        timer = setTimeout(() => {
          setLetterIndex((prev) => prev + 1);
          setSelectedLetters([]);
        }, 1000);
      } else {
        timer = setTimeout(() => {
          setSelectedLetters([]);
          setShowGreetings(true);
        }, 1000);
      }

      // setSelectedLetters([])
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [selectedLetters]);

  return (
    <Box>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Знайти приголосні
      </Button>

      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <Box sx={drawerContentStyle}>
          <LinearProgress elementsCount={letterConfig.length - 1} currentValue={letterIndex} />
          <ModalCloseButton onClose={handleDrawerClose} />

          <VStack height="100%" py={20}>
            {showGreetings && (
              <VStack py={5}>
                <Typography gutterBottom variant="h3">
                  {greeting}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleDrawerClose();
                    setShowGreetings(false);
                    setLetterIndex(0);
                  }}
                >
                  Завершити
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    setLetterIndex(0);
                    setShowGreetings(false);
                  }}
                >
                  Почати з початку
                </Button>
              </VStack>
            )}

            {!showGreetings && (
              <VStack>
                <Typography variant="h3">Знайди 4 приголосні літери</Typography>

                {/* {selectedLetters.length !== 4 && ( */}
                {
                  <Box>
                    <HStack maxWidth={360}>
                      {currentLetter.letters.map((letter: string, index: number) => {
                        return (
                          <Letter
                            key={index}
                            letter={letter}
                            selectedLetters={selectedLetters}
                            goToNext={() => {
                              setSelectedLetters((prev) => [...prev, letter]);
                              // if (selectedLetters.length !== 4) {
                              // }
                            }}
                            correctLetters={currentLetter.correctLetters}
                          />
                        );
                      })}
                    </HStack>
                  </Box>
                }
              </VStack>
            )}

            {selectedLetters.length === 4 && (
              <Box py={5}>
                <CheckAnim />
              </Box>
            )}
          </VStack>
        </Box>
      </Drawer>
    </Box>
  );
};

const Letter: React.FC<{
  letter: string;
  correctLetters: string[];
  selectedLetters: string[];
  goToNext: () => void;
}> = ({ letter, correctLetters, selectedLetters, goToNext }) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let timer: any = null;

    if (isError) {
      timer = setTimeout(() => {
        setIsError(false);
      }, 500);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  // useEffect(() => {
  //   let timer: any = null;

  //   if (isSuccess) {
  //     timer = setTimeout(() => {
  //       setIsSuccess(false);
  //     }, 500);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [isSuccess]);

  return (
    <Box>
      <Button
        color={isError ? "error" : selectedLetters.includes(letter) ? "primary" : "info"}
        variant={selectedLetters.includes(letter) ? "contained" : "text"}
        size="small"
        sx={{ fontSize: 22 }}
        onClick={() => {
          if (correctLetters.includes(letter)) {
            // setIsSuccess(true);
            // if (successCount + 1 === 4) {
            // } else {
            //   setSuccessCount(successCount + 1);
            // }
            goToNext();
          } else {
            setIsError(true);
          }
        }}
      >
        {letter}
      </Button>
    </Box>
  );
};

export default FindConconantLetter;
