import { Typography } from "@mui/material";

const formatLetterWithSound = (letter: string) => {
  if (letter.includes("(")) {
    const parts = letter.split(/[()]/).filter(Boolean);

    return (
      <>
        {parts[0]}
        <Typography
          component="span"
          fontSize={14}
          color="#da9a0b"
          sx={{
            verticalAlign: "bottom",
          }}
        >
          {/* ({parts[1]}) */}
          <Typography
            component="span"
            fontSize={14}
            sx={{
              display: "inline-block",
              backgroundColor: "white",
              border: "2px solid #da9a0b",
              borderRadius: "10px",
              lineHeight: 1,
              padding: "0.5px 3px",
            }}
          >
            {parts[1]}
          </Typography>
        </Typography>
        {parts[2]}
      </>
    );
  }

  return letter;
};

export default formatLetterWithSound;
