import { Typography } from "@mui/material";
import Button from "../../Unknown/Button";

const CopyNewWordsForGemini: React.FC<{ words: string[]; copy10?: boolean }> = ({
  words,
  copy10,
}) => {
  return (
    <>
      <Typography variant="caption">{words.join(", ")}</Typography>
      <Button
        variant="contained"
        color="info"
        onClick={() => {
          navigator.clipboard.writeText(`
        1. here a list of words/phases:
        ${copy10 ? words.slice(0, 10).join(", ") : words.join(", ")}
        
        2. how many meanings each word/phrase has?
        3. give me 10 simple sentence examples for each word/phrase meaning. Don't give me very long sentences. All examples must be natural, so I could use it in real life. And plese no change the word tense, if I provided a word in present, please give me the sentence where this word is using as it is!!!
        4. response is in uglified JSON format
        
        response example:
        [
          {
            word: 'a word from the list I have provided',
            translation: string[] (all possible ukrainian translations),
            languagePart: string[] (posible values: "іменник" | "дієслово" | "прислівник" | "прикметник" | "сполучник" | "займенник" | "числівник" | "частка" | "вигук")
            transcription: 'american transcription', (example: |səˈvɪr| - this is a correct pattern, the transcription must starts with | sign and must ends with | signs. Not \\ sign!!! Not / sign!!!)
            examples: [
              {
                en: "english sentence",
                ua: "ukrainian translation"
              },
              ...
            ]
          },
          ...
        ]
        `);
        }}
      >
        {copy10 ? "Copy 10 new words" : "Copy new words"}
      </Button>
    </>
  );
};

export default CopyNewWordsForGemini;
