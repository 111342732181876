import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import React from "react";
import Box from "../Unknown/Box";

import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lightgallery.css";
import VStack from "../Unknown/VStack";

const ImageCarouselModal: React.FC<{ images: string[] }> = ({ images }) => {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <VStack>
        <LightGallery plugins={[lgThumbnail, lgZoom]}>
          {images.map((image, index) => (
            <Box
              component="a"
              href={image}
              key={index}
              sx={{
                width: 100,
                position: "relative",
              }}
            >
              <Box
                component="img"
                alt=""
                src={image}
                sx={{
                  display: "inline-block",
                  width: !index ? 200 : 0,
                  height: !index ? "auto" : 0,
                }}
              />
            </Box>
          ))}
        </LightGallery>
      </VStack>
    </div>
  );
};

export default ImageCarouselModal;
