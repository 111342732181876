import removePunctuationFromString from "./removePunctuationFromString";
import upperCaseWords from "./uppercaseWords";

const getSentenceWords = (sentence: string) => {
  return Array.from(
    new Set(
      sentence
        .split(/[\s\n]/)
        .map((w) => removePunctuationFromString(w))
        .map((w) => {
          if (!upperCaseWords.includes(w)) {
            return w.toLowerCase();
          }
          return w;
        }),
    ),
  );
};

export default getSentenceWords;
