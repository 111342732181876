import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { useFirebaseApp } from "reactfire";
import VStack from "../Unknown/VStack";

// play, yes, thank, no, sorry, alright

const AiAskSentencesNaturalness: React.FC<{
  text: string;
}> = ({ text }) => {
  const [isLoading, setIsLoading] = useState(false);

  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");
  const askOpenAi = httpsCallable(functions, "askOpenAiFunction");

  return (
    <Box width="100%">
      <LoadingButton
        variant="outlined"
        loading={isLoading}
        disabled={!text}
        size="small"
        color="error"
        onClick={async () => {
          setIsLoading(true);

          try {
            const textAnalysesResult = await askOpenAi({
              question: `                  
           
              Зроби аналіз наступних речень: ${JSON.stringify(
                text.split("\n").map((s) => s.trim()),
              )}

              1. Чи є в реченнях сталі словосполучення?
              2. Чи є в реченнях сталі вирази?
              3. Чи є в реченнях фразові дієслова?
              4. Чи всі ці звучать природньо?
              5. Чи всі ці граматично правильні?

              Дай відповідь у JSON форматі:
              Ось приклад:
              {
                collocations: {sentence: 'current sentence with a collocation', collocations: 'collocations from the current sentence'[]}[],
                idioms: {sentence: 'current sentence with an idiom', collocations: 'idioms from the current sentence'[]}[],
                phrasalVerbs: {sentence: string, phrasalVerbs: {name: 'phrasal verb from the current sentence here', description: 'why is this a phrasal verb here'}[]}[],
                wrongSoundSentences: {sentence: string, problem: 'detailed description here'}[],
                wrongGrammarSentences: {sentence: string, problem: 'detailed description here'}[],
              }
            `,
            });

            console.log("textAnalysesResult", textAnalysesResult);
            const sentencesData = textAnalysesResult.data as string[];

            if (sentencesData) {
              // setSentences(sentencesData);
              navigator.clipboard.writeText(JSON.stringify(sentencesData));
            }
          } catch (err) {
            console.log("🚀 ~ err:", err);
            setIsLoading(false);
          }
          setIsLoading(false);
        }}
      >
        Ai Check sentences
      </LoadingButton>

      <VStack alignItems="flex-start"></VStack>
    </Box>
  );
};

export default AiAskSentencesNaturalness;
