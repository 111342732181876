const A1Words = [
  "a",
  "an",
  "about",
  "above",
  "across",
  "action",
  "activity",
  "actor",
  "actress",
  "add",
  "address",
  "adult",
  "advice",
  "afraid",
  "after",
  "afternoon",
  "again",
  "age",
  "ago",
  "agree",
  "air",
  "airport",
  "all",
  "also",
  "always",
  "amazing",
  "and",
  "angry",
  "animal",
  "another",
  "answer",
  "any",
  "anyone",
  "anything",
  "apartment",
  "apple",
  "April",
  "area",
  "arm",
  "around",
  "arrive",
  "art",
  "article",
  "artist",
  "as",
  "ask",
  "at",
  "August",
  "aunt",
  "autumn",
  "away",
  "baby",
  "back",
  "bad",
  "bag",
  "ball",
  "banana",
  "band",
  "bank (money)",
  "bath",
  "bathroom",
  "be",
  "beach",
  "beautiful",
  "because",
  "become",
  "bed",
  "bedroom",
  "beer",
  "before",
  "begin",
  "beginning",
  "behind",
  "believe",
  "below",
  "best",
  "better",
  "between",
  "bicycle",
  "big",
  "bike",
  "bill",
  "bird",
  "birthday",
  "black",
  "blog",
  "blonde",
  "blue",
  "boat",
  "body",
  "book",
  "boot",
  "bored",
  "boring",
  "born",
  "both",
  "bottle",
  "box",
  "boy",
  "boyfriend",
  "bread",
  "break",
  "breakfast",
  "bring",
  "brother",
  "brown",
  "build",
  "building",
  "bus",
  "business",
  "busy",
  "but",
  "butter",
  "buy",
  "by",
  "bye",
  "cafe",
  "cake",
  "call",
  "camera",
  "can",
  "cannot",
  "capital",
  "car",
  "card",
  "career",
  "carrot",
  "carry",
  "cat",
  "CD",
  "cent",
  "centre",
  "century",
  "chair",
  "change",
  "chart",
  "cheap",
  "check",
  "cheese",
  "chicken",
  "child",
  "chocolate",
  "choose",
  "cinema",
  "city",
  "class",
  "classroom",
  "clean",
  "climb",
  "clock",
  "close",
  "clothes",
  "club",
  "coat",
  "coffee",
  "cold",
  "college",
  "colour",
  "come",
  "common",
  "company",
  "compare",
  "complete",
  "computer",
  "concert",
  "conversation",
  "cook",
  "cooking",
  "cool",
  "correct",
  "cost",
  "could",
  "country",
  "course",
  "cousin",
  "cow",
  "cream",
  "create",
  "culture",
  "cup",
  "customer",
  "cut",
  "dad",
  "dance",
  "dancer",
  "dancing",
  "dangerous",
  "dark",
  "date",
  "daughter",
  "day",
  "dear",
  "December",
  "decide",
  "delicious",
  "describe",
  "description",
  "design",
  "desk",
  "detail",
  "dialogue",
  "dictionary",
  "die",
  "diet",
  "difference",
  "different",
  "difficult",
  "dinner",
  "dirty",
  "discuss",
  "dish",
  "do",
  "doctor",
  "dog",
  "dollar",
  "door",
  "down",
  "downstairs",
  "draw",
  "dress",
  "drink",
  "drive",
  "driver",
  "during",
  "DVD",
  "each",
  "ear",
  "early",
  "east",
  "easy",
  "eat",
  "egg",
  "eight",
  "eighteen",
  "eighty",
  "elephant",
  "eleven",
  "else",
  "email",
  "end",
  "enjoy",
  "enough",
  "euro",
  "even",
  "evening",
  "event",
  "ever",
  "every",
  "everybody",
  "everyone",
  "everything",
  "exam",
  "example",
  "excited",
  "exciting",
  "exercise",
  "expensive",
  "explain",
  "extra",
  "eye",
  "face",
  "fact",
  "fall",
  "false",
  "family",
  "famous",
  "fantastic",
  "far",
  "farm",
  "farmer",
  "fast",
  "fat",
  "father",
  "favourite",
  "February",
  "feel",
  "feeling",
  "festival",
  "few",
  "fifteen",
  "fifth",
  "fifty",
  "fill",
  "film",
  "final",
  "find",
  "fine",
  "finish",
  "fire",
  "first",
  "fish",
  "five",
  "flat",
  "flight",
  "floor",
  "flower",
  "fly",
  "follow",
  "food",
  "foot",
  "football",
  "for",
  "forget",
  "form",
  "forty",
  "four",
  "fourteen",
  "fourth",
  "free",
  "Friday",
  "friend",
  "friendly",
  "from",
  "front",
  "fruit",
  "full",
  "fun",
  "funny",
  "future",
  "game",
  "garden",
  "geography",
  "get",
  "girl",
  "girlfriend",
  "give",
  "glass",
  "go",
  "good",
  "goodbye",
  "grandfather",
  "grandmother",
  "grandparent",
  "great",
  "green",
  "grey",
  "group",
  "grow",
  "guess",
  "guitar",
  "gym",
  "hair",
  "half",
  "hand",
  "happen",
  "happy",
  "hard",
  "hat",
  "hate",
  "have",
  "have to",
  "he",
  "head",
  "health",
  "healthy",
  "hear",
  "hello",
  "help",
  "her",
  "here",
  "hey",
  "hi",
  "high",
  "him",
  "his",
  "history",
  "hobby",
  "holiday",
  "home",
  "homework",
  "hope",
  "horse",
  "hospital",
  "hot",
  "hotel",
  "hour",
  "house",
  "how",
  "however",
  "hundred",
  "hungry",
  "husband",
  "I",
  "ice",
  "ice cream",
  "idea",
  "if",
  "imagine",
  "important",
  "improve",
  "in",
  "include",
  "information",
  "interest",
  "interested",
  "interesting",
  "internet",
  "interview",
  "into",
  "introduce",
  "island",
  "it",
  "its",
  "jacket",
  "January",
  "jeans",
  "job",
  "join",
  "journey",
  "juice",
  "July",
  "June",
  "just",
  "keep",
  "key",
  "kilometre",
  "kind (type)",
  "kitchen",
  "know",
  "land",
  "language",
  "large",
  "last (final)",
  "late",
  "later",
  "laugh",
  "learn",
  "leave",
  "left",
  "leg",
  "lesson",
  "let",
  "letter",
  "library",
  "lie",
  "life",
  "light (from the sun/a lamp)",
  "like (similar)",
  "like (find sb/sth pleasant)",
  "line",
  "lion",
  "list",
  "listen",
  "little",
  "live",
  "local",
  "long",
  "look",
  "lose",
  "lot",
  "love",
  "lunch",
  "machine",
  "magazine",
  "main",
  "make",
  "man",
  "many",
  "map",
  "March",
  "market",
  "married",
  "match (contest)",
  "May",
  "maybe",
  "me",
  "meal",
  "mean",
  "meaning",
  "meat",
  "meet",
  "meeting",
  "member",
  "menu",
  "message",
  "metre",
  "midnight",
  "mile",
  "milk",
  "million",
  "minute",
  "miss",
  "mistake",
  "model",
  "modern",
  "moment",
  "Monday",
  "money",
  "month",
  "more",
  "morning",
  "most",
  "mother",
  "mountain",
  "mouse",
  "mouth",
  "move",
  "movie",
  "much",
  "mum",
  "museum",
  "music",
  "must",
  "my",
  "name",
  "natural",
  "near",
  "need",
  "negative",
  "neighbour",
  "never",
  "new",
  "news",
  "newspaper",
  "next",
  "next to",
  "nice",
  "night",
  "nine",
  "nineteen",
  "ninety",
  "no",
  "no one",
  "nobody",
  "north",
  "nose",
  "not",
  "note",
  "nothing",
  "November",
  "now",
  "number",
  "nurse",
  "object",
  "o'clock",
  "October",
  "of",
  "off",
  "office",
  "often",
  "oh",
  "OK",
  "old",
  "on",
  "once",
  "one",
  "onion",
  "online",
  "only",
  "open",
  "opinion",
  "opposite",
  "or",
  "orange",
  "order",
  "other",
  "our",
  "out",
  "outside",
  "over",
  "own",
  "page",
  "paint",
  "painting",
  "pair",
  "paper",
  "paragraph",
  "parent",
  "park",
  "part",
  "partner",
  "party",
  "passport",
  "past",
  "pay",
  "pen",
  "pencil",
  "people",
  "pepper",
  "perfect",
  "period",
  "person",
  "personal",
  "phone",
  "photo",
  "photograph",
  "phrase",
  "piano",
  "picture",
  "piece",
  "pig",
  "pink",
  "place",
  "plan",
  "plane",
  "plant",
  "play",
  "player",
  "please",
  "point",
  "police",
  "policeman",
  "pool",
  "poor",
  "popular",
  "positive",
  "possible",
  "post",
  "potato",
  "pound",
  "practice",
  "practise",
  "prefer",
  "prepare",
  "present",
  "pretty",
  "price",
  "probably",
  "problem",
  "product",
  "programme",
  "project",
  "purple",
  "put",
  "quarter",
  "question",
  "quick",
  "quickly",
  "quiet",
  "quite",
  "radio",
  "rain",
  "read",
  "reader",
  "reading",
  "ready",
  "real",
  "really",
  "reason",
  "red",
  "relax",
  "remember",
  "repeat",
  "report",
  "restaurant",
  "result",
  "return",
  "rice",
  "rich",
  "ride",
  "right",
  "river",
  "road",
  "room",
  "routine",
  "rule",
  "run",
  "sad",
  "salad",
  "salt",
  "same",
  "sandwich",
  "Saturday",
  "say",
  "school",
  "science",
  "scientist",
  "sea",
  "second",
  "section",
  "see",
  "sell",
  "send",
  "sentence",
  "September",
  "seven",
  "seventeen",
  "seventy",
  "share",
  "she",
  "sheep",
  "shirt",
  "shoe",
  "shop",
  "shopping",
  "short",
  "should",
  "show",
  "shower",
  "sick",
  "similar",
  "sing",
  "singer",
  "sister",
  "sit",
  "situation",
  "six",
  "sixteen",
  "sixty",
  "skill",
  "skirt",
  "sleep",
  "slow",
  "small",
  "snake",
  "snow",
  "so",
  "some",
  "somebody",
  "someone",
  "something",
  "sometimes",
  "son",
  "song",
  "soon",
  "sorry",
  "sound",
  "soup",
  "south",
  "space",
  "speak",
  "special",
  "spell",
  "spelling",
  "spend",
  "sport",
  "spring",
  "stand",
  "star",
  "start",
  "statement",
  "station",
  "stay",
  "still",
  "stop",
  "story",
  "street",
  "strong",
  "student",
  "study",
  "style",
  "subject",
  "success",
  "sugar",
  "summer",
  "sun",
  "Sunday",
  "supermarket",
  "sure",
  "sweater",
  "swim",
  "swimming",
  "table",
  "take",
  "talk",
  "tall",
  "taxi",
  "tea",
  "teach",
  "teacher",
  "team",
  "teenager",
  "telephone",
  "television",
  "tell",
  "ten",
  "tennis",
  "terrible",
  "test",
  "text",
  "than",
  "thank",
  "thanks",
  "that",
  "the",
  "theatre",
  "their",
  "them",
  "then",
  "there",
  "they",
  "thing",
  "think",
  "third",
  "thirsty",
  "thirteen",
  "thirty",
  "this",
  "thousand",
  "three",
  "through",
  "Thursday",
  "ticket",
  "time",
  "tired",
  "title",
  "to",
  "today",
  "together",
  "toilet",
  "tomato",
  "tomorrow",
  "tonight",
  "too",
  "tooth",
  "topic",
  "tourist",
  "town",
  "traffic",
  "train",
  "travel",
  "tree",
  "trip",
  "trousers",
  "true",
  "try",
  "T-shirt",
  "Tuesday",
  "turn",
  "TV",
  "twelve",
  "twenty",
  "twice",
  "two",
  "type",
  "umbrella",
  "uncle",
  "under",
  "understand",
  "university",
  "until",
  "up",
  "upstairs",
  "us",
  "use",
  "useful",
  "usually",
  "vacation",
  "vegetable",
  "very",
  "video",
  "village",
  "visit",
  "visitor",
  "wait",
  "waiter",
  "wake",
  "walk",
  "wall",
  "want",
  "warm",
  "wash",
  "watch",
  "water",
  "way",
  "we",
  "wear",
  "weather",
  "website",
  "Wednesday",
  "week",
  "weekend",
  "welcome",
  "well",
  "west",
  "what",
  "when",
  "where",
  "which",
  "white",
  "who",
  "why",
  "wife",
  "will",
  "win",
  "window",
  "wine",
  "winter",
  "with",
  "without",
  "woman",
  "wonderful",
  "word",
  "work",
  "worker",
  "world",
  "would",
  "write",
  "writer",
  "writing",
  "wrong",
  "yeah",
  "year",
  "yellow",
  "yes",
  "yesterday",
  "you",
  "young",
  "your",
  "yourself",
];

export default A1Words;
