import { PropsWithChildren } from "react";
import { useLessonsStore } from "../../../../../store/lessonsStore";
import Box from "../../../Box";
import Button from "../../../Button";
import Typography from "../../../Typography";

const BackgroundBoxWraper: React.FC<
  PropsWithChildren & {
    lesson: number;
  }
> = ({ children, lesson }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  if (lessonNumber < lesson) return null;

  if (lessonNumber === lesson) {
    return (
      <Box
        // px={isSpan ? 1 : px || 3}
        // mt={mt || 0}
        // mb={mb || 0}
        // py={0.3}
        // component={isSpan ? "span" : "div"}
        // fontWeight={isBold ? "bold" : 400}
        // color={isSpan ? "red" : "black"}
        // fontSize={isSpan ? 16 : 18}
        width="100%"
        sx={{
          // zIndex: 1,
          pl: 3,
          borderLeft: lessonNumber === lesson ? "3px solid #4aa805" : "none",
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      // px={isSpan ? 1 : px || 3}
      // mt={mt || 0}
      // mb={mb || 0}
      // component={isSpan ? "span" : "div"}
      // color={isSpan ? "red" : "black"}
      // fontSize={isSpan ? 16 : 18}
      // fontWeight={isBold ? "bold" : 400}
    >
      {children}
    </Box>
  );
};

export default BackgroundBoxWraper;
