import { Box, Grid, Typography } from "@mui/material";
import AccentStrong from "../../Description/ModuleDescription/utils/AccentString";
import AccentWord from "../../Difference/components/AccentWord";
import HStack from "../HStack";
import VStack from "../VStack";
import CheckBadge from "../../Difference/components/CheckBange";

export type RuleType = {
  name: any;
  description?: string;
  isVisible?: boolean;
  rules: {
    title: any;
    description?: any;
    lesson: number[];
    raw?: boolean;
    examples?: any[];
  }[];
};

const Highlight: React.FC<{ sentence: string }> = ({ sentence }) => {
  return (
    <Typography variant="h3">
      {sentence.split("|").map((w) => {
        console.log("🚀 ~ w:", w);
        let color = "black";
        let word = w;
        if (w.match("'питальне'")) {
          word = word.replace("'питальне'", "");
          color = "#c70003";
        } else if (w.match("'допоміжне'")) {
          word = word.replace("'допоміжне'", "");
          color = "#16a73a";
        } else if (w.match("'підмет'")) {
          word = word.replace("'підмет'", "");
          color = "#ffbc09";
        } else if (w.match("'обставина'")) {
          word = word.replace("'обставина'", "");
          color = "#7130a1";
        } else if (w.match("'час'")) {
          word = word.replace("'час'", "");
          color = "#127ab6";
        } else if (w.match("'непрямийдодаток'")) {
          word = word.replace("'непрямийдодаток'", "");
          color = "#aa5527";
        } else if (w.match("'додаток'")) {
          word = word.replace("'додаток'", "");
          color = "#aa5527";
        } else if (w.match("'іменнийприсудок'")) {
          word = word.replace("'іменнийприсудок'", "");
          color = "#149fdfc7";
        } else if (w.match("'присудок'")) {
          word = word.replace("'присудок'", "");
          color = "#002060";
        } else if (w.match("'заперечення'")) {
          word = word.replace("'заперечення'", "");
          color = "#df1414c7";
        } else if (w.match("'місце'")) {
          word = word.replace("'місце'", "");
          color = "#1433dfc7";
        } else if (w.match("'спосіб'")) {
          word = word.replace("'спосіб'", "");
          color = "#df14d7c7";
        }

        return (
          <Typography
            component="span"
            variant="h3"
            fontWeight={500}
            sx={{
              position: "relative",
              borderBottom: `3px solid ${color}`,
            }}
          >
            {`${word}`}
          </Typography>
        );
      })}
    </Typography>
  );
};

const SentensePazzle: React.FC<{
  title: string;
  words: string[];
}> = ({ words, title }) => {
  return (
    <HStack gap={1} mb={1}>
      <Typography
        variant="h3"
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        {title}
      </Typography>
      {words.map((title, index) => {
        let color = "white";
        if (title.match("питальне слово")) {
          color = "#c70003";
        } else if (title.match("допоміжне дієслово")) {
          color = "#16a73a";
        } else if (title.match("субʼєкт")) {
          color = "#ffbc09";
        } else if (title.match("обставина")) {
          color = "#7130a1";
        } else if (title.match("час")) {
          color = "#127ab6";
          // } else if (title.match("'непрямийдодаток'")) {
          //   color = "yellow";
        } else if (title.match("додаток")) {
          color = "#aa5527";
        } else if (title.match("іменний присудок")) {
          color = "#149fdfc7";
        } else if (title.match("присудок")) {
          color = "#002060";
        } else if (title.match("заперечення")) {
          color = "#df1414c7";
        } else if (title.match("місце")) {
          color = "#1433dfc7";
        } else if (title.match("спосіб")) {
          color = "#df14d7c7";
        }

        return (
          <>
            <Box position="relative" sx={{ zIndex: words.length - index }}>
              <Box
                sx={{
                  backgroundColor: color,
                  color: "white",
                  position: "relative",
                  overflow: "hidden",
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                }}
                px={2}
                pl={5}
                fontSize={16}
                fontWeight={500}
              >
                <Box
                  sx={{
                    position: "absolute",
                    width: 0,
                    height: 0,
                    transformOrigin: "19% 45%",
                    top: 0,
                    left: 0,
                    borderStyle: "solid",
                    borderWidth: "0px 20px 16px 20px",
                    borderColor: "transparent transparent white transparent",
                    transform: "rotate(90deg)",
                    opacity: index === 0 ? 0 : 1,
                  }}
                ></Box>
                {title}
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  width: 0,
                  height: 0,
                  transformOrigin: "19% 45%",
                  top: 0,
                  right: -41,
                  borderStyle: "solid",
                  borderWidth: "0px 20px 16px 20px",
                  borderColor: `transparent transparent ${color} transparent`,
                  transform: "rotate(90deg)",
                }}
              ></Box>
            </Box>
          </>
        );
      })}
    </HStack>
  );
};

export const BuldSentenceParts = () => {
  return (
    <>
      <Typography fontWeight={600}>Я вчу англійську мову о 15:00.</Typography>
      <Typography>
        <Typography component="span" fontWeight={600} color="primary">
          Я
        </Typography>{" "}
        - субʼєкт
      </Typography>
      <Typography>
        <Typography component="span" fontWeight={600} color="primary">
          вчу
        </Typography>{" "}
        - присудок, дія
      </Typography>
      <Typography>
        <Typography component="span" fontWeight={600} color="primary">
          англійську мову
        </Typography>{" "}
        - додаток
      </Typography>
      <Typography>
        <Typography component="span" fontWeight={600} color="primary">
          о 15:00
        </Typography>{" "}
        - обставина
      </Typography>
      <Typography fontWeight={600}>O 15:00 я вчу англійську мову.</Typography>
      <br />
      <Typography fontWeight={600}>
        Ти вчиш англійську сьогодні
        <Typography
          component="span"
          color="primary"
          sx={{ textDecoration: "underline" }}
          fontWeight={600}
        >
          !
        </Typography>
      </Typography>
      <Typography fontWeight={600}>
        Ти вчиш англійську сьогодні
        <Typography
          component="span"
          color="primary"
          sx={{ textDecoration: "underline" }}
          fontWeight={600}
        >
          ?
        </Typography>
      </Typography>
      <Typography fontWeight={600}>
        <Typography
          component="span"
          color="primary"
          sx={{ textDecoration: "underline" }}
          fontWeight={600}
        >
          Чи
        </Typography>{" "}
        ти вчиш англійську сьогодні
        <Typography
          component="span"
          color="primary"
          sx={{ textDecoration: "underline" }}
          fontWeight={600}
        >
          ?
        </Typography>
      </Typography>
      <br />
      <Typography fontWeight={600}>
        <Typography fontWeight={600} color="primary" component="span">
          (Do/Does)
        </Typography>{" "}
        ти вчиш....
      </Typography>
      <Typography fontWeight={600}>
        <Typography fontWeight={600} color="primary" component="span">
          Do
        </Typography>{" "}
        you speak English?
      </Typography>
      <Typography fontWeight={600}>
        <Typography fontWeight={600} color="primary" component="span">
          Does
        </Typography>{" "}
        she speak English?
      </Typography>
      <br />
      <Typography fontWeight={600}>
        I{" "}
        <Typography fontWeight={600} color="primary" component="span">
          do
        </Typography>{" "}
        not speak Spanish!
      </Typography>
      <Typography fontWeight={600}>
        He{" "}
        <Typography fontWeight={600} color="primary" component="span">
          does
        </Typography>{" "}
        not speak English!
      </Typography>
      <br />
      <br />
      <Typography fontWeight={600}>
        {" "}
        <Typography fontWeight={600} color="primary" component="span">
          Джейн
        </Typography>{" "}
        дивилася{" "}
        <Typography fontWeight={600} color="primary" component="span">
          цей фільм
        </Typography>{" "}
        минулої неділі.
      </Typography>
      <Typography fontWeight={600}>
        {" "}
        <Typography fontWeight={600} color="primary" component="span">
          Цей фільм
        </Typography>{" "}
        дивилася{" "}
        <Typography fontWeight={600} color="primary" component="span">
          Джейн
        </Typography>{" "}
        минулої неділі.
      </Typography>
      <br />
      <Typography fontWeight={600}>
        {" "}
        <Typography fontWeight={600} color="primary" component="span">
          Jane
        </Typography>{" "}
        watched{" "}
        <Typography fontWeight={600} color="primary" component="span">
          this movie
        </Typography>{" "}
        last Sunday.
      </Typography>
      <Typography fontWeight={600}>
        {" "}
        <Typography fontWeight={600} color="primary" component="span">
          This movie
        </Typography>{" "}
        watched{" "}
        <Typography fontWeight={600} color="primary" component="span">
          Jane
        </Typography>{" "}
        last Sunday.
      </Typography>
      <br />
      <Grid container spacing={5} mb={7} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["субʼєкт"]} />
          <Typography variant="h3">хто? що?</Typography>
          <Typography variant="body2">іменник, той чи те що виконує дію.</Typography>
          <Typography variant="body2">я, він, вона, хлопець, жінка, автомобіль...</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["присудок"]} />
          <Typography variant="h3">що робить?</Typography>
          <Typography variant="body2">дієслова, дія</Typography>
          <Typography variant="body2">робити, працювати, співати...</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["додаток"]} />
          <Typography variant="h3">кому? для кого? що? чим? ким? чому? кого?</Typography>
          <Typography variant="body2">доповнює дієслово, що саме робить субʼєкт?</Typography>
          <Typography variant="body2">(вчу) англійську, (роблю) домашню роботу...</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["обставина"]} />
          <Typography variant="h3">де? коли? як? куди?</Typography>
          <Typography variant="body2">де саме (коли/як) це відбувається?</Typography>
          <Typography variant="body2">включає у себе час, місце та спосіб дії</Typography>
          <Typography variant="body2">вдома, на роботі, сьогодні...</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["допоміжне дієслово"]} />
          <Typography variant="h3">do / does / did ...</Typography>
          <Typography variant="body2">
            в основному використовується для правильно формування речення в різних часах
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["заперечення"]} />
          <Typography variant="h3">not </Typography>
          <Typography variant="body2">ні / не</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["час"]} />
          <Typography variant="h3">коли?</Typography>
          <Typography variant="body2">коли відбувається дія?</Typography>
          <Typography variant="body2">вчора, у понеділок, сьогодні</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["місце"]} />
          <Typography variant="h3">де?</Typography>
          <Typography variant="body2">де відбувається дія?</Typography>
          <Typography variant="body2">вдома, в саду, в школі</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["спосіб дії"]} />
          <Typography variant="h3">як?</Typography>
          <Typography variant="body2">прислівник, як відбувається дія?</Typography>
          <Typography variant="body2">повільно, обережно, щасливо</Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["іменний присудок"]} />
          <Typography variant="h3">
            хто? що? який? яке? які? чий? чия? чиє? чиї? котрий? котра? котре? котрі?
          </Typography>
          <Typography variant="body2">
            головне завдання іменного присудка - розкрити, ким/чим/яким є підмет, дати йому
            визначення
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SentensePazzle title="" words={["питальне слово"]} />
          <Typography variant="h3">when? how? where?</Typography>
          {/* <Typography variant="body2">
            головне завдання іменного присудка - розкрити, ким/чим/яким є підмет, дати йому
            визначення
          </Typography> */}
          {/* <Typography variant="body2">повільно, обережно, щасливо</Typography> */}
        </Grid>
      </Grid>
    </>
  );
};

export const buildSentenceRules: RuleType[] = [
  {
    name: (
      <>
        Прямий порядок слів (direct word order)
        <Typography gutterBottom>
          Використовується в розповідних реченнях а також в деяких питальних реченнях. Особливістю
          прямого порядку слів є те, що підмет завжди стоїть перед присудковою частиною.{" "}
        </Typography>
      </>
    ),
    rules: [
      {
        title: (
          <SentensePazzle
            title="Базове стверджувальне речення"
            words={["субʼєкт", "присудок", "додаток", "обставина"]}
          />
        ),
        description: [],
        lesson: [1, 4],
        examples: [
          <Highlight sentence="I'підмет'| learn'присудок'| English'додаток'| today'обставина'" />,
          <Highlight sentence="She'підмет'| studies'присудок'| in school'обставина'" />,
          // <CheckBadge lesson={4}>
          <Highlight sentence="Please| give'присудок'| it'додаток'| to him'непрямийдодаток'" />,
          // </CheckBadge>,
          // <CheckBadge lesson={4}>
          <Highlight sentence="Give'присудок'| him'непрямийдодаток'| flowers'додаток'" />,
          // </CheckBadge>,
        ],
      },
      {
        title: (
          <SentensePazzle
            title="Базове заперечне речення"
            words={[
              "субʼєкт",
              "допоміжне дієслово",
              "заперечення",
              "присудок",
              "додаток",
              "обставина",
            ]}
          />
        ),
        description: [],
        lesson: [1],
        examples: [
          <Highlight sentence="I'підмет'| do'допоміжне'|n't'заперечення'| speak'присудок'| English'додаток'" />,
          <Highlight sentence="They'підмет'| do'допоміжне'|n't'заперечення'| work'присудок'| today'обставина'" />,
          <Highlight sentence="We'підмет'| do'допоміжне'|n't'заперечення'| do'присудок'| it'додаток'| every&nbsp;day'обставина'" />,
          <Highlight sentence="He'підмет'| does'допоміжне'|n't'заперечення'| work'присудок'| every&nbsp;day" />,
        ],
      },
      {
        title: (
          <SentensePazzle
            title="Питальне речення у якому питальне слово є підметом"
            words={[
              "питальне слово (субʼєкт)",
              // "субʼєкт",
              // "допоміжне дієслово",
              // "заперечення",
              "присудок",
              "додаток",
              "обставина",
            ]}
          />
        ),
        description: [],
        lesson: [1],
        examples: [
          <Highlight sentence="Who'питальне'| knows'присудок'| it'додаток'| well?'обставина'" />,
          <Highlight sentence="Who'питальне'| speaks'присудок'| English?'додаток'" />,
          <Highlight sentence="Who'питальне'| works'присудок'| today?'обставина'" />,
        ],
      },
    ],
  },
  {
    name: (
      <>
        Непрямий порядок слів (indirect word order).
        <Typography gutterBottom>
          Порядок слів, в якому підмет стоїть після присудка або ж певної частини присудка.
        </Typography>
      </>
    ),

    rules: [
      {
        title: (
          <SentensePazzle
            title="Базове питальне речення"
            words={[
              // "питальне слово",
              "допоміжне дієслово",
              "субʼєкт",
              // "заперечення",
              "присудок",
              "додаток",
              "обставина",
            ]}
          />
        ),
        description: [],
        lesson: [1],
        examples: [
          <Highlight sentence="Do'допоміжне'| you'підмет'| study'присудок'| English?'додаток'" />,
          <Highlight sentence="Does'допоміжне'| he'підмет'| do'присудок'| it'додаток'| every&nbsp;day?'обставина'" />,
          <Highlight sentence="Do'допоміжне'| they'підмет'| speak'присудок'| English?'додаток'" />,
        ],
      },
      {
        title: (
          <SentensePazzle
            title="Питальне речення з питальним словом"
            words={[
              "питальне слово (роль додатка)",
              "допоміжне дієслово",
              "субʼєкт",
              // "заперечення",
              "присудок",
              // "додаток",
              "обставина",
            ]}
          />
        ),
        description: [],
        lesson: [2],
        examples: [
          <Highlight sentence="Who'питальне'| does'допоміжне'| he'підмет'| want'присудок'| to&nbsp;be?'присудок'" />,
          <Highlight sentence="What'питальне'| do'допоміжне'| you'підмет'| want'присудок'| to&nbsp;know?'присудок'" />,
          <Highlight sentence="What'питальне'| do'допоміжне'| you'підмет'| know'присудок'| very&nbsp;well?'обставина'" />,
          <Highlight sentence="What'питальне'| does'допоміжне'| she'підмет'| want'присудок'| to&nbsp;learn today?'обставина'" />,
          <Highlight sentence="What&nbsp;language'питальне'| do'допоміжне'| you'підмет'| speak?'присудок'" />,
          <Highlight sentence="What&nbsp;school'питальне'| do'допоміжне'| you'підмет'| go&nbsp;to?'присудок'" />,
        ],
      },
    ],
  },
];

export const words: RuleType[] = [
  {
    name: "Допоміжні слова які не перекладаються",
    rules: [
      {
        title: "Do",
        // raw: true,
        lesson: [1],
        description: [
          "Вживається для створення питань та заперечень у теперішньому часі (Present Simple) ",
          "_code_Вчивається з I/you/we/they",
        ],
        examples: [
          <>
            <AccentWord word="Do" /> you want it?
          </>,
          <>
            I <AccentWord word="do" /> not want it.
          </>,
        ],
      },
      {
        title: "does",
        // raw: true,
        lesson: [1],
        description: [
          "Вживається для створення питань та заперечень у теперішньому часі (Present Simple)",
          "_code_Вчивається з he/she/it",
        ],
        examples: [
          <>
            <AccentWord word="Does" /> she want it?
          </>,
          <>
            She <AccentWord word="does" /> not want it.
          </>,
        ],
      },
    ],
  },
  {
    name: "Прислівники часу",
    isVisible: false,
    // Annually
    // Before
    // Constantly
    // Daily
    // Early
    // Earlier
    // Eventually
    // Ever
    // Frequently
    // Finally
    // First
    // Formerly
    // Fortnightly
    // Generally
    // Hourly
    // Immediately
    // Infrequently
    // Just
    // Last
    // Late
    // Later
    // Lately
    // Monthly
    // Not until
    // Now
    // Normally
    // Never
    // Next
    // Often
    // Occasionally
    // Previously
    // Quarterly
    // Rarely
    // Regularly
    // Recently
    // Seldom
    // Sometimes
    // Since
    // Soon
    // Still
    // Then
    // Today
    // Tomorrow
    // Tonight
    // Yesterday
    // Usually
    // Yet
    // Weekly
    // Yearly
    rules: [
      {
        title: "Always",
        raw: true,
        lesson: [1000],
      },
      {
        title: "Already",
        raw: true,
        lesson: [1000],
      },
      {
        title: "Every day",
        raw: true,
        lesson: [1000],
      },
    ],
  },
];
export const tensesRules: RuleType[] = [
  {
    name: "Present Simple Tense (теперішній простий час)",
    rules: [
      {
        title: (
          <Typography fontSize={20} fontStyle="italic">
            Стверджувальна форма (дія):
            <br />
            <strong>I/you/we/they</strong> + [дієслово] + ...
            <br />
            <strong>He/she/it</strong> + [дієслово + <AccentWord word="s" />] ...
          </Typography>
        ),
        // "[he/she/it] + [дія + s]",
        // description: ["Якщо дію виконує третя особа однини, то до дієслова добавляється закінчення '-s'"],
        lesson: [1],
        examples: [
          <Typography variant="h4">I speak English.</Typography>,
          <Typography variant="h4">You speak English.</Typography>,
          <Typography variant="h4">
            She speak
            <AccentWord word="s" /> English.
          </Typography>,
          <Typography variant="h4">
            He speak
            <AccentWord word="s" /> English.
          </Typography>,
          <Typography variant="h4">
            It work
            <AccentWord word="s" />.
          </Typography>,
        ],
      },
      {
        title: (
          <Typography fontSize={20} fontStyle="italic">
            Заперечна форма (дія):
            <br />
            <strong>I/you/we/they</strong> + <strong>do not/don't</strong> + [дієслово] ...
            <br />
            <strong>He/she/it</strong> +{" "}
            <strong>
              do
              <AccentWord word="es" /> not / do
              <AccentWord word="es" />
              't
            </strong>{" "}
            + [дієслово] ...
          </Typography>
        ),
        // "[he/she/it] + [дія + s]",
        // description: ["Якщо дію виконує третя особа однини, то до дієслова добавляється закінчення '-s'"],
        lesson: [1],
        examples: [
          <Typography variant="h4">
            I <AccentWord word="don't" /> speak English.
          </Typography>,
          <Typography variant="h4">
            You <AccentWord word="do not" /> speak English.
          </Typography>,
          <Typography variant="h4">
            She <AccentWord word="doesn't" /> speak English.
          </Typography>,
          <Typography variant="h4">
            He <AccentWord word="does not" /> speak English.
          </Typography>,
          <Typography variant="h4">
            It <AccentWord word="doesn't" /> work.
          </Typography>,
        ],
      },
      {
        title: (
          <Typography fontSize={20} fontStyle="italic">
            Питальна форма (дія):
            <br />
            <strong>Do</strong> + <strong>I/you/we/they</strong> + [дієслово] ...
            <br />
            <strong>
              Do
              <AccentWord word="es" />
            </strong>{" "}
            + <strong>He/she/it</strong> + [дієслово] ...
          </Typography>
        ),
        // "[he/she/it] + [дія + s]",
        // description: ["Якщо дію виконує третя особа однини, то до дієслова добавляється закінчення '-s'"],
        lesson: [1],
        examples: [
          <Typography variant="h4">
            <AccentWord word="Do" /> you speak English.
          </Typography>,
          <Typography variant="h4">
            <AccentWord word="Do" /> they speak English.
          </Typography>,
          <Typography variant="h4">
            <AccentWord word="Does" /> she speak English.
          </Typography>,
          <Typography variant="h4">
            <AccentWord word="Does" /> he speak English.
          </Typography>,
          <Typography variant="h4">
            <AccentWord word="Does" /> it work.
          </Typography>,
        ],
      },
    ],
  },
];
// {/* <AccentStrong text="He speaks English." /> */}

export const rules = [
  { name: "Після субʼєкта обовʼязково повинно бути дієслово.", lesson: [1000] },
  {
    name: "За допомогою займенника можна замінити іменник щоб не повторювати вже сказане слово.",
    lesson: [1000],
    examples: [
      "Sarah has always loved fashion. She announced that she wants to go to fashion school.",
    ],
  },
];

export const personalPronouns: RuleType[] = [
  {
    name: "Особові займенники (personal pronouns)",
    description:
      "Займенники, які замінюють іменники, що позначають осіб, і вказують на особу, яка говорить (перша особа), особу, до якої звертаються (друга особа), або особу, про яку йдеться (третя особа).",
    rules: [
      { title: "I", lesson: [1] }, // description: "Займенник першої особи однини, вживається у називному відмінку, коли мовець говорить про себе."
      { title: "me", lesson: [1] }, // description: "Займенник першої особи однини, вживається в об’єктному відмінку (після дієслова або прийменника)."
      { title: "you", lesson: [1] }, // description: "Займенник другої особи однини та множини, вживається як в називному, так і в об’єктному відмінку."
      { title: "we", lesson: [1] }, // description: "Займенник першої особи множини, вживається в називному відмінку, коли мовець говорить про себе та інших осіб."
      { title: "us", lesson: [1] }, // description: "Займенник першої особи множини, вживається в об’єктному відмінку."
      { title: "they", lesson: [1] }, // description: "Займенник третьої особи множини, вживається в називному відмінку."
      { title: "them", lesson: [1] }, // description: "Займенник третьої особи множини, вживається в об’єктному відмінку."
      { title: "he", lesson: [1] }, // description: "Займенник третьої особи однини, чоловічий рід, вживається в називному відмінку."
      { title: "him", lesson: [1] }, // description: "Займенник третьої особи однини, чоловічий рід, вживається в об’єктному відмінку."
      { title: "she", lesson: [1] }, // description: "Займенник третьої особи однини, жіночий рід, вживається в називному відмінку."
      { title: "her", lesson: [1] }, // description: "Займенник третьої особи однини, жіночий рід, вживається в об’єктному відмінку."
      { title: "it", lesson: [1] }, // description: "Займенник третьої особи однини, вживається для неживих предметів та тварин, а також для невизначеної статі в називному та об’єктному відмінку."
      { title: "who", lesson: [1] }, // description: "Питальний, сполучний та особовий займенник."
    ],
  },
];

export const subjectPronouns = {
  en: "Subject pronouns",
  ua: "Субʼєктні займенники",
  description: "Займенники, які вказують на особу, яка виконує дію.",
  rules: [
    { name: "I", lesson: [1000] },
    { name: "you", lesson: [1000] },
    { name: "we", lesson: [1000] },
    { name: "they", lesson: [1000] },
    { name: "he", lesson: [1000] },
    { name: "she", lesson: [1000] },
    { name: "it", lesson: [1000] },
    { name: "who", lesson: [1000] },
  ],
};

export const objectPronouns = {
  en: "Object pronouns",
  ua: "Обʼєктні займенники",
  description: "Займенники, які вказують на обʼєкт, до якого відбувається дія.",
  rules: [
    { name: "me", lesson: [1000] },
    { name: "you", lesson: [1000] },
    { name: "him", lesson: [1000] },
    { name: "her", lesson: [1000] },
    { name: "us", lesson: [1000] },
    { name: "them", lesson: [1000] },
    { name: "it", lesson: [1000] },
    { name: "whom", lesson: [1000] },
  ],
};

export const reflexivePronouns = {
  en: "Reflexive pronouns",
  ua: "Зворотні займенники",
  description:
    "Зворотні займенники є формами особових займенників які закінчуються на –self або –selves.",
  rules: [
    { name: "myself", lesson: [1000] },
    { name: "yourself/yourselves", lesson: [1000] },
    { name: "himself", lesson: [1000] },
    { name: "herself", lesson: [1000] },
    { name: "itself", lesson: [1000] },
    { name: "oneself", lesson: [1000] },
    { name: "ourselves", lesson: [1000] },
    { name: "themself/themselves", lesson: [1000] },
  ],
};

export const intensivePronouns = {
  en: "Intensive pronouns",
  ua: "Інтенсивні займенники",
  description:
    "Інтенсивні займенники виглядають так само як і зворотні. Закінчуються на –self або –selves. Головна відмінність: можна прибрати з речення і суть його не зміниться.",
  rules: [
    { name: "myself", lesson: [1000] },
    { name: "yourself/yourselves", lesson: [1000] },
    { name: "himself", lesson: [1000] },
    { name: "herself", lesson: [1000] },
    { name: "itself", lesson: [1000] },
    { name: "oneself", lesson: [1000] },
    { name: "ourselves", lesson: [1000] },
    { name: "themself/themselves", lesson: [1000] },
  ],
};

export const possessivePronouns = {
  en: "Independent possessive pronouns",
  ua: "Незалежні присвійні займенники",
  description:
    "Займенники, які вказують на належність об'єкта або особи до певної особи. Використовуються незалежно від іменника.",
  rules: [
    { name: "mine", lesson: [1000] },
    { name: "yours", lesson: [1000] },
    { name: "ours", lesson: [1000] },
    { name: "his", lesson: [1000] },
    { name: "hers", lesson: [1000] },
    { name: "theirs", lesson: [1000] },
    { name: "its", lesson: [1000] },
  ],
};

export const possessiveAdjectivesPronouns = {
  en: "Possessive pronouns (adjectives)",
  ua: "Присвійні займенники (прикметники)",
  description:
    "Займенники, які вказують на належність об'єкта або особи до певної особи. Завжди використовуються в парі з іменником.",
  rules: [
    { name: "my", lesson: [1000] },
    { name: "your", lesson: [1000] },
    { name: "our", lesson: [1000] },
    { name: "his", lesson: [1000] },
    { name: "her", lesson: [1000] },
    { name: "their", lesson: [1000] },
    { name: "its", lesson: [1000] },
  ],
};

export const interrogativePronouns = {
  en: "Interrogative pronouns",
  ua: "Питальні займенники",
  description:
    "Займенники, що використовуються для задавання питань, щоб отримати інформацію про об'єкт, особу або якість.",
  rules: [
    { name: "who", lesson: [1000] },
    { name: "whose", lesson: [1000] },
    { name: "whom", lesson: [1000] },
    { name: "what", lesson: [1000] },
    { name: "which", lesson: [1000] },
  ],
};
export const reciprocalPronouns = {
  en: "Reciprocal pronouns",
  ua: "Взаємні займенники",
  description:
    "Взаємні займенники вказують на взаємну дію між двома або більше особами або предметами. Вони виражають взаємність.",
  rules: [
    { name: "each other", lesson: [1000] },
    { name: "one another", lesson: [1000] },
  ],
};
export const distributivePronouns = {
  en: "Distributive pronouns",
  ua: "Розподільні займенники",
  description:
    "Розподільні займенники вказують на окремих членів групи, підкреслюючи їх індивідуальність.",
  rules: [
    { name: "either", lesson: [1000] },
    { name: "each", lesson: [1000] },
    { name: "neither", lesson: [1000] },
    { name: "any", lesson: [1000] },
    { name: "none", lesson: [1000] },
  ],
};
export const genderNeutralPronouns = {
  en: "Gender-neutral pronouns",
  ua: "Гендерно-нейтральні займенники",
  description:
    "Гендерно-нейтральні займенники використовуються для заміни іменників, які не мають чіткого гендерного значення.",
  rules: [
    { name: "they/them/theirs", lesson: [1000] },
    { name: "he/him/his", lesson: [1000] },
    { name: "she/her/hers", lesson: [1000] },
  ],
};

export const relativePronouns = {
  en: "Relative pronouns",
  ua: "Сполучникові займенники",
  description:
    "Сполучникові займенники поєднують в собі функції займенників і сполучників. Вони замінюють слово або фразу з попереднього речення і одночасно з'єднують його з наступним.",
  rules: [
    { name: "that", lesson: [1000] },
    { name: "what", lesson: [1000] },
    { name: "which", lesson: [1000] },
    { name: "who", lesson: [1000] },
    { name: "whom", lesson: [1000] },
    { name: "whose", lesson: [1000] },
    { name: "where", lesson: [1000] },
  ],
};
export const demonstrativePronouns = {
  en: "Demonstrative pronouns",
  ua: "Вказівні займенники",
  description: "Займенники, що вказують на певний об'єкт або особу, виділяючи його/її з інших.",
  rules: [
    { name: "this", lesson: [1000] },
    { name: "that", lesson: [1000] },
    { name: "these", lesson: [1000] },
    { name: "those", lesson: [1000] },
  ],
};
export const indefinitePronouns = {
  en: "Indefinite pronouns",
  ua: "Неозначені займенники",
  description: "Використовуються коли не потрібно ідентифікувати особу.",
  rules: [
    { name: "one", lesson: [1000] },
    { name: "other", lesson: [1000] },
    { name: "another", lesson: [1000] },
    { name: "none", lesson: [1000] },
    { name: "some", lesson: [1000] },
    { name: "somebody / someone", lesson: [1000] },
    { name: "anybody / anyone", lesson: [1000] },
    { name: "everybody / everyone", lesson: [1000] },
    { name: "nobody / no one", lesson: [1000] },
    { name: "anything", lesson: [1000] },
    { name: "each", lesson: [1000] },
    { name: "either", lesson: [1000] },
    { name: "everything", lesson: [1000] },
    { name: "little", lesson: [1000] },
    { name: "much", lesson: [1000] },
    { name: "neither", lesson: [1000] },
    { name: "nothing", lesson: [1000] },
    { name: "something", lesson: [1000] },
    { name: "both", lesson: [1000] },
    { name: "few", lesson: [1000] },
    { name: "many", lesson: [1000] },
    { name: "others", lesson: [1000] },
    { name: "several", lesson: [1000] },
    { name: "all", lesson: [1000] },
    { name: "any", lesson: [1000] },
    { name: "more", lesson: [1000] },
    { name: "most", lesson: [1000] },
    { name: "none", lesson: [1000] },
    { name: "some", lesson: [1000] },
    { name: "such", lesson: [1000] },
  ],
};

export const wordsDifferences = {
  rules: [{ words: ["love", "like"], lesson: [1000] }],
};
