// @ts-ignore
import type {} from "@redux-devtools/extension"; // required for devtools typing
import { Session } from "@supabase/supabase-js";
import { produce } from "immer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { RPCUser } from "../common/fetchCurrentUser";
import { Module, Sentence, Word as SpWord, Word } from "../types/supabase";
import { LessonsState, setLessonNumber } from "./utils/functions";

export const useLessonsStore = create<LessonsState>()(
  devtools(
    (set) => ({
      session: null,
      authUser: null,
      isPersonalLesson: null,
      isGrammarFeedOpen: false,
      englishGrammarCourseSliderPositions: {},
      // lessons: null,
      // words: {},
      sentences: {},
      isLoading: true,
      currentLessonId: 0,
      lastLessonNumber: 0,
      personalLessonsWords: {},
      newWords: [],
      lessonNumber: 0,
      setLessonNumber: setLessonNumber(set),
      setIsGrammarFeedOpen: (isOpen) => {
        set(
          produce((state) => {
            state.isGrammarFeedOpen = isOpen;
          }),
        );
      },
      setNewWords: (words, toReplace) => {
        console.log("------", typeof words);
        set(
          produce((state) => {
            state.newWords = toReplace
              ? words
              : Array.from(new Set([...state.newWords, ...words].filter(Boolean)));
          }),
        );
      },
      userWords: [],
      setUserWords: (words: string[]) => {
        set(
          produce((state) => {
            state.userWords = Array.from(new Set(...state.userWords, ...words));
          }),
        );
      },
      globalWords: [],
      grammarModules: [],
      setGrammarModules: (modules: Module[]) => {
        console.log("🚀 ~ modules:", modules);
        set(
          produce((state) => {
            state.grammarModules = modules;
          }),
        );
      },
      lessonData: {},
      setLessonData: ({ lessonId, module, sentences }) => {
        set(
          produce((state) => {
            state.lessonData = {
              ...state.lessonData,
              [lessonId]: {
                lessonId,
                module,
                sentences,
              },
            };
          }),
        );
      },
      setGlobalWords: (words: Word[]) => {
        console.log("🚀 ~ words:", words);
        set(
          produce((state) => {
            const stateWords: Word[] = state.globalWords;
            const currentWordsMap = stateWords.reduce<Record<string, Word>>((prev, curr) => {
              return {
                ...prev,
                [curr.name]: curr,
              };
            }, {});
            const newWordsMap = words.reduce<Record<string, Word>>((prev, curr) => {
              return {
                ...prev,
                [curr.name]: curr,
              };
            }, {});

            state.globalWords = Object.values({
              ...currentWordsMap,
              ...newWordsMap,
            });
          }),
        );
      },
      explainText: [],
      setExplainText: (text) => {
        set(
          produce((state) => {
            state.explainText = text;
          }),
        );
      },
      setIsPersonalLesson: (isPersonalLesson) => {
        set(
          produce((state) => {
            state.isPersonalLesson = isPersonalLesson;
          }),
        );
      },
      setRPCUser: (user) => {
        set(
          produce((state) => {
            state.authUser = user;
          }),
        );
      },
      setSession: (session) => {
        set(
          produce((state) => {
            state.session = session;
          }),
        );
      },
      resetPersonalLessons: () => {
        set(
          produce((state) => {
            state.personalLessonsWords = {};
            state.personalLessonsSentences = {};
          }),
        );
      },
      setPersonalLessonsWords: (lesson, words) => {
        set(
          produce((state) => {
            state.personalLessonsWords = {
              ...state.personalLessonsWords,
              [lesson]: words,
            };
          }),
        );
      },
      personalLessonsSentences: {},
      setPersonalLessonsSentences: (lesson, sentences) => {
        set(
          produce((state) => {
            state.personalLessonsSentences = {
              ...state.personalLessonsSentences,
              [lesson]: sentences,
            };
          }),
        );
      },
      setEnglishGrammarCourseSliderPositions: (sliderId, index) => {
        set(
          produce((state) => {
            if (state.englishGrammarCourseSliderPositions[sliderId] !== index) {
              state.englishGrammarCourseSliderPositions = {
                ...state.englishGrammarCourseSliderPositions,
                [sliderId]: index,
              };
            }
          }),
        );
      },
    }),
    {
      name: "lessons-storage",
    },
  ),
);

// useMaterialStore.subscribe((state, prevState) => {
//   const stateWordsLength = Object.values(state.words).length;
//   const prevStateWordsLength = Object.values(prevState.words).length;

//   if (stateWordsLength !== prevStateWordsLength) {
//     const words = Object.values(state.words).flat();

//     state.wordsMap = words.reduce(
//       (prevWords, word) => ({
//         ...prevWords,
//         [word.word]: word,
//       }),
//       {},
//     );
//   }
// });

useLessonsStore.subscribe(async (state, prevState) => {
  // if (
  //   (state.currentLessonId !== prevState.currentLessonId ||
  //     Object.keys(prevState.lessons).length === 0) &&
  //   state.lessons
  // ) {
  // }
});
