import { Box, Button, Typography } from "@mui/material";
import isEqual from "deep-equal";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { Item, Menu, useContextMenu } from "react-contexify";
import getRandomLetter from "../../../common/getRandomLetter";
import isAdmin from "../../../common/isAdmin";
import shuffle from "../../../common/shuffle";
import supabaseClient from "../../../common/supabaseClient";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { useLessonsStore } from "../../../store/lessonsStore";
import { FunctionGetWordsWithAdditionalReturn, Word } from "../../../types/supabase";
import WordAudio from "../../Audio/WordAudio";
import UkrainianWord from "../../Sentence/UkrainianWord";
import WordPazleItem from "../../Sentence/WordPazleItem";
import AdminModalChange from "../../Unknown/AdminModalChange";

interface WordPazleItemProps {
  word: Word;
  // finish, move to next word to build
  handleNext: () => void;
}

const MENU_ID = "blahblahsdfds";

// export const buildSentenceAudioWrapClassName = "s6ed02a88055d4e2c900e81cc5aa33d94";
// export const buildSentenceButtonNextClassName = "s211a93da248344acb5d86b96fe393e58";

const WordLetterPazleItem = ({ word, handleNext }: WordPazleItemProps) => {
  const ref = useRef<HTMLElement>();
  const [partialWord, setPartialWord] = useState("");
  const [typeOfUpdate, setTypeOfUpdate] = useState<string>("");
  const [adminWordUpdateInitialValue, setAdminWoerUpdateInitialValue] = useState<string>("");
  const isInViewport = useIsInViewport(ref);

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isInViewport) {
      setCount(0);
      setPartialWord("");
    }
  }, [isInViewport]);

  const [wordMap, setWordMap] = useState<Record<string, { word: string; visible: boolean }>>({});

  useEffect(() => {
    if (count === 0) {
      const newMap = word.name
        .split("")
        .filter(Boolean)
        .reduce(
          (prevMap, word, index) => ({
            ...prevMap,
            [index.toString()]: {
              word: word,
              visible: true,
            },
          }),
          {},
        );
      setWordMap(newMap);
    }
  }, [word, count]);

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const authUser = useLessonsStore((state) => state.authUser);

  function handleContextMenu(event: any, word: any) {
    show({
      event,
      props: {
        word,
      },
    });
  }

  const handleChangeTranslation = (event: any) => {
    const { word } = event.props;
    setWordToUpdate(word);
    setTypeOfUpdate("change-translation");
    setAdminWoerUpdateInitialValue(word.translation);
    setIsAdminModalOpen(true);
  };

  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [wordToUpdate, setWordToUpdate] = useState<FunctionGetWordsWithAdditionalReturn | null>(
    null,
  );

  const [wordsToSelect, setWordsToSelect] = useState<any[]>([]);

  useEffect(() => {
    if (count === 0) {
      setWordsToSelect([]);
    }
  }, [count]);

  useEffect(() => {
    const wordMapBuilt = Object.keys(wordMap).length;
    console.log("🚀 ~ wordMap:", wordMap);
    if (!wordsToSelect.length && wordMapBuilt) {
      try {
        setWordsToSelect(
          shuffle([
            ...Object.entries(wordMap),
            ["999", { word: getRandomLetter(word.name.split("")), visible: true }],
          ]),
        );
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    } else if (wordMapBuilt) {
      // try {
      //   const newWordsToSelect = wordsToSelect.map((item) => {
      //     // console.log("wordMap[item[0]]", item, item[0], item[1], wordMap[item[0]]);
      //     if (wordMap[item[0]]?.visible !== item?.[1]?.visible) {
      //       return [
      //         item[0],
      //         {
      //           ...item[1],
      //           visible: wordMap[item[0]].visible,
      //         },
      //       ];
      //     }
      //     return item;
      //   });
      //   console.log("🚀 ~ newWordsToSelect:", newWordsToSelect);
      //   if (!isEqual(newWordsToSelect, wordsToSelect)) {
      //     setWordsToSelect(newWordsToSelect);
      //   }
      // } catch (err) {
      //   console.log("🚀 ~ err:", err);
      // }
    }
  }, [wordMap, wordsToSelect]);

  const isSuccess = wordsToSelect.length - 1 === count;

  return (
    <Box display="flex" alignItems="center" justifyContent="center" position="relative">
      {isAdmin(authUser?.id) && (
        <AdminModalChange
          open={isAdminModalOpen}
          onClose={() => {
            setIsAdminModalOpen(false);
          }}
          typeOfUpdate={typeOfUpdate}
          currentValue={
            typeof adminWordUpdateInitialValue === "string"
              ? adminWordUpdateInitialValue
              : (adminWordUpdateInitialValue as any).join(", ")
          }
          onUpdate={async (newValue) => {
            if (!wordToUpdate) return;

            if (typeOfUpdate === "change-variant") {
              await supabaseClient
                .from("harry_potter_words")
                .update({ variant: newValue })
                .eq("id", wordToUpdate.id);
            } else if (typeOfUpdate === "change-translation") {
              await supabaseClient
                .from("words")
                .update({ translation: newValue })
                .eq("name", wordToUpdate.word);
            } else if (typeOfUpdate === "add-additional") {
              await supabaseClient
                .from("harry_potter_words")
                .update({ additional: newValue.split(", ") })
                .eq("id", wordToUpdate.id);
            } else if (typeOfUpdate === "add-description") {
              await supabaseClient
                .from("words")
                .update({ description: newValue })
                .eq("name", wordToUpdate.word);
            } else if (typeOfUpdate === "change-transcription") {
              await supabaseClient
                .from("words")
                .update({ transcription: newValue })
                .eq("name", wordToUpdate.word);
            } else if (typeOfUpdate === "add-row-examples") {
              await supabaseClient
                .from("words")
                .update({ row_examples: newValue })
                .eq("name", wordToUpdate.word);
            } else if (typeOfUpdate === "add-question-answer") {
              await supabaseClient
                .from("harry_potter_words")
                .update({ question_answer: newValue.split("\n") })
                .eq("id", wordToUpdate.id);
            }

            setIsAdminModalOpen(false);
          }}
        />
      )}
      {isAdmin(authUser?.id) && (
        <Menu id={MENU_ID}>
          <Item id="changeTranslation" onClick={handleChangeTranslation}>
            Change translation
          </Item>
        </Menu>
      )}
      <Box
        textAlign="center"
        width="100%"
        gap={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        {/* center box to detect i in viewport */}
        <Box
          ref={ref}
          width={10}
          height={10}
          // sx={{
          //   backgroundColor: "red",
          // }}
        />
        <Box
          onContextMenu={(event) => {
            handleContextMenu(event, word);
          }}
        >
          <UkrainianWord
            // isAudioPlay={isInViewport}
            translation={word.translation}
            // audioUrl={sentence.ua_audio_url}
          />
        </Box>
        {/* {word.audio_url && isSuccess && (
          <Box display="inline-block" pr={2}>
            <WordAudio url={word.audio_url} small autoPlay={true} />
          </Box>
        )} */}
        {/* <HoverDetails
          isCenter
          words={
            Object.values(sentenceMap).map((w: any, index: number) => {
              if (index < count) {
                const cleanWord = removePunctuationFromString(w);
                const word = moduleWordsMap[cleanWord] || moduleWordsMap[cleanWord.toLowerCase()];

                return {
                  ...(word ? word : {}),
                  word: word?.name || cleanWord,
                  label: w,
                };
              }

              return {
                label: "_".repeat(w.length),
              };
            }) as any
          }
        /> */}
        <Box minHeight={80} position="relative">
          {word.audio_url && isSuccess && (
            <WordAudio
              url={word.audio_url}
              autoPlay={true}
              // boxHide={true}
            />
          )}
          <Typography variant="h2" textAlign="center" color="secondary.600" fontWeight={100}>
            {/* {Object.values(wordMap).join(", ")} */}

            {Object.values(wordMap)
              .map((word, index) => {
                if (index < count && word.word === " ") return "_";
                if (index < count) return word.word;
                return <Fragment key={index}></Fragment>;
              })
              // .join(" ")
              // .split("_")
              .map((p, index) => {
                if (index !== 0) {
                  return (
                    <Box component="span" key={index}>
                      {/* &nbsp; */}
                      <span>{p}</span>
                    </Box>
                  );
                }

                return <span>{p}</span>;
              })}
            <Typography variant="caption" fontSize={30} fontWeight={100}>
              {Object.values(wordMap).map((word, index) => {
                if (index < count) return "";
                return (
                  <Box component="span" key={index}>
                    {"_".repeat(word.word.length)}
                  </Box>
                );
              })}
            </Typography>
          </Typography>
          {isSuccess && (
            <Typography
              component="span"
              fontSize={20}
              fontStyle="initial"
              color="gray"
              fontWeight={400}
              fontFamily="Roboto"
              textAlign="center"
              sx={{
                whiteSpace: "nowrap",
                letterSpacing: 2,
              }}
            >
              {word.transcription}
              {/* <WordAudio url={wordProps.url} small boxHide /> */}
            </Typography>
          )}
        </Box>

        <Box gap={2} display="flex" flexWrap="wrap" alignItems="center" justifyContent="center">
          {wordsToSelect?.map(([mapIndex, { word: letter, visible }], index) => {
            // const wordWithoutPunctuation = word.replace(/[.!\,?]/g, "");

            return (
              <WordPazleItem
                word={letter}
                label={letter}
                // audio={moduleWordsMap[word] ? moduleWordsMap[word].audio_url : ""}
                byLetters
                initialSentence={word.name}
                partialSentence={partialWord}
                visible={visible}
                key={mapIndex}
                // key={index}
                wordIndex={Number(mapIndex)}
                onAnswer={(newPartialWord) => {
                  if (newPartialWord) {
                    setPartialWord(newPartialWord);

                    const newMap = {
                      ...wordMap,
                    };
                    if (newMap[mapIndex]) newMap[mapIndex].visible = false;
                    setWordMap(newMap);
                    setCount((prev) => prev + 1);
                  } else {
                    // setPartialWord("");
                    // setCount(0);
                  }
                }}
              />
            );
          })}
        </Box>

        <Box pt={5} pb={3}>
          <Button
            variant="contained"
            disabled={!isSuccess}
            onClick={() => {
              setCount(0);
              setPartialWord("");
              // finish, move to next word to build
              handleNext();
            }}
          >
            продовжити
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(WordLetterPazleItem);
