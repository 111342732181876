import { Box, Typography } from "@mui/material";
import { ReactElement, memo, useCallback, useEffect, useState } from "react";
import { Word } from "../../../types/supabase";
import WordAudio from "../../Audio/WordAudio";
import HStack from "../../Unknown/HStack";

interface WordTranscriptionProps {
  word: Word;
  play: boolean;
  small: boolean;
  autoplay: boolean;
  isReadToLearn?: boolean;
}

const WordTranscription = ({
  word: w,
  play,
  small,
  autoplay,
  isReadToLearn,
}: WordTranscriptionProps) => {
  const [audio, setAudio] = useState<ReactElement | null>(<WordAudio url={""} small />);

  useEffect(() => {
    if (play) {
      setAudio(<WordAudio url={w.audio_url || ""} autoPlay={autoplay} small={small} />);
    } else {
      setAudio(<WordAudio url={""} autoPlay={false} small={small} />);
    }
  }, [play, w.audio_url]);

  return (
    <HStack>
      {audio}
      <Box textAlign="center">
        <Typography gutterBottom variant="body1" fontSize={20} fontFamily="Roboto">
          {isReadToLearn
            ? w.transcription.split(" ")[0].replace("us:", "").replace("uk:", "").trim()
            : w.transcription?.replace("uk:", "🇬🇧")?.replace("us:", "🇺🇸")}
        </Typography>
      </Box>
    </HStack>
  );
};

export default memo(WordTranscription);
