import {
  Badge,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import ModalCloseButton from "../ModalCloseButton";
import useGlobalWords from "../../hooks/getGlobalWords";
import WordAudio from "../../Audio/WordAudio";
import VStack from "../VStack";
import WordTranscription from "../../Word/WordTranscription";
import useLessonWords from "../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../store/lessonsStore";
import { fullScreenModalStyle } from "../../../common/constants";
import AccentStrong from "../../Description/ModuleDescription/utils/AccentString";
import AccentWord from "../../Difference/components/AccentWord";
import UkrainianWord from "../../Sentence/UkrainianWord";

type CreateDataProp = {
  title: any;
  en: string;
  ua: any;
  description: string;
  examples: any[];
};

function createData(
  item1?: CreateDataProp | null,
  item2?: CreateDataProp | null,
  item3?: CreateDataProp | null,
  item4?: CreateDataProp | null,
  item5?: CreateDataProp | null,
  item6?: CreateDataProp | null,
  item7?: CreateDataProp | null,
  item8?: CreateDataProp | null,
  item9?: CreateDataProp | null,
) {
  return {
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item7,
    item8,
    item9,
  };
}

const rows = [
  createData(
    {
      title: "do",
      en: "do",
      ua: "робити",
      description:
        "Використовується для утворення заперечних і питальних речень в Present Simple, а також для підсилення значення.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Do" /> you do it?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Do" /> we work today?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Do" /> they study English?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              I <AccentWord word="don't" /> work today.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              They <AccentWord word="do" /> not know it.
            </>
          ),
        },
      ],
    },
    {
      title: "am",
      en: "am",
      ua: <UkrainianWord translation="(я) є" />,
      description: "Форма дієслова 'be' для першої особи однини в Present Simple.",
      examples: [
        {
          lesson: 4,
          title: (
            <>
              I <AccentWord word="am" /> happy.
            </>
          ),
        },
      ],
    },
    {
      title: "have",
      en: "have",
      ua: "мати",
      description: "Використовується для утворення часів Perfect, а також в значенні 'повинен'",
      examples: [],
    },
    {
      title: "can",
      en: "can",
      ua: "могти, вміти",
      description: "Виражає здатність, дозвіл, можливість.",
      examples: [
        {
          lesson: 7,
          title: (
            <>
              I <AccentWord word="can" /> help you.
            </>
          ),
        },
        {
          lesson: 7,
          title: (
            <>
              <AccentWord word="Can" /> you do it for me?
            </>
          ),
        },
        {
          lesson: 7,
          title: (
            <>
              You <AccentWord word="can" /> do that!
            </>
          ),
        },
        {
          lesson: 7,
          title: (
            <>
              He <AccentWord word="can't" /> speak English.
            </>
          ),
        },
      ],
    },
    {
      title: "dare",
      en: "dare",
      ua: "наважуватися, сміти",
      description: "Виражає виклик, виклик на сміливість.",
      examples: [],
    },
  ),
  createData(
    {
      title: "does",
      en: "does",
      ua: "робить (форма для 3-ї особи однини)",
      description:
        "Використовується для утворення заперечних і питальних речень в Present Simple для 3-ї особи однини.",
      examples: [
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Does" /> he do it?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Does" /> she work today?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="Does" /> it work?
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              She <AccentWord word="doesn't" /> work today.
            </>
          ),
        },
        {
          lesson: 1,
          title: (
            <>
              <AccentWord word="" />
              He <AccentWord word="does" /> not know it.
            </>
          ),
        },
      ],
    },
    {
      title: "is",
      en: "is",
      ua: <UkrainianWord translation="(він/вона/воно) є" />,
      description: "Форма дієслова 'be' для 3-ї особи однини в Present Simple.",
      examples: [
        {
          lesson: 4,
          title: (
            <>
              She <AccentWord word="is" /> happy.
            </>
          ),
        },
        {
          lesson: 4,
          title: (
            <>
              He <AccentWord word="is" /> a boy.
            </>
          ),
        },
      ],
    },
    {
      title: "has",
      en: "has",
      ua: "має (форма для 3-ї особи однини)",
      description:
        "Форма дієслова 'have' для 3-ї особи однини в Present Simple. Використовується для утворення Present Perfect, а також в значенні 'повинен'.",
      examples: [],
    },
    {
      title: "should",
      en: "should",
      ua: "слід, варто",
      description: "Виражає пораду, рекомендацію, припущення.",
      examples: [
        {
          lesson: 29,
          title: (
            <>
              I <AccentWord word="should" /> buy a new bicycle.
            </>
          ),
        },
        {
          lesson: 29,
          title: (
            <>
              You <AccentWord word="should" /> drink more water.
            </>
          ),
        },
        {
          lesson: 29,
          title: (
            <>
              You <AccentWord word="should" /> learn more.
            </>
          ),
        },
        {
          lesson: 29,
          title: (
            <>
              We <AccentWord word="should" /> help them.
            </>
          ),
        },
        {
          lesson: 29,
          title: (
            <>
              <AccentWord word="Should" /> I buy a bicycle or a motorcycle?
            </>
          ),
        },
      ],
    },
    {
      title: "need",
      en: "need",
      ua: "потребувати",
      description:
        "Може вживатися як модальне дієслово (без 'to') або як звичайне дієслово (з 'to') в значенні 'потрібно'",
      examples: [],
    },
  ),
  createData(
    {
      title: "did",
      en: "did",
      ua: "зробив, зробила, зробили (форма минулого часу)",
      description: "Використовується для утворення заперечних і питальних речень в Past Simple.",
      examples: [],
    },
    {
      title: "are",
      en: "are",
      ua: <UkrainianWord translation="(ти/ви/ми/вони) є" />,
      description: "Форма дієслова 'be' для  другої особи в Present Simple.",
      examples: [
        {
          lesson: 4,
          title: (
            <>
              We <AccentWord word="are" /> happy.
            </>
          ),
        },
        {
          lesson: 4,
          title: (
            <>
              You <AccentWord word="are" /> a good boy.
            </>
          ),
        },
      ],
    },
    {
      title: "had",
      en: "had",
      ua: "мав, мала, мали (форма минулого часу)",
      description:
        "Форма минулого часу від 'have'. Використовується для утворення Past Perfect, а також в значенні 'повинен був'.",
      examples: [],
    },
    {
      title: "may",
      en: "may",
      ua: "можу, можеш, можемо, дозволено",
      description: "Виражає дозвіл,  ймовірність,  а також вживається у  проханнях.",
      examples: [],
    },
    {
      title: "ought to",
      en: "ought to",
      ua: "слід, повинен",
      description: "Виражає  моральний обов'язок,  рекомендацію.",
      examples: [],
    },
  ),
  createData(
    null,
    {
      title: "were",
      en: "were",
      ua: "були (множина)",
      description: "Форма дієслова 'be' для множини в Past Simple.",
      examples: [],
    },
    {
      title: "having",
      en: "having",
      ua: "маючи",
      description:
        "Форма Present Participle від 'have'.  Використовується в часах Perfect Continuous, а також як дієприкметник.",
      examples: [],
    },
    {
      title: "might",
      en: "might",
      ua: "міг би, могла б, могли б",
      description:
        "Минулий час від 'may'. Виражає менш імовірну можливість, дозвіл,  а також  вживається у ввічливих проханнях.",
      examples: [],
    },
    {
      title: "used to",
      en: "used to",
      ua: "робив/робила раніше, мав/мала звичку",
      description: "Виражає  звички  або стани  в минулому, яких  вже  немає.",
      examples: [],
    },
  ),
  createData(
    null,
    {
      title: "was",
      en: "was",
      ua: "був, була",
      description: "Форма дієслова 'be' для однини в Past Simple.",
      examples: [],
    },
    null,
    {
      title: "must",
      en: "must",
      ua: "повинен, мусити",
      description: "Виражає  обов'язок,  примус,  високу  ймовірність.",
      examples: [],
    },
    {
      title: "going",
      en: "going",
      ua: "йдучи, збираючись",
      description:
        "Форма Present Participle від 'go'. Може вживатися в часах Continuous та в деяких ідіоматичних виразах.",
      examples: [],
    },
  ),
  createData(
    null,
    {
      title: "been",
      en: "been",
      ua: "був, була, були (дієприкметник минулого часу)",
      description: "Форма Past Participle від 'be'.  Вживається  в часах  Perfect.",
      examples: [],
    },
    null,
    {
      title: "shall",
      en: "shall",
      ua: "буду (формальний варіант)",
      description:
        "Формальне  дієслово для майбутнього часу,  часто  вживається  в  питаннях-пропозиціях.",
      examples: [],
    },
    {
      title: "get",
      en: "get",
      ua: "отримувати, ставати",
      description:
        "В деяких випадках може вживатися як допоміжне дієслово  в пасивному стані  або для вираження  поступовості дії.",
      examples: [],
    },
  ),
  createData(
    null,
    {
      title: "being",
      en: "being",
      ua: "будучи",
      description:
        "Форма Present Participle від 'be'. Вживається в часах Continuous, а також як дієприкметник.",
      examples: [],
    },
    null,
    {
      title: "could",
      en: "could",
      ua: "міг, могла, могли",
      description:
        "Минулий час від 'can'.  Виражає минулу здатність,  дозвіл, можливість, а також вживається у ввічливих проханнях.",
      examples: [],
    },
    {
      title: "getting",
      en: "getting",
      ua: "отримуючи, стаючи",
      description:
        "Форма Present Participle від 'get'.  Вживається в часах Continuous, а також  для вираження поступовості дії.",
      examples: [],
    },
  ),
  createData(
    null,
    null,
    null,
    {
      title: "will",
      en: "will",
      ua: "буду, будеш, будемо (скорочено: 'll)",
      description:
        "Використовується для вираження майбутнього часу, а також для позначення волі, наміру, передбачення.",
      examples: [],
    },
    {
      title: "got",
      en: "got",
      ua: "отримав, отримала, отримали",
      description:
        "Форма минулого часу від 'get'. Часто  використовується в розмовній мові замість 'have' в Present Perfect.",
      examples: [],
    },
  ),
  createData(null, null, null, {
    title: "would",
    en: "would",
    ua: "би (умовний спосіб)",
    description:
      "Використовується для утворення умовного способу, а також для позначення ввічливого прохання або пропозиції.",
    examples: [],
  }),
];

const HelpingWordsTable: React.FC<{}> = ({}) => {
  const [isCellModalOpen, setIsCellModalOpen] = useState(false);
  const [isHeaderCellModalOpen, setIsHeaderCellModalOpen] = useState(false);
  const [activeWord, setActiveWord] = useState("");
  const [activeTranslation, setActiveTranslation] = useState("");
  const [activeDescription, setActiveDescription] = useState("");
  const [activeExamples, setActiveExamples] = useState<any[]>([]);
  const [headerCellModalBody, setHeaderCellModalBody] = useState<any>(null);

  const globalWordsOptions = useMemo(() => {
    if (activeWord) {
      return { words: [activeWord] };
    }
    return { words: [] };
  }, [activeWord]);
  const [word] = useGlobalWords(globalWordsOptions);
  console.log("🚀 ~ word:", word);

  return (
    <Box>
      <Modal
        open={isCellModalOpen}
        onClose={() => {
          setIsCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsCellModalOpen(false)} />
          <VStack alignItems="flex-start">
            <Typography variant="h3">{activeWord}</Typography>
            <Typography>{activeTranslation}</Typography>
            {word && <WordTranscription word={word} play small={false} autoplay={false} />}
            <Typography>{activeDescription}</Typography>
            <VStack alignItems="flex-start">
              {activeExamples.map((e, i) => {
                return (
                  <Typography variant="h3" fontSize={18} fontWeight={500} key={i}>
                    {e.title}
                  </Typography>
                );
              })}
            </VStack>
          </VStack>
        </Box>
      </Modal>
      <Modal
        open={isHeaderCellModalOpen}
        onClose={() => {
          setIsHeaderCellModalOpen(false);
        }}
      >
        <Box sx={fullScreenModalStyle}>
          <ModalCloseButton onClose={() => setIsHeaderCellModalOpen(false)} />
          <VStack alignItems="flex-start">{headerCellModalBody}</VStack>
        </Box>
      </Modal>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <TableRow>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">"To do" verb auxiliaries</Typography>,
                    <Typography variant="body1">
                      Допоміжні дієслова утворені від "to do" дієслова
                    </Typography>,
                    // <Typography variant="body1">
                    //   Займенники, які вказують на особу, яка виконує дію.
                    // </Typography>,
                  ]);
                }}
              >
                "To do" verb Auxiliaries
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">"To be" verb auxiliaries</Typography>,
                    <Typography variant="body1">
                      Допоміжні дієслова утворені від "to be" дієслова
                    </Typography>,
                    // <Typography variant="body1">
                    //   Займенники, які вказують на обʼєкт, до якого відбувається дія.
                    // </Typography>,
                  ]);
                }}
              >
                "To be" verb auxiliaries
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">"To have" verb auxiliaries</Typography>,
                    <Typography variant="body1">
                      Допоміжні дієслова утворені від "to have" дієслова
                    </Typography>,
                    // <Typography variant="body1">
                    //   Займенники, які вказують на належність об'єкта або особи до певної особи.
                    //   Завжди використовуються в парі з іменником.
                    // </Typography>,
                  ]);
                }}
              >
                "To have" verb auxiliaries
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">Modal Auxiliaries</Typography>,
                    <Typography variant="body1">
                      Модальні допоміжні слова (передають можливість, дозвіл тощо)
                    </Typography>,
                    <Typography variant="body1">
                      Не виражають основної дії. Надає додаткового значення основному дієслову.
                    </Typography>,
                    <Typography variant="body1">
                      Не змінюється за часами. Мають лише одну форму незалежно від особи.
                    </Typography>,
                    <Typography variant="body1">
                      Використовується перед інфінитивом без частки <strong>to</strong>
                    </Typography>,
                    <Typography variant="body1">
                      Використовується у питальних реченнях замість допоміжних слів{" "}
                      <strong>do, does, did</strong>
                    </Typography>,
                    // <Typography variant="body1">
                    // </Typography>,
                  ]);
                }}
              >
                Modal Auxiliaries
              </TableCell>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setIsHeaderCellModalOpen(true);
                  setHeaderCellModalBody([
                    <Typography variant="h3">Semi-Modal Auxiliaries</Typography>,
                    <Typography variant="body1">
                      Напівмодальні допоміжні (іноді діють як головні дієслова, іноді як допоміжні)
                    </Typography>,
                    // <Typography variant="body1">
                    // </Typography>,
                  ]);
                }}
              >
                Semi-Modal Auxiliaries
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={
                  {
                    // "&:last-child td, &:last-child th": { border: 0 }
                  }
                }
              >
                <CustomTableCell
                  onClick={() => {
                    if (row.item1) {
                      setActiveWord(row.item1.en);
                      setActiveTranslation(row.item1.ua);
                      setActiveDescription(row.item1.description);
                      setActiveExamples(row.item1.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item1?.examples.map((e) => e.lesson) || []}
                  en={row.item1?.en || ""}
                />
                <CustomTableCell
                  onClick={() => {
                    if (row.item2) {
                      setActiveWord(row.item2.en);
                      setActiveTranslation(row.item2.ua);
                      setActiveDescription(row.item2.description);
                      setActiveExamples(row.item2.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item2?.examples.map((e) => e.lesson) || []}
                  en={row.item2?.en || ""}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item3) {
                      setActiveWord(row.item3.en);
                      setActiveTranslation(row.item3.ua);
                      setActiveDescription(row.item3.description);
                      setActiveExamples(row.item3.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item3?.examples.map((e) => e.lesson) || []}
                  en={row.item3?.en || ""}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item4) {
                      setActiveWord(row.item4.en);
                      setActiveTranslation(row.item4.ua);
                      setActiveDescription(row.item4.description);
                      setActiveExamples(row.item4.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item4?.examples.map((e) => e.lesson) || []}
                  en={row.item4?.en || "-"}
                ></CustomTableCell>
                <CustomTableCell
                  onClick={() => {
                    if (row.item5) {
                      setActiveWord(row.item5.en);
                      setActiveTranslation(row.item5.ua);
                      setActiveDescription(row.item5.description);
                      setActiveExamples(row.item5.examples);
                      setIsCellModalOpen(true);
                    }
                  }}
                  lessons={row.item5?.examples.map((e) => e.lesson) || []}
                  en={row.item5?.en || "-"}
                ></CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CustomTableCell: React.FC<{
  en: string;
  lessons: number[];
  onClick: () => void;
}> = ({ en, lessons, onClick }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady =
    (lessons?.length && lessons.includes(lessonNumber)) ||
    (lessons?.length && Math.min(...lessons) <= lessonNumber);

  return (
    <TableCell
      component="th"
      scope="row"
      onClick={onClick}
      sx={{
        // p: 0,
        m: 0,
        //
        // backgroundColor: "#4aa805",
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lessons?.length && lessons.includes(lessonNumber)
            ? "#ddffc5"
            : lessons?.length && Math.min(...lessons) <= lessonNumber
            ? "#fcffdb"
            : "#d0d0d0",
        // boxSizing: "content-box",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
      }}
    >
      <Box
        sx={
          {
            // borderTop: "1px solid rgba(224, 224, 224, 1)",
          }
        }
      >
        {lessons.includes(lessonNumber) ? (
          <Badge
            badgeContent=" "
            color="secondary"
            variant="dot"
            sx={{
              // color: "red",
              "& .MuiBadge-badge": {
                position: "absolute",
                left: -15,
                top: 0,
                backgroundColor: "red",
                width: 0,
                padding: "0 4px",
                opacity: 0,
              },
            }}
          >
            {en}
          </Badge>
        ) : (
          en
        )}
      </Box>
    </TableCell>
  );
};

export default HelpingWordsTable;
