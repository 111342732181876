import { Box, Paper, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import firstLetterUppercase from "../../../common/firstLetterUppercase";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import startsWithSubstringAndNonLetter from "../../../common/startsWithSubstringAndNonLetter";
import WordAudio from "../../Audio/WordAudio";

interface WordPazleItemProps {
  word: string;
  label: string;
  audio?: string;
  visible: boolean;
  correctWords: string[];
  onAnswer: (newPartialSentence?: string, minus?: number) => void;
}

// const alphabetIndexMap

const WordPazleItem = ({ word, audio, visible, correctWords, onAnswer }: WordPazleItemProps) => {
  const [color, setColor] = useState<"inherit" | "error">("inherit");
  const [play, setPlay] = useState(false);
  // const [visible, setVisible] = useState(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (color === "error") {
      timeout = setTimeout(() => {
        setColor("inherit");
      }, 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [color]);

  return (
    <Box
      component={Paper}
      elevation={2}
      borderRadius={2}
      borderColor="#efefef"
      alignItems="center"
      display="flex"
      color={color}
      px={4}
      py={2}
      gap={2}
      sx={{
        padding: "5px 17px",
        fontSize: "20px",
        textAlign: "center",
        cursor: "pointer",
        outline: "none",
        color: !visible ? "#e3e3e3" : "#a77301",
        backgroundColor: "#fff",
        border: `3px solid ${color === "error" ? "#ffb0b0" : "#efefef"}`,
        borderRadius: "15px",
        boxShadow: "0 5px #e8e8e8",
        pointerEvents: !visible ? "none" : "auto",
        opacity: !visible ? 0.6 : 1,
        transition: "border .3s ease",
        "&:active": {
          backgroundColor: "#fff",
          boxShadow: "0 5px #e8e8e8",
          transform: "translateY(2px)",
        },
      }}
      onClick={() => {
        setPlay(true);
        // const cleanLabel = removePunctuationFromString(label);

        if (correctWords.includes(word)) {
          // setVisible(false);
          onAnswer("success");
        } else {
          setColor("error");
          onAnswer("error");
        }

        // onAnswer("");
        setTimeout(() => {
          setPlay(false);
        }, 2000);
      }}
    >
      <Typography
        variant="h3"
        fontSize={17}
        fontWeight={500}
        position="relative"
        sx={{
          // fontFamily: "Helvetica",
          fontWeight: 600,
          fontFamily: "monospace",
          // : Arimo (sans-serif), Tinos (serif), and Cousine (monospace)
        }}
      >
        {word.trim() ? word : <Box component="span">_</Box>}
        {/* {word} */}
        {audio && play && <WordAudio url={audio} small autoPlay boxHide />}
      </Typography>
    </Box>
  );
};

export default memo(WordPazleItem);
