import { footerHeight } from "../../../common/constants";
import Box from "../Box";
import Typography from "../Typography";
import VStack from "../VStack";

const Footer: React.FC = () => {
  return (
    <VStack textAlign="center">
      <Box width="100%" height={footerHeight}>
        <hr />
        <Typography gutterBottom>© Copyright 2024 SpeakEnglish</Typography>
      </Box>
    </VStack>
  );
};

export default Footer;
