import Box from "../../Unknown/Box";

const FoodItemImage: React.FC<{ image: string; size?: "big"; folderName?: string }> = ({
  image,
  size,
  folderName,
}) => {
  console.log("🚀 ~ image:", image);
  return (
    <Box
      width="100%"
      maxWidth={320}
      height={size ? 250 : 100}
      overflow="hidden"
      sx={{
        backgroundImage: folderName
          ? `url(/read-to-learn-images/${image}.jpg)`
          : `url(food-items-images/${image}.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    />
  );
};

export default FoodItemImage;
