import { useTheme } from "@mui/material";
import Typography from "../Typography";

interface LogoProps {
  fontSize?: number;
  isAccentWhite?: boolean;
}

const Logo: React.FC<LogoProps> = ({ fontSize, isAccentWhite }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="h1"
      fontWeight={700}
      sx={{
        color: isAccentWhite ? "white" : "#4aa805",
        fontSize: fontSize || 40,
        [theme.breakpoints.up("md")]: {
          fontSize: fontSize || 60,
        },
      }}
    >
      <Typography
        variant="h1"
        component="span"
        color="secondary.dark"
        fontWeight={700}
        sx={{
          fontSize: "inherit",
        }}
      >
        Speak{" "}
      </Typography>
      English
    </Typography>
  );
};

export default Logo;
