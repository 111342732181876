import { Word } from "../../../../types/supabase";
import { Drawer, Link as MuiLink } from "@mui/material";
import Box from "../../../Unknown/Box";
import Button from "../../../Unknown/Button";
import Typography from "../../../Unknown/Typography";
import VStack from "../../../Unknown/VStack";
import HoverDetails from "../../../Word/HoverDetails";
import WordLetterPazleItem from "../../../Word/WordLetterPazleItem";
import { Link, useParams } from "react-router-dom";
import LinearProgress from "../../../User/LinearProgress";
import { useMemo, useState } from "react";
import ModalCloseButton from "../../../Unknown/ModalCloseButton";

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const BuildWords: React.FC<{
  curerntModuleWordsMap: Record<string, Word>;
  areThereQuotes: boolean;
  wordsGreeting: string;
  prevWords: string[];
  moduleWords: string[];
  wordsLength: number;
}> = ({ curerntModuleWordsMap, areThereQuotes, wordsGreeting, moduleWords, wordsLength }) => {
  const { lessonId, lessonType } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [wordIndex, setWordIndex] = useState(0);

  const prevWords = useMemo(() => {
    const w = [...moduleWords];
    w.length = wordIndex;
    return w;
  }, [moduleWords, wordIndex]);
  console.log("🚀 ~ moduleWords:", moduleWords);

  const currentWord = useMemo(() => {
    const w = moduleWords[wordIndex];
    if (!w) return null;

    return w;
  }, [moduleWords, wordIndex]);

  const handleDrawerClose = () => {
    setIsOpen(false);
    // if (onDrawerClose) onDrawerClose();
  };

  const allWordsAreBuilt = currentWord === null;

  return (
    <>
      {/* <LinearProgress height={4} elementsCount={wordsLength} currentValue={wordIndex} /> */}
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Скласти слова ({wordsLength})
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9999999,
        }}
      >
        <Box sx={drawerContentStyle}>
          <ModalCloseButton onClose={handleDrawerClose} />
          <LinearProgress height={7} elementsCount={moduleWords.length} currentValue={wordIndex} />
          <Box sx={{ backgroundColor: "white" }} py={15} px={3} mb={2} width="100%">
            {/* {!lessonSentences.length && <Loader />} */}
            {allWordsAreBuilt && (
              <Box py={4}>
                <Typography color="primary" textAlign="center">
                  {wordsGreeting}
                </Typography>
                <Typography textAlign="center">
                  Тепер прочитай всі складені слова та переклади.
                </Typography>
              </Box>
            )}
            {currentWord !== null && curerntModuleWordsMap[currentWord] && (
              <WordLetterPazleItem
                word={curerntModuleWordsMap[currentWord]}
                // word={curerntModuleWordsMap["ice cream"]}
                handleNext={() => {
                  setWordIndex((prev) => prev + 1);
                }}
              />
            )}
          </Box>

          {!!prevWords.length && (
            <VStack sx={{ backgroundColor: "white" }}>
              <Box pt={5} pb={allWordsAreBuilt ? 0 : 5} px={3}>
                {prevWords.map((w, index) => {
                  return (
                    <HoverDetails
                      key={index}
                      // isCenter
                      words={[
                        {
                          ...curerntModuleWordsMap[w],
                          id: w,
                          page: 1,
                          word: w,
                          label: w,
                        },
                      ]}
                    />
                  );
                })}
              </Box>
              {allWordsAreBuilt && (
                <VStack pb={5}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setWordIndex(0);
                    }}
                  >
                    Почати з початку
                  </Button>
                </VStack>
              )}
            </VStack>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default BuildWords;
