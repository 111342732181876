import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import getWordLevelColor from "../../common/getWordLevelColor";
import supabaseClient from "../../common/supabaseClient";
import useCreateAudio from "../../common/useCreateAudio";
import { useLessonsStore } from "../../store/lessonsStore";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import MenuLayout from "../Layout/MenuLayout";
import HStack from "../Unknown/HStack";
import storiesData from "./stories";
import useGlobalWords from "../hooks/getGlobalWords";
import PdfModal from "../Unknown/PdfModal";
import getSentenceWords from "../../common/getSentenceWords";

const Story: React.FC<{ story: any; onUpdateStories: () => void }> = ({
  story,
  onUpdateStories,
}) => {
  console.log("🚀 ~ story:", story);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { createAudio } = useCreateAudio();
  const [storyText, setStoryText] = useState(story.id);

  useEffect(() => {
    setStoryText(story.id);
  }, [story.id]);

  // const options = useMemo(() => {
  //   return { words: story.words };
  // }, [story.words]);

  // useGlobalWords(options);

  return (
    <Box key={story.id} mb={3}>
      <Button
        component="a"
        target="_blank"
        href={`https://www.google.com/search?q=${story.author}`}
      >
        {story.author}
      </Button>
      <Box width="100%">
        <CopyToClipboard
          text={`
        Explain this phase of ${story.author}:
        ${story.id}
        
        When it was said (year and place)? What does it mean?
        
        Give me a description of this phrase in a format:
        [Phrase]
        
        [When was said]
        [The meaning]
        
        The answer must be in Ukrainian language. 
        Don't put some title in the answer. Just follow my answer pattern.
        `}
        >
          <Button>What does it mean?</Button>
        </CopyToClipboard>
      </Box>
      {/* <Typography>{story.id}</Typography> */}
      <TextField
        color="primary"
        value={storyText}
        multiline={true}
        fullWidth
        // sx={{
        //   width: 500,
        // }}
        placeholder="Description"
        onChange={(event) => {
          setStoryText(event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <Box color="brown">Words: {story.word_count}</Box>
      <Box color="brown">User intersection: {story.all_intersection_count}</Box>
      <Box color="brown">User new words: {story.new_words_count}</Box>
      <HStack>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(story.story_id);
          }}
        >
          Copy story id
        </Button>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(`
            I have a clip transcript.
            It's for English learning.
            I need to get more sentences suit to the clip context.
            I need to learn the next words: ${JSON.stringify(story.row_new_words)}
            1. So create some additional simple sentences for me with the words I need to learn in the context of the clip. And also give some popular sentences/phases (if exists) including new words.
            2. Give me some questions and possible answers (2-3 ones) to this clip context.

            Give me the response in JSON format.
            The example of response pattern: {
              sentences: string[], // sentences to learn/train new words
              questions: {
                'question here': [
                  'possible answer 1',
                  'possible answer 2',
                  'possible answer 3'
                ],
                ...
              }
            }

            The transcript is:
            ${story.id}
            `);
          }}
        >
          AI explain
        </Button>
        {story.link && (
          <Button
            color="info"
            component={Link}
            target="_blank"
            href={
              story.link.includes("/")
                ? story.link
                : `https://www.youtube.com/watch?v=${story.link
                    .replace("(1)", "")
                    .replace("(2)", "")
                    .replace("(3)", "")
                    .replace("(4)", "")
                    .replace("(5)", "")}`
            }
          >
            external story
          </Button>
        )}
        {story.storage_link && (
          <Button color="info" component={Link} target="_blank" href={story.storage_link}>
            storage video
          </Button>
        )}
        {story.type === "pdf" && <Typography>Is PDF: {story.pdf_name}</Typography>}
        {story.type === "pdf" && <PdfModal />}
        {/* <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={async () => {
            setIsLoading(true);
            const audio = await createAudio(story.id);

            await supabaseClient
              .from("english_verses")
              .update({
                audio,
              })
              .eq("id", story.id);
            setIsLoading(false);
          }}
        >
          Create audio
        </LoadingButton> */}

        {/* <TextField
          color="primary"
          value={description}
          multiline={true}
          sx={{
            width: 500,
          }}
          placeholder="Description"
          onChange={(event) => {
            setDescription(event.target.value);
          }}
          type="text"
          variant="outlined"
        /> */}
      </HStack>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={async () => {
          setIsLoading(true);
          const result = await supabaseClient
            .from("english_verses")
            .update({
              verse: storyText,
              words: getSentenceWords(storyText),
            })
            .eq("id", story.story_id);
          console.log("story", story);
          console.log("result", result);
          setIsLoading(false);
        }}
      >
        Update
      </LoadingButton>
      {/* <LoadingButton
        loading={isLoading}
        variant="contained"
        onClick={async () => {
          setIsLoading(true);
          const result = await supabaseClient
            .from("english_verses")
            .update({
              verse: storyText,
            })
            .eq("id", story.story_id);
          console.log("story", story);
          console.log("result", result);
          setIsLoading(false);
        }}
      >
        Update 
      </LoadingButton> */}
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          setIsLoading(true);
          await supabaseClient.from("english_verses").delete().eq("verse", story.id);
          onUpdateStories();
          setIsLoading(false);
        }}
      >
        remove
      </Button>
      <Button
        variant="contained"
        onClick={async () => {
          setIsLoading(true);
          const result = await supabaseClient
            .from("english_verses")
            .update({
              grammar: true,
            })
            .eq("id", story.story_id);
          setIsLoading(false);

          console.log("🚀 ~ result:", result, story.story_id);
          // setStories(stories.filter((q) => q.id != story.id));
        }}
      >
        add to grammar
      </Button>

      {story.row_new_words && (
        <>
          {/* <Button
            variant="outlined"
            // color="error"
            onClick={async () => {
              setIsLoading(true);
              const { data } = await supabaseClient
                .from("words")
                .select()
                .in("name", story.row_new_words);
              console.log("🚀 ~ data:", data);
              if (data) {
                setGlobalWords(data);
              }
              setIsLoading(false);
              // setStories(stories.filter((q) => q.id != story.id));
            }}
          >
            check words
          </Button> */}
          {/* <CopyNewWordsForGemini words={story.row_new_words} /> */}
          <Box color="brown">
            <Typography variant="h4">New words:</Typography>
            {story.row_new_words.map((word: string) => {
              // const existingWord = globalWords.find((w) => w.name === word);
              return (
                <Button key={word} sx={{ color: getWordLevelColor(word) }}>
                  {word}
                </Button>
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
};
const AdminCheckUserStories: React.FC = () => {
  const [stories, setStories] = useState(storiesData);

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }} p={5}>
        <Grid container>
          <Grid item xs={12}>
            {stories.map((story: any) => {
              return (
                <Story
                  key={story.id}
                  story={story}
                  onUpdateStories={() => {
                    setStories(stories.filter((q: any) => q.id != story.id));
                  }}
                />
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminCheckUserStories;
