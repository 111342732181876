import { Box, Button, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import useIsInViewport from "../../../hooks/useIsInViewport";
import { ExtendedUserSentence, Sentence } from "../../../types/supabase";
import useLessonWords from "../../../hooks/useLessonWords";
import PersonalEnPronounce from "../../Word/WordTranslationSection/PersonalEnPronounce";
import { useLessonsStore } from "../../../store/lessonsStore";
import Hint, { HintType } from "../../Sentence/Hint";
import UkrainianAudioSentence from "../../Sentence/UkrainianAudioSentence";
import EnglishAudioSentence from "../../Sentence/EnglishAudioSentence";
import TranslateButton from "../../Sentence/TranslateButton";
import RepetitionButtons from "../../Sentence/RepetitionButtons";
import VStack from "../../Unknown/VStack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface RepetitionUserSentenceProps {
  sentence: Sentence;
  wordsCount: number;
  isRepeated?: boolean;
  belongsToUser: boolean;
  goToNextSlide: (sentence: string, period?: string) => Promise<void>;
}

const HomeworkSentence = ({
  sentence,
  isRepeated,
  belongsToUser,
  wordsCount,
  goToNextSlide,
}: RepetitionUserSentenceProps) => {
  const isPersonalLesson = useLessonsStore((state) => state.isPersonalLesson);
  const [isTranslated, setIsTranslated] = useState(false);
  const ref = useRef<HTMLElement>();
  const isInViewport = useIsInViewport(ref);

  const resetIsTranslatedAfterSlideChange = () => {
    setTimeout(() => {
      setIsTranslated(false);
    }, 400);
  };

  useEffect(() => {
    if (!isInViewport) resetIsTranslatedAfterSlideChange();
  }, [isInViewport]);

  const { lessonWords } = useLessonWords({
    sentences: [sentence.en],
    trigger: sentence.en,
  });

  return (
    <Box position="relative">
      <VStack width="100%" textAlign="center" pb={5}>
        {/* center box to detect i in viewport */}
        <Box ref={ref} width={10} height={10} />
        <UkrainianAudioSentence sentence={sentence.ua} />
        <EnglishAudioSentence
          belongsToUser={belongsToUser}
          sentence={sentence.en}
          audioUrl={sentence.en_audio_url}
          isVisible={isTranslated}
        >
          {isPersonalLesson && (
            <Typography
              fontStyle="italic"
              fontSize={25}
              color="secondary.dark"
              fontWeight={500}
              textAlign="center"
            >
              <PersonalEnPronounce sentence={sentence.en} lessonWords={lessonWords} />
            </Typography>
          )}
        </EnglishAudioSentence>
        <TranslateButton isVisible={!isTranslated} onTranslate={() => setIsTranslated(true)} />
        {isRepeated && (
          <Box py={3}>
            <Button
              onClick={() => {
                goToNextSlide(sentence.en);
                setIsTranslated(false);
              }}
              variant="outlined"
              sx={{ padding: 2 }}
            >
              <ArrowForwardIcon sx={{ fontSize: 16 }} />
            </Button>
          </Box>
        )}
        {!isRepeated && (
          <RepetitionButtons
            isVisible={isTranslated}
            wordsCount={wordsCount}
            onlyCurrentDay
            goToNextSlide={(period) => {
              goToNextSlide(sentence.en, period);
              setIsTranslated(false);
            }}
          />
        )}
      </VStack>
    </Box>
  );
};

export default memo(HomeworkSentence);
