import LastPageIcon from "@mui/icons-material/LastPage";
import { Drawer, Grid, Paper } from "@mui/material";
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import { Word as SpWord } from "../../../types/supabase";
import LinearProgress from "../../User/LinearProgress";
import WordTranscription from "../../Word/WordTranscription";
import WordTranslationSection from "../../Word/WordTranslationSection";
import Box from "../Box";
import Button from "../Button";
import FullScreenLoader from "../FullScreenLoader";
import HStack from "../HStack";
import ModalCloseButton from "../ModalCloseButton";
import Typography from "../Typography";
import VStack from "../VStack";
import WordExample from "./WordExample";
import FoodItemImage from "../../Food/FoodItemImage";
import ReactPlayer from "react-player";
import { readToLearnCourseWords } from "../../LearnToRead/LearnToReadScreen";
import { wordsWithoutImages } from "../../../common/constants";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import isAdmin from "../../../common/isAdmin";
import AdminUsers from "../../AdminUsers";

const isTouchDevice = require("is-touch-device");

export interface PartWord {
  word: string;
  normalizedWord: string;
}

export const ChangePositionButton: React.FC<
  PropsWithChildren & { isPrimary?: boolean; onClick: () => void }
> = ({ children, isPrimary, onClick }) => {
  return (
    <Box
      component={Paper}
      elevation={2}
      borderRadius={2}
      borderColor="#efefef"
      alignItems="center"
      display="flex"
      px={4}
      py={2}
      gap={2}
      sx={{
        padding: "5px 13px",
        fontSize: "24px",
        textAlign: "center",
        alignItems: "center",
        cursor: isTouchDevice() ? "auto" : "pointer",
        outline: "none",
        backgroundColor: "#fff",
        border: "3px solid #efefef",
        borderRadius: "15px",
        boxShadow: "0 5px #e8e8e8",
        transition: "border .3s ease",
        "&:active, &:focus": {
          outline: "none",
          backgroundColor: "#fff",
          boxShadow: "0 5px #e8e8e8",
          transform: "translateY(2px)",
        },
      }}
      onClick={onClick}
    >
      <Typography variant="h3" color={isPrimary ? "primary" : "default"} fontWeight={500}>
        {children}
      </Typography>
    </Box>
  );
};

interface LearnWordsSectionProps {
  isOpen?: boolean;
  lessonId: string;
  currentWords: string[];
  withoutButton?: boolean;
  withoutExamples?: boolean;
  isReadToLearn?: boolean;
  buttonLabel?: string;
  onDrawerClose?: () => void;
  onUpdateWords: (newWords: string[]) => void;
}

const LearnWordSection: React.FC<LearnWordsSectionProps> = ({
  isOpen: inputIsOpen,
  lessonId,
  currentWords,
  withoutButton,
  isReadToLearn,
  withoutExamples,
  buttonLabel,
  onDrawerClose,
  onUpdateWords,
}) => {
  const [isOpen, setIsOpen] = useState(inputIsOpen || false);

  useEffect(() => {
    if (inputIsOpen === false || inputIsOpen) setIsOpen(inputIsOpen);
  }, [inputIsOpen]);

  // useEffect(() => {
  //   const channel = supabaseClient
  //     .channel("room8")
  //     .on(
  //       "postgres_changes",
  //       { event: "UPDATE", schema: "public", table: "users" },
  //       async (payload) => {
  //         // console.log("🚀 ~ payload:", payload);
  //         if (payload.new.id === authUser?.id) {
  //           setRPCUser(payload.new as RPCUser);
  //         }
  //       },
  //     )
  //     .subscribe();

  //   return () => {
  //     channel.unsubscribe();
  //   };
  // }, []);

  const handleDrawerClose = () => {
    setIsOpen(false);
    if (onDrawerClose) onDrawerClose();
  };

  const authUser = useLessonsStore((state) => state.authUser);
  const [studentUserUuid, setStudentUserUuid] = useState<string | null>(null);
  const [users, setUsers] = useState<any[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (isAdmin(authUser?.id) && !users.length) {
      const { data } = await supabaseClient.from("users").select();
      if (data) setUsers(data);
    }
  }, [users, authUser]);

  const [currentWord] = currentWords;

  return (
    <>
      {!withoutButton && (
        <Button variant="outlined" onClick={() => setIsOpen(true)}>
          {buttonLabel
            ? `${buttonLabel} (${currentWords?.length})`
            : `Вчити слова (${currentWords?.length})`}
        </Button>
      )}

      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        <DrawerContent
          lessonId={lessonId}
          currentWord={currentWord}
          currentWords={currentWords}
          isReadToLearn={isReadToLearn}
          withoutExamples={withoutExamples}
          studentUserUuid={studentUserUuid || undefined}
          onClose={handleDrawerClose}
          onUpdateWords={onUpdateWords}
        >
          {" "}
          {isAdmin(authUser?.id) && (
            <AdminUsers
              users={users}
              onChange={(user) => {
                setStudentUserUuid(user.uuid);
              }}
            />
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const DrawerContent: React.FC<
  {
    lessonId: string;
    currentWord: string;
    currentWords: string[];
    withoutExamples?: boolean;
    studentUserUuid?: string;
    isReadToLearn?: boolean;
    onClose: () => void;
    onUpdateWords: (newWords: string[]) => void;
  } & PropsWithChildren
> = ({
  lessonId,
  currentWord: currentRowWord,
  studentUserUuid,
  withoutExamples,
  currentWords,
  isReadToLearn,
  children,
  onClose,
  onUpdateWords,
}) => {
  const [repeatedWords, setRepeatedWords] = useState<string[]>([]);
  const [isTranslated, setIsTranslated] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const lessonStoreWords = useLessonsStore((state) => state.personalLessonsWords[lessonId]);
  const setPersonalLessonsWords = useLessonsStore((state) => state.setPersonalLessonsWords);
  const drawerContentRef = useRef<any>(null);

  const currentWord = useMemo(() => {
    if (!lessonStoreWords) return null;

    return lessonStoreWords.find((w) => w.name === currentRowWord) || null;
  }, [lessonStoreWords, currentRowWord]);

  const wordExamples = useMemo(() => {
    if (!currentWord?.examples.length || withoutExamples) return [];

    return <WordExample currentWord={currentWord} currentRowWord={currentRowWord} />;
  }, [currentWord, currentRowWord, withoutExamples]);

  const moveToTheEnd = () => {
    if (!authUser) return;

    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.push(firstWord);
    setTimeout(async () => {
      setIsTranslated(false);
      onUpdateWords(newWords);
      const updatedRepeatedWords = Array.from(
        new Set([...repeatedWords, currentWord?.name]),
      ).filter(Boolean) as string[];
      setRepeatedWords(updatedRepeatedWords);
      if (updatedRepeatedWords.length === currentWords.length) {
        setRepeatedWords([]);
      } else {
        setRepeatedWords(updatedRepeatedWords);
      }

      if (!currentWord) return;

      const { count: wordExists } = await supabaseClient
        .from("user-words")
        .select("*", { count: "exact", head: true })
        .eq("id", `${authUser.uuid}-${currentWord.name}`);

      if (!wordExists) {
        await supabaseClient.rpc("add_user_word", {
          userprop: authUser.id,
          word: currentWord.name,
        });
      }
    }, 200);
  };

  const moveBy2 = () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(1, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      onUpdateWords(newWords);
    }, 200);
  };

  const moveBy4 = () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(3, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      onUpdateWords(newWords);
    }, 200);
  };

  const moveBy10 = () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(9, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      onUpdateWords(newWords);
    }, 200);
  };

  const moveBy20 = () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(19, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      onUpdateWords(newWords);
    }, 200);
  };

  useEffect(() => {
    const user = authUser as any;

    if (user && user.uuid === user.studentUserUuid) {
      if (user.type === "word_is_translated") {
        setIsTranslated(true);
      } else if (user.type === "2") {
        moveBy2();
      } else if (user.type === "4") {
        moveBy4();
      } else if (user.type === "10") {
        moveBy10();
      } else if (user.type === "20") {
        moveBy20();
      } else if (user.type === "end") {
        moveToTheEnd();
      }
      if (drawerContentRef.current) {
        drawerContentRef.current.scrollTo({ top: 0 });
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (!lessonStoreWords) {
      (async () => {
        const { data: spWords, error } = await supabaseClient
          .from("words")
          .select()
          .in("name", currentWords)
          .returns<SpWord[]>();

        if (!spWords) {
          if (error) {
            throw error;
          } else {
            throw new Error("Something went wrong. No Sp words found");
          }
        }
        setPersonalLessonsWords(lessonId, spWords);
      })();
    }
  }, [lessonStoreWords, currentWords, lessonId, setPersonalLessonsWords]);

  const adminControl = async (type: string) => {
    if (authUser && isAdmin(authUser.id)) {
      if (studentUserUuid && studentUserUuid !== authUser.uuid) {
        await supabaseClient
          .from("dialog-admin")
          .update({
            user: studentUserUuid,
            value: (Math.random() * Math.random()).toString(),
            type,
          })
          .eq("id", "words_moving");
      } else if (!studentUserUuid) {
        await supabaseClient
          .from("dialog-admin")
          .update({
            user: authUser.uuid,
            type: "",
          })
          .eq("id", "words_moving");
      }
    }
  };

  const drawer = useMemo(() => {
    if (!currentWord)
      return (
        <Box sx={drawerContentStyle}>
          <FullScreenLoader />
        </Box>
      );

    return (
      <Box sx={drawerContentStyle} ref={drawerContentRef}>
        <LinearProgress
          elementsCount={currentWords.length - 1}
          currentValue={repeatedWords.length}
        />
        {children}
        <ModalCloseButton onClose={onClose} />
        <VStack
          px={5}
          justifyContent="center"
          position="relative"
          width="100vw"
          flexWrap="nowrap"
          pt={30}
          pb={10}
          sx={{
            overflowX: "hidden",
            // backgroundColor: "red",
          }}
        >
          <Typography variant="h2" color="primary.main" gutterBottom>
            {currentRowWord}
          </Typography>
          <VStack position="relative" sx={{ width: "100%", overflowX: "hidden", pb: 5 }}>
            {!isTranslated && (
              <Box
                position="absolute"
                width="calc(100% + 10px)"
                height="100%"
                minWidth={280}
                left="50%"
                zIndex={3}
                alignItems="flex-start"
                pt={15}
                justifyContent="center"
                textAlign="center"
                fontSize={14}
                onClick={async () => {
                  setIsTranslated(true);
                  await adminControl("word_is_translated");
                }}
                display={!isTranslated ? "flex" : "none"}
                sx={{
                  transform: "translate(-50%)",
                  backdropFilter: "blur(35px)",
                  cursor: "pointer",
                }}
              >
                {/* натисни щоб побачити переклад */}
                <Button variant="contained">перекласти</Button>
              </Box>
            )}
            <WordTranscription
              isReadToLearn={isReadToLearn}
              word={currentWord}
              play={isTranslated}
              autoplay
              small
            />
            <WordTranslationSection
              translation={currentWord.translation || ""}
              short
              sentences={[]}
              isVisible
              examples={false}
            />

            <HStack pt={3} justifyContent="center" alignItems="stretch" width={300}>
              <ChangePositionButton
                onClick={async () => {
                  moveBy2();
                  await adminControl("2");

                  // await adminControl();
                }}
              >
                2
              </ChangePositionButton>
              <ChangePositionButton
                onClick={async () => {
                  moveBy4();
                  await adminControl("4");
                }}
              >
                4
              </ChangePositionButton>
              {currentWords.length > 15 && (
                <ChangePositionButton
                  onClick={async () => {
                    moveBy10();
                    await adminControl("10");
                  }}
                >
                  10
                </ChangePositionButton>
              )}
              {currentWords.length > 30 && (
                <ChangePositionButton
                  onClick={async () => {
                    moveBy20();
                    await adminControl("20");
                  }}
                >
                  20
                </ChangePositionButton>
              )}
              <ChangePositionButton
                onClick={async () => {
                  if (!authUser) return;

                  moveToTheEnd();
                  await adminControl("end");
                }}
              >
                <LastPageIcon />
              </ChangePositionButton>
            </HStack>

            {/* <Typography variant="caption" gutterBottom position="relative" top={-5}>
              \ наступна позиція у списку повторені /
            </Typography> */}

            {/* <FoodItemImage image={getImageName(row.en_name)} /> */}

            {((currentWord.image_name && !wordsWithoutImages.includes(currentWord.name)) ||
              (!wordsWithoutImages.includes(currentWord.name) &&
                readToLearnCourseWords.includes(currentWord.name))) && (
              <VStack pt={5}>
                <FoodItemImage
                  size="big"
                  image={currentWord.image_name || currentWord.name}
                  folderName="read-to-learn-images"
                />
              </VStack>
            )}

            {currentWord.pronounce_video_links && (
              <Box py={5}>
                <ReactPlayer
                  controls
                  style={{ maxWidth: "420px", maxHeight: "290px" }}
                  url={currentWord.pronounce_video_links[0]}
                />
              </Box>
            )}
            {wordExamples}
          </VStack>
        </VStack>
      </Box>
    );
  }, [
    authUser,
    currentWord,
    currentWords,
    wordExamples,
    isTranslated,
    repeatedWords,
    currentRowWord,
    onClose,
    onUpdateWords,
  ]);

  return drawer;
};

export default LearnWordSection;
