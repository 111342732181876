import { Box, Button, Drawer, Grid, Link, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { EnglishStory, Word } from "../../../types/supabase";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import useLessonWords from "../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../store/lessonsStore";
import useGlobalWords from "../../hooks/getGlobalWords";
import WordAudio from "../../Audio/WordAudio";
import HStack from "../../Unknown/HStack";
import FullScreenLoader from "../../Unknown/FullScreenLoader";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import { useParams } from "react-router-dom";
import isAdmin from "../../../common/isAdmin";
import AiQuestionAnswer from "./AiQuestionAnswer";

const Story: React.FC<{ story: EnglishStory }> = ({ story }) => {
  const [isTranslated, setIsTranslated] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const options = useMemo(() => {
    return { words: story.words };
  }, [story]);
  console.log("🚀 [Story] lesson words to fetch:", options);
  useGlobalWords(options);

  // const phrases = ["United States"]

  useEffect(() => {
    const words = story.words.map((w) => w.replace("–", "-"));

    const map = globalWords
      .filter(
        (w) =>
          words.includes(w.name) ||
          words.includes(w.name.toLowerCase()) ||
          words.includes(w.name.toUpperCase()),
      )
      .reduce((acm, w) => {
        return {
          ...acm,
          [w.name]: w,
          [w.name.toLowerCase()]: w,
          [w.name.toUpperCase()]: w,
        };
      }, {});

    setCurrentModuleWordsMap(map);
  }, [globalWords, story]);

  return (
    <VStack alignItems="flex-start">
      <VStack alignItems="flex-start">
        {/* <Box
          component="video"
          src={
            "https://storage.googleapis.com/leeearning.appspot.com/videos/treeparts.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qGlvmiORCwgV5Epb5nBO3bBmB87PKu%2BOyqhtyuoC93qq4Exz7VGGSsQ1ILGDfcOtxVx9uMZc6w0oYLOPBZHEt12RvrbIHNflYgjWoJCqWmP0VRBFbZGkOtUfOj8pJuu17M8y4MKxfmQouLkekmMM5LNeEnYYkBBitJSNV1TyYGoZPE4cwdBFmdageo%2BLfwVGs2Glsy4Hc5ZsZO%2BaPMBmVPMzS8eD3gWDXxxsYEayUjsIkAXCIThQmqjWtGWKmY%2BpyK15IXgCvF6viED9RCsz7Tv46S7SEkva42gaUSz%2BltONjXtHCLfsz6iy2PNugIHXfbd%2BVskaegSAtw923bBi1g%3D%3D"
          }
          controls
          width="100%"
          maxWidth={500}
          maxHeight={200}
        /> */}
        {/* <iframe
          width="300"
          height="600"
          src="https://storage.googleapis.com/leeearning.appspot.com/videos/treeparts.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qGlvmiORCwgV5Epb5nBO3bBmB87PKu%2BOyqhtyuoC93qq4Exz7VGGSsQ1ILGDfcOtxVx9uMZc6w0oYLOPBZHEt12RvrbIHNflYgjWoJCqWmP0VRBFbZGkOtUfOj8pJuu17M8y4MKxfmQouLkekmMM5LNeEnYYkBBitJSNV1TyYGoZPE4cwdBFmdageo%2BLfwVGs2Glsy4Hc5ZsZO%2BaPMBmVPMzS8eD3gWDXxxsYEayUjsIkAXCIThQmqjWtGWKmY%2BpyK15IXgCvF6viED9RCsz7Tv46S7SEkva42gaUSz%2BltONjXtHCLfsz6iy2PNugIHXfbd%2BVskaegSAtw923bBi1g%3D%3D"
        /> */}
        {!story.storage_link && <Box py={2}></Box>}
        {story.storage_link && (
          <VStack>
            <Box
              component="video"
              src={story.storage_link}
              controls
              width="100%"
              maxWidth={500}
              maxHeight={200}
            />
          </VStack>
        )}
        <Box width="calc(100vw - 30px)">
          {story.audio && <WordAudio url={story.audio} />}
          {story.title && (
            <Box>
              <Typography color="secondary.dark" fontSize={22} fontWeight={700}>
                {story.title}
              </Typography>
            </Box>
          )}

          {story.verse.split("\n").map((line, index) => {
            // console.log("line", line, story);
            let audio = "";
            if (story.id === 933) {
              if (line.startsWith("A ")) audio = curerntModuleWordsMap.A?.audio_url;
              if (line.startsWith("B ")) audio = curerntModuleWordsMap.B?.audio_url;
              if (line.startsWith("C ")) audio = curerntModuleWordsMap.C?.audio_url;
              if (line.startsWith("D ")) audio = curerntModuleWordsMap.D?.audio_url;
              if (line.startsWith("E ")) audio = curerntModuleWordsMap.E?.audio_url;
              if (line.startsWith("F ")) audio = curerntModuleWordsMap.F?.audio_url;
              if (line.startsWith("G ")) audio = curerntModuleWordsMap.G?.audio_url;
              if (line.startsWith("H ")) audio = curerntModuleWordsMap.H?.audio_url;
              if (line.startsWith("I ")) audio = curerntModuleWordsMap.I?.audio_url;
              if (line.startsWith("J ")) audio = curerntModuleWordsMap.J?.audio_url;
              if (line.startsWith("K ")) audio = curerntModuleWordsMap.K?.audio_url;
              if (line.startsWith("L ")) audio = curerntModuleWordsMap.L?.audio_url;
              if (line.startsWith("M ")) audio = curerntModuleWordsMap.M?.audio_url;
              if (line.startsWith("N ")) audio = curerntModuleWordsMap.N?.audio_url;
              if (line.startsWith("O ")) audio = curerntModuleWordsMap.O?.audio_url;
              if (line.startsWith("P ")) audio = curerntModuleWordsMap.P?.audio_url;
              if (line.startsWith("Q ")) audio = curerntModuleWordsMap.Q?.audio_url;
              if (line.startsWith("R ")) audio = curerntModuleWordsMap.R?.audio_url;
              if (line.startsWith("S ")) audio = curerntModuleWordsMap.S?.audio_url;
              if (line.startsWith("T ")) audio = curerntModuleWordsMap.T?.audio_url;
              if (line.startsWith("U ")) audio = curerntModuleWordsMap.U?.audio_url;
              if (line.startsWith("V ")) audio = curerntModuleWordsMap.V?.audio_url;
              if (line.startsWith("W ")) audio = curerntModuleWordsMap.W?.audio_url;
              if (line.startsWith("X ")) audio = curerntModuleWordsMap.X?.audio_url;
              if (line.startsWith("Y ")) audio = curerntModuleWordsMap.Y?.audio_url;
              if (line.startsWith("Z ")) audio = curerntModuleWordsMap.Z?.audio_url;
            }

            return (
              <HStack key={index} sx={{ flexWrap: "nowrap" }}>
                {audio && (
                  <Typography component="span">
                    <WordAudio url={audio} small />
                  </Typography>
                )}
                <HoverDetails
                  // isCenter
                  words={
                    line.split(" ").map((w: any, index: number) => {
                      const cleanWord = removePunctuationFromString(w).replace("__", " ");

                      const word =
                        curerntModuleWordsMap[cleanWord] ||
                        curerntModuleWordsMap[cleanWord.toLowerCase()];
                      // console.log("🚀 ~ cleanWord:", w, cleanWord, word);

                      return {
                        ...(word ? word : {}),
                        word: word?.name || cleanWord,
                        label: w.replace("__", " "),
                      };
                    }) as any
                  }
                />
              </HStack>
            );
          })}
          {isAdmin(authUser?.id) && (
            <Box py={3}>
              <AiQuestionAnswer chapterId={story.id.toString()} chapterText={story.verse} />
            </Box>
          )}
          <Box mb={10}>
            {!story.is_external && (
              <Link href={story.link} target="_blank">
                {story.link.startsWith("https://www.eslfast.com")
                  ? "прослухати кращий запис"
                  : "повне відео"}
              </Link>
            )}
            {story.is_external && (
              <Link href={story.link} target="_blank">
                подивитися відео
              </Link>
            )}
          </Box>
        </Box>
      </VStack>
    </VStack>
  );
};

const Stories: React.FC<{
  stories: EnglishStory[];
  buttonLabel?: string;
  isReadingVideo?: boolean;
}> = ({ stories, isReadingVideo, buttonLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { lessonId, lessonType } = useParams();
  const lessonData = useLessonsStore((state) => state.lessonData);

  const handleDrawerClose = () => {
    setIsOpen(false);
    // if (onDrawerClose) onDrawerClose();
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        {(lessonId && lessonData?.[lessonId]?.module.is_video) || isReadingVideo
          ? buttonLabel || "Відео"
          : buttonLabel || "Історія"}
      </Button>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9999999,
          // opacity: 0,
          // backgroundColor: "rgba(0, 0, 0, 1) !important",
        }}
      >
        <DrawerContent stories={stories} onClose={handleDrawerClose} />
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
  // backgroundColor: "rgba(0, 0, 0, 0) !important",
};

const DrawerContent: React.FC<{
  stories: EnglishStory[];
  onClose: () => void;
}> = ({ stories, onClose }) => {
  const drawer = useMemo(() => {
    return (
      <Box sx={drawerContentStyle}>
        <ModalCloseButton onClose={onClose} />
        <VStack gap={10}>
          {stories.map((story, index) => (
            <Box mb={3} key={index}>
              {" "}
              <Story key={story.id} story={story} />
            </Box>
          ))}
        </VStack>
      </Box>
    );
  }, [onClose]);

  return drawer;
};

export default Stories;
