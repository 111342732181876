import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Checkbox, Grid, TextField } from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useFirebaseApp } from "reactfire";
import supabaseClient from "../../common/supabaseClient";
import WordAudio from "../Audio/WordAudio";
import MenuLayout from "../Layout/MenuLayout";

const AdminAddBanglaSentence: React.FC = () => {
  const [pattern, setPattern] = useState("");
  const [audio, setAudio] = useState("");
  const [script, setScript] = useState("");
  const [transliteration, setTransliteration] = useState("");
  const [translation, setTranslation] = useState("");
  const [explanation, setExplanation] = useState("");
  const [isFormal, setIsFormal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");
  const textToSpeechFunction = httpsCallable(
    functions,
    "recordBengaliTextToSpeechAndReturnStorageUrlFunction",
  );

  const createAudio = async (sentence: string, voice?: string) => {
    try {
      const result: any = await textToSpeechFunction({
        bn: sentence,
        voiceName: "bn-IN-Standard-D",
      });
      return result?.data?.url;
    } catch (err) {
      console.log("🚀 try/catch create audio error:", err);
    }
  };

  return (
    <MenuLayout>
      <Box sx={{ backgroundColor: "white" }} p={5}>
        <Grid container>
          <Grid item xs={12} mb={10}>
            <Box display="flex" gap={3}>
              <CopyToClipboard
                text={`
              I learn Bengali language.
              How to translate: "${pattern}" to Bengali language.
              
              Does the translation depend on the person (he, she, we...) and a count of people? If so, give me the full sentence for each person.
              All combine the same sentences (for example: for he and she)
              Will the translation sound different in formal and informal situations? Give such examples.

              Please follow my example pattern for each example you will provide:
              [English sentence] : [Bengali script] : [Transliteration / romanized version with diacritic marks if they are needed] : [formality]

              Ps. each example must be without brackets ([]) and separated with " : ". All example must be showd as a list of strings.
              `}
                onCopy={() => {}}
              >
                <Button variant="outlined">Copy</Button>
              </CopyToClipboard>
              <Box minWidth={500}>
                <TextField
                  fullWidth
                  color="primary"
                  value={pattern}
                  multiline={true}
                  placeholder="Pattern"
                  onChange={(event) => {
                    setPattern(event.target.value);
                  }}
                  type="text"
                  variant="outlined"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              color="primary"
              value={script}
              multiline={true}
              placeholder="Script"
              onChange={(event) => {
                setScript(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              color="primary"
              value={transliteration}
              multiline={true}
              placeholder="Transliteration"
              onChange={(event) => {
                setTransliteration(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              color="primary"
              value={translation}
              multiline={true}
              placeholder="Translation"
              onChange={(event) => {
                setTranslation(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              color="primary"
              value={explanation}
              multiline={true}
              placeholder="Explanation"
              onChange={(event) => {
                setExplanation(event.target.value);
              }}
              type="text"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checked={isFormal}
              onChange={(value) => {
                setIsFormal(value.target.checked);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              onClick={async () => {
                setIsLoading(true);

                const audio = await createAudio(script);

                console.log("🚀 ~ audio:", audio);

                if (audio) setAudio(audio as any);

                setIsLoading(false);
              }}
            >
              Record Audio
            </LoadingButton>
          </Grid>
          {audio && (
            <Grid item xs={12}>
              <WordAudio url={audio} autoPlay={true} />
            </Grid>
          )}
          <Grid item xs={12}>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              onClick={async () => {
                setIsLoading(true);

                const newSentence = {
                  script,
                  transliteration,
                  translation,
                  explanation,
                  is_formal: isFormal,
                  repeat: new Date().toISOString(),
                  audio_url: audio,
                };

                await supabaseClient.from("bangla-sentences").insert(newSentence).throwOnError();

                setScript("");
                setTranslation("");
                setTransliteration("");
                setExplanation("");
                setIsFormal(false);
                setIsLoading(false);
              }}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
    </MenuLayout>
  );
};

export default AdminAddBanglaSentence;
