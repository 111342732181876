import { Typography } from "@mui/material";
import VStack from "../components/Unknown/VStack";

const greetings = [
  "Супер!",
  "Фантастично!",
  "Браво!",
  "Неймовірно!",
  "Ти молодець!",
  "Прекрасна робота!",
  "Чудово!",
  "Вітаємо!",
  "Так тримати!",
  "Класно!",
  "Вражаєш!",
  "Неймовірна робота!",
  "Ти справжня зірка!",
  "Здорово!",
  "Вражаюче!",
  "Велика робота!",
  "Відмінно!",
  "Високий рівень!",
  "Чудова робота!",
  "Аплодисменти!",
  "Вражаюча робота!",
  "Браво, молодець!",
  "Ти вражаєш!",
  "Феноменально!",
  "Продовжуй в тому ж дусі!",
  "Ти робиш дива!",
  "Браво, мій друже!",
  "Вражаюча робота!",
  "Ти мовний майстер!",
];

const getRandomGreetings = (additionalText?: string): any => {
  const random = greetings[Math.floor(Math.random() * greetings.length)];

  if (additionalText) {
    return (
      <VStack mb={4}>
        {/* {random} */}
        <Typography color="primary">{random}</Typography>
        <Typography>{additionalText}</Typography>
      </VStack>
    );
  }

  return random;
};

export default getRandomGreetings;
