import LoadingButton from "@mui/lab/LoadingButton";
import React, { memo, useState } from "react";
import supabaseClient from "../../common/supabaseClient";
import Drawer from "../Unknown/Drawer";
import ModalCloseButton from "../Unknown/ModalCloseButton";
import Box from "../Unknown/Box";
import getSentenceWords from "../../common/getSentenceWords";
import { Typography } from "@mui/material";
import HStack from "../Unknown/HStack";

// play, yes, thank, no, sorry, alright

const GrammarSentences: React.FC = () => {
  const [grammarSentence, setGrammarSetnences] = useState<any[]>([]);
  const [words, setWords] = useState<any[]>([]);
  // console.log("🚀 ~ grammarSentence:", grammarSentence);
  const [isOpen, setIsOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <LoadingButton
        loading={false}
        variant="contained"
        color="primary"
        size="small"
        onClick={async () => {
          if (grammarSentence.length) {
            setIsOpen(true);
            return;
          }

          const { data: userSentences } = await supabaseClient
            .from("grammar_course_sentences")
            .select()
            .order("module");
          console.log("🚀 ~ userSentences:", userSentences);

          if (!userSentences) return;

          const rowUserSentences = userSentences.map((s) => s.sentence);
          setGrammarSetnences(userSentences);

          const words: any = Object.entries(
            userSentences!.reduce<Record<string, string[]>>((acc, curr) => {
              const sentenceWords = getSentenceWords(curr.sentence);
              return {
                ...acc,
                [curr.module]: acc[curr.module]
                  ? [...acc[curr.module], sentenceWords]
                  : sentenceWords,
              };
            }, {}),
          )
            .sort((a: any, b: any) => a[0] - b[0])
            .map(([module, words]) => {
              const flatWords = (words as any).flat();
              const uniqeWords = Array.from(new Set(flatWords));
              return [module, uniqeWords];
            });

          setWords(words);

          setIsOpen(true);
        }}
      >
        All course sentences/words
      </LoadingButton>
      <Drawer
        open={isOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9999999,
        }}
      >
        <ModalCloseButton onClose={handleDrawerClose} />
        <Box p={10}>
          {words.map(([module, words], index) => {
            return (
              <Box key={index}>
                <Box>Module: {module}</Box>
                <HStack py={2}>
                  {words.map((w: string) => (
                    <Typography variant="caption">{w}</Typography>
                  ))}
                </HStack>
              </Box>
            );
          })}
        </Box>
        <Box p={10}>
          {grammarSentence.map((s, index) => {
            return (
              <Box key={index}>
                {index + 1}. ({s.module}) {s.sentence}
              </Box>
            );
          })}
        </Box>
      </Drawer>
    </>
  );
};

export default memo(GrammarSentences);
