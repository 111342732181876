import { TrainersConfig } from "../constants";

const getButtonLabel = (trainer: TrainersConfig) => {
  if (trainer === TrainersConfig.UA_SORT_VOWELS_AND_CONSONANTS) {
    return "🇺🇦 Відсортуй голосні та приголосні";
  } else if (trainer === TrainersConfig.EN_SORT_VOWELS_AND_CONSONANTS) {
    return "🇺🇸 Відсортуй голосні та приголосні";
  }
};

export default getButtonLabel;
